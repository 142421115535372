// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".PhotoHandler-Photo-module--cls1--r0VeD { /*!*/ }\n\n.PhotoHandler-Photo-module--cls2--cLkZr {\n  background-color: var(--color-bg-box);\n  border-radius: var(--size-radius);\n  width: 320px;\n  margin-top: 32px;\n  position: relative;\n}\n.PhotoHandler-Photo-module--cls2--cLkZr.PhotoHandler-Photo-module--border--iAoTR {\n  box-shadow: 0 0 0 2px var(--color-base);\n}", "",{"version":3,"sources":["webpack://./src/views/page/generator/sections/components/PhotoHandler-Photo.module.scss"],"names":[],"mappings":"AAEA,0CAAA,IAAA,EAAA;;AACA;EAGA,qCAAA;EACA,iCAAA;EACA,YAAA;EACA,gBAAA;EACA,kBAAA;AADA;AAGA;EACA,uCAAA;AADA","sourcesContent":["@import '../../../../../assets/styles/mixins.scss';\n\n.cls1 { /*!*/ }\n.cls2 {\ncomposes: cls1;\n\nbackground-color: var(--color-bg-box);\nborder-radius: var(--size-radius);\nwidth: 320px;\nmargin-top: 32px;\nposition: relative;\n\n&.border {\nbox-shadow: 0 0 0 2px var(--color-base);\n}\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"cls1": "PhotoHandler-Photo-module--cls1--r0VeD",
	"cls2": "PhotoHandler-Photo-module--cls2--cLkZr PhotoHandler-Photo-module--cls1--r0VeD",
	"border": "PhotoHandler-Photo-module--border--iAoTR"
};
export default ___CSS_LOADER_EXPORT___;
