// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".PrivatePageLayout-Bg-module--cls1--sRxaV { /*!*/ }\n\n.PrivatePageLayout-Bg-module--cls2--e3LjZ {\n  position: fixed;\n  top: 0;\n  right: 0;\n  height: var(--app-height);\n  width: 100%;\n  object-fit: cover;\n  z-index: -1;\n}", "",{"version":3,"sources":["webpack://./src/views/components/layouts/PrivatePageLayout/PrivatePageLayout-Bg.module.scss"],"names":[],"mappings":"AAAA,4CAAA,IAAA,EAAA;;AACA;EAGA,eAAA;EACA,MAAA;EACA,QAAA;EACA,yBAAA;EACA,WAAA;EACA,iBAAA;EACA,WAAA;AACA","sourcesContent":[".cls1 { /*!*/ }\n.cls2 {\ncomposes: cls1;\n\nposition: fixed;\ntop: 0;\nright: 0;\nheight: var(--app-height);\nwidth: 100%;\nobject-fit: cover;\nz-index: -1;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"cls1": "PrivatePageLayout-Bg-module--cls1--sRxaV",
	"cls2": "PrivatePageLayout-Bg-module--cls2--e3LjZ PrivatePageLayout-Bg-module--cls1--sRxaV"
};
export default ___CSS_LOADER_EXPORT___;
