import React, { FC, PropsWithChildren } from 'react'
import styled from 'astroturf/react'
import { ErrorOption } from 'react-hook-form'

export interface FieldWrap {
  wSize?: 'full' | 'auto'
  error?: { [key: string]: ErrorOption }
  name: string
}

export const FieldWrap: FC<PropsWithChildren<FieldWrap>> = ({ children, wSize = 'auto', error, name }) => {
  const isError = error && error[name]
  return (
    <Core wSize={wSize}>
      {children}
      {isError && (
        <Error>
          <p>{error[name].message}</p>
        </Error>
      )}
    </Core>
  )
}

interface CoreProps {
  isOffset?: boolean
  wSize?: 'full' | 'auto'
}

const Core = styled.div<CoreProps>`
  display: block;
  margin-bottom: 12px;
  text-align: left;

  &.wSize-full {
    width: 100%;
  }

  &.wSize-auto {
    width: auto;
  }
`

const Error = styled.div`
  color: var(--color-error);
  font-size: 12px;
  font-weight: 500;
  padding-top: 8px;
  // height: 0;
`
