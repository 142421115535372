// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".SharingAvatar-Preview-module--cls1--URLXN { /*!*/ }\n\n.SharingAvatar-Preview-module--cls2--rEe6p {\n  position: relative;\n}", "",{"version":3,"sources":["webpack://./src/views/components/common/SharingAvatar-Preview.module.scss"],"names":[],"mappings":"AAAA,6CAAA,IAAA,EAAA;;AACA;EAGA,kBAAA;AACA","sourcesContent":[".cls1 { /*!*/ }\n.cls2 {\ncomposes: cls1;\n\nposition: relative;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"cls1": "SharingAvatar-Preview-module--cls1--URLXN",
	"cls2": "SharingAvatar-Preview-module--cls2--rEe6p SharingAvatar-Preview-module--cls1--URLXN"
};
export default ___CSS_LOADER_EXPORT___;
