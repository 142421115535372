// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".PhotoHandler-ErrorMSG-module--cls1--Sa03C { /*!*/ }\n\n.PhotoHandler-ErrorMSG-module--cls2--Qqhq1 {\n  color: var(--color-error);\n  margin-top: 12px;\n  font-size: 14px;\n  font-weight: 500;\n  text-align: center;\n}", "",{"version":3,"sources":["webpack://./src/views/page/generator/sections/components/PhotoHandler-ErrorMSG.module.scss"],"names":[],"mappings":"AAAA,6CAAA,IAAA,EAAA;;AACA;EAGA,yBAAA;EACA,gBAAA;EACA,eAAA;EACA,gBAAA;EACA,kBAAA;AACA","sourcesContent":[".cls1 { /*!*/ }\n.cls2 {\ncomposes: cls1;\n\ncolor: var(--color-error);\nmargin-top: 12px;\nfont-size: 14px;\nfont-weight: 500;\ntext-align: center;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"cls1": "PhotoHandler-ErrorMSG-module--cls1--Sa03C",
	"cls2": "PhotoHandler-ErrorMSG-module--cls2--Qqhq1 PhotoHandler-ErrorMSG-module--cls1--Sa03C"
};
export default ___CSS_LOADER_EXPORT___;
