import React, { FC } from 'react'
import { useLayoutContext } from '@/views/components/core/LayoutProvider'
import logo from '@/assets/img/logo.svg'
import logoW from '@/assets/img/logo-w.svg'

export interface CoverProps {
  width: number
  height: number
  className?: string
}

const logoMap = new Map([
  ['dark', logo],
  ['light', logoW],
])

export const Logo: FC<CoverProps> = ({ width, height, className }) => {
  const { theme } = useLayoutContext()
  return <img className={className} src={logoMap.get(theme)} alt='Logotype' width={width} height={height} />
}
