import React from 'react'
import styled from 'astroturf/react'
import { Facebook, Twitter, Google } from '@/components/ui/icons'

export const Social = () => {
  return (
    <>
      {/* <Separator>
        <SeparatorText>OR USE SOCIAL NETWORKS</SeparatorText>
      </Separator>
      <Actions>
        <Btn variant='fb'>
          <Facebook />
        </Btn>
        <Btn variant='tw'>
          <Twitter />
        </Btn>
        <Btn variant='gg'>
          <Google />
        </Btn>
      </Actions> */}
    </>
  )
}

const Separator = styled.div`
  position: relative;
  overflow: hidden;
  text-align: center;
`

const SeparatorText = styled.span`
  position: relative;
  padding-left: 28px;
  padding-right: 28px;
  color: var(--color-text-gray);
  font-size: 13px;
  font-weight: 500;

  &:before,
  &:after {
    content: '';
    position: absolute;
    background-color: var(--color-base);
    height: 1px;
    width: 100%;
    top: 50%;
    opacity: 0.2;
  }

  &:before {
    left: 100%;
  }

  &:after {
    right: 100%;
  }
`

const Actions = styled.div`
  display: flex;
  gap: 8px;
  margin-top: 28px;
`

export interface BtnProps {
  variant: 'fb' | 'tw' | 'gg'
}

const Btn = styled.button<BtnProps>`
  @import '../../../assets/styles/mixins.scss';
  @include anim(fill, background-color);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: var(--size-radius);
  background-color: var(--color-bg-filed);
  height: var(--size-field-height);
  width: 100%;
  fill: var(--color-base);

  &:hover {
    fill: #fff;
    &.variant-fb {
      background-color: #2353ff;
    }
    &.variant-tw {
      background-color: #23caff;
    }
    &.variant-gg {
      background-color: #ff2323;
    }
  }
`
