// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Empty-Action-module--cls1--FwRWK { /*!*/ }\n\n.Empty-Action-module--cls2--hAncH {\n  margin-top: 46px;\n}", "",{"version":3,"sources":["webpack://./src/views/page/gallery/components/Empty-Action.module.scss"],"names":[],"mappings":"AAAA,oCAAA,IAAA,EAAA;;AACA;EAGA,gBAAA;AACA","sourcesContent":[".cls1 { /*!*/ }\n.cls2 {\ncomposes: cls1;\n\nmargin-top: 46px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"cls1": "Empty-Action-module--cls1--FwRWK",
	"cls2": "Empty-Action-module--cls2--hAncH Empty-Action-module--cls1--FwRWK"
};
export default ___CSS_LOADER_EXPORT___;
