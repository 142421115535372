// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Generator-Core-module--cls1--L4dma { /*!*/ }\n\n.Generator-Core-module--cls2--G227w {\n  min-height: var(--app-height);\n}", "",{"version":3,"sources":["webpack://./src/views/page/generator/Generator-Core.module.scss"],"names":[],"mappings":"AAAA,sCAAA,IAAA,EAAA;;AACA;EAGA,6BAAA;AACA","sourcesContent":[".cls1 { /*!*/ }\n.cls2 {\ncomposes: cls1;\n\nmin-height: var(--app-height);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"cls1": "Generator-Core-module--cls1--L4dma",
	"cls2": "Generator-Core-module--cls2--G227w Generator-Core-module--cls1--L4dma"
};
export default ___CSS_LOADER_EXPORT___;
