// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Social-SeparatorText-module--cls1--f9o4O { /*!*/ }\n\n.Social-SeparatorText-module--cls2--H8fvN {\n  position: relative;\n  padding-left: 28px;\n  padding-right: 28px;\n  color: var(--color-text-gray);\n  font-size: 13px;\n  font-weight: 500;\n}\n.Social-SeparatorText-module--cls2--H8fvN:before, .Social-SeparatorText-module--cls2--H8fvN:after {\n  content: \"\";\n  position: absolute;\n  background-color: var(--color-base);\n  height: 1px;\n  width: 100%;\n  top: 50%;\n  opacity: 0.2;\n}\n.Social-SeparatorText-module--cls2--H8fvN:before {\n  left: 100%;\n}\n.Social-SeparatorText-module--cls2--H8fvN:after {\n  right: 100%;\n}", "",{"version":3,"sources":["webpack://./src/views/components/common/Social-SeparatorText.module.scss"],"names":[],"mappings":"AAAA,4CAAA,IAAA,EAAA;;AACA;EAGA,kBAAA;EACA,kBAAA;EACA,mBAAA;EACA,6BAAA;EACA,eAAA;EACA,gBAAA;AACA;AACA;EAEA,WAAA;EACA,kBAAA;EACA,mCAAA;EACA,WAAA;EACA,WAAA;EACA,QAAA;EACA,YAAA;AAAA;AAGA;EACA,UAAA;AADA;AAIA;EACA,WAAA;AAFA","sourcesContent":[".cls1 { /*!*/ }\n.cls2 {\ncomposes: cls1;\n\nposition: relative;\npadding-left: 28px;\npadding-right: 28px;\ncolor: var(--color-text-gray);\nfont-size: 13px;\nfont-weight: 500;\n\n&:before,\n&:after {\ncontent: '';\nposition: absolute;\nbackground-color: var(--color-base);\nheight: 1px;\nwidth: 100%;\ntop: 50%;\nopacity: 0.2;\n}\n\n&:before {\nleft: 100%;\n}\n\n&:after {\nright: 100%;\n}\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"cls1": "Social-SeparatorText-module--cls1--f9o4O",
	"cls2": "Social-SeparatorText-module--cls2--H8fvN Social-SeparatorText-module--cls1--f9o4O"
};
export default ___CSS_LOADER_EXPORT___;
