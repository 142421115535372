import React, { FC } from 'react'
import { Outlet } from 'react-router-dom'
import { Container } from '@/components/wrapper'

export const AuthLayout: FC = () => {
  return (
    <Container variant='center'>
      <Outlet />
    </Container>
  )
}
