// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Photo-Field-module--cls1--VmDdn { /*!*/ }\n\n.Photo-Field-module--cls2--GxaeK {\n  position: absolute;\n  opacity: 0;\n  left: -99999999px;\n}", "",{"version":3,"sources":["webpack://./src/views/page/generator/sections/Photo-Field.module.scss"],"names":[],"mappings":"AAAA,mCAAA,IAAA,EAAA;;AACA;EAGA,kBAAA;EACA,UAAA;EACA,iBAAA;AACA","sourcesContent":[".cls1 { /*!*/ }\n.cls2 {\ncomposes: cls1;\n\nposition: absolute;\nopacity: 0;\nleft: -99999999px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"cls1": "Photo-Field-module--cls1--VmDdn",
	"cls2": "Photo-Field-module--cls2--GxaeK Photo-Field-module--cls1--VmDdn"
};
export default ___CSS_LOADER_EXPORT___;
