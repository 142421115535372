// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ProgressBar-ProgressContainer-module--cls1--Mh3UN { /*!*/ }\n\n.ProgressBar-ProgressContainer-module--cls2--cwSM6 {\n  width: 100%;\n  height: 20px;\n  background-color: #f2f2f2;\n  border-radius: 10px;\n  overflow: hidden;\n  margin-bottom: 12px;\n}", "",{"version":3,"sources":["webpack://./src/components/ui/ProgressBar-ProgressContainer.module.scss"],"names":[],"mappings":"AAAA,qDAAA,IAAA,EAAA;;AACA;EAGA,WAAA;EACA,YAAA;EACA,yBAAA;EACA,mBAAA;EACA,gBAAA;EACA,mBAAA;AACA","sourcesContent":[".cls1 { /*!*/ }\n.cls2 {\ncomposes: cls1;\n\nwidth: 100%;\nheight: 20px;\nbackground-color: #f2f2f2;\nborder-radius: 10px;\noverflow: hidden;\nmargin-bottom: 12px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"cls1": "ProgressBar-ProgressContainer-module--cls1--Mh3UN",
	"cls2": "ProgressBar-ProgressContainer-module--cls2--cwSM6 ProgressBar-ProgressContainer-module--cls1--Mh3UN"
};
export default ___CSS_LOADER_EXPORT___;
