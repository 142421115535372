import React, { FC, forwardRef } from 'react'
import styled from 'astroturf/react'
import { ErrorOption, ChangeHandler } from 'react-hook-form'

import { FieldWrap } from './FieldWrap'

export interface TextfieldProps {
  name: string
  value?: string
  placeholder?: string
  disabled?: boolean
  onChange: ChangeHandler
  onBlur: ChangeHandler
  wSize?: 'full' | 'auto'
  error?: { [key: string]: ErrorOption }
}

export const Textfield: FC<TextfieldProps> = forwardRef(
  ({ name, value, placeholder, disabled, onChange, onBlur, wSize, error }, ref) => {
    const isError = error && error[name]
    return (
      <FieldWrap wSize={wSize} error={error} name={name}>
        <Input
          ref={ref}
          type='text'
          name={name}
          disabled={disabled}
          placeholder={placeholder}
          value={value}
          onChange={onChange}
          onBlur={onBlur}
          error={isError}
        />
      </FieldWrap>
    )
  },
)

const Input = styled.input`
  @import '../../assets/styles/mixins.scss';
  @include anim(background-color, border-color);
  width: 100%;
  height: var(--size-field-height);
  padding-left: var(--size-field-padding);
  padding-right: var(--size-field-padding);
  background-color: var(--color-bg-filed);
  font-weight: 700;
  font-size: 14px;
  line-height: 150%;
  border-radius: 16px;
  border-width: 0;
  color: var(--color-base);
  padding-bottom: 3px;
  text-align: left;

  &:placeholder {
    font-weight: 500;
  }

  &:hover {
    background-color: var(--color-bg-filed-hover);
  }

  &.error {
    border-color: var(--color-error);
  }
`
