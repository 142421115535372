// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Empty-Core-module--cls1--EINJm { /*!*/ }\n\n.Empty-Core-module--cls2--dfpKx {\n  position: relative;\n  display: flex;\n  justify-content: space-between;\n  padding-top: 12px;\n  max-height: calc(var(--app-height) - var(--content-base-offset));\n  overflow: hidden;\n}", "",{"version":3,"sources":["webpack://./src/views/page/gallery/components/Empty-Core.module.scss"],"names":[],"mappings":"AAAA,kCAAA,IAAA,EAAA;;AACA;EAGA,kBAAA;EACA,aAAA;EACA,8BAAA;EACA,iBAAA;EACA,gEAAA;EACA,gBAAA;AACA","sourcesContent":[".cls1 { /*!*/ }\n.cls2 {\ncomposes: cls1;\n\nposition: relative;\ndisplay: flex;\njustify-content: space-between;\npadding-top: 12px;\nmax-height: calc(var(--app-height) - var(--content-base-offset));\noverflow: hidden;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"cls1": "Empty-Core-module--cls1--EINJm",
	"cls2": "Empty-Core-module--cls2--dfpKx Empty-Core-module--cls1--EINJm"
};
export default ___CSS_LOADER_EXPORT___;
