// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Article-Separator-module--cls1--cznwA { /*!*/ }\n\n.Article-Separator-module--cls2--DnUiT {\n  width: 100%;\n  height: 1px;\n  background-color: var(--color-base);\n  opacity: 0.15;\n  margin-top: 32px;\n  margin-bottom: 32px;\n}", "",{"version":3,"sources":["webpack://./src/views/page/apps/Article-Separator.module.scss"],"names":[],"mappings":"AAAA,yCAAA,IAAA,EAAA;;AACA;EAGA,WAAA;EACA,WAAA;EACA,mCAAA;EACA,aAAA;EACA,gBAAA;EACA,mBAAA;AACA","sourcesContent":[".cls1 { /*!*/ }\n.cls2 {\ncomposes: cls1;\n\nwidth: 100%;\nheight: 1px;\nbackground-color: var(--color-base);\nopacity: 0.15;\nmargin-top: 32px;\nmargin-bottom: 32px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"cls1": "Article-Separator-module--cls1--cznwA",
	"cls2": "Article-Separator-module--cls2--DnUiT Article-Separator-module--cls1--cznwA"
};
export default ___CSS_LOADER_EXPORT___;
