export { ReactComponent as Logo } from '@/assets/icons/logo.svg'
export { ReactComponent as LogoPic } from '@/assets/icons/logo-pic.svg'
export { ReactComponent as Twitter } from '@/assets/icons/tw.svg'
export { ReactComponent as Facebook } from '@/assets/icons/fb.svg'
export { ReactComponent as Google } from '@/assets/icons/gg.svg'
export { ReactComponent as GalleryIcon } from '@/assets/icons/gallery.svg'
export { ReactComponent as AppIcon } from '@/assets/icons/app.svg'
export { ReactComponent as SupportIcon } from '@/assets/icons/support.svg'
export { ReactComponent as Logout } from '@/assets/icons/logout.svg'
export { ReactComponent as Star } from '@/assets/icons/star.svg'
export { ReactComponent as StarFill } from '@/assets/icons/star-fill.svg'
export { ReactComponent as KebabMenu } from '@/assets/icons/kebab-menu.svg'
export { ReactComponent as Plus } from '@/assets/icons/plus.svg'
export { ReactComponent as Play } from '@/assets/icons/play.svg'
export { ReactComponent as Close } from '@/assets/icons/close.svg'
export { ReactComponent as ChevronLeft } from '@/assets/icons/chevron-left.svg'
export { ReactComponent as Spinner } from '@/assets/icons/spinner.svg'
export { ReactComponent as Home } from '@/assets/icons/home.svg'
export { ReactComponent as Profile } from '@/assets/icons/profile.svg'
export { ReactComponent as ArrowLeft } from '@/assets/icons/arr-left.svg'
export { ReactComponent as ArrowRight } from '@/assets/icons/arr-right.svg'
export { ReactComponent as Smile } from '@/assets/icons/smile.svg'
export { ReactComponent as CheckCircle } from '@/assets/icons/check-circle.svg'
export { ReactComponent as CheckCircleGradient } from '@/assets/icons/check-circle-gradient.svg'
export { ReactComponent as Color } from '@/assets/icons/editor/color.svg'
export { ReactComponent as Face } from '@/assets/icons/editor/face-male.svg'
export { ReactComponent as Skin } from '@/assets/icons/editor/skin-color.svg'
export { ReactComponent as Body } from '@/assets/icons/editor/body.svg'
export { ReactComponent as Hairs } from '@/assets/icons/editor/hairs-male.svg'
export { ReactComponent as Man } from '@/assets/icons/editor/man.svg'
export { ReactComponent as Glasses } from '@/assets/icons/editor/glasses.svg'
export { ReactComponent as Shirt } from '@/assets/icons/editor/shirt.svg'
export { ReactComponent as Animation } from '@/assets/icons/editor/animation.svg'
export { ReactComponent as Minus } from '@/assets/icons/editor/minus.svg'
export { ReactComponent as Reset } from '@/assets/icons/editor/reset.svg'
export { ReactComponent as ColorG } from '@/assets/icons/editor/color-g.svg'
export { ReactComponent as FaceG } from '@/assets/icons/editor/face-male-g.svg'
export { ReactComponent as SkinG } from '@/assets/icons/editor/skin-color-g.svg'
export { ReactComponent as BodyG } from '@/assets/icons/editor/body-g.svg'
export { ReactComponent as HairsG } from '@/assets/icons/editor/hairs-male-g.svg'
export { ReactComponent as ManG } from '@/assets/icons/editor/man-g.svg'
export { ReactComponent as GlassesG } from '@/assets/icons/editor/glasses-g.svg'
export { ReactComponent as ShirtG } from '@/assets/icons/editor/shirt-g.svg'
export { ReactComponent as AnimationG } from '@/assets/icons/editor/animation-g.svg'
