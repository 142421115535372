// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Cover-Core-module--cls1--pkQCt { /*!*/ }\n\n.Cover-Core-module--cls2--pjq0V {\n  width: 100%;\n  display: block;\n}", "",{"version":3,"sources":["webpack://./src/components/ui/Cover-Core.module.scss"],"names":[],"mappings":"AAAA,kCAAA,IAAA,EAAA;;AACA;EAGA,WAAA;EACA,cAAA;AACA","sourcesContent":[".cls1 { /*!*/ }\n.cls2 {\ncomposes: cls1;\n\nwidth: 100%;\ndisplay: block;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"cls1": "Cover-Core-module--cls1--pkQCt",
	"cls2": "Cover-Core-module--cls2--pjq0V Cover-Core-module--cls1--pkQCt"
};
export default ___CSS_LOADER_EXPORT___;
