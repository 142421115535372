import { FC } from 'react'
import { SVGIcon } from '@/helpers/types'
import { Age, Gender, Type, Photo, NoPhoto, Choice, Editor, Result } from './sections'
import {
  Color,
  Face,
  Skin,
  Body,
  Hairs,
  Glasses,
  Shirt,
  Animation,
  Man, 
  ColorG,
  FaceG,
  SkinG,
  BodyG,
  HairsG,
  GlassesG,
  ShirtG,
  AnimationG, 
  ManG,
} from '@/components/ui/icons'
import {
  EditorHairColor,
  EditorSkinColor,
  EditorBody,
  EditorFace,
  EditorGlasses,
  EditorAnimation,
  EditorHair,
  EditorShirt,
  EditorAge,
} from './sections/Editor/components'

export interface Setting {
  sections: {
    gender: {}
    type: {}
    photo: { withoutNoPhoto?: boolean }
    no_photo: {}
    choice: {}
    editor: {
      skin_color?: boolean
      haircut_color?: boolean
      face?: boolean
      body?: boolean
      hair?: boolean
      glasses?: boolean
      shirt?: boolean
      agetypes?: boolean
      animation?: boolean
    }
    result: {}
  }
  availableSections: string[]
  texts: {
    general: {
      company_name: string
      save: string
      waiting_for_generation: string
      progress: string
      waiting_for_loading: string
      progress2: string
      progress3: string
    }
    sections: {
      age: {
        page_title: string
        title: string
        descr: string
        actions: {
          Adult: string
          Teen_15: string
          Teen_12: string
        }
      },
      gender: {
        page_title: string
        title: string
        descr: string
        actions: {
          male: string
          female: string
          without_specify: string
        }
      }
      type: {
        page_title: string
        title: string
        descr: string
        actions: {
          head: string
          bust: string
          body: string
        }
      }
      photo: {
        page_title: string
        title: string
        descr: {
          desktop: string
          mobile: string
          scanQR: string
          readyToDownload: string
          after_scan_qr: string
        }
        text_loader: string
        success: string
        actions: {
          new_photo: string
          existing_photo: string
          without_your_photo: string
          accept_and_continue: string
          again: string
          cancel_qr_mode: string
          new_selfie: string
          load_from_disk: string
        }
      }
      no_photo: {
        page_title: string
        title: string
        descr: string
      }
      choice: {
        page_title: string
        title: string
        descr: string
        actions: {
          edit: string
          save: string
        }
      }
      editor: {
        page_title: string
        title: string
        descr: string
        breasts: string
        eyes: string
        nose: string
        glasses: string
        hair: string
        shirt: string
        skin: string
      }
      result: {
        page_title: string
        title: string
        descr: string
        actions: {
          share: string
          to_home: string
        }
      }
    }
  }
}

export interface Section {
  component: () => JSX.Element
  componentID: 'age' | 'gender' | 'type' | 'photo' | 'no_photo' | 'choice' | 'editor' | 'result'
  settings: {
    withStartNav?: boolean
    withPrevNav?: boolean
    withSaveAvatar?: boolean
  }
}

export interface Sections {
  [key: string]: Section
}

export const SECTIONS: Sections = {
  age: {
    component: Age,
    componentID: 'age',
    settings: {
      withStartNav: true,
      withPrevNav: true,
    },
  },
  gender: {
    component: Gender,
    componentID: 'gender',
    settings: {
      withStartNav: true,
      withPrevNav: true,
    },
  },
  type: {
    component: Type,
    componentID: 'type',
    settings: {
      withStartNav: true,
      withPrevNav: true,
    },
  },
  photo: {
    component: Photo,
    componentID: 'photo',
    settings: {
      withStartNav: true,
      withPrevNav: true,
    },
  },
  noPhoto: {
    component: NoPhoto,
    componentID: 'no_photo',
    settings: {
      withStartNav: true,
      withPrevNav: true,
    },
  },
  choice: {
    component: Choice,
    componentID: 'choice',
    settings: {
      withStartNav: true,
    },
  },
  editor: {
    component: Editor,
    componentID: 'editor',
    settings: {
      withStartNav: true,
      withSaveAvatar: true,
    },
  },
  result: {
    component: Result,
    componentID: 'result',
    settings: {},
  },
}

interface Icons {
  light: SVGIcon
  dark: SVGIcon
}
export const MENU_FULL: [
  {
    apiKey: 'haircut_color'
    icon: Icons
    component: FC
  },
  {
    apiKey: 'skin_color'
    icon: Icons
    component: FC
  },
  {
    apiKey: 'face'
    icon: Icons
    component: FC
  },
  {
    apiKey: 'body'
    icon: Icons
    component: FC
  },
  {
    apiKey: 'hair'
    icon: Icons
    component: FC
  },
  {
    apiKey: 'glasses'
    icon: Icons
    component: FC
  },
  {
    apiKey: 'animation'
    icon: Icons
    component: FC
  },
  {
    apiKey: 'agetypes'
    icon: Icons
    component: FC
  },
  {
    apiKey: 'shirt'
    icon: Icons
    component: FC
  },
] = [
  {
    apiKey: 'shirt',
    icon: {
      dark: Shirt,
      light: ShirtG,
    },
    component: EditorShirt,
  },
  {
    apiKey: 'body',
    icon: {
      dark: Body,
      light: BodyG,
    },
    component: EditorBody,
  },
  {
    apiKey: 'skin_color',
    icon: {
      dark: Skin,
      light: SkinG,
    },
    component: EditorSkinColor,
  },
  {
    apiKey: 'face',
    icon: {
      dark: Face,
      light: FaceG,
    },
    component: EditorFace,
  },
  {
    apiKey: 'hair',
    icon: {
      dark: Hairs,
      light: HairsG,
    },
    component: EditorHair,
  },
  {
    apiKey: 'haircut_color',
    icon: {
      dark: Color,
      light: ColorG,
    },
    component: EditorHairColor,
  },
  {
    apiKey: 'glasses',
    icon: {
      dark: Glasses,
      light: GlassesG,
    },
    component: EditorGlasses,
  },
  {
    apiKey: 'agetypes',
    icon: {
      dark: Man,   
      light: ManG,
    },
    component: EditorAge,
  },
  {
    apiKey: 'animation',
    icon: {
      dark: Animation,   
      light: AnimationG, 
    },
    component: EditorAnimation,
  },
]
