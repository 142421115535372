// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Modal-Body-module--cls1--yrVHy { /*!*/ }\n\n.Modal-Body-module--cls2--A9_7t {\n  background-color: var(--color-bg-dark);\n  border-radius: var(--size-radius);\n  padding: 40px;\n}", "",{"version":3,"sources":["webpack://./src/components/wrapper/Modal-Body.module.scss"],"names":[],"mappings":"AAAA,kCAAA,IAAA,EAAA;;AACA;EAGA,sCAAA;EACA,iCAAA;EACA,aAAA;AACA","sourcesContent":[".cls1 { /*!*/ }\n.cls2 {\ncomposes: cls1;\n\nbackground-color: var(--color-bg-dark);\nborder-radius: var(--size-radius);\npadding: 40px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"cls1": "Modal-Body-module--cls1--yrVHy",
	"cls2": "Modal-Body-module--cls2--A9_7t Modal-Body-module--cls1--yrVHy"
};
export default ___CSS_LOADER_EXPORT___;
