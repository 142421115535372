// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Developer-Form-module--cls1--dT6cn { /*!*/ }\n\n.Developer-Form-module--cls2--UG50n {\n  margin-top: 32px;\n  max-width: 360px;\n  margin-left: auto;\n  margin-right: auto;\n  width: 100%;\n}", "",{"version":3,"sources":["webpack://./src/views/page/auth/Developer-Form.module.scss"],"names":[],"mappings":"AAAA,sCAAA,IAAA,EAAA;;AACA;EAGA,gBAAA;EACA,gBAAA;EACA,iBAAA;EACA,kBAAA;EACA,WAAA;AACA","sourcesContent":[".cls1 { /*!*/ }\n.cls2 {\ncomposes: cls1;\n\nmargin-top: 32px;\nmax-width: 360px;\nmargin-left: auto;\nmargin-right: auto;\nwidth: 100%;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"cls1": "Developer-Form-module--cls1--dT6cn",
	"cls2": "Developer-Form-module--cls2--UG50n Developer-Form-module--cls1--dT6cn"
};
export default ___CSS_LOADER_EXPORT___;
