/**
 * myDelay() - busy loop delay for debug
 *
 * param ms     - delay, ms
 * param remark - message to console
 */

export function myDelay(ms, message = '') {
  console.log('myDelay()', message)

  const start = Date.now()
  while (Date.now() - start < ms) {}

  console.log('myDelay() finished')
}
