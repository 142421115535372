import React from 'react'
import styled from 'astroturf/react'
import { Container } from '@/components/wrapper'
import { Button } from '@/components/ui'
import { TitleSection } from '@/views/components/common'
import { storeGeneratorPipeline } from '@/services/store'
import { useRQ, getSetting } from '@/services/adapters/req'
import { Setting } from '../_helper'

export const Type = () => {
  const { setAndNext } = storeGeneratorPipeline
  const {
    data: {
      texts: {
        sections: { type: PAGE },
      },
    },
  } = useRQ<Setting>(getSetting)
  return (
    <Container variant='center'>
      <TitleSection title='Select avatar’s type' descr='Please specify the required type of an avatar' />
      <Form>
        <Action variant='surface' wSize='full' onClick={() => setAndNext('type', 'Body')}>
          {PAGE.actions.body}
        </Action>
        <Action variant='surface' wSize='full' onClick={() => setAndNext('type', 'Bust')}>
          {PAGE.actions.bust}
        </Action>
        <Action variant='surface' wSize='full' onClick={() => setAndNext('type', 'Head')}>
          {PAGE.actions.head}
        </Action>
      </Form>
    </Container>
  )
}

const Form = styled.div`
  margin-top: 32px;
  max-width: 360px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
`

const Action = styled(Button)`
  text-align: left;
  justify-content: flex-start;
  &:not(:last-child) {
    margin-bottom: 12px;
  }
`
