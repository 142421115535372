// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Generator-ResetButton-module--cls1--ZMrfm { /*!*/ }\n\n.Generator-ResetButton-module--cls2--vHL9y {\n  width: 48px !important;\n  background-color: var(--color-bg-box);\n  margin-right: 8px;\n  border-radius: var(--size-radius-large);\n  fill: var(--color-base);\n}\n.Generator-ResetButton-module--cls2--vHL9y svg {\n  fill: var(--color-base);\n}", "",{"version":3,"sources":["webpack://./src/views/page/generator/Generator-ResetButton.module.scss"],"names":[],"mappings":"AAAA,6CAAA,IAAA,EAAA;;AACA;EAGA,sBAAA;EACA,qCAAA;EACA,iBAAA;EACA,uCAAA;EACA,uBAAA;AACA;AACA;EACA,uBAAA;AACA","sourcesContent":[".cls1 { /*!*/ }\n.cls2 {\ncomposes: cls1;\n\nwidth: 48px !important;\nbackground-color: var(--color-bg-box);\nmargin-right: 8px;\nborder-radius: var(--size-radius-large);\nfill: var(--color-base);\n\nsvg {\nfill: var(--color-base);\n}\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"cls1": "Generator-ResetButton-module--cls1--ZMrfm",
	"cls2": "Generator-ResetButton-module--cls2--vHL9y Generator-ResetButton-module--cls1--ZMrfm"
};
export default ___CSS_LOADER_EXPORT___;
