import React, { useState, useEffect, useLayoutEffect, useRef } from 'react'

import * as THREE from 'three'
import { useFrame } from '@react-three/fiber'

import { storeAvatarBody } from './models/AvatarBody'
import { storeHaircut } from './models/Haircut'
import { storeOutfits } from './models/Outfits'
import { storeGlasses } from './models/Glasses'

import { animationController } from './helpers/animationController'
import { myDelay } from './helpers/myDelay'

/**
 * AvatarViewer React Component
 *
 * param avatar             - avatar object    (see readme.md for details)
 * param haircut            - haircut object   (see readme.md for details)
 * param outfits            - outfits object   (see readme.md for details)
 * param glasses            - glasses object   (see readme.md for details)
 * param hat                - hat object       (see readme.md for details)
 * param animation          - animation object (see readme.md for details)
 * param params             - params           (see readme.md for details)
 * param onDownloadResource - callback to load absend in .zip textures data (see readme.md for details)
 * param setReady           - callback to notify caller
 */

export const AvatarViewerComponent = ({
  avatar,
  haircut,
  outfits,
  glasses,
  hat,
  animation,
  params,
  onDownloadResource,
  setReady,
}) => {
  //console.log('')
  //console.log('!!!!!!!!!! <AvatarViewerComponent>')
  //console.log('  avatar:', avatar)
  //console.log('  haircut:', haircut)
  //console.log('  outfits:', outfits)
  //console.log('  glasses:', glasses)
  //console.log('  animation:', animation)
  //console.log('  hat:', hat)
  //console.log('  params:', params)

//  if (haircut.preset === null || haircut.preset === "Bald")
  outfits.hat = hat
//  else outfits.hat = { name: null, subtype: null }

  const refs = {
    avatarBody: useRef(null),
    haircut: useRef(null),
    glasses: useRef(null),

    hat: useRef(null),
    complect: useRef(null),
    top: useRef(null),
    bottom: useRef(null),
    shoes: useRef(null),
  }

  animationController.refs = refs

  useLayoutEffect(() => {
    //console.log('!!! useLayoutEffect(), AvatarViewer init')

    return function cleanup() {
      //console.log('!!! useLayoutEffect(), AvatarViewer cleanup')

      //console.log("animationController:", animationController)
      if (animationController.selectedAnimation) animationController.stopAnimation()

      for (const section of ['hat', 'complect', 'top', 'bottom', 'shoes']) {
        if (storeOutfits.animations[section] && storeOutfits.animations[section][0]) {
          for (const ref of [refs['avatarBody'].current, refs[section].current]) {
            if (!ref) continue
            animationController.stopOutfitAnimation(storeOutfits.animations[section][0], ref)
          }
        }
      }
      // console.log('animationController.mixer._actions.length:', animationController.mixer._actions.length)

      //storeAvatarBody.clearModel()
      //storeHaircut.clearModel()
      //storeGlasses.clearModel()
      //storeOutfits.clearModel()
    }
  }, [])

  useFrame((state, delta) => {
    const leftCornea = refs['avatarBody'].current?.children.find((element) => element.name === 'AvatarLeftCornea')
    const rightCornea = refs['avatarBody'].current?.children.find((element) => element.name === 'AvatarRightCornea')

    if (window.isTraceEnabled) {
      console.log('useFrame()')
      // console.log('  refs["avatarBody"].current:', refs['avatarBody'].current)
      // console.log('  leftCornea:', leftCornea)
      // console.log('  rightCornea:', rightCornea)
      window.isTraceEnabled = false
    }

    if (leftCornea) leftCornea.computeBoundingSphere()
    if (rightCornea) rightCornea.computeBoundingSphere()

    animationController.mixer.update(delta)
  })

  /**
   * update AvatarBody
   */

  const updateAvatarBody = async () => {
    //console.log('updateAvatarBody()')
    //console.log('  avatar.id:', avatar.id)
    //console.log('  storeAvatarBody.id:', storeAvatarBody.id)

    setReady('avatar', false)
    setReady('haircut', false)
    setReady('glasses', false)

    storeHaircut.clearModel()
    storeGlasses.clearModel()
    storeOutfits.clearModel()

    await storeAvatarBody.prepareAvatarBody(avatar, onDownloadResource, haircut?.color)

    animationController.animations = storeAvatarBody.animations

    const getColorCode = (color) => {
      if (color && typeof color === 'object' && 'red' in color && 'green' in color && 'blue' in color) {
        const { red, green, blue } = color
        const redHex = red.toString(16).padStart(2, '0')
        const greenHex = green.toString(16).padStart(2, '0')
        const blueHex = blue.toString(16).padStart(2, '0')
        return `#${redHex}${greenHex}${blueHex}`
      }
      return null
    }

    storeAvatarBody.skinColor = getColorCode(storeAvatarBody.info.skin_color)
    storeAvatarBody.lipsColor = getColorCode(storeAvatarBody.info.lips_color)
    storeAvatarBody.eyebrowsColor = getColorCode(storeAvatarBody.info.eyebrows_color)
    storeHaircut.color = getColorCode(storeAvatarBody.info.hair_color)

    setReady('avatar', false, {
      hairColorInitial: storeHaircut.color,
      skinColorInitial: storeAvatarBody.skinColor,
      lipsColorInitial: storeAvatarBody.lipsColor,
      eyebrowsColorInitial: storeAvatarBody.eyebrowsColor,
      animationInitial: storeAvatarBody.getInitialAnimation(),
      isBald: avatar.isBald,
      height: storeAvatarBody.height,
    })

    //console.log('updateAvatarBody() finished')
  }

  useLayoutEffect(() => {
    //console.log('useLayoutEffect(), avatarBody')
    //console.log('  animation.name:', animation.name)
    //console.log('  animationController.selectedAnimation:', animationController.selectedAnimation)
    //console.log('  refs["avatarBody"].current:', refs["avatarBody"].current)

    if (storeAvatarBody.busy) return

    if (animationController.selectedAnimation) animationController.stopAnimation()

    if (animation.name && refs['avatarBody'].current !== null) animationController.startAnimation(animation.name)

    applyFootAnimation(refs, refs['avatarBody'])

    //    setReady('avatar', true)

    setReady('avatar', true, {
      hairColorInitial: storeHaircut.color,
      skinColorInitial: storeAvatarBody.skinColor,
      lipsColorInitial: storeAvatarBody.lipsColor,
      eyebrowsColorInitial: storeAvatarBody.eyebrowsColor,
      animationInitial: storeAvatarBody.getInitialAnimation(),
      isBald: avatar.isBald,
      height: storeAvatarBody.height,
    })

    setReady('haircut', true)
    setReady('glasses', true)
  }, [storeAvatarBody.version])

  useLayoutEffect(() => {
    //console.log('useLayoutEffect(), animation changed')
    //console.log('  animation.name:', animation.name)
    //console.log('  animationController.selectedAnimation:', animationController.selectedAnimation)
    //console.log('  refs["avatarBody"].current:', refs["avatarBody"].current)

    if (!storeAvatarBody.busy && refs['avatarBody'].current !== null) {
      if (animationController.selectedAnimation) animationController.stopAnimation()
      if (animation.name) animationController.startAnimation(animation.name)
    }

    applyFootAnimation(refs, refs['avatarBody'])
  }, [animation.name])

  /**
   * update haircut
   */

  const updateHaircut = async () => {
    //console.log('updateHaircut():', haircut.preset)
    //console.log('  storeHaircut.name:', storeHaircut.name)

    setReady('haircut', false)

    storeHaircut.prevRef = refs['haircut'].current
    await storeHaircut.prepareHaircut(haircut, onDownloadResource)

    recoloringPresetHaircut()

    if (haircut.preset === null) {
      storeAvatarBody.updateScalpTexture(null)
      storeAvatarBody.setHaircutedHeadTexture(true)
    }

    if (haircut.preset === 'Bald') {
      storeAvatarBody.updateScalpTexture(null)
      storeAvatarBody.setHaircutedHeadTexture(false)
    }

    if ((haircut.preset === null || haircut.preset === 'Bald') && storeHaircut.prevRef) {
      animationController.stopAnimationFor(storeHaircut.prevRef)
    }

    storeHaircut.updateBlendshapes(storeAvatarBody)

    setReady('haircut', Date.now()) // to rerender, probably FIX IT
  }

  useLayoutEffect(() => {
    //console.log('useLayoutEffect(), storeHaircut changed')
    //console.log("storeHaircut:", storeHaircut)

    if (haircut.preset !== 'Bald' && haircut.preset !== null)
      storeAvatarBody.updateScalpTexture(storeHaircut.textures?.Scalp)

    if (refs['haircut'].current?.children?.length > 2) {
      //console.log('refs["haircut"].current.children.length:', refs["haircut"].current.children.length)
      //console.log('refs["haircut"].current.children', refs["haircut"].current.children)

      let hipsIndexes = []
      for (let i = 0; i < refs['haircut'].current.children.length; ++i) {
        //console.log(refs["haircut"].current.children[i])
        if (refs['haircut'].current.children[i].name === 'Hips') hipsIndexes.push(i)
      }
      //console.log('hipsIndexes:', hipsIndexes)

      if (hipsIndexes.length > 1) refs['haircut'].current.children.splice(hipsIndexes[0], 1)
    }

    animationController.stopAnimationFor(storeHaircut.prevRef)

    if (refs['haircut'].current && refs['avatarBody'].current) {
      refs['haircut'].current.position.y = refs['avatarBody'].current.position.y
      animationController.applyAnimationTo(refs['haircut'], refs['avatarBody'])
    }

    //if (storeHaircut.name !== null) {
      //hat.name = null
      //storeOutfits.clearModelSection('hat')
      //storeOutfits.version++
    //}
  }, [storeHaircut.version])

  /**
   * update glasses
   */

  const updateGlasses = async () => {
    //console.log('updateGlasses()')
    //console.log('  glasses.name:', glasses.name)
    //console.log('  storeGlasses.name:', storeGlasses.name)
    //console.log('  refs["glasses"].current:', refs["glasses"].current)
    //console.log('  storeGlasses.prevRef:', storeGlasses.prevRef)

    setReady('glasses', false)

    storeGlasses.prevRef = refs['glasses'].current
    await storeGlasses.prepareGlasses(glasses, onDownloadResource)

    if (glasses.name === null && storeGlasses.prevRef) {
      animationController.stopAnimationFor(storeGlasses.prevRef)
    }

    storeGlasses.updateBlendshapes(storeAvatarBody)

    setReady('glasses', true)
  }

  useLayoutEffect(() => {
    //console.log('useLayoutEffect(), storeGlasses changed')

    if (refs['glasses'].current?.children?.length > 2) refs['glasses'].current.children.splice(1, 1)

    animationController.stopAnimationFor(storeGlasses.prevRef)

    if (refs['glasses'].current && refs['avatarBody'].current) {
      refs['glasses'].current.position.y = refs['avatarBody'].current.position.y
      animationController.applyAnimationTo(refs['glasses'], refs['avatarBody'])
    }
  }, [storeGlasses.version])

  /**
   * recoloring preset haircut
   */

  const recoloringPresetHaircut = async () => {
    //console.log('recoloringPresetHaircut()')
    //console.log('  storeHaircut.name:', storeHaircut.name)
    //console.log('  haircut.color:', haircut.color)

    storeHaircut.recoloringHaircut(
      {
        target: haircut.color,
        roots: haircut.color, // haircut.roots
      },
      {
        AOImpact: 0.75,
        DepthImpact: 1.0,
        IDsImpact: 1.0,
      },
    )

    storeAvatarBody.recoloringScalp(haircut.color) // ? FIX IT - another color for scalp
  }

  /**
   * recoloring generated haircut
   */

  const recoloringGeneratedHaircut = () => {
    //console.log('recoloringGeneratedHaircut()')
    //console.log('  haircut.color:', haircut.color)
    //console.log('  storeHaircut.color:', storeHaircut.color)

    storeAvatarBody.recoloringHaircut(haircut.color)

    storeHaircut.color = haircut.color
    storeHaircut.name = null
  }

  /**
   * Update Outfits
   */

  const updateOutfits = async () => {
    if (!outfits['complect'].name && !outfits['top'].name) return
    //console.log('updateOutfits()')

    setReady('outfit', false)

    for (const section of ['hat', 'complect', 'top', 'bottom', 'shoes']) {
      storeOutfits.prevRefs[section] = refs[section].current

      storeOutfits.prevAnimations[section] = storeOutfits.animations[section]
        ? storeOutfits.animations[section][0]
        : null
    }

    await storeOutfits.prepareOutfits(outfits, onDownloadResource)

    storeOutfits.updateBlendshapes(storeAvatarBody)

    storeAvatarBody.needToApplyVisibilityMasks = true

    setReady('outfit', true, {
      infos: storeOutfits.infos,
      colors: storeOutfits.colors,
    })

    setReady('hat', true)

    if (outfits.hat?.name) {
      setReady('haircut', true)
    }

    //console.log('updateOutfits() finished')
  }

  /**
   * useEffect(), outfits changed
   */

  useLayoutEffect(() => {
    //console.log('useLayoutEffect() for outfits changed')

    if (storeOutfits.busy) return

    for (const section of ['hat', 'complect', 'top', 'bottom', 'shoes']) {
      if (refs[section]?.current?.children?.length > 2) {
        refs[section].current.children.splice(1, 1)
      }

      if (refs[section]?.current && refs['avatarBody']?.current)
        refs[section].current.position.y = refs['avatarBody'].current.position.y

      if (storeOutfits.prevAnimations[section]) {
        for (const ref of [refs['avatarBody'].current, storeOutfits.prevRefs[section]]) {
          if (!ref) continue

          animationController.stopOutfitAnimation(storeOutfits.prevAnimations[section], ref)
        }
      }
    }

    if (animation.name !== null) {
      if (
        animationController.selectedAnimation === null &&
        !storeAvatarBody.busy &&
        refs['avatarBody'].current !== null
      ) {
        animationController.startAnimation(animation.name)
        return
      }

      for (const section of ['hat', 'complect', 'top', 'bottom', 'shoes']) {
        if (storeOutfits.prevRefs[section]) animationController.stopAnimationFor(storeOutfits.prevRefs[section])

        if (refs[section].current) animationController.applyAnimationTo(refs[section], refs['avatarBody'])
      }
    }

    applyFootAnimation(refs, refs['avatarBody'])
  }, [storeOutfits.version])

  // check and update if needed

  if (avatar?.zip) {
    if (avatar.zip !== storeAvatarBody.zip) updateAvatarBody()

    // recolor avatar skin/lips/brows if specified

    if (avatar?.skinColor !== storeAvatarBody.skinColor) storeAvatarBody.recoloringSkin(avatar.skinColor)
    if (avatar?.lipsColor !== storeAvatarBody.lipsColor) storeAvatarBody.recoloringLips(avatar.lipsColor)
    if (avatar?.eyebrowsColor !== storeAvatarBody.eyebrowsColor)
      storeAvatarBody.recoloringEyebrows(avatar.eyebrowsColor)

    // change/recolor haircut if specified

    if (!storeHaircut.busy && haircut.preset !== storeHaircut.name) updateHaircut()

    if (haircut.color !== storeHaircut.color) haircut.preset ? recoloringPresetHaircut() : recoloringGeneratedHaircut()

    // change glasses if specified

    if (!storeGlasses.busy && glasses.name !== storeGlasses.name) updateGlasses()

    // change outfits if specified

    if (!storeOutfits.busy && storeOutfits.needsUpdate(outfits)) updateOutfits()

    for (const section of ['hat', 'complect', 'top', 'bottom', 'shoes']) {
      //console.log(`${section}: ${storeOutfits.colors[section]} -> ${outfits[section].color}`)
      //console.log(`${section}: ${storeOutfits.logos[section]} -> ${outfits[section].logo}`)

      if (!storeOutfits.busy && storeOutfits.names[section] && !outfits[section].texture_code &&
        (outfits[section].color !== storeOutfits.colors[section] ||
          outfits[section].logo !== storeOutfits.logos[section])
      ) {
        storeOutfits.recoloringOutfits(section, outfits[section].color, outfits[section].logo)
      }
    }
  }

  if (storeAvatarBody.needToApplyVisibilityMasks) storeAvatarBody.applyVisibilityMasks(storeOutfits.visibilityMasks)

  const isBodyPrepared = !storeAvatarBody.busy

  const isComplectPrepared = storeOutfits.nodes?.complect && storeOutfits.materials?.complect ? true : false
  const isDetailedPrepared =
    storeOutfits.nodes?.top &&
    storeOutfits.materials?.top &&
    storeOutfits.nodes?.bottom &&
    storeOutfits.materials?.bottom &&
    storeOutfits.nodes?.shoes &&
    storeOutfits.materials?.shoes
      ? true
      : false

  //console.log(' isBodyPrepared:', isBodyPrepared)
  //console.log(' isComplectPrepared:', isComplectPrepared)
  //console.log(' isDetailedPrepared:', isDetailedPrepared)

  //myDelay(1000, 'before render')

  const jsxFloor = () => {
    return (
      <mesh
        name={'Floor'}
        position={[0, -0.005, 0]}
        geometry={new THREE.BoxGeometry(1, 0.01, 1)}
        material={new THREE.MeshBasicMaterial({ color: new THREE.Color('blue') })}
      />
    )
  }

  if (storeOutfits.names['hat'] === 'Ghutra') {
    storeAvatarBody.setHaircutedHeadTexture(false)
    storeHaircut.hide = true
  } else {
    if (storeHaircut.name === null) storeAvatarBody.setHaircutedHeadTexture(true)
    else storeHaircut.hide = false
  }

  return isBodyPrepared && (isComplectPrepared || isDetailedPrepared) ? (
    <>
      {/* jsxFloor() */}

      {storeAvatarBody.jsxAvatarBody(refs['avatarBody'], params)}
      {storeHaircut.jsxHaircut(refs['haircut'], storeAvatarBody, params, haircut.renderPasses)}
      {isComplectPrepared
        ? storeOutfits.jsxOutfitsComplect(refs['complect'], params)
        : storeOutfits.jsxOutfitsDetailed(refs['top'], refs['bottom'], refs['shoes'], params)}
      {storeOutfits.jsxOutfitsHat(refs['hat'], params)}
      {storeGlasses.jsxGlasses(refs['glasses'], params)}
    </>
  ) : null
}

function applyFootAnimation(refOutfits, refAvatarBody) {
  //console.log("applyFootAnimation()")
  //console.log("  refOutfits:", refOutfits)
  //console.log("  refAvatarBody:", refAvatarBody)

  for (const section of ['hat', 'complect', 'top', 'bottom', 'shoes']) {
    if (storeOutfits.animations[section] && storeOutfits.animations[section][0]) {
      for (const ref of [refOutfits[section], refAvatarBody]) {
        if (!ref?.current) continue
        animationController.applyOutfitAnimation(storeOutfits.animations[section][0], ref)
      }
    }
  }
}
