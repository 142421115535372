// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".GeneratorPageLayout-Loading-module--cls1--ye5Zm { /*!*/ }\n\n.GeneratorPageLayout-Loading-module--cls2--uZHDS {\n  min-height: var(--app-height);\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  justify-content: space-between;\n  padding-top: 32vh;\n  padding-bottom: 120px;\n}", "",{"version":3,"sources":["webpack://./src/views/components/layouts/GeneratorPageLayout-Loading.module.scss"],"names":[],"mappings":"AAAA,mDAAA,IAAA,EAAA;;AACA;EAGA,6BAAA;EACA,aAAA;EACA,sBAAA;EACA,mBAAA;EACA,8BAAA;EACA,iBAAA;EACA,qBAAA;AACA","sourcesContent":[".cls1 { /*!*/ }\n.cls2 {\ncomposes: cls1;\n\nmin-height: var(--app-height);\ndisplay: flex;\nflex-direction: column;\nalign-items: center;\njustify-content: space-between;\npadding-top: 32vh;\npadding-bottom: 120px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"cls1": "GeneratorPageLayout-Loading-module--cls1--ye5Zm",
	"cls2": "GeneratorPageLayout-Loading-module--cls2--uZHDS GeneratorPageLayout-Loading-module--cls1--ye5Zm"
};
export default ___CSS_LOADER_EXPORT___;
