import React from 'react'
import styled from 'astroturf/react'
import { Container } from '@/components/wrapper'
import { Row, Col } from 'react-grid-system'
import { AvatarPreview } from '@/components/ui'
import { TitleSection } from '@/views/components/common'
import img from '@/assets/demo/avatar-gallery.png'
import { storeGeneratorPipeline } from '@/services/store'

import { useRQ, getSetting } from '@/services/adapters/req'
import { Setting } from '../_helper'

export { sampleAvatars }

const sampleAvatarsProd = {
  female: [
    {
      gender: 'female',
      preview: 'sample_avatar_1_preview_480.png',
      avatar_code: '2cf48f59-06f2-4a84-ac8f-2abea6ce7333',
      export_code: '6ed6df34-aa4e-4550-aa0d-0f7425adf72f',
      outfits: {
        top: { name: 'hoodie_ARAS', color: '#254E71', logo_code: 'e0b4c5a9-8f7e-45fc-b91a-74eb7db09233' },
        bottom: { name: 'jeans_AYGER' },
        shoes: { name: 'sneakers_ARPI' }
      }
    },
    {
      gender: 'female',
      preview: 'sample_avatar_3_preview_480.png',
      avatar_code: '3da15aab-8b9a-4729-a8a1-8c6224aa4936',
      export_code: '1cd7ee16-3e06-44ee-94ba-3f0c4cb522f7',
      outfits: {
        complect: { name: 'ARPI', texture_code: '79f06c6b-543a-400b-93b7-69bbc8aef40a' }
      }
    },
    {
      gender: 'female',
      preview: 'sample_avatar_5_preview_480.png',
      avatar_code: 'a35f1042-be16-47c6-805b-ff28746546a8',
      export_code: 'b8c4eefb-888c-4c8c-8346-7ec833aae25c',
      outfits: {
        top: { name: 't-shirt_Vardenis', logo_code: '3e21be07-41ed-4f85-b7eb-b86299e73ffa' },
        bottom: {name: 'jeans_VEDI' },
        shoes: { name: 'sneakers_ARPI' }
      }

    },
    {
      gender: 'female',
      preview: 'sample_avatar_8_preview_480.png',
      avatar_code: '8b77057a-d86e-46b3-99e9-965f4c8f07bd',
      export_code: 'e5277110-7446-4822-bfa9-dfb7f0b5001d',
      outfits: {
        complect: { name: 'Akna' }
      }
    },
  ],
  male: [
    {
      gender: 'male',
      preview: 'sample_avatar_0_preview_480.png',
      avatar_code: 'b3009766-f472-4a61-be43-4bae2098ce7c',
      export_code: '72e6cbd0-36b9-4bf9-8a24-3b49f31e8a8f',
      outfits: {
        complect: { name: 'KARI', texture_code: 'c7dd8a71-5930-40f6-b348-3b44b1d3796c'}
      }
    },
    {
      gender: 'male',
      preview: 'sample_avatar_2_preview_480.png',
      avatar_code: 'e356d81a-9992-4149-a9a1-b9a4ab2e6973',
      export_code: '6833650d-d469-4344-a14a-9c725d787b60',
      outfits: {
        top: { name: 'longsleeve_Debed', color: '#0B707C', logo_code: '3e21be07-41ed-4f85-b7eb-b86299e73ffa' },
        bottom: { name: 'jeans_AZAT' },
        shoes: { name: 'sneakers_AZAT' }
      }
    },
    {
      gender: 'male',
      preview: 'sample_avatar_4_preview_480.png',
      avatar_code: '7e266b4e-1ab8-42d3-ba44-2f5ac01fff62',
      export_code: 'd24ff5ca-9da8-4bf9-9d09-a1909a6618a7',
      outfits: {
        top: { name: 't-shirt_KARI', color: '#262626', logo_code: 'e0b4c5a9-8f7e-45fc-b91a-74eb7db09233' },
        bottom: {name: 'pants_SEVAN' },
        shoes: { name: 'sneakers_SEVAN' }
      }
    },
    {
      gender: 'male',
      preview: 'sample_avatar_6_preview_480.png',
      avatar_code: 'ebffd7bf-23a6-45d3-9b79-19df18372e9b',
      export_code: 'b9c8ed68-ea20-4111-8644-b1c2b7973da8',
      outfits: {
        complect: { name: 'SEVAN', texture_code: '624400b5-6392-46b9-bedd-2387ed3eadb6' },
      }
    },
    {
      gender: 'male',
      preview: 'sample_avatar_7_preview_480.png',
      avatar_code: 'b9037af9-5cc9-4d48-9e1d-1d6ce3180ba7',
      export_code: 'f75136e1-1061-46b6-aa4f-0a671ce612a1',
      outfits: {
        top: { name: 'hoodie_AZAT', logo_code: 'e0b4c5a9-8f7e-45fc-b91a-74eb7db09233' },
        bottom: { name: 'jeans_AZAT' },
        shoes: { name: 'sneakers_AZAT' }
      }
    },
  ],
}

const sampleAvatarsMirror = {
  female: [
    {
      gender: 'female',
      preview: 'sample_avatar_1_preview_480.png',
      avatar_code: 'f45e34e8-88c5-4406-8c02-039636a99183',
      export_code: '558e7ffe-9daf-4636-8cd3-405558ab4d55',
      outfits: {
        top: { name: 'hoodie_ARAS', color: '#254E71' },
        bottom: { name: 'jeans_AYGER' },
        shoes: { name: 'sneakers_ARPI' }
      }
    },
    {
      gender: 'female',
      preview: 'sample_avatar_3_preview_480.png',
      avatar_code: '5650a22f-3b3b-4a56-873e-2face79bf8bd',
      export_code: '805cad9b-3852-4ed2-ba24-0bde49fce0d2',
      outfits: {
        complect: { name: 'ARPI' }
      }
    },
    {
      gender: 'female',
      preview: 'sample_avatar_5_preview_480.png',
      avatar_code: '40fd0647-1432-48e9-b7ae-099e94135d41',
      export_code: 'b617d73c-f204-4973-8bb1-3970ce9b39bf',
      outfits: {
        top: { name: 't-shirt_Vardenis' },
        bottom: {name: 'jeans_VEDI' },
        shoes: { name: 'sneakers_ARPI' }
      }
    },
    {
      gender: 'female',
      preview: 'sample_avatar_8_preview_480.png',
      avatar_code: '72d74212-f720-4937-919b-d70738d664fd',
      export_code: '902f6cc4-1f28-425e-8912-d13dc8b6924f',
      outfits: {
        complect: { name: 'Akna' }
      }
    },
  ],
  male: [
    {
      gender: 'male',
      preview: 'sample_avatar_0_preview_480.png',
      avatar_code: '79b49f2f-c5be-4da1-bddc-843943740bfa',
      export_code: 'ba0d517c-e62d-4b3c-bbc1-5ee3e829d9ec',
      outfits: {
        complect: { name: 'KARI' }
      }
    },
    {
      gender: 'male',
      preview: 'sample_avatar_2_preview_480.png',
      avatar_code: '7fff5ec2-88a3-4a47-9b6f-3d53d95b122a',
      export_code: '3a8404a3-c54e-4da0-9f09-52530bab15f1',
      outfits: {
        top: { name: 'longsleeve_Debed', color: '#0B707C' },
        bottom: { name: 'jeans_AZAT' },
        shoes: { name: 'sneakers_AZAT' }
      }
    },
    {
      gender: 'male',
      preview: 'sample_avatar_4_preview_480.png',
      avatar_code: 'd1fc7615-661f-4b0d-bbf8-d316a1315693',
      export_code: '30754f9f-0721-49c2-8005-c8adff8f8d08',
      outfits: {
        top: { name: 't-shirt_KARI', color: '#262626' },
        bottom: {name: 'pants_SEVAN' },
        shoes: { name: 'sneakers_SEVAN' }
      }
    },
    {
      gender: 'male',
      preview: 'sample_avatar_6_preview_480.png',
      avatar_code: 'b7ee81d7-0ab5-48e7-a267-8056f9006b92',
      export_code: 'aa6cb556-e3c4-4f3b-a315-7487ce37dd68',
      outfits: {
        complect: { name: 'SEVAN' }
      }
    },
    {
      gender: 'male',
      preview: 'sample_avatar_7_preview_480.png',
      avatar_code: 'aad12af8-ee36-445f-90b3-dbf768c08d3e',
      export_code: '234dfbec-ed2d-4414-917a-1ee0d5c23b36',
      outfits: {
        top: { name: 'hoodie_AZAT' },
        bottom: { name: 'jeans_AZAT' },
        shoes: { name: 'sneakers_AZAT' }
      }
    },
  ],
}

const sampleAvatars = process.env.USE_MIRROR === '1' ? sampleAvatarsMirror : sampleAvatarsProd

sampleAvatars['without_specify'] = [...sampleAvatars['female'], ...sampleAvatars['male']]
sampleAvatars['without_specify'].sort((a, b) => (a.preview > b.preview ? 1 : -1))


export const NoPhoto = () => {
  const { submitPreSettingsForSampleAvatar } = storeGeneratorPipeline

  const {
    data: {
      texts: {
        sections: { no_photo: PAGE },
      },
    },
  } = useRQ<Setting>(getSetting)

  const subtype = storeGeneratorPipeline.preSettings.gender
  
  return (
    <Container variant='center'>
      <TitleSection title={PAGE.title} descr={PAGE.descr} />
      <Avatars>
        {sampleAvatars[subtype].map((sampleAvatar) => (
          <ColCard key={sampleAvatar.preview} xs={6} sm={4}>
            <Avatar>
              <AvatarPreview
                handleClick={() => submitPreSettingsForSampleAvatar(sampleAvatar)}
                img={`/avatars/${sampleAvatar.preview}`}
                width={120}
                height={80}
              />
            </Avatar>
          </ColCard>
        ))}
      </Avatars>
    </Container>
  )
}

const Avatars = styled(Row)`
  margin-top: 16px;
  max-width: 408px;
  margin-left: -8px !important;
  margin-right: -8px !important;
`

const ColCard = styled(Col)`
  margin-top: 16px;
  padding-left: 8px !important;
  padding-right: 8px !important;
`

const Avatar = styled.div`
  position: relative;
  width: 100%;
  cursor: pointer;
`
