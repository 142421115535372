// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ProfileDetails-Header-module--cls1--kSofu { /*!*/ }\n\n.ProfileDetails-Header-module--cls2--WmnbG {\n  display: flex;\n  width: calc(100% - 64px);\n  margin-left: auto;\n  margin-right: auto;\n  padding-top: 32px;\n  position: absolute;\n  justify-content: space-between;\n  top: 0;\n  left: 32px;\n  z-index: 5;\n}", "",{"version":3,"sources":["webpack://./src/views/page/auth/ProfileDetails-Header.module.scss"],"names":[],"mappings":"AAAA,6CAAA,IAAA,EAAA;;AACA;EAGA,aAAA;EACA,wBAAA;EACA,iBAAA;EACA,kBAAA;EACA,iBAAA;EACA,kBAAA;EACA,8BAAA;EACA,MAAA;EACA,UAAA;EACA,UAAA;AACA","sourcesContent":[".cls1 { /*!*/ }\n.cls2 {\ncomposes: cls1;\n\ndisplay: flex;\nwidth: calc(100% - 64px);\nmargin-left: auto;\nmargin-right: auto;\npadding-top: 32px;\nposition: absolute;\njustify-content: space-between;\ntop: 0;\nleft: 32px;\nz-index: 5;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"cls1": "ProfileDetails-Header-module--cls1--kSofu",
	"cls2": "ProfileDetails-Header-module--cls2--WmnbG ProfileDetails-Header-module--cls1--kSofu"
};
export default ___CSS_LOADER_EXPORT___;
