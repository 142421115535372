// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".List-Head-module--cls1--mqTs1 { /*!*/ }\n\n.List-Head-module--cls2--ekQfs {\n  display: flex;\n  justify-content: space-between;\n  width: 100%;\n  margin-bottom: 28px;\n}", "",{"version":3,"sources":["webpack://./src/views/page/gallery/components/List/List-Head.module.scss"],"names":[],"mappings":"AAAA,iCAAA,IAAA,EAAA;;AACA;EAGA,aAAA;EACA,8BAAA;EACA,WAAA;EACA,mBAAA;AACA","sourcesContent":[".cls1 { /*!*/ }\n.cls2 {\ncomposes: cls1;\n\ndisplay: flex;\njustify-content: space-between;\nwidth: 100%;\nmargin-bottom: 28px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"cls1": "List-Head-module--cls1--mqTs1",
	"cls2": "List-Head-module--cls2--ekQfs List-Head-module--cls1--mqTs1"
};
export default ___CSS_LOADER_EXPORT___;
