// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Sidebar-Logo-module--cls1--RK4lL { /*!*/ }\n\n.Sidebar-Logo-module--cls2--j8i91 {\n  margin-left: 12px;\n}", "",{"version":3,"sources":["webpack://./src/views/components/layouts/PrivatePageLayout/components/Sidebar-Logo.module.scss"],"names":[],"mappings":"AAAA,oCAAA,IAAA,EAAA;;AACA;EAGA,iBAAA;AACA","sourcesContent":[".cls1 { /*!*/ }\n.cls2 {\ncomposes: cls1;\n\nmargin-left: 12px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"cls1": "Sidebar-Logo-module--cls1--RK4lL",
	"cls2": "Sidebar-Logo-module--cls2--j8i91 Sidebar-Logo-module--cls1--RK4lL"
};
export default ___CSS_LOADER_EXPORT___;
