// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".RangeSlider-Minus-module--cls1--KaXD3 { /*!*/ }\n\n.RangeSlider-Minus-module--cls2--SohyD {\n  margin-right: 10px;\n  margin-top: 1px;\n  color: var(--color-base);\n}", "",{"version":3,"sources":["webpack://./src/views/page/generator/sections/components/RangeSlider-Minus.module.scss"],"names":[],"mappings":"AAAA,yCAAA,IAAA,EAAA;;AACA;EAGA,kBAAA;EACA,eAAA;EACA,wBAAA;AACA","sourcesContent":[".cls1 { /*!*/ }\n.cls2 {\ncomposes: cls1;\n\nmargin-right: 10px;\nmargin-top: 1px;\ncolor: var(--color-base);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"cls1": "RangeSlider-Minus-module--cls1--KaXD3",
	"cls2": "RangeSlider-Minus-module--cls2--SohyD RangeSlider-Minus-module--cls1--KaXD3"
};
export default ___CSS_LOADER_EXPORT___;
