// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Generator-Body-module--cls1--sqZJF { /*!*/ }\n\n.Generator-Body-module--cls2--rcNND {\n  align: center;\n  border: 2px solid gray;\n  color: var(--color-base);\n  background-color: var(--color-bg-dark);\n  border-radius: var(--size-radius);\n  padding: 20px;\n}", "",{"version":3,"sources":["webpack://./src/views/page/generator/Generator-Body.module.scss"],"names":[],"mappings":"AAAA,sCAAA,IAAA,EAAA;;AACA;EAGA,aAAA;EACA,sBAAA;EACA,wBAAA;EACA,sCAAA;EACA,iCAAA;EACA,aAAA;AACA","sourcesContent":[".cls1 { /*!*/ }\n.cls2 {\ncomposes: cls1;\n\nalign: center;\nborder: 2px solid gray;\ncolor: var(--color-base);\nbackground-color: var(--color-bg-dark);\nborder-radius: var(--size-radius);\npadding: 20px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"cls1": "Generator-Body-module--cls1--sqZJF",
	"cls2": "Generator-Body-module--cls2--rcNND Generator-Body-module--cls1--sqZJF"
};
export default ___CSS_LOADER_EXPORT___;
