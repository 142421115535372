// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".RangeSlider-RenderThumb-module--cls1--jN0sY { /*!*/ }\n\n.RangeSlider-RenderThumb-module--cls2--HBbw_ {\n  width: 16px;\n  height: 16px;\n  border-radius: 100%;\n  background-color: #fff;\n}", "",{"version":3,"sources":["webpack://./src/views/page/generator/sections/components/RangeSlider-RenderThumb.module.scss"],"names":[],"mappings":"AAAA,+CAAA,IAAA,EAAA;;AACA;EAGA,WAAA;EACA,YAAA;EACA,mBAAA;EACA,sBAAA;AACA","sourcesContent":[".cls1 { /*!*/ }\n.cls2 {\ncomposes: cls1;\n\nwidth: 16px;\nheight: 16px;\nborder-radius: 100%;\nbackground-color: #fff;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"cls1": "RangeSlider-RenderThumb-module--cls1--jN0sY",
	"cls2": "RangeSlider-RenderThumb-module--cls2--HBbw_ RangeSlider-RenderThumb-module--cls1--jN0sY"
};
export default ___CSS_LOADER_EXPORT___;
