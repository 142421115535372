// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".GalleryCard-Core-module--cls1--Sdw09 { /*!*/ }\n\n.GalleryCard-Core-module--cls2--sKcKG {\n  position: relative;\n  display: block;\n  cursor: pointer;\n}", "",{"version":3,"sources":["webpack://./src/views/page/gallery/components/List/components/GalleryCard-Core.module.scss"],"names":[],"mappings":"AAAA,wCAAA,IAAA,EAAA;;AACA;EAGA,kBAAA;EACA,cAAA;EACA,eAAA;AACA","sourcesContent":[".cls1 { /*!*/ }\n.cls2 {\ncomposes: cls1;\n\nposition: relative;\ndisplay: block;\ncursor: pointer;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"cls1": "GalleryCard-Core-module--cls1--Sdw09",
	"cls2": "GalleryCard-Core-module--cls2--sKcKG GalleryCard-Core-module--cls1--Sdw09"
};
export default ___CSS_LOADER_EXPORT___;
