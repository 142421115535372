// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".List-ColCard-module--cls1--jhosX { /*!*/ }\n\n.List-ColCard-module--cls2--UgT9Y {\n  margin-top: 24px;\n}", "",{"version":3,"sources":["webpack://./src/views/page/gallery/components/List/List-ColCard.module.scss"],"names":[],"mappings":"AAAA,oCAAA,IAAA,EAAA;;AACA;EAGA,gBAAA;AACA","sourcesContent":[".cls1 { /*!*/ }\n.cls2 {\ncomposes: cls1;\n\nmargin-top: 24px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"cls1": "List-ColCard-module--cls1--jhosX",
	"cls2": "List-ColCard-module--cls2--UgT9Y List-ColCard-module--cls1--jhosX"
};
export default ___CSS_LOADER_EXPORT___;
