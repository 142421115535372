export const getMaleAnimations = (isPose = true) => {
  return isPose
    ? [
      'Male_Standing_Pose',
      'Male_Dance_Pose',
      'Male_Sitting_Pose', //
      'Male_Standing_Pose_2', //
      'Male_001',
      'Male_002', //
      'Male_003',
      'Male_004',
      'Male_005', //
    ] : [
      'Male_Idle', 
      'Bashful', 
      'Backflip', 
      'Bboy_Uprock', //
      'Happy', 
      'samba_dancing', //
      'Standard_Walk' //
    ]
}

export const getFemaleAnimations = (isPose = true) => {
  return isPose
    ? [
      'Female_Standing_Pose', //
      'Male_Standing_Pose',
      'Female_001', //
      'Female_002',
      'Female_003',
      'Female_004', //
      'Female_005', //
      'Female_006',
    ] : [
      'Female_Idle',
      'Bashful',
      'Catwalk_Idle',
      'Happy',
      'samba_dancing',
      'Standard_Walk'
    ]
}
