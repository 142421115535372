// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".SpinnerInfinite-OutlineBg-module--cls1--KEMTD { /*!*/ }\n\n.SpinnerInfinite-OutlineBg-module--cls2--UYSbW {\n  stroke: var(--color-base);\n  opacity: 0.2;\n}", "",{"version":3,"sources":["webpack://./src/components/ui/SpinnerInfinite-OutlineBg.module.scss"],"names":[],"mappings":"AAAA,iDAAA,IAAA,EAAA;;AACA;EAGA,yBAAA;EACA,YAAA;AACA","sourcesContent":[".cls1 { /*!*/ }\n.cls2 {\ncomposes: cls1;\n\nstroke: var(--color-base);\nopacity: 0.2;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"cls1": "SpinnerInfinite-OutlineBg-module--cls1--KEMTD",
	"cls2": "SpinnerInfinite-OutlineBg-module--cls2--UYSbW SpinnerInfinite-OutlineBg-module--cls1--KEMTD"
};
export default ___CSS_LOADER_EXPORT___;
