// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Video-Core-module--cls1--enZ2e { /*!*/ }\n\n.Video-Core-module--cls2--ZjGv8 {\n  border-radius: var(--size-radius);\n  background-color: var(--color-bg-filed);\n  position: relative;\n}", "",{"version":3,"sources":["webpack://./src/components/ui/Video-Core.module.scss"],"names":[],"mappings":"AAAA,kCAAA,IAAA,EAAA;;AACA;EAGA,iCAAA;EACA,uCAAA;EACA,kBAAA;AACA","sourcesContent":[".cls1 { /*!*/ }\n.cls2 {\ncomposes: cls1;\n\nborder-radius: var(--size-radius);\nbackground-color: var(--color-bg-filed);\nposition: relative;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"cls1": "Video-Core-module--cls1--enZ2e",
	"cls2": "Video-Core-module--cls2--ZjGv8 Video-Core-module--cls1--enZ2e"
};
export default ___CSS_LOADER_EXPORT___;
