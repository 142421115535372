// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".List-Core-module--cls1--t__LE { /*!*/ }\n\n.List-Core-module--cls2--Zljiu {\n}", "",{"version":3,"sources":["webpack://./src/views/page/gallery/components/List/List-Core.module.scss"],"names":[],"mappings":"AAAA,iCAAA,IAAA,EAAA;;AACA;AAGA","sourcesContent":[".cls1 { /*!*/ }\n.cls2 {\ncomposes: cls1;\n\n\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"cls1": "List-Core-module--cls1--t__LE",
	"cls2": "List-Core-module--cls2--Zljiu List-Core-module--cls1--t__LE"
};
export default ___CSS_LOADER_EXPORT___;
