export const avatarHead_fragment1 = `

//#define COLORSPACE_GAMMA

  precision mediump float;

  uniform sampler2D uHead;
  uniform sampler2D uHaircut;
  uniform sampler2D uScalp;
  uniform sampler2D uHeadMasks;

  uniform bool  uUseGeneratedHaircut;
  uniform bool  uUseGeneratedHaircutRecoloring;
  uniform vec3  uColorTint;
  uniform float uTintCoeff;
  uniform float uDarkeningCoeff;

  uniform bool  uUseScalpRecoloring;
  uniform vec3  uScalpColor;

  uniform bool  uUseSkinRecoloring;
  uniform mat3  uR;

  uniform bool  uUseLipsRecoloring;
  uniform mat3  uLipsR;
  uniform vec3  uLipsColor;

  uniform bool  uUseEyebrowsRecoloring;
  uniform mat3  uEyebrowsR;
  uniform vec3  uEyebrowsColor;

#ifdef COLORSPACE_GAMMA
  vec4 gammaToLinear(vec4 c) {
    return pow(c, vec4(2.2));
  }

  vec4 linearToGamma(vec4 c) {
    return pow(c, vec4(1./2.2));
  }
#endif
`
