// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".EditorShirt-Content-module--cls1--fifeP { /*!*/ }\n\n.EditorShirt-Content-module--cls2--vfVxM {\n  width: calc(100% - 90px);\n  padding-top: 40px;\n  padding-bottom: 40px;\n  max-width: 440px;\n  position: relative;\n  text-align: left;\n  display: inline-block;\n  vertical-align: middle;\n}", "",{"version":3,"sources":["webpack://./src/views/page/generator/sections/Editor/components/EditorShirt-Content.module.scss"],"names":[],"mappings":"AAAA,2CAAA,IAAA,EAAA;;AACA;EAGA,wBAAA;EACA,iBAAA;EACA,oBAAA;EACA,gBAAA;EAEA,kBAAA;EACA,gBAAA;EACA,qBAAA;EACA,sBAAA;AAAA","sourcesContent":[".cls1 { /*!*/ }\n.cls2 {\ncomposes: cls1;\n\nwidth: calc(100% - 90px);\npadding-top: 40px;\npadding-bottom: 40px;\nmax-width: 440px;\n\nposition: relative;\ntext-align: left;\ndisplay: inline-block;\nvertical-align: middle;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"cls1": "EditorShirt-Content-module--cls1--fifeP",
	"cls2": "EditorShirt-Content-module--cls2--vfVxM EditorShirt-Content-module--cls1--fifeP"
};
export default ___CSS_LOADER_EXPORT___;
