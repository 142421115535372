// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Apps-ColWithOffset-module--cls1--RZB29 { /*!*/ }\n\n.Apps-ColWithOffset-module--cls2--mWe28 {\n  margin-top: 24px;\n}", "",{"version":3,"sources":["webpack://./src/views/page/apps/Apps-ColWithOffset.module.scss"],"names":[],"mappings":"AAAA,0CAAA,IAAA,EAAA;;AACA;EAGA,gBAAA;AACA","sourcesContent":[".cls1 { /*!*/ }\n.cls2 {\ncomposes: cls1;\n\nmargin-top: 24px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"cls1": "Apps-ColWithOffset-module--cls1--RZB29",
	"cls2": "Apps-ColWithOffset-module--cls2--mWe28 Apps-ColWithOffset-module--cls1--RZB29"
};
export default ___CSS_LOADER_EXPORT___;
