import React from 'react'
import { toast as toastLib } from 'react-toastify'
import styled from 'astroturf/react'
import icoSuccess from '@/assets/img/ico-success.svg'
import icoError from '@/assets/img/ico-error.svg'

const icons = new Map([
  ['success', icoSuccess],
  ['error', icoError],
])

const Markup = ({ title, msg, icon }: { title: string; msg: string; icon?: 'success' | 'error' }) => {
  return (
    <Core>
      {icon && <Icons src={icons.get(icon)} />}
      <div>
        <Title>{title}</Title>
        <Msg>{msg}</Msg>
      </div>
    </Core>
  )
}

const Core = styled.div`
  display: flex;
  align-items: flex-start;
`
const Title = styled.div`
  font-weight: 700;
  font-size: 13px;
  line-height: 14px;
  color: var(--color-base);
`

const Msg = styled.div`
  color: var(--color-base);
  font-weight: 500;
  font-size: 12px;
  line-height: 12px;
  margin-top: 6px;
  opacity: 0.5;
`

const Icons = styled.img`
  width: 32px;
  height: 32px;
  flex-shrink: 0;
  margin-right: 12px;
`

export const toast = (title: string, msg: string, icon?: 'success' | 'error') =>
  toastLib(<Markup title={title} msg={msg} icon={icon} />, {
    position: 'top-right',
    autoClose: 4000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
  })

export const handleError = (error: TypeError) => {
//console.log("handleError(), error:", error)
//console.trace()

  if (!error.status) {
    toast('Error!', error.message, 'error')
    return
  }
  toast(error.status, error.statusText, 'error')
}
