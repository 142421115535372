import React from 'react'

import * as THREE from 'three'
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader'

import { proceedZip, modelHeight } from '../helpers/prepareModel'
import { prepareBodyVisibilityMask } from '../helpers/prepareTextures'
import { prepareR } from '../helpers/recoloring'

import { myDelay } from '../helpers/myDelay'

class Outfits {
  refs = { hat: null, complect: null, top: null, bottom: null, shoes: null }
  prevRefs = { hat: null, complect: null, top: null, bottom: null, shoes: null }

  names = { hat: null, complect: null, top: null, bottom: null, shoes: null }
  infos = { hat: null, complect: null, top: null, bottom: null, shoes: null }
  colors = { hat: null, complect: null, top: null, bottom: null, shoes: null }
  nodes = { hat: null, complect: null, top: null, bottom: null, shoes: null }
  texture_codes = { hat: null, complect: null, top: null, bottom: null, shoes: null }
  textures = { hat: null, complect: null, top: null, bottom: null, shoes: null }
  deftextures = { hat: null, complect: null, top: null, bottom: null, shoes: null }
  recoloringMasks = { hat: null, complect: null, top: null, bottom: null, shoes: null }
  materials = { hat: null, complect: null, top: null, bottom: null, shoes: null }
  visibilityMasks = { hat: null, complect: null, top: null, bottom: null, shoes: null, detailed: null, head: null }
  logos = { hat: null, complect: null, top: null, bottom: null, shoes: null }
  logoTextures = { hat: null, complect: null, top: null, bottom: null, shoes: null }

  animations = { hat: null, complect: null, top: null, bottom: null, shoes: null }
  prevAnimations = { hat: null, complect: null, top: null, bottom: null, shoes: null }

  uniforms = {
    hat: { uColorTint: { value: new THREE.Vector3(0, 0, 0) } },
    complect: { uColorTint: { value: new THREE.Vector3(0, 0, 0) } },
    top: { uColorTint: { value: new THREE.Vector3(0, 0, 0) } },
    bottom: { uColorTint: { value: new THREE.Vector3(0, 0, 0) } },
    shoes: { uColorTint: { value: new THREE.Vector3(0, 0, 0) } },
  }

  height = 0
  scale = 1
  headScale = 1

  busy = false
  version = 0

  /**
   * Clear model
   */

  clearModel = () => {
    //console.log('clearModel()')

    for (const section of ['hat', 'complect', 'top', 'bottom', 'shoes']) {
      if (this.names[section]) this.clearModelSection(section)
    }

    this.scale = 1
    this.headScale = 1
  }

  /**
   * Clear model section
   */

  clearModelSection = (section) => {
    //console.log('clearModelSection(), section:', section)

    this.names[section] = null
    this.infos[section] = null

    if (this.nodes[section])
      Object.entries(this.nodes[section]).forEach(([key, value]) => {
        if (value?.geometry) value.geometry.dispose()
        if (value?.Hips) value.Hips.dispose()
      })
    this.nodes[section] = null

    if (this.materials[section])
      Object.entries(this.materials[section]).forEach(([key, value]) => {
        if (value) value.dispose()
      })
    this.materials[section] = null

    this.animations[section] = null

    if (this.visibilityMasks[section]) this.visibilityMasks[section].dispose()
    this.visibilityMasks[section] = null

    this.texture_codes[section] = null
    this.textures[section] = null
  }

  needsUpdate(outfits) {
    const res =
      outfits['complect'].name !== this.names['complect'] ||
      outfits['complect'].texture_code !== this.texture_codes['complect'] ||
      outfits['top'].name !== this.names['top'] ||
      outfits['top'].texture_code !== this.texture_codes['top'] ||
      outfits['bottom'].name !== this.names['bottom'] ||
      outfits['bottom'].texture_code !== this.texture_codes['bottom'] ||
      outfits['shoes'].name !== this.names['shoes'] ||
      outfits['shoes'].texture_code !== this.texture_codes['shoes'] ||
      outfits['hat'].name !== this.names['hat']

    return res
  }

  /**
   * Prepare Outfits Section
   *
   * param outfits            - object with outfits names
   * param section            - outfits section ('complect', 'top', 'bottom', 'shoes')
   * param onDownloadResource - callback to load .zip and absend in .zip textures
   */

  outfitsInfo(outfitName) {
    //console.log('outfitsInfo(), outfitName:', outfitName)

    const defColors = {
      'ARPI':             '#D5E4EF',
      'Akna':             '#C2C2C2',
      'CHATIN':           '#FED3E2',
      'KARI':             '#BFCBE0',
      'SEVAN':            '#48564A',
      'dress_Shaki':      '#CC2C48',
      'jeans_AYGER':      '#47545D',
      'jeans_AZAT':       '#172A3D',
      'jeans_SWAN':       '#545F72',
      'jeans_VEDI':       '#1E2831',
      'pants_Arteni':     '#131313',
      'pants_SEVAN':      '#4E4E4E',
      'shorts_Getar':     '#262522',
      'shorts_Vedi':      '#948B83',
      'shoes_KARI':       '#72574A',
      'sneakers_ARPI':    '#9E9B99',
      'sneakers_AZAT':    '#87827D',
      'sneakers_SEVAN':   '#020202',
      'hoodie_ARAS':      '#9B7172',
      'hoodie_AZAT':      '#7C2D2D',
      'jacket_Arteni':    '#201F1B',
      'jacket_Tavush':    '#202020',
      'longsleeve_Debed': '#4C5B81',
      'polo_Getik':       '#8E6B4D',
      'polo_Jil':         '#975F5F',
      'shirt_Olympus':    '#284738',
      't-shirt_ARPI':     '#D5E4EF',
      't-shirt_Hakari':   '#919191',
      't-shirt_Vardenis': '#7EA064',
      't-shirt_KARI':     '#BFCBE0',
      'top_Urasar':       '#1E1E1E',
      'jacket_Oroklini':  '#202020',
      'jacket_Ergates':   '#474567',
      'pants_Ergates':    '#252525',
      'Kandura_Artanish': '#B6B6B6',
      'Kandura_Lalvar':   '#AAAAAA',
      'leggins_Khustup':  '#2A2A2A',
      'saree_Aramazd':    '#CA6CA1',
      'skirt_Lessing':    '#513944',
      'top_Khustup':      '#5F5F5F',
      'top_Lessing':      '#232323',
      'Urasar':           '#242424',
      'dhoti_Tandzut':    '#AEABA4',
      'kurta_Tandzut':    '#CBA940',
      'Parz':             '#533F34',
      'sandals_Parz':     '#3C2B22',
      'shoes_Tandzut':    '#999588',
    }

    return { colorInitial: defColors[outfitName] || '#000000' }
  }

  useTextureFromFile(outfitName) {
    //console.log('useTextureFromFile(), outfitName:', outfitName)

    return (
      {
        // female outfits
        't-shirt_ARPI:': true,
        't-shirt_Vardenis': true,

        // male outfits
        't-shirt_KARI': true,
        't-shirt_Hakari': true,
      }[outfitName] || false
    )
  }

  logoPlacement(outfitName) {
    //console.log('logoPlacement(), outfitName:', outfitName)

    // [left, top, right, bottom]
    const placement = {
      'ARPI': null,
      'Akna': null,
      'CHATIN': null,
      'KARI': [0.0943, 0.2543, 0.895, 0.835],
      'SEVAN': null,
      'dress_Shaki': null,
      'jeans_AYGER': null,
      'jeans_AZAT': null,
      'jeans_SWAN': null,
      'jeans_VEDI': null,
      'pants_Arteni': null,
      'pants_SEVAN': null,
      'shorts_Getar': null,
      'shorts_Vedi': null,
      'shoes_KARI': null,
      'sneakers_ARPI': null,
      'sneakers_AZAT': null,
      'sneakers_SEVAN': null,
      'hoodie_ARAS': [0.11, 0.28, 0.318, 0.258],
      'hoodie_AZAT': [0.117, 0.325, 0.79, 0.714],
      'jacket_Arteni': null,
      'jacket_Tavush': null,
      'longsleeve_Debed': [0.505, 0.715, 0.42, 0.345],
      'polo_Getik': [0.1633, 0.3836, 0.4775, 0.3925],
      'polo_Jil': [0.18, 0.358, 0.37, 0.305],
      'shirt_Olympus': null,
      't-shirt_ARPI': null,
      't-shirt_Hakari': [0.128, 0.38, 0.485, 0.395],
      't-shirt_Vardenis': [0.5722, 0.8512, 0.84, 0.74],
      't-shirt_KARI': [0.0943, 0.2543, 0.895, 0.835],
      'top_Urasar': [0.61, 0.8, 0.225, 0.15],
      'jacket_Oroklini': null,
      'jacket_Ergates': [0.733, 0.875, 0.82, 0.763],
      'pants_Ergates': null,
      'top_Khustup': [0.062, 0.312, 0.75, 0.65],
      'top_Lessing': [0.533, 0.783, 0.365, 0.265],
      'Urasar': [0.271, 0.327, 0.17, 0.125]
    }[outfitName] || [0, 0, 0, 0]

    return placement
  }

  prepareOutfitsSection = async (outfits, section, onDownloadResource) => {
    //console.log(`prepareOutfitsSection() for ${section}:${outfits[section].name} started`)
    //console.log('  outfits[section]', outfits[section])

    if (outfits[section].name === null) {
      this.clearModelSection(section)
      return
    }

    if (outfits[section].name !== this.names[section]) {
      //const zip = await onDownloadResource('outfit', outfits.subtype + '/' + outfits[section].name + '.zip')

      const zip =
        section !== 'hat'
          ? await onDownloadResource('outfit', outfits.subtype + '/' + outfits[section].name + '.zip')
          : await onDownloadResource('hat', outfits.hat.name)

      //console.log('zip:', zip)

      const blobs = await proceedZip(zip)
      //console.log('blobs:', blobs)

      const model = await fetch(blobs['model.gltf'])
        .then((res) => res.text())
        .then((data) => JSON.parse(data))

      if (blobs['model.bin']) model.buffers[0].uri = blobs['model.bin']

      if (blobs['animations.bin']) model.buffers[1].uri = blobs['animations.bin']

      let headVisibilityMaskIndex = -1
      let bodyVisibilityMaskIndex = -1
      let recoloringMaskIndex = -1

      for (let i = 0; i < model.images.length; ++i) {
        //console.log('model.images[i].uri:', model.images[i].uri)

        if (model.images[i].uri.includes('HeadVisibilityMask')) headVisibilityMaskIndex = i
        if (model.images[i].uri.includes('BodyVisibilityMask')) bodyVisibilityMaskIndex = i
        if (model.images[i].uri.includes('RecoloringMask')) recoloringMaskIndex = i

        if (model.images[i].uri.includes('Color') && outfits[section].texture) {
          //console.log('Change Color texture')
          model.images[i].uri = outfits[section].texture
        } else {
          model.images[i].uri = blobs[model.images[i].uri]
            ? blobs[model.images[i].uri]
            : URL.createObjectURL(await onDownloadResource('texture', model.images[i].uri))
        }

        //      if (model.images[i].uri.includes('Color') this.uniforms.uBody.value = textureLoader.load(blobs[uri])
      }

      //console.log('model.images:', model.images)

      const textureLoader = new THREE.TextureLoader()
      const headVisibilityMask =
        headVisibilityMaskIndex > -1 ? await textureLoader.loadAsync(model.images[headVisibilityMaskIndex].uri) : null
      const bodyVisibilityMask =
        bodyVisibilityMaskIndex > -1 ? await textureLoader.loadAsync(model.images[bodyVisibilityMaskIndex].uri) : null
      const recoloringMask =
        recoloringMaskIndex > -1 ? await textureLoader.loadAsync(model.images[recoloringMaskIndex].uri) : null

      if (headVisibilityMask) headVisibilityMask.flipY = false
      if (bodyVisibilityMask) bodyVisibilityMask.flipY = false
      if (recoloringMask) recoloringMask.flipY = false

      this.recoloringMasks[section] = recoloringMask

      const url = URL.createObjectURL(new Blob([JSON.stringify(model, null, 2)], { type: 'text/plain' }))

      var gltfLoader = new GLTFLoader()
      const gltf = await gltfLoader.loadAsync(url)

      const nodes = await gltf.parser.getDependencies('node')
      const materials = await gltf.parser.getDependencies('material')

      if (section === 'complect' || section === 'top') {
        if (this.visibilityMasks['head']) this.visibilityMasks['head'].dispose()
        this.visibilityMasks['head'] = headVisibilityMask
      }

      if (this.visibilityMasks['detailed']) this.visibilityMasks['detailed'].dispose()

      if (section === 'complect') {
        for (const sect of ['complect', 'top', 'bottom', 'shoes']) {
          if (this.names[sect]) this.clearModelSection(sect)
        }

        this.visibilityMasks['complect'] = bodyVisibilityMask
        this.visibilityMasks['detailed'] = null
      } else {
        this.clearModelSection('complect')
        this.clearModelSection(section)

        this.visibilityMasks[section] = bodyVisibilityMask

        this.visibilityMasks['detailed'] = await prepareBodyVisibilityMask(
          this.visibilityMasks['top'],
          this.visibilityMasks['bottom'],
          this.visibilityMasks['shoes'],
        )
      }

      this.names[section] = outfits[section].name

      this.height = modelHeight(outfits.subtype)

      this.infos[section] = this.outfitsInfo(outfits[section].name)
      this.infos[section].useLogo = this.logoPlacement(outfits[section].name)[0] !== 0
      this.infos[section].logoPlacement = this.logoPlacement(outfits[section].name)
      this.infos[section].useTextureFromFile = this.useTextureFromFile(outfits[section].name)

      //console.log(`this.infos["${section}"]:`, this.infos[section])

      this.colors[section] = this.infos[section].colorInitial

      this.nodes[section] = {}
      nodes.forEach((item) => {
        this.nodes[section][item.name] = item
      })

      this.materials[section] = {}
      materials.forEach((item) => {
        item.side = THREE.DoubleSide
        this.materials[section][item.name] = item
        this.deftextures[section] = item.map
      })

      this.animations[section] = gltf.animations

      //console.log(`prepareOutfitsSection() for ${section}:${outfits[section].name} finished`)
      //console.log('this:', this)
    }

    if (
      outfits[section].name === this.names[section] &&
      outfits[section].texture_code !== this.texture_codes[section]
    ) {
      if (outfits[section].texture) {
        //console.log("set ai outfit's texture")
        //console.log('  outfits[section].texture:', outfits[section].texture)

        const textureLoader = new THREE.TextureLoader()

        if (this.textures[section]) {
          this.textures[section].dispose()
          this.textures[section] = null
        }

        if (!outfits[section].texture) return

        this.textures[section] = await textureLoader.loadAsync(outfits[section].texture)
        this.textures[section].flipY = false
        //this.textures[section].encoding = THREE.sRGBEncoding
        this.textures[section].colorSpace = THREE.SRGBColorSpace

        // console.log('texture:', this.textures[section])
        //console.log("this.materials[section][this.names[section]].map:", this.materials[section][this.names[section]].map)
        this.materials[section][this.names[section]].map = this.textures[section]
        this.texture_codes[section] = outfits[section].texture_code
      } else {
        // console.log("set default outfit's texture")
        this.materials[section][this.names[section]].map = this.deftextures[section]
        this.texture_codes[section] = null
        this.textures[section] = null
      }
    }

    if (!this.texture_codes[section]) this.recoloringOutfits(section, outfits[section].color, outfits[section].logo)
  }

  /**
   * Prepare Outfits
   *
   * param outfits            - object with outfits names
   * param onDownloadResource - callback to load .zip and absend in .zip textures
   */

  prepareOutfits = async (outfits, onDownloadResource) => {
    if (this.busy) return

    //console.log('prepareOutfits()')
    //console.log('  outfits:', outfits)

    // console.log('this.busy:', this.busy, ', set true')

    this.busy = true

    if (outfits['hat'].name && outfits['hat'].name !== this.names['hat'])
      await this.prepareOutfitsSection(outfits, 'hat', onDownloadResource)

    function doNothing() {}
    await doNothing()

    for (const section of ['complect', 'top', 'bottom', 'shoes']) {
      if (
        outfits[section].name !== this.names[section] ||
        outfits[section].texture_code !== this.texture_codes[section]
      )
        await this.prepareOutfitsSection(outfits, section, onDownloadResource)
    }

    if (!outfits['hat'].name && outfits['hat'].name !== this.names['hat']) {
      await this.prepareOutfitsSection(outfits, 'hat', onDownloadResource)
    }

    // console.log('this.busy:', this.busy, ', set false')
    this.busy = false
    this.version++

    //console.log('prepareOutfits() finished')
    //console.log('  this:', this)
  }

  /**
   * Update Blendshapes
   *
   * param storeAvatarBody - avatar body data
   */

  updateBlendshapes = (storeAvatarBody) => {
    //console.log('updateBlendshapes()')

    if (storeAvatarBody.nodes?.AvatarBody?.morphTargetDictionary) {
      const bodyMesh = storeAvatarBody.nodes['AvatarBody']
      //console.log("bodyMesh:", bodyMesh)

      for (const section of ['hat', 'complect', 'top', 'bottom', 'shoes']) {
        if (!storeOutfits.nodes[section]) continue
        this.scale = storeAvatarBody.scale

        const outfitMesh = storeOutfits.nodes[section][storeOutfits.names[section]]

        Object.keys(outfitMesh.morphTargetDictionary).forEach((prop) => {
          const outfitProp = outfitMesh.morphTargetDictionary[prop]

          const bodyProp = bodyMesh.morphTargetDictionary[prop]
          if (bodyMesh.morphTargetInfluences[bodyProp]) {
            outfitMesh.morphTargetInfluences[outfitProp] = bodyMesh.morphTargetInfluences[bodyProp]
          }
        })

        const headScale = storeAvatarBody.nodes['Head'].scale.x
        //console.log("headScale:", headScale)
        this.headScale = headScale

        const bone = this.nodes[section]['Head']
        bone.scale.x = bone.scale.y = bone.scale.z = headScale
      }
    }
  }

  /**
   * Outfits (complect) jsx
   *
   * param ref          - react.js useRef()
   * param params       - outfits' parameters
   */

  jsxOutfitsComplect = (ref, params) => {
    //console.log('jsxOutfitsComplect()')
    //console.log('  this.names:', this.names)

    if (!this.nodes['complect']) return null

    this.refs['complect'] = ref

    if (process.env.USE_INVISIBLEOUTFITS === '1')
      return <group {...params} dispose={null} ref={this.refs['complect']}></group>

    const complectName = this.names['complect']

    //this.saveTextureImage("complect-texture.png", this.materials['complect'][complectName].map.source.data.currentSrc)

    return (
      <group {...params} dispose={null} ref={this.refs['complect']} scale={[this.scale, this.scale, this.scale]}>
        <primitive object={this.nodes['complect'].Hips} />
        <skinnedMesh
          name={complectName}
          geometry={this.nodes['complect'][complectName].geometry}
          material={this.materials['complect'][complectName]}
          skeleton={this.nodes['complect'][complectName].skeleton}
          morphTargetDictionary={this.nodes['complect'][complectName].morphTargetDictionary}
          morphTargetInfluences={this.nodes['complect'][complectName].morphTargetInfluences}
        />
      </group>
    )
  }

  /**
   * Outfits (complect) jsx
   *
   * param ref          - react.js useRef()
   * param params       - outfits' parameters
   */

  jsxOutfitsHat = (ref, params) => {
    //console.log('jsxOutfitsHat()')
    //console.log('  this.names:', this.names)

    this.refs['hat'] = ref

    if (!this.nodes['hat'])
      //return null
      return <group {...params} dispose={null} ref={this.refs['hat']}></group>

    if (process.env.USE_INVISIBLEOUTFITS === '1')
      return <group {...params} dispose={null} ref={this.refs['hat']}></group>

    const hatName = this.names['hat']

    const bone = this.nodes['hat']['Head']
    bone.scale.x = bone.scale.y = bone.scale.z = this.headScale

    return (
      <group {...params} dispose={null} ref={this.refs['hat']} scale={[this.scale, this.scale, this.scale]}>
        <primitive object={this.nodes['hat'].Hips} />
        <skinnedMesh
          name={hatName}
          geometry={this.nodes['hat'][hatName].geometry}
          material={this.materials['hat'][hatName]}
          skeleton={this.nodes['hat'][hatName].skeleton}
          morphTargetDictionary={this.nodes['hat'][hatName].morphTargetDictionary}
          morphTargetInfluences={this.nodes['hat'][hatName].morphTargetInfluences}
        />
      </group>
    )
  }

  /**
   * Outfits (detailed) jsx
   *
   * param refTop    - react.js useref
   * param refBottom - react.js useref
   * param refShoes  - react.js useref
   * param params    - outfits' parameters
   */

  jsxOutfitsDetailed = (refTop, refBottom, refShoes, params) => {
    //console.log('jsxOutfitsDetailed()')
    //console.log('  this:', this)

    this.refs['top'] = refTop
    this.refs['bottom'] = refBottom
    this.refs['shoes'] = refShoes

    if (process.env.USE_INVISIBLEOUTFITS === '1')
      return (
        <>
          <group {...params} dispose={null} ref={this.refs['top']} />
          <group {...params} dispose={null} ref={this.refs['bottom']} />
          <group {...params} dispose={null} ref={this.refs['shoes']} />
        </>
      )

    const topName = this.names['top']
    const bottomName = this.names['bottom']
    const shoesName = this.names['shoes']

    if (!this.nodes['top'] || !this.nodes['bottom'] || !this.nodes['shoes']) return null

    return (
      <>
        <group {...params} dispose={null} ref={this.refs['top']} scale={[this.scale, this.scale, this.scale]}>
          <primitive object={this.nodes['top'].Hips} />
          <skinnedMesh
            name={topName}
            geometry={this.nodes['top'][topName].geometry}
            material={this.materials['top'][topName]}
            skeleton={this.nodes['top'][topName].skeleton}
            morphTargetDictionary={this.nodes['top'][topName].morphTargetDictionary}
            morphTargetInfluences={this.nodes['top'][topName].morphTargetInfluences}
          />
        </group>

        <group {...params} dispose={null} ref={this.refs['bottom']} scale={[this.scale, this.scale, this.scale]}>
          <primitive object={this.nodes['bottom'].Hips} />
          <skinnedMesh
            name={bottomName}
            geometry={this.nodes['bottom'][bottomName].geometry}
            material={this.materials['bottom'][bottomName]}
            skeleton={this.nodes['bottom'][bottomName].skeleton}
            morphTargetDictionary={this.nodes['bottom'][bottomName].morphTargetDictionary}
            morphTargetInfluences={this.nodes['bottom'][bottomName].morphTargetInfluences}
          />
        </group>

        <group {...params} dispose={null} ref={this.refs['shoes']} scale={[this.scale, this.scale, this.scale]}>
          <primitive object={this.nodes['shoes'].Hips} />
          <skinnedMesh
            name={shoesName}
            geometry={this.nodes['shoes'][shoesName].geometry}
            material={this.materials['shoes'][shoesName]}
            skeleton={this.nodes['shoes'][shoesName].skeleton}
            morphTargetDictionary={this.nodes['shoes'][shoesName].morphTargetDictionary}
            morphTargetInfluences={this.nodes['shoes'][shoesName].morphTargetInfluences}
          />
        </group>
      </>
    )
  }

  /**
   * Change Outfits Blandshapes
   *
   * param bodyParams   - body parameters
   */

  changeBlendshapes = (blendshapes) => {
    //console.log('changeBlendshapes()', blendshapes)

    for (const section of ['hat', 'complect', 'top', 'bottom', 'shoes']) {
      if (this.nodes[section] === null) continue

      if (blendshapes['height']) {
        //const bone = this.nodes[section]['Hips']
        //console.log("bone:", bone)

        //bone.scale.x = bone.scale.y = bone.scale.z = blendshapes['height'] / this.height
        //bone.parent.position.y = (blendshapes['height'] / this.height - 1) * bone.position.y

        this.scale = blendshapes['height'] / this.height

        continue
      }

      if (blendshapes['headScale']) {
        this.headScale = blendshapes['headScale']
        //const bone = this.nodes[section]['Head']
        //bone.scale.x = bone.scale.y = bone.scale.z = blendshapes['headScale']
        continue
      }

      if (this.refs[section]?.current) {
        const mesh = this.refs[section].current.children.find((item) => item.type === 'SkinnedMesh')
        for (const prop in blendshapes) {
          if (isNaN(blendshapes[prop])) continue
          if (mesh.morphTargetDictionary[prop] !== undefined)
            mesh.morphTargetInfluences[mesh.morphTargetDictionary[prop]] = blendshapes[prop]
        }
      }
    }
  }

  /**
   * Recoloring outfit
   *
   * section - outfit section
   * color   - new color
   */

  recoloringOutfits = async (section, color, logo) => {
    //console.log(`recoloringOutfits('${section}', '${color}')`)
    //console.log('  logo:', logo)

    //console.log('  this.infos[section]:', this.infos[section])

    if (color === undefined) color = null
    //if (this.infos[section] === null) return

    this.colors[section] = color

    if (color === null) color = this.infos[section].colorInitial

    const targetColor = new THREE.Vector3(
      parseInt('0x' + color[1] + color[2]) / 255,
      parseInt('0x' + color[3] + color[4]) / 255,
      parseInt('0x' + color[5] + color[6]) / 255,
    )

    const color0 = this.infos[section].colorInitial
    const initialColor = new THREE.Vector3(
      parseInt('0x' + color0[1] + color0[2]) / 255,
      parseInt('0x' + color0[3] + color0[4]) / 255,
      parseInt('0x' + color0[5] + color0[6]) / 255,
    )

    if (this.logoTextures[section]) {
      this.logoTextures[section].dispose()
      this.logoTextures[section] = null
    }

    if (logo) {
      const textureLoader = new THREE.TextureLoader()

      this.logoTextures[section] = await textureLoader.loadAsync(logo, (texture) => {
        //console.log("logoTexture:", texture)

        texture.flipY = true // false
        texture.colorSpace = THREE.SRGBColorSpace
      })
    } else {
      this.logoTextures[section] = null
    }

    this.logos[section] = logo

    const logoPlacement = this.logoPlacement(this.names[section])

    const uniforms = {
      //      uTexture: { value: this.textures[section] || this.deftextures[section] },
      uTexture: { value: this.deftextures[section] },
      uRecoloringMask: { value: this.recoloringMasks[section] },
      uR: { value: prepareR(initialColor, targetColor) },
      uLogo: { value: this.logoTextures[section] },
      uLogoPlacement: { value: this.logoTextures[section] ? new THREE.Vector4(...logoPlacement) : [0, 0, 0, 0] },
    }
    //console.log('  uniforms:', uniforms)

    const material = new THREE.ShaderMaterial({
      uniforms: uniforms,

      vertexShader: `
        precision mediump float;
        varying vec2 vUv;
        void main() {
          vUv = uv;
          gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.0);
        }
      `,

      fragmentShader: `
        precision mediump float;

        uniform sampler2D uTexture;
        uniform sampler2D uRecoloringMask;
        uniform sampler2D uLogo;

        uniform mat3 uR;

        uniform vec3 uTargetColor;
        uniform vec4 uLogoPlacement;

        varying vec2 vUv;

        void main() {
          vec4 res = texture2D(uTexture, vUv);
          vec4 mask = texture2D(uRecoloringMask, vUv);
          //vec4 logo = texture2D(uLogo, vUv);

          // if (mask.r > 0.) {
          //   //res = vec4(uTargetColor, 1.);
          //   res.rgb = clamp(res.rgb * uR, 0., 1.);
          // }

          vec3 weightedColor = mix(res.rgb, res.rgb * uR, mask.r);
          res.rgb = clamp(weightedColor, 0., 1.);

          vec2 mainSize = vec2(float(textureSize(uTexture, 0).x), float(textureSize(uTexture, 0).y) );
          vec2 logoSize = vec2(float(textureSize(uLogo, 0).x), float(textureSize(uLogo, 0).y));

          vec2 placementRectSize;
          placementRectSize.x = (uLogoPlacement.y - uLogoPlacement.x) * mainSize.x;
          placementRectSize.y = (uLogoPlacement.z - uLogoPlacement.w) * mainSize.y;

          float logoScale = min(placementRectSize.x / float(logoSize.x), placementRectSize.y / logoSize.y);
          vec2 logoScaledSize = logoSize * logoScale;

          vec2 logoPadding = (placementRectSize - logoScaledSize) / (2. * mainSize);

          //vec2 logoPadding = vec2(0., 0.);

          vec2 minUv, maxUv;

          minUv.x = uLogoPlacement.x + logoPadding.x;
          maxUv.x = uLogoPlacement.y - logoPadding.x;
          minUv.y = 1. - uLogoPlacement.z + logoPadding.y;
          maxUv.y = 1. - uLogoPlacement.w - logoPadding.y;

          if (vUv.x >= minUv.x && vUv.x <= maxUv.x && vUv.y >= minUv.y && vUv.y <= maxUv.y) {
            vec2 uvLogo = (vUv - minUv) / (maxUv - minUv);
            uvLogo.y = 1. - uvLogo.y;
            vec4 logo = texture2D(uLogo, uvLogo);
            res.rgb = logo.rgb * logo.a + res.rgb * (1. - logo.a);
            //res.rgb = logo.rgb;
          }

          gl_FragColor = res;

        }
      `,
    })

    //uniforms.uTexture.value.encoding = THREE.LinearEncoding
    uniforms.uTexture.value.colorSpace = THREE.LinearSRGBColorSpace

    const geometry = new THREE.PlaneGeometry(2, 2)

    const bufferScene = new THREE.Scene()

    const mesh = new THREE.Mesh(geometry, material)
    mesh.scale.set(1, -1, 1) // flip
    bufferScene.add(mesh)

    const camera = new THREE.OrthographicCamera(-1, 1, 1, -1, -1, 1)

    const renderer = new THREE.WebGLRenderer()
    renderer.setSize(uniforms.uTexture.value.source.data.width, uniforms.uTexture.value.source.data.height)

    renderer.render(bufferScene, camera)

    const image = renderer.domElement.toDataURL('image/png')
    //console.log("image:", image)

    /*
    const filename = `${this.names[section]}_${color}.png`
    console.log('filename:', filename)
    const link = document.createElement('a')
    link.download = filename
    link.href = image
    link.click()
    link.delete
*/

    material.dispose()
    geometry.dispose()
    renderer.dispose()
    renderer.forceContextLoss()

    //uniforms.uTexture.value.encoding = THREE.sRGBEncoding
    uniforms.uTexture.value.colorSpace = THREE.SRGBColorSpace

    const textureLoader = new THREE.TextureLoader()

    if (this.textures[section]) {
      this.textures[section].dispose()
      this.textures[section] = null
    }

    this.logos[section] = logo

    this.textures[section] = textureLoader.load(image, (texture) => {
      //console.log(`${section} texture:`, texture)

      texture.flipY = false
      //texture.encoding = THREE.sRGBEncoding
      texture.colorSpace = THREE.SRGBColorSpace
      this.textures[section] = texture
      this.materials[section][this.names[section]].map = texture
    })
  }

  saveTextureImage(filename, image) {
    const link = document.createElement('a')
    link.download = filename
    link.href = image
    link.click()
    link.delete
  }

}

const storeOutfits = new Outfits()

export { storeOutfits }
