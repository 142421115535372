import React, { FC } from 'react'
import styled from 'astroturf/react'
import { Cover } from '@/components/ui'
import { useLayoutContext } from '@/views/components/core/LayoutProvider'

export interface PresetsProps {
  presets: {
    id: string
    img: string
  }[]
  value: string
  setValue: (agr: string) => void
}

export const Presets: FC<PresetsProps> = ({ presets, value, setValue, disabled = false }) => {
  const { mediaQueries } = useLayoutContext()
  const variant = mediaQueries.isTablet ? 'not-mobile' : 'mobile'
  return (
    <Core variant={variant}>
      {presets.map(({ img, id }) => (
        <Preset disabled={disabled} key={id} active={id === value} onClick={() => setValue(id)}>
          <Cover width={86} height={86} />
          <Img src={img} />
        </Preset>
      ))}
    </Core>
  )
}

export interface CoreProps {
  variant: 'not-mobile' | 'mobile'
}

const Core = styled.div<CoreProps>`
  gap: 12px;
  &.variant-mobile {
    display: flex;
    overflow-x: auto;
    width: 100%;
  }

  &.variant-not-mobile {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }
`

interface PresetProps {
  active?: boolean
}

const Preset = styled.button<PresetProps>`
  position: relative;
  background-color: #fff;
  border-radius: var(--size-radius-small);
  width: 86px;
  height: 86px;
  flex-shrink: 0;

  &.active,
  &:hover {
    &:before {
      opacity: 1;
    }
  }

  &:before {
    content: '';
    @import '../../../../../assets/styles/mixins.scss';
    @include anim(opacity);
    border-radius: var(--size-radius-small);
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-image: linear-gradient(102.67deg, #7d1bf5 0%, #e32dd1 99.48%);
    opacity: 0;
  }
`

const Img = styled.img`
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: var(--size-radius-small);
  top: 0;
  left: 0;
`
