import { ConfigForGetting, METHODS } from './types'

let accessToken = ''
let businessAccessToken = ''
let businessMode = ''
let playerID = ''

// const orderedPipeline = ['gender', 'type', 'photo', 'noPhoto', 'choice', 'editor', 'result']
export const getSetting: ConfigForGetting = {
  method: METHODS.get,
  endpoint: '/settings.json',
  initialData: {
    sections: {},
    availableSections: [],
    texts: {
      general: {},
      sections: {},
    },
  },
}

export const getApiUrl = (endpoint) => {
  return process.env.USE_MIRROR === '0'
    ? `https://api.avatarsdk.com/${endpoint}`
    : `https://api-staging.avatarsdk.com/${endpoint}`
}

export const getFilesToAvatar: ConfigForGetting = {
  method: METHODS.get,
  endpoint: '/files-to-avatar.json',
  initialData: null,
}

export const getAccessToken = () => {
  return accessToken
}

export const getBusinessAccessToken = () => {
  return businessAccessToken
}

export const setBusinessAccessToken = (token: string) => {
  businessAccessToken = token
}

export const getBusinessMode = () => {
  return businessMode
}

export const setBusinessMode = (mode: string) => {
  businessMode = mode
}

export const getPlayerID = () => {
  return playerID
}

export const setPlayerID = (id: string) => {
  playerID = id
}

export const postAuth: ConfigForGetting = {
  method: METHODS.post,
  endpoint: getApiUrl('o/token/'),
  initialData: null,
  preFetching: (data) => {
    const body = new FormData()
    body.append('grant_type', 'client_credentials')
    return {
      headers: {
        Authorization: 'Basic ' + btoa(data.client_id + ':' + data.client_secret),
      },
      body,
    }
  },
  postFetching: async (response, initialData) => {
    if (!response) {
      return initialData
    }

    const data = await response.json()
    accessToken = data.access_token
    return data
  },
}

export const createPlayerID: ConfigForGetting = {
  method: METHODS.post,
  endpoint: getApiUrl('players/'),
  initialData: null,
  privateRequest: true,
}

export const createQR: ConfigForGetting = {
  method: METHODS.post,
  endpoint: getApiUrl('selfies/'),
  initialData: null,
  privateRequest: true,
}

export const getQRFile: ConfigForGetting = {
  method: METHODS.get,
  endpoint: getApiUrl('selfies/'),
  initialData: null,
  privateRequest: true,
  postFetching: async (response, initialData) => {
    if (!response) {
      return initialData
    }
    const imageBlob = await response.blob()
    return URL.createObjectURL(imageBlob)
  },
}

export const polingQRFile: ConfigForGetting = {
  method: METHODS.get,
  endpoint: ({ qrToken }) => getApiUrl(`selfies/${qrToken}/`),
  initialData: null,
  privateRequest: true,
}

export const polingUploadFile: ConfigForGetting = {
  method: METHODS.get,
  endpoint: ({ qrToken }) => getApiUrl(`selfies/${qrToken}/file/`),
  initialData: null,
  privateRequest: true,
  postFetching: async (response, initialData) => {
    if (!response) {
      return initialData
    }
    return await response.blob()
  },
}

export const createAvatar: ConfigForGetting = {
  method: METHODS.post,
  endpoint: getApiUrl('avatars/'),
  initialData: null,
  privateRequest: true,
}

export const setDescription: ConfigForGetting = {
  method: METHODS.patch,
  endpoint: ({ avatarID }) => getApiUrl(`avatars/${avatarID}/`),
  initialData: null,
  privateRequest: true,
}

export const pollingCreatingAvatar: ConfigForGetting = {
  method: METHODS.get,
  endpoint: ({ avatarID }) => getApiUrl(`avatars/${avatarID}/`),
  initialData: null,
  privateRequest: true,
}

export const exportsFile: ConfigForGetting = {
  method: METHODS.get,
  endpoint: ({ avatarID }) => getApiUrl(`avatars/${avatarID}/exports/`),
  initialData: null,
  privateRequest: true,
}

export const avatarFile: ConfigForGetting = {
  method: METHODS.get,
  endpoint: ({ avatarID, exportsCodeID }) =>
    getApiUrl(`avatars/${avatarID}/exports/${exportsCodeID}/files/avatar/file/`),
  initialData: null,
  privateRequest: true,
  postFetching: async (response, initialData) => {
    if (!response) {
      return initialData
    }
    const imageBlob = await response.blob()
    return imageBlob
  },
}

export const haircutFile: ConfigForGetting = {
  method: METHODS.get,
  endpoint: ({ avatarID, exportsCodeID, haircutName }) =>
    getApiUrl(`avatars/${avatarID}/exports/${exportsCodeID}/files/${haircutName}/file/`),
  initialData: null,
  privateRequest: true,
  postFetching: async (response, initialData) => {
    if (!response) {
      return initialData
    }
    const imageBlob = await response.blob()
    return imageBlob
  },
}

export const glassesFile: ConfigForGetting = {
  method: METHODS.get,
  endpoint: ({ avatarID, exportsCodeID, glassesName }) =>
    getApiUrl(`avatars/${avatarID}/exports/${exportsCodeID}/files/${glassesName}/file/`),
  initialData: null,
  privateRequest: true,
  postFetching: async (response, initialData) => {
    if (!response) {
      return initialData
    }
    const imageBlob = await response.blob()
    return imageBlob
  },
}

export const hatFile: ConfigForGetting = {
  method: METHODS.get,
  endpoint: ({ avatarID, exportsCodeID, hatName }) =>
    getApiUrl(`avatars/${avatarID}/exports/${exportsCodeID}/files/${hatName}/file/`),
  initialData: null,
  privateRequest: true,
  postFetching: async (response, initialData) => {
    if (!response) {
      return initialData
    }
    const imageBlob = await response.blob()
    return imageBlob
  },
}


export const getLocalModel: ConfigForGetting = {
  method: METHODS.get,
  endpoint: ({ url }) => `${url}`,
  initialData: null,
  privateRequest: true,
  postFetching: async (response, initialData) => {
    if (!response) {
      return initialData
    }
    const imageBlob = await response.blob()
    return URL.createObjectURL(imageBlob)
  },
}
