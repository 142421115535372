// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Menu-Core-module--cls1--fAzSJ { /*!*/ }\n\n.Menu-Core-module--cls2--r4rWI {\n}\n.Menu-Core-module--cls2--r4rWI.Menu-Core-module--variant-mobile--Hm8zj {\n  display: flex;\n  flex-direction: column-reverse;\n}\n.Menu-Core-module--cls2--r4rWI.Menu-Core-module--variant-not-mobile--KflDp {\n  display: flex;\n  align-items: center;\n  position: absolute;\n  height: 100%;\n  left: 0;\n  top: 0;\n}", "",{"version":3,"sources":["webpack://./src/views/page/generator/sections/Editor/components/Menu-Core.module.scss"],"names":[],"mappings":"AAAA,iCAAA,IAAA,EAAA;;AACA;AAGA;AAAA;EACA,aAAA;EACA,8BAAA;AAEA;AACA;EACA,aAAA;EACA,mBAAA;EACA,kBAAA;EACA,YAAA;EACA,OAAA;EACA,MAAA;AACA","sourcesContent":[".cls1 { /*!*/ }\n.cls2 {\ncomposes: cls1;\n\n&.variant-mobile {\ndisplay: flex;\nflex-direction: column-reverse;\n}\n\n&.variant-not-mobile {\ndisplay: flex;\nalign-items: center;\nposition: absolute;\nheight: 100%;\nleft: 0;\ntop: 0;\n}\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"cls1": "Menu-Core-module--cls1--fAzSJ",
	"cls2": "Menu-Core-module--cls2--r4rWI Menu-Core-module--cls1--fAzSJ",
	"variant-mobile": "Menu-Core-module--variant-mobile--Hm8zj",
	"variant-not-mobile": "Menu-Core-module--variant-not-mobile--KflDp"
};
export default ___CSS_LOADER_EXPORT___;
