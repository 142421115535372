// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Photo-ActionUpload-module--cls1--mV5ru { /*!*/ }\n\n.Photo-ActionUpload-module--cls2--pdGCL {\n  display: block;\n}\n.Photo-ActionUpload-module--cls2--pdGCL:not(:last-child) {\n  margin-bottom: 12px;\n}", "",{"version":3,"sources":["webpack://./src/views/page/generator/sections/Photo-ActionUpload.module.scss"],"names":[],"mappings":"AAAA,0CAAA,IAAA,EAAA;;AACA;EAGA,cAAA;AACA;AAAA;EACA,mBAAA;AAEA","sourcesContent":[".cls1 { /*!*/ }\n.cls2 {\ncomposes: cls1;\n\ndisplay: block;\n&:not(:last-child) {\nmargin-bottom: 12px;\n}\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"cls1": "Photo-ActionUpload-module--cls1--mV5ru",
	"cls2": "Photo-ActionUpload-module--cls2--pdGCL Photo-ActionUpload-module--cls1--mV5ru"
};
export default ___CSS_LOADER_EXPORT___;
