import React, { FC } from 'react'
import styled from 'astroturf/react'
import { Cover } from '@/components/ui'
import { useLayoutContext } from '@/views/components/core/LayoutProvider'
import { storeEditor, storeGeneratorPipeline } from '@/services/store'
import { SliderPicker, HuePicker } from 'react-color'

export interface MPresetsProps {
  presetsTop: {
    id: string
    img: string
  }[]
  presetsBottom: {
    id: string
    img: string
  }[]
  presetsComplect: {
    id: string
    img: string
  }[]
  presetsShoes: {
    id: string
    img: string
  }[]
  value: {name: string}[]
  setValue: (arg: string, arg2: string) => void
}

const presetStyle = {
  marginRight: '10px', // Adjust the margin value as needed
  marginBottom: '10px'
};

const iconSize = '86px'; // Adjust the icon size as needed

export const MPresets: FC<MPresetsProps> = ({ presetsHat, presetsTop, presetsBottom, presetsComplect, presetsShoes, value, setValue }) => {
  const { mediaQueries } = useLayoutContext()
  const variant = mediaQueries.isTablet ? 'not-mobile' : 'mobile'
  return (
    <Core variant={variant}>
      <table>
        <tbody>
          <tr key='items'>

              {presetsHat.map(({ img, id }) => (
                <td key={id}>
              <MPreset key={id} active={value.includes(id)} onClick={() => setValue(id, 'hat')} style={presetStyle}>
                <Img src={img} style={{ width: iconSize, height: iconSize }} /> {/* Adjusted icon size */}
              </MPreset>
              </td>
            ))}

              {presetsComplect.map(({ img, id }) => (
                <td key={id}>
              <MPreset key={id} active={value.includes(id)} onClick={() => setValue(id, 'complect')} style={presetStyle}>
                <Img src={img} style={{ width: iconSize, height: iconSize }} /> {/* Adjusted icon size */}
              </MPreset>
              </td>
            ))}

            {presetsTop.map(({ img, id }) => ( 
            <td key={id}>
              <MPreset key={id} active={value.includes(id)} onClick={() => setValue(id, 'top')} style={presetStyle}>
                <Img src={img} style={{ width: iconSize, height: iconSize }} /> {/* Adjusted icon size */}
              </MPreset>
              </td>
            ))}
           {presetsBottom.map(({ img, id }) => (
             <td key={id}>
              <MPreset key={id} active={value.includes(id)} onClick={() => setValue(id, 'bottom')} style={presetStyle}>
                <Img src={img} style={{ width: iconSize, height: iconSize }} /> {/* Adjusted icon size */}
              </MPreset>
              </td>
            ))}
            {presetsShoes.map(({ img, id }) => (
              <td key={id}>
              <MPreset key={id} active={value.includes(id)} onClick={() => setValue(id, 'shoes')} style={presetStyle}>
                <Img src={img} style={{ width: iconSize, height: iconSize }} /> {/* Adjusted icon size */}
              </MPreset>
              </td>
            ))}
          </tr>
          <tr key='colors'>
            <td colSpan={presetsComplect.length} style={{ padding: '0 5px 0 5px' }}>
              {storeEditor.outfits['complect'].name && storeEditor.outfits['complect'].texture_code === null && storeEditor.outfits['complect'].colorInitial !== '#000000' && (
                <SliderPicker
                color={storeEditor.outfits['complect'].color || '#ffffff'}
                  onChangeComplete={(color) => storeEditor.setOutfitsColor('complect', color.hex)}
                />)}
            </td>
            <td colSpan={presetsTop.length} style={{ padding: '0 5px 0 5px' }}>
              {storeEditor.outfits['top'].name && storeEditor.outfits['top'].texture_code === null && storeEditor.outfits['top'].colorInitial !== '#000000' && (
                <SliderPicker
                color={storeEditor.outfits['top'].color || '#ffffff'}
                  onChangeComplete={(color) => storeEditor.setOutfitsColor('top', color.hex)}
                />
              )}
            </td>
            <td colSpan={presetsBottom.length} style={{ padding: '0 5px 0 5px' }}>
              {storeEditor.outfits['bottom'].name && storeEditor.outfits['bottom'].texture_code === null && storeEditor.outfits['bottom'].colorInitial !== '#000000' && (
                <SliderPicker
                  color={storeEditor.outfits['bottom'].color || '#ffffff'}
                  onChangeComplete={(color) => storeEditor.setOutfitsColor('bottom', color.hex)}
                />
              )}
            </td>
            <td colSpan={presetsShoes.length} style={{ padding: '0 5px 0 5px' }}>
              {storeEditor.outfits['shoes'].name && storeEditor.outfits['shoes'].texture_code === null && storeEditor.outfits['shoes'].colorInitial !== '#000000' && (
                <SliderPicker
                color={storeEditor.outfits['shoes'].color || '#ffffff'}
                  onChangeComplete={(color) => storeEditor.setOutfitsColor('shoes', color.hex)}
                />
              )}
            </td>
          </tr> 
        </tbody>
      </table>
    </Core>
  )
}

export interface CoreProps {
  variant: 'not-mobile' | 'mobile'
}

const Core = styled.div<CoreProps>`
  gap: 12px;
  &.variant-mobile {
    display: flex;
    overflow-x: auto;
    width: 100%;
  }

  &.variant-not-mobile {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }
`

interface MPresetProps {
  active?: boolean
}

const MPreset = styled.button<MPresetProps>`
  position: relative;
  background-color: #fff;
  border-radius: var(--size-radius-small);
  width: ${iconSize}; /* Adjusted icon size */
  height: ${iconSize}; /* Adjusted icon size */
  flex-shrink: 0;

  &.active,
  &:hover {
    &:before {
      opacity: 1;
    }
  }

  &:before {
    content: '';
    @import '../../../../../assets/styles/mixins.scss';
    @include anim(opacity);
    border-radius: var(--size-radius-small);
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-image: linear-gradient(102.67deg, #7d1bf5 0%, #e32dd1 99.48%);
    opacity: 0;
  }
`

const Img = styled.img`
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: var(--size-radius-small);
  top: 0;
  left: 0;
`