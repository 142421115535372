// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Article-Text-module--cls1--vnbF_ { /*!*/ }\n\n.Article-Text-module--cls2--Tyesl {\n  opacity: 0.5;\n}\n.Article-Text-module--cls2--Tyesl p {\n  font-weight: 500;\n}\n.Article-Text-module--cls2--Tyesl p:not(:last-child) {\n  margin-bottom: 20px;\n}", "",{"version":3,"sources":["webpack://./src/views/page/apps/Article-Text.module.scss"],"names":[],"mappings":"AAAA,oCAAA,IAAA,EAAA;;AACA;EAGA,YAAA;AACA;AAAA;EACA,gBAAA;AAEA;AAAA;EACA,mBAAA;AAEA","sourcesContent":[".cls1 { /*!*/ }\n.cls2 {\ncomposes: cls1;\n\nopacity: 0.5;\np {\nfont-weight: 500;\n\n&:not(:last-child) {\nmargin-bottom: 20px;\n}\n}\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"cls1": "Article-Text-module--cls1--vnbF_",
	"cls2": "Article-Text-module--cls2--Tyesl Article-Text-module--cls1--vnbF_"
};
export default ___CSS_LOADER_EXPORT___;
