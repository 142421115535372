import React, { FC } from 'react'
import { Outlet } from 'react-router-dom'
import styled from 'astroturf/react'
import { Typography, Loader, Button } from '@/components/ui'
import { Logo, Home, Profile, ArrowLeft, ArrowRight } from '@/components/ui/icons'
import { Container } from '@/components/wrapper'

export const GeneratorPageLayout: FC = () => {
  // if (false) {
  //   return (
  //     <Loading>
  //       <Logo />
  //       <NameCompany variant='h3'>MetaPerson</NameCompany>
  //       <Loader spinner='circle' variant='content' />
  //     </Loading>
  //   )
  // }
  // if (true) {
  //   return (
  //     <Container variant='center'>
  //       <Loader spinner='infinite' variant='content'/>
  //       <LoadingMSG variant='small'>Generating avatar. Please wait for a few seconds</LoadingMSG>
  //     </Container>
  //   )
  // }
  return (
    <Core>
      <Header>
        <ToStart isOnlyIcon variant='surface'>
          <Profile />
        </ToStart>
        <ToPrev isOnlyIcon variant='surface'>
          <ArrowLeft />
        </ToPrev>
        <ToNext isOnlyIcon variant='surface'>
          <ArrowRight />
        </ToNext>
      </Header>
      <Outlet />
    </Core>
  )
}

const Loading = styled.div`
  min-height: var(--app-height);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding-top: 32vh;
  padding-bottom: 120px;
`

const NameCompany = styled(Typography)`
  margin-top: 22px;
  margin-bottom: auto;
`

const LoadingMSG = styled(Typography)`
  margin-top: 4px;
  text-align: center;
  max-width: 240px;
`

const Core = styled.div`
  min-height: var(--app-height);
`

const Header = styled.div`
  display: flex;
  width: calc(100% - 64px);
  margin-left: auto;
  margin-right: auto;
  padding-top: 32px;
  position: absolute;
  top: 0;
  left: 32px;
`

const ToStart = styled(Button)``
const ToPrev = styled(Button)`
  margin-left: auto;
`
const ToNext = styled(Button)`
  margin-left: 8px;
`
