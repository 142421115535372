import React, { useEffect, useCallback, useState } from 'react'
import { Generator as GeneratorPage } from '@/views/page/generator'
import { LayoutProvider } from './LayoutProvider'
import { QueryClient, QueryClientProvider } from 'react-query'

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      staleTime: 240000,
    },
  },
})

export const Generator = () => {
  const [externalData, setExternalData] = useState('')
  const getExternalData = useCallback((e) => {
    if (e?.data?.eventName === 'login') {
      setExternalData(e.data)
    }
  }, [])

  useEffect(() => {
    window.addEventListener('message', getExternalData)
    return () => window.removeEventListener('message', getExternalData)
  }, [])
  return (
    <QueryClientProvider client={queryClient}>
      <LayoutProvider>
        {externalData.clientID && externalData.clientSecret && (
          <GeneratorPage clientID={externalData.clientID} clientSecret={externalData.clientSecret} />
        )}
      </LayoutProvider>
    </QueryClientProvider>
  )
}
