import React, { FC, ReactNode } from 'react'
import styled from 'astroturf/react'
import { useLayoutContext } from '@/views/components/core/LayoutProvider'

export interface ContainerProps {
  children: ReactNode
  className?: string
  variant?: 'main' | 'center' | 'center-big'
}

export const Container: FC<ContainerProps> = ({ children, className, variant = 'main' }) => {
  const { mediaQueries } = useLayoutContext()
  return (
    <Core className={className} variant={variant} mod={mediaQueries.isTablet ? 'tablet' : 'mobile'}>
      {children}
    </Core>
  )
}

export interface CoreProps {
  variant: 'main' | 'center' | 'center-big'
  mod: 'tablet' | 'mobile'
}

const Core = styled.div<CoreProps>`
  margin-left: auto;
  margin-right: auto;
  width: calc(100% - 260px);

  &.variant-main {
    max-width: 948px;
  }

  &.variant-center {
    max-width: 440px;
    min-height: var(--app-height);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding-top: 70px;
    padding-bottom: 20px;
  }

  &.variant-center-big {
    min-height: var(--app-height);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    max-width: 768px;
    text-align: center;
    padding-top: 70px;
    padding-bottom: 20px;
  }

  &.mod-tablet {
    width: calc(100% - 260px);
  }

  &.mod-mobile {
    width: calc(100% - 72px);
  }
`
