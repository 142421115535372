import React from 'react'
import { useParams } from 'react-router-dom'
import { Row, Col } from 'react-grid-system'
import styled from 'astroturf/react'
import { css } from 'astroturf'

import { useLayoutContext } from '@/views/components/core/LayoutProvider'
import { Typography } from '@/components/ui'
import { AvatarCard, BackLink } from './components'
import { Plus } from '@/components/ui/icons'

import avatarPreview from '@/assets/demo/avatar-gallery.png'
const imgs: { img: string; id: string }[] = []

for (let index = 0; index < 10; index++) {
  imgs.push({
    img: avatarPreview,
    id: Math.random().toString(),
  })
}

export type ConnectedAvatars = { img: string; id: string }[]

export const AvatarSelection = () => {
  const { setSelectedAvatars } = useLayoutContext()
  const { article } = useParams()
  return (
    <>
      <Head>
        <BackLink to={`/apps/${article}`} />
        <Typography as='h1' variant='h2'>
          Select avatar
        </Typography>
      </Head>
      <Row>
        {!!imgs.length &&
          imgs.map((item) => (
            <ColCard key={item.id} sm={4}>
              <AvatarCard
                key={item.id}
                css={css`
                  margin-top: 12px;
                `}
                img={item.img}
                onClick={() =>
                  setSelectedAvatars((prev) => {
                    prev.push(item)
                    return prev
                  })
                }
                icon={Plus}
              />
            </ColCard>
          ))}
      </Row>
    </>
  )
}

const Head = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  margin-bottom: 28px;
`

const ColCard = styled(Col)`
  margin-top: 24px;
`
