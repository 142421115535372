// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Gender-Form-module--cls1--a5rvX { /*!*/ }\n\n.Gender-Form-module--cls2--pvrtt {\n  margin-top: 32px;\n  max-width: 360px;\n  width: 100%;\n  margin-left: auto;\n  margin-right: auto;\n}", "",{"version":3,"sources":["webpack://./src/views/page/generator/sections/Gender-Form.module.scss"],"names":[],"mappings":"AAAA,mCAAA,IAAA,EAAA;;AACA;EAGA,gBAAA;EACA,gBAAA;EACA,WAAA;EACA,iBAAA;EACA,kBAAA;AACA","sourcesContent":[".cls1 { /*!*/ }\n.cls2 {\ncomposes: cls1;\n\nmargin-top: 32px;\nmax-width: 360px;\nwidth: 100%;\nmargin-left: auto;\nmargin-right: auto;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"cls1": "Gender-Form-module--cls1--a5rvX",
	"cls2": "Gender-Form-module--cls2--pvrtt Gender-Form-module--cls1--a5rvX"
};
export default ___CSS_LOADER_EXPORT___;
