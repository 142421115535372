// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Generator-Footer-module--cls1--GVtHq { /*!*/ }\n\n.Generator-Footer-module--cls2--OV4M9 {\n  display: flex;\n  position: absolute;\n  bottom: 40px;\n}", "",{"version":3,"sources":["webpack://./src/views/page/generator/Generator-Footer.module.scss"],"names":[],"mappings":"AAAA,wCAAA,IAAA,EAAA;;AACA;EAGA,aAAA;EACA,kBAAA;EACA,YAAA;AACA","sourcesContent":[".cls1 { /*!*/ }\n.cls2 {\ncomposes: cls1;\n\ndisplay: flex;\nposition: absolute;\nbottom: 40px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"cls1": "Generator-Footer-module--cls1--GVtHq",
	"cls2": "Generator-Footer-module--cls2--OV4M9 Generator-Footer-module--cls1--GVtHq"
};
export default ___CSS_LOADER_EXPORT___;
