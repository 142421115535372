import React from 'react'

import * as THREE from 'three'
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader'

import { proceedZip, modelHeight } from '../helpers/prepareModel'

import { haircut_fragment1 } from '../shaders/haircut_fragment1.glsl'
import { haircut_fragment2 } from '../shaders/haircut_fragment2.glsl'

class Haircut {
  ref = null
  prevRef = null

  color = null
  subtype = null
  name = null
  hide = false

  nodes = null
  materials = null
  textures = null

  height = 0
  scale = 1
  headScale = 1

  version = 0
  busy = false

  uniforms = {}

  /**
   * Constructor
   */

  constructor() {
    this.uniforms.uAO = { value: null }
    this.uniforms.uAlpha = { value: null }
    this.uniforms.uColor = { value: null }
    this.uniforms.uDepth = { value: null }
    this.uniforms.uRoot = { value: null }
    this.uniforms.uShade = { value: null }
    this.uniforms.uUniqueID = { value: null }

    this.uniforms.uRecoloring = { value: false }
    this.uniforms.uTargetColor = { value: new THREE.Vector4(0, 0, 0, 0) }
    this.uniforms.uRootsColor = { value: new THREE.Vector4(0, 0, 0, 0) }

    this.uniforms.uAOImpact = { value: 0.75 }
    this.uniforms.uDepthImpact = { value: 1.0 }
    this.uniforms.uIDsImpact = { value: 1.0 }
  }

  /**
   * Clear model
   */

  /**
   * Hide model
   */

  clearModel = () => {
    //console.log('clearModel()')

    if (this.nodes && this.nodes[this.name]?.geometry) this.nodes[this.name].geometry.dispose()
    if (this.nodes && this.nodes[this.name]?.Hips) this.nodes[this.name].Hips.dispose()

    for (const key in this.materials) this.materials[key].dispose()

    this.nodes = null
    this.materials = null
    //this.textures = null

    this.subtype = null
    this.name = null
    //    this.color = null
  }

  /**
   * Prepare Haircut
   *
   * param haircut            - object with haircut name
   * param onDownloadResource - callback to load .zip and absend in .zip textures
   */

  prepareHaircut = async (haircut, onDownloadResource) => {
    //console.log('prepareHaircut()')
    //console.log('  haircut:', haircut)
    //console.log('  this:', this)

    //console.log('  performance.memory.usedJSHeapSize, MB:', performance.memory.usedJSHeapSize / 1048576)

    if (haircut.preset === null) {
      this.clearModel()
      this.version++
      return
    }

    if (haircut.preset === 'Bald') {
      this.clearModel()
      this.subtype = haircut.subtype
      this.name = haircut.preset
      this.color = null
      this.version++
      return
    }

    this.busy = true
    this.color = null

    //    const zip = await onDownloadResource('haircut', haircut.subtype + '/' + haircut.preset + '.zip')
    const zip = await onDownloadResource('haircut', haircut.preset)
    //console.log('zip:', zip)

    const blobs = await proceedZip(zip)
    //console.log('blobs:', blobs)

    const model = await fetch(blobs['model.gltf'])
      .then((res) => res.text())
      .then((data) => JSON.parse(data))

    if (blobs['model.bin']) model.buffers[0].uri = blobs['model.bin']
    if (blobs['animations.bin']) model.buffers[1].uri = blobs['animations.bin']

    //console.log('model.images:', model.images)

    this.textures = {}
    const textureLoader = new THREE.TextureLoader()

    for (let i = 0; i < model.images.length; ++i) {
      //console.log('model.images:', i, model.images[i])

      const uri = model.images[i].uri
      model.images[i].uri = blobs[model.images[i].uri]
        ? blobs[model.images[i].uri]
        : URL.createObjectURL(await onDownloadResource('texture', model.images[i].uri))

      for (const texture_name of ['Color', 'Alpha', 'AO', 'Depth', 'UniqueID', 'Root', 'Shade', 'Scalp']) {
        if (uri.includes(texture_name) && !uri.includes('ScalpShade')) {
          //             this.textures[texture_name] = uri
          this.textures[texture_name] = textureLoader.load(model.images[i].uri)
          this.textures[texture_name].flipY = false
          //this.textures[texture_name].encoding = THREE.sRGBEncoding
          this.textures[texture_name].colorSpace = THREE.SRGBColorSpace
        }
      }

      //      for (const texture_name of ['ScalpShadeAO', 'ScalpShadeAlpha']) {
      //        if (uri.includes(texture_name)) {
      //             this.textures[texture_name] = uri
      //          this.textures[texture_name] = textureLoader.load(model.images[i].uri)
      //          this.textures[texture_name].flipY = false
      //          this.textures[texture_name].encoding = THREE.sRGBEncoding
      //        }
      //      }
    }
    //console.log('model:', model)
    //console.log('this.textures:', this.textures)
    //console.log('this.textures.Scalp:', this.textures.Scalp)

    const url = URL.createObjectURL(new Blob([JSON.stringify(model, null, 2)], { type: 'text/plain' }))
    //console.log('url:', url)

    var gltfLoader = new GLTFLoader()
    const gltf = await gltfLoader.loadAsync(url)

    const nodes = await gltf.parser.getDependencies('node')
    const materials = await gltf.parser.getDependencies('material')

    this.clearModel()

    this.nodes = {}
    nodes.forEach((item) => {
      this.nodes[item.name] = item
    })

    this.materials = {}
    materials.forEach((material) => {
      //console.log('material:', material)

      material.onBeforeCompile = (shader) => {
        //console.log("material.onBeforeCompile()")
        //console.log("  material:", material)

        //shader.vertexShader = shader.vertexShader.replace('#include <uv_vertex>', '#include <uv_vertex>\nvUv=uv;')

        shader.fragmentShader = shader.fragmentShader
          .replace(
            '#include <clipping_planes_pars_fragment>',
            '#include <clipping_planes_pars_fragment>\n' + haircut_fragment1,
          )
          .replace(
            'vec4 diffuseColor = vec4( diffuse, opacity );',
            'vec4 diffuseColor = vec4( diffuse, opacity );\n' + haircut_fragment2,
          )
          .replace('#include <map_fragment>', '//#include <map_fragment>')

        this.uniforms.uAO = { value: this.textures.AO }
        this.uniforms.uAlpha = { value: this.textures.Alpha }
        this.uniforms.uColor = { value: this.textures.Color }
        this.uniforms.uDepth = { value: this.textures.Depth }
        this.uniforms.uRoot = { value: this.textures.Root }
        this.uniforms.uShade = { value: this.textures.Shade }
        this.uniforms.uUniqueID = { value: this.textures.UniqueID }

        this.uniforms.uRecoloring.value = this.color !== null ? true : false

        shader.uniforms = { ...shader.uniforms, ...this.uniforms }

        //console.log('shader:', shader)
        //console.log("this:", this)
      }

      this.materials[material.name] = material

      if (process.env.USE_MULTIPASS_HAIRCUT_RENDER === '1') {
        // for 2-passes render method
        //const materialA1 = material.clone()
        //const materialA2 = material.clone()

        const materialA1 = new THREE.MeshStandardMaterial({
          map: this.textures['Color'],
          alphaMap: this.textures['Alpha'],
        })
        const materialA2 = new THREE.MeshStandardMaterial({
          map: this.textures['Color'],
          alphaMap: this.textures['Alpha'],
        })

        materialA1.transparent = true
        materialA1.side = THREE.DoubleSide
        materialA1.opacity = 0.8
        materialA1.needsUpdate = true
        materialA1.blending = THREE.NormalBlending
        materialA1.depthFunc = THREE.LessDepth
        materialA1.depthTest = true
        materialA1.depthWrite = false
        materialA1.roughness = 0.6
        materialA1.blendDst = THREE.OneMinusDstColorFactor
        //        materialA1.shading = THREE.SmoothShading
        materialA1.onBeforeCompile = material.onBeforeCompile

        materialA2.transparent = true
        materialA2.side = THREE.FrontSide
        materialA2.opacity = 0.8
        materialA2.needsUpdate = true
        materialA2.blending = THREE.NormalBlending
        materialA2.depthTest = true
        materialA2.alphaTest = 0.65
        materialA2.onBeforeCompile = material.onBeforeCompile

        // for 3-passes render method
        const materialB1 = material.clone()
        const materialB2 = material.clone()
        const materialB3 = material.clone()

        materialB1.side = THREE.BackSide
        materialB1.depthWrite = false
        materialB1.onBeforeCompile = material.onBeforeCompile

        materialB2.side = THREE.FrontSide
        materialB2.depthWrite = false
        materialB2.onBeforeCompile = material.onBeforeCompile

        //materialB3.side = THREE.DoubleSide
        materialB3.depthWrite = true
        materialB3.alphaToCoverage = true
        materialB3.onBeforeCompile = material.onBeforeCompile

        //material1: BackSide, depthWrite=false, alphaToCoverage=false
        //material2: FrontSide, depthWrite=true, alphaToCoverage=false
        //Для второго материала можно попробовать включить alphaToCoverage. Но как правило, alphaToCoverage влияет больше всего на fps.

        //const materialD1 = new THREE.MeshStandardMaterial({
        //  map: this.textures['Color'],
        //  alphaMap: this.textures['Alpha'],
        //})
        //const materialD2 = new THREE.MeshStandardMaterial({
        //  map: this.textures['Color'],
        //  alphaMap: this.textures['Alpha'],
        //})

        //const materialD1 = material.clone()
        //const materialD2 = material.clone()

        //materialD1.side = THREE.BackSide
        //materialD1.depthWrite = false
        //materialD1.alphaToCoverage = false
        //materialD1.onBeforeCompile = material.onBeforeCompile

        //materialD2.side = THREE.FrontSide
        //materialD2.depthWrite = true
        //materialD2.alphaToCoverage = false
        //materialD2.onBeforeCompile = material.onBeforeCompile

        this.materials[material.name + 'A1'] = materialA1
        this.materials[material.name + 'A2'] = materialA2

        //this.materials[material.name + 'D1'] = materialD1
        //this.materials[material.name + 'D2'] = materialD2

        this.materials[material.name + 'B1'] = materialB1
        this.materials[material.name + 'B2'] = materialB2
        this.materials[material.name + 'B3'] = materialB3
      } // process.env.USE_MULTIPASS_HAIRCUT_RENDER
    })

    this.subtype = haircut.subtype
    this.name = haircut.preset
    this.color = null

    // FIX IT
    //this.nodes[this.name].geometry.morphAttributes = {}
    //this.nodes[this.name].geometry.morphTargetsRelative = false
    // FIX IT

    /*
const bones = this.nodes[this.name].skeleton.bones.splice(0, 11)
const skeleton = new THREE.Skeleton( bones );
this.nodes[this.name].add( bones[ 0 ] );
this.nodes[this.name].bind( skeleton );
console.log('nodes:', this.nodes[this.name])
console.log('skeleton:', this.nodes[this.name].skeleton)
*/

    this.height = modelHeight(haircut.subtype)

    this.version++
    this.busy = false

    //console.log('prepareHaircut() finished')
    //console.log('  this:', this)
  }

  /**
   * Haircut jsx
   *
   * param ref          - react.js useRef()
   */

  jsxHaircut = (ref, storeAvatarBody, params, renderPasses = 1) => {
    // if (process.env.USE_MULTIPASS_HAIRCUT_RENDER === '1') console.log(`jsxHaircut(), renderPasses: ${renderPasses}`)

    //console.log('  name:', this.name)
    //console.log('  renderPasses:', renderPasses)

    if (!this.nodes || !this.name || !this.nodes[this.name] || !this.nodes[this.name].geometry) return null

    this.ref = ref

    storeAvatarBody.setHaircutedHeadTexture(false)

    //console.log('  this.headScale:', this.headScale)

    const boneHead = this.nodes['Head']
    if (boneHead) boneHead.scale.x = boneHead.scale.y = boneHead.scale.z = this.headScale

    //return (<group {...params} dispose={null} ref={this.ref}></group>)

    if (process.env.USE_MULTIPASS_HAIRCUT_RENDER === '1') {
      // 2-passes render method
      if (renderPasses === 2) {
        return (
          <group {...params} dispose={null} ref={this.ref} scale={[this.scale, this.scale, this.scale]}>
            <primitive object={this.nodes.Hips} />

            <skinnedMesh
              castShadow
              receiveShadow
              name={this.name + 'B2'}
              geometry={this.nodes[this.name].geometry}
              material={this.materials[this.name + 'B2']}
              skeleton={this.nodes[this.name].skeleton}
              morphTargetDictionary={this.nodes[this.name].morphTargetDictionary}
              morphTargetInfluences={this.nodes[this.name].morphTargetInfluences}
              visible={!this.hide}
            />

            <skinnedMesh
              name={this.name + 'B3'}
              geometry={this.nodes[this.name].geometry}
              material={this.materials[this.name + 'B3']}
              skeleton={this.nodes[this.name].skeleton}
              visible={!this.hide}
              morphTargetDictionary={this.nodes[this.name].morphTargetDictionary}
              morphTargetInfluences={this.nodes[this.name].morphTargetInfluences}
            />
          </group>
        )
      }

      // 3-passes render method
      if (renderPasses === 3) {
        return (
          <group {...params} dispose={null} ref={this.ref} scale={[this.scale, this.scale, this.scale]}>
            <primitive object={this.nodes.Hips} />

            <skinnedMesh
              name={this.name + 'B1'}
              geometry={this.nodes[this.name].geometry}
              material={this.materials[this.name + 'B1']}
              skeleton={this.nodes[this.name].skeleton}
              morphTargetDictionary={this.nodes[this.name].morphTargetDictionary}
              morphTargetInfluences={this.nodes[this.name].morphTargetInfluences}
              visible={!this.hide}
            />

            <skinnedMesh
              name={this.name + 'B2'}
              geometry={this.nodes[this.name].geometry}
              material={this.materials[this.name + 'B2']}
              skeleton={this.nodes[this.name].skeleton}
              morphTargetDictionary={this.nodes[this.name].morphTargetDictionary}
              morphTargetInfluences={this.nodes[this.name].morphTargetInfluences}
              visible={!this.hide}
            />

            <skinnedMesh
              name={this.name + 'B3'}
              geometry={this.nodes[this.name].geometry}
              material={this.materials[this.name + 'B3']}
              skeleton={this.nodes[this.name].skeleton}
              morphTargetDictionary={this.nodes[this.name].morphTargetDictionary}
              morphTargetInfluences={this.nodes[this.name].morphTargetInfluences}
              visible={!this.hide}
            />
          </group>
        )
      }
    } // process.env.USE_MULTIPASS_HAIRCUT_RENDER

    // on 1-pass (old) render method

    return (
      <group {...params} dispose={null} ref={this.ref} scale={[this.scale, this.scale, this.scale]}>
        <primitive object={this.nodes.Hips} />

        <skinnedMesh
          renderOrder={2}
          name={this.name}
          geometry={this.nodes[this.name].geometry}
          material={this.materials[this.name]}
          skeleton={this.nodes[this.name].skeleton}
          morphTargetDictionary={this.nodes[this.name].morphTargetDictionary}
          morphTargetInfluences={this.nodes[this.name].morphTargetInfluences}
          visible={!this.hide}
        />
      </group>
    )
  }

  /**
   * Change Blandshapes
   *
   * param bodyParams   - body parameters
   */

  changeBlendshapes = (blendshapes) => {
    //console.log('changeBlendshapes()', blendshapes)

    if (this.nodes === null) return

    if (blendshapes['height']) {
      //      const bone = this.nodes['Hips']
      //      bone.scale.x = bone.scale.y = bone.scale.z = blendshapes['height'] / this.height
      //      bone.parent.position.y = (blendshapes['height'] / this.height - 1) * bone.position.y

      this.scale = blendshapes['height'] / this.height
    }

    if (blendshapes['headScale']) {
      this.headScale = blendshapes['headScale']
      //      const boneHead = this.nodes['Head']
      //      if (boneHead) {
      //        boneHead.scale.x = boneHead.scale.y = boneHead.scale.z = blendshapes['headScale']
      //      }
    }

    if (this.ref?.current) {
      //console.log("this.ref.current:", this.ref.current)

      const meshA = this.ref.current.children.find((item) => item.name === this.name)
      const meshB1 = this.ref.current.children.find((item) => item.name === this.name + 'B1')
      const meshB2 = this.ref.current.children.find((item) => item.name === this.name + 'B2')
      const meshB3 = this.ref.current.children.find((item) => item.name === this.name + 'B3')

      for (const prop in blendshapes) {
        if (isNaN(blendshapes[prop])) continue

        for (const mesh of [meshA, meshB1, meshB2, meshB3]) {
          if (mesh === undefined) continue

          //console.log("mesh:", mesh)
          //console.log("prop:", prop)
          //console.log(mesh.morphTargetDictionary)
          //console.log(mesh.morphTargetDictionary[prop])
          if (mesh && mesh.morphTargetDictionary[prop] !== undefined) {
            //console.log('blendshapes[prop]:', blendshapes[prop])
            mesh.morphTargetInfluences[mesh.morphTargetDictionary[prop]] = blendshapes[prop]
          }
        }
      }
    }
  }

  /**
   * Update Blendshapes
   *
   * param storeAvatarBody - avatar body data
   */

  updateBlendshapes = (storeAvatarBody) => {
    //console.log('updateBlendshapes()')

    if (!this.nodes) return

    if (storeAvatarBody.nodes?.AvatarBody?.morphTargetDictionary) {
      const bodyMesh = storeAvatarBody.nodes['AvatarBody']
      //console.log("bodyMesh:", bodyMesh)

      const bodyBone = bodyMesh.skeleton.bones.find((element) => element.name === 'Hips')
      //console.log("bodyBone:", bodyBone)

      //const bone = this.nodes['Hips']
      //bone.scale.x = bodyBone.scale.x
      //bone.scale.y = bodyBone.scale.y
      //bone.scale.z = bodyBone.scale.z

      //bone.parent.position.y = bodyBone.parent.position.y

      const bodyBoneHead = bodyMesh.skeleton.bones.find((element) => element.name === 'Head')
      const boneHead = this.nodes['Head']

      //      if (boneHead) {
      //          boneHead.scale.x = boneHead.scale.y = boneHead.scale.z = bodyBoneHead.scale.x;
      //      }

      this.scale = storeAvatarBody.scale
      this.headScale = bodyBoneHead.scale.x

      const haircutMesh = this.nodes[this.name]

      Object.keys(haircutMesh.morphTargetDictionary).forEach((prop) => {
        const haircutProp = haircutMesh.morphTargetDictionary[prop]

        const bodyProp = bodyMesh.morphTargetDictionary[prop]
        if (bodyMesh.morphTargetInfluences[bodyProp]) {
          haircutMesh.morphTargetInfluences[outfitProp] = bodyMesh.morphTargetInfluences[bodyProp]
        }
      })
      ///
    }
  }

  /**
   * Recoloring Haircut to specified color
   *
   * colors - specified colors (target, roots)
   * params - params for proceeding (AOImpact, DepthImpact, IDsImpact)
   */

  recoloringHaircut = (colors, params) => {
    // console.log('recoloringHaircut')
    // console.log('  colors:', colors)
    // console.log('  params:', params)

    this.color = colors.target
    this.roots = colors.roots

    if (this.color === null) {
      // no recoloring
      this.uniforms.uRecoloring.value = false
      return
    }

    this.uniforms.uRecoloring.value = true

    this.uniforms.uTargetColor.value.x = ('0x' + this.color[1] + this.color[2]) / 255
    this.uniforms.uTargetColor.value.y = ('0x' + this.color[3] + this.color[4]) / 255
    this.uniforms.uTargetColor.value.z = ('0x' + this.color[5] + this.color[6]) / 255

    this.uniforms.uRootsColor.value.x = ('0x' + this.roots[1] + this.roots[2]) / 255
    this.uniforms.uRootsColor.value.y = ('0x' + this.roots[3] + this.roots[4]) / 255
    this.uniforms.uRootsColor.value.z = ('0x' + this.roots[5] + this.roots[6]) / 255

    this.uniforms.uAOImpact.value = params.AOImpact
    this.uniforms.uDepthImpact.value = params.DepthImpact
    this.uniforms.uIDsImpact.value = params.IDsImpact

    //console.log('this.uniforms:', this.uniforms)
  }
}

const storeHaircut = new Haircut()

export { storeHaircut }
