import React, { FC, PropsWithChildren } from 'react'
import styled from 'astroturf/react'

import { Cover, Loader } from '@/components/ui'
import { TitleSection } from '@/views/components/common'
import { Smile } from '@/components/ui/icons'

export interface PhotoHandlerProps {
  title: string
  descr: string
  img?: string
  isLoading?: boolean
  isBorder?: boolean
  error?: unknown
}

function getMSG(status: number) {
  switch (status) {
    case 404:
      return 'Resource not found'
    case 403:
      return 'Access is denied'
    case 401:
      return 'Authorization required'
    default:
      return ''
  }
}

export const PhotoHandler: FC<PropsWithChildren<PhotoHandlerProps>> = ({
  children,
  title,
  descr,
  img,
  isLoading,
  isBorder,
  error,
}) => {
  return (
    <>
      <TitleSection title={title} descr={descr} />
      <Photo border={isBorder && !error}>
        <Cover width={320} height={320} />
        {!isLoading ? img ? <Img src={img}></Img> : <Icon /> : <Loader spinner='circle' />}
      </Photo>
      {error && <ErrorMSG>{getMSG(error?.status)}</ErrorMSG>}
      <Actions>{children}</Actions>
    </>
  )
}

export interface PhotoProps {
  border?: boolean
}

const Photo = styled.div<PhotoProps>`
  @import '../../../../../assets/styles/mixins.scss';
  background-color: var(--color-bg-box);
  border-radius: var(--size-radius);
  width: 320px;
  margin-top: 32px;
  position: relative;

  &.border {
    box-shadow: 0 0 0 2px var(--color-base);
  }
`

const Icon = styled(Smile)`
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -24px;
  margin-left: -24px;
`

const Actions = styled.div`
  max-width: 240px;
  width: 100%;
  margin-top: 32px;
`
const Img = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: var(--size-radius);
`
const ErrorMSG = styled.p`
  color: var(--color-error);
  margin-top: 12px;
  font-size: 14px;
  font-weight: 500;
  text-align: center;
`
