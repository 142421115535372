// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Choice-CssProp1_Button-module--cls1--bbziW { /*!*/ }\n\n.Choice-CssProp1_Button-module--cls2--qIGeD {\n  margin-bottom: 10px;\n}", "",{"version":3,"sources":["webpack://./src/views/page/generator/sections/Choice-CssProp1_Button.module.scss"],"names":[],"mappings":"AAAA,8CAAA,IAAA,EAAA;;AACA;EAGA,mBAAA;AACA","sourcesContent":[".cls1 { /*!*/ }\n.cls2 {\ncomposes: cls1;\n\nmargin-bottom: 10px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"cls1": "Choice-CssProp1_Button-module--cls1--bbziW",
	"cls2": "Choice-CssProp1_Button-module--cls2--qIGeD Choice-CssProp1_Button-module--cls1--bbziW"
};
export default ___CSS_LOADER_EXPORT___;
