import { action, makeAutoObservable } from 'mobx'
import {
  request,
  createAvatar,
  pollingCreatingAvatar,
  exportsFile,
  avatarFile,
  haircutFile,
  glassesFile,
  hatFile,
  setDescription,
} from '@/services/adapters/req'
import { exportParameters, avatarParameters } from '@/helpers/exportParameters'
import { handleError, toast } from '@/services/adapters/toast'
import { storeEditor, BodyInterface, FaceInterface } from './editor'
import { Gender, Age } from '@/helpers/types'
import axios from 'axios'
import { getApiUrl, getAccessToken, getBusinessAccessToken, setBusinessAccessToken, getBusinessMode, setBusinessMode, getPlayerID, setPlayerID } from '../adapters/req/config'

function getAuthorizationHeaders(includeContentType: boolean = false, playerID: string = '') {
  const headers: { [key: string]: string } = {
    Authorization: `Bearer ${getAccessToken()}`,
  };

  if (getBusinessMode() === "active") {
    headers['X-Customer-Authorization'] = 'Bearer ' + getBusinessAccessToken();
  }

  if (playerID !== '') {
    headers['X-PlayerUID'] = playerID;
  }

  if (includeContentType) {
    headers['Content-Type'] = 'application/x-www-form-urlencoded';
  }

  return headers;
}

const exportComment = JSON.stringify({
  "createdFor": {
    "appName": "MetaPerson Creator Mobile",
    "version": "1.5.0",
  }
});

function hexToRgb(hex: string) {
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex)
  return result
    ? {
      r: parseInt(result[1], 16),
      g: parseInt(result[2], 16),
      b: parseInt(result[3], 16),
    }
    : null
}

function isEmptyObject(obj) {
  return JSON.stringify(obj) === '{}'
}

export interface PreSettings {
  gender: Gender,
  age: Age,
  photo?: File
}

function serializeAvatarState(avatarState) {

  try {
    return JSON.stringify(avatarState);
  } catch (error) {
    // console.error("Error occurred during serialization:", error);
    return null;
  }
}

class GeneratorPipeline {
  timeoutId = null
  
  sectionIndex = 0
  availableSections: string[] = []
  actualNumberSection: { [key: string]: number } = {}
  preSettings: PreSettings = {
    gender: 'female',
    age: 'Adult',
  }
  avatarFiles = null
  editorIndex: null | number = null
  avatarId: string | undefined

  startStatePhoto: (() => void) | null = null

  isSendingAvatar = false

  isLoadingAvatar = false

  isSendingPreSetting = false
  isPendingGetAvatar = false
  progress = 0
  
  skipSampleAvatars = false

  avatar = {
    id: 0,
    exportID: 0,
    photo: null,
    subtype: null,
    zip: null,
    skinColor: null,
    hairColorInitial: null,
    skinColorInitial: null,
    lipsColor: null,
    lipsColorInitial: null,
    eyebrowsColor: null,
    eyebrowsColorInitial: null,
    isBald: false,
    height: 0
  }

  avatarState = {
    age: this.preSettings.age,
    haircut: { name: null, color: null },
    glasses: { name: null },
    hat: { name: null },
    outfits: null,
    skinColor: null,
    lipsColor: null,
    eyebrowsColor: null,
    bodyShape: {},
    faceModifications: {},
  }

  constructor() {
    makeAutoObservable(this, {
      setSkipSampleAvatars: action
    })
  }

  setSkipSampleAvatars(value: boolean) {
    this.skipSampleAvatars = value
  }

  setIsSendingPreSetting(value: boolean) {
    this.isSendingPreSetting = value
  }

  async saveAvatarState(avatarState, avatarID) {
    if (avatarID)
    {
      const jsonState = serializeAvatarState(avatarState)
      clearTimeout(this.timeoutId) // Clear the previous timeout
      this.timeoutId = setTimeout(async () => {
        const body = {
          description: jsonState,
        }
        const data = await request(setDescription.endpoint({ avatarID }), setDescription, body);
      }, 1000)
    }
  }

   sendAvatarGenerationEvent(isAvatarGenerationReady: boolean) {
    window.parent.postMessage(
      {
        isAvailable: isAvatarGenerationReady,
        eventName: "action_availability_changed",
        actionName: "avatar_generation",
        source: 'metaperson_creator',
      },
      '*'
    )
  }

  sendModelGeneratedEvent(avatarCode, gender, photo) {
    // console.log("sendModelGeneratedEvent()")
    // console.log("  avatarCode:", avatarCode)
    // console.log("  gender:", gender)
    // console.log("  photo:", photo)

    window.parent.postMessage({
      source: 'metaperson_creator',
      eventName: "model_generated",
      avatarCode: avatarCode,
      gender:     gender,
      photoFileName: photo.name,
      photoUrl:  URL.createObjectURL(photo)
    }, '*')
  }

  setHaircutColor = (color: string) => {
    this.avatarState.haircut.color = color
    this.saveAvatarState(this.avatarState, this.avatarId)
  }

  setHaircutName = (name: string) => {
    this.avatarState.haircut.name = name
    this.saveAvatarState(this.avatarState, this.avatarId)
  }

  setGlassesName = (name: string) => {
    this.avatarState.glasses.name = name
    this.saveAvatarState(this.avatarState, this.avatarId)
  }

  setHatName = (name: string) => {
    if (this.avatarState.hat !== undefined && this.avatarState.hat !== null) {
      this.avatarState.hat.name = name
      
    }
    else {
      this.avatarState.hat = {
        name: name,
      }
    }
    this.saveAvatarState(this.avatarState, this.avatarId)
  }

  setSkinColor = (color: string) => {
    this.avatar = { ...this.avatar, skinColor: color }
    this.avatarState.skinColor = color

    if (color!==this.avatar.skinColorInitial) storeEditor.isAvatarModified = true
    this.saveAvatarState(this.avatarState, this.avatarId)
  }

  setLipsColor = (color: string) => {
    this.avatar = { ...this.avatar, lipsColor: color }
    this.avatarState.lipsColor = color

    if (color!==this.avatar.lipsColorInitial) storeEditor.isAvatarModified = true
    this.saveAvatarState(this.avatarState, this.avatarId)
  }

  setEyebrowsColor = (color: string) => {
    // console.info('setEyebrowsColor), color:', color)
    this.avatar = { ...this.avatar, eyebrowsColor: color }
    this.avatarState.eyebrowsColor = color

    if (color!==this.avatar.eyebrowsColorInitial) storeEditor.isAvatarModified = true
    this.saveAvatarState(this.avatarState, this.avatarId)
  }

  setBodyShape = (first: string, second: string, res) => {
    this.avatarState.bodyShape[first] = res[first]
    this.avatarState.bodyShape[second] = res[second]
    this.saveAvatarState(this.avatarState, this.avatarId)
  }

  setAvatarAge = (age: string) => {
    //console.log(`setAvatarAge, age: ${age}`)

    this.avatarState.age = age
    this.saveAvatarState(this.avatarState, this.avatarId)
  }

  setAvatarHeight = (height: number) => {
    //console.log(`setAvatarHeight, height: ${height}`)

    this.setAvatarScale(height / this.avatar.height / storeEditor.scalesForAge(storeEditor.age))
  }

  setAvatarScale = (scale: number) => {
    //console.log(`setAvatarScale, scale: ${scale}`)

    this.avatarState['scale'] = scale
    this.saveAvatarState(this.avatarState, this.avatarId)
  }

  setFaceBlendshape = (name: string, value: number) => {
    this.avatarState.faceModifications[name] = value
    this.saveAvatarState(this.avatarState, this.avatarId)
  }


  setFaceShape = (first: string, second: string, res) => {
    this.avatarState.faceModifications[first] = res[first]
    this.avatarState.faceModifications[second] = res[second]
    this.saveAvatarState(this.avatarState, this.avatarId)
  }

  setEyeSquint = (value) => {
    this.avatarState.faceModifications['Eye_SquintH_L'] = value
    this.avatarState.faceModifications['Eye_SquintH_R'] = value
    this.saveAvatarState(this.avatarState, this.avatarId)
  }
  
  setJawNarrow = (value) => {
    this.avatarState.faceModifications['Jaw_Narrow'] = value
    this.saveAvatarState(this.avatarState, this.avatarId)
  }

  setOutfits = (outfits) => {
    const outfits2 = JSON.parse(JSON.stringify(outfits))

    for (const slot of ['complect', 'top', 'bottom', 'shoes']) {
      delete outfits2[slot].texture

      delete outfits2[slot].logo
      delete outfits2[slot].useLogo
    }

    this.avatarState.outfits = outfits2
    this.saveAvatarState(this.avatarState, this.avatarId)
    //console.log("this.avatarState.outfits:", this.avatarState.outfits)
  }

  uploadOutfitLogo = async (logo) => {
    //console.log("uploadOutfitLogo(), logo:", logo)

    const image = await fetch(logo)
      .then(res => res.blob())

    const formData = new FormData();
    formData.append('file', image, "logo.jpg");

    const res = await fetch(getApiUrl(`outfit_logos/`), {
      method: "POST",
      body: formData,
      cache: 'no-cache',
      credentials: 'same-origin',
      headers: getAuthorizationHeaders(),
      mode: 'cors',
    })
      .then(response => {
        return response.json()
      })

    if (res.file) {
      toast("Invalid logo image", res.file, "error")
      return null
    }

    return res.code ? res.code : null
  }

  setProgress(progress) {
    this.progress = progress
  }

  setAvatarId(avatarId: string) {
    this.avatarId = avatarId
  }

  cleanPipeline = () => {
    this.preSettings = {
      gender: 'female',
      age: 'Adult',
    }
    this.avatarFiles = null
    this.setProgress(0)
  }

  setStartStatePhoto = (payload: (() => void) | null) => {
    this.startStatePhoto = payload
  }

  setAvailableSections = (payload: string[]) => {
    this.availableSections = payload
    this.actualNumberSection = payload?.reduce((acc, val, i) => {
      return { ...acc, [val]: i }
    }, {})
  }

  setSectionIndex = (payload: number) => {
    this.sectionIndex = payload
  }

  nextSection = () => {
    const nextNumber: number = this.sectionIndex + 1
    if (nextNumber < this.availableSections.length) {
      this.setSectionIndex(nextNumber)
    }
  }

  toSection = (section: string) => {
    if (this.availableSections.includes(section)) {
      this.setSectionIndex(this.actualNumberSection[section])
    }
  }

  setPreSettings = (key: string, value: string) => {
    this.preSettings[key] = value
  }

  checkIsReady = () => {

    if (storeEditor.isReady()) {
      // If storeEditor is ready, execute the function

      if (this.avatarState["age"]) {
        storeEditor.setAge(this.avatarState["age"])
      }

      if (this.avatarState.skinColor !== null)
      {
        this.setSkinColor(this.avatarState.skinColor)
      }
      if (this.avatarState.eyebrowsColor !== null)
      {
        this.setEyebrowsColor(this.avatarState.eyebrowsColor)
      }
      if (this.avatarState.lipsColor !== null)
      {
        this.setLipsColor(this.avatarState.lipsColor)
      }
      if (this.avatarState.haircut.color !== null)
      {
        storeEditor.setHaircutColor(this.avatarState.haircut.color)
      }
      if (this.avatarState.haircut.name !== null)
      {
        storeEditor.setHaircut(this.preSettings.gender, this.avatarState.haircut.name)
      }
      if (this.avatarState.glasses.name !== null)
      {
        storeEditor.setGlasses(this.preSettings.gender, this.avatarState.glasses.name)
      }
      if (this.avatarState.hat !== undefined && this.avatarState.hat !== null && this.avatarState.hat.name !== null)
      {
        storeEditor.setHat(this.preSettings.gender, this.avatarState.hat.name)
      }
      if (!isEmptyObject(this.avatarState.bodyShape))
      {
        let controlsList = ["Shoulders", "Forearms", "Legs", "Hips", "Waist", "Chest"]
        for (var control of controlsList)
        {
          let minusControl = this.avatarState.bodyShape[control+'-'] || 0
          let plusControl = this.avatarState.bodyShape[control+'+']  || 0

          if (minusControl !== null && plusControl !== null) {
            var res = []
            if (minusControl > 0)
            {
              res = [(0.5 - minusControl/2)*10]
            }
            else 
            {
              res = [(plusControl/2 + 0.5)*10]
            }
            let fieldName: keyof BodyInterface = control.toLowerCase()
            let body: Partial<BodyInterface> = {
              [fieldName]: res
            };
            storeEditor.setBody(body as BodyInterface)
          }
        }

        if (this.avatarState["scale"]) {
          //console.log("storeEditor.isReady(), this.avatarState", this.avatarState.scale)
          storeEditor.setBody({ scale: [this.avatarState["scale"]] })
        }
      }

      if (!isEmptyObject(this.avatarState.faceModifications))
      {
        let minusList = ["Nose_Sharp", "Lips_Narrow"]
        let plusList = ["Nose_Large", "Lips_Large"]
        for (let i = 0; i < minusList.length; i++)
        {
          let minusControl = this.avatarState.faceModifications[minusList[i]]
          let plusControl = this.avatarState.faceModifications[plusList[i]]

          if (minusControl !== null && plusControl !== null)
          {
            var res = []
            if (minusControl > 0)
            {
              res = [(0.5 - minusControl/2)*10]
            }
            else 
            {
              res = [(plusControl/2 + 0.5)*10]
            }
            let words = minusList[i].split("_")
            let fieldName: keyof FaceInterface = words[0].toLowerCase()
            let faceInt: Partial<FaceInterface> = {
              [fieldName]: res
            };
            storeEditor.setFace(faceInt as FaceInterface)
          }

          let leftEye = this.avatarState.faceModifications["Eye_SquintH_L"]
          let rightEye = this.avatarState.faceModifications["Eye_SquintH_R"]
          if (leftEye !== null && rightEye !== null)
          {
            const res = [(1-leftEye)*10]
            storeEditor.setFace({ eyes: res })
          }

          let jawNarrow = this.avatarState.faceModifications["Jaw_Narrow"]
          if (jawNarrow !== null)
          {
            const res = [(1-jawNarrow)*10]
            storeEditor.setFace({ jaw: res })
          }

          const blendshapes = {
            "Head_Height_Dn": "headHeight",
            "Head_Vol_Dn":    "upperHeadVolume",
            "Head_Width_Dn":  "upperHeadWidth",
            "JawLine_Narrow": "lowerHeadWidth",
            "Jaw_Narrow":     "jawLine"
          }

          //console.log("this.avatarState.faceModifications:", this.avatarState.faceModifications)

          for (const item in blendshapes) {
            //console.log(`${item}: ${blendshapes[item]}`)
            const value = this.avatarState.faceModifications[item]
            //console.log("value:", value)

            if (value!==undefined) {
              const data = {}
              data[blendshapes[item]] = [10*value]
              storeEditor.setFace(data)
            }
          }
        }
      }
    } else {
      // If storeEditor is not ready, wait and check again
      setTimeout(this.checkIsReady, 1000);
    }
  }

  downloadAITexture = async (textureCode) => {
    if (textureCode) {
      const headers = getAuthorizationHeaders(true)
      const endpoint = getApiUrl(`generated_textures/${textureCode}/file/`)
      const data = await fetch(endpoint, { method: 'GET', headers: headers })
      // console.log(data)
      const blob = await data.blob()
      // console.log(blob)

      return URL.createObjectURL(blob)
    }
    return null
  }

  submitPreSettingsForSampleAvatar = async (sampleAvatar) => {
    //console.info('submitPreSettingsForSampleAvatar()')
    //console.info('  sampleAvatar:', sampleAvatar)
    //console.log('  this.preSettings:', this.preSettings)

    this.preSettings.gender = sampleAvatar.gender // FIX IT ?

    // console.info('  preview:', sampleAvatar.preview)
    // console.info('  avatar_code:', sampleAvatar.avatar_code)
    // console.info('  export_code:', sampleAvatar.export_code)
    // console.info('  texture_code:', sampleAvatar.texture_code)
    //console.info('  avatarState:', sampleAvatar.avatarState)

    // console.log('  preSettings.gender:', this.preSettings.gender)
    this.isLoadingAvatar = true
    if (this.preSettings.gender === 'without_specify') {
      this.preSettings.gender = sampleAvatar.gender
    }

    this.setIsSendingPreSetting(true)

    this.avatarState = {
      age: this.preSettings.age,
      haircut: { name: null, color: null },
      glasses: { name: null },
      hat: { name: null },
      outfits: null,
      skinColor: null,
      lipsColor: null,
      eyebrowsColor: null,
      bodyShape: {},
      faceModifications: {},
    }

    this.avatarState = {
      age: this.preSettings.age,
      haircut: { name: null, color: null },
      glasses: { name: null },
      hat: { name: null },
      outfits: null,
      skinColor: null,
      lipsColor: null,
      eyebrowsColor: null,
      bodyShape: {},
      faceModifications: {},
    }
    
    if (sampleAvatar.avatarState === null || typeof(sampleAvatar.avatarState) === "undefined")
    {
      // console.log("sampleAvatar.avatarState  null")
      storeEditor.setInitialOutfits(this.preSettings.gender)
    }
    storeEditor.setInitialHaircut(this.preSettings.gender)
    storeEditor.setInitialGlasses(this.preSettings.gender)
    storeEditor.setInitialHat(this.preSettings.gender)
    storeEditor.setInitialAnimation(this.preSettings.gender)

    this.setAvatarId(sampleAvatar.avatar_code)

    if (sampleAvatar.avatarState)
    {
      this.avatarState = sampleAvatar.avatarState

      this.checkIsReady()
    }
    
    
    if (sampleAvatar.avatarState)
    {
      if (sampleAvatar.avatarState.outfits.complect.name != null) {
         const complect = sampleAvatar.avatarState.outfits.complect
        if (complect.texture_code !== null) {
          complect.texture = await this.downloadAITexture(complect.texture_code)
        }
        storeEditor.setOutfits("complect", sampleAvatar.avatarState.outfits.subtype, complect.name, complect.texture, complect.texture_code)
        if (complect.color !== null) {
          storeEditor.setOutfitsColor("complect", complect.color, complect.initialColor)
        }
      }
      else 
      {
        const outfitTypes = ['top', 'bottom', 'shoes']

        for (const type of outfitTypes) {
          const outfit = sampleAvatar.avatarState.outfits[type]
          if (outfit.name != null) {
            if (outfit.texture_code !== null) {
              outfit.texture = await this.downloadAITexture(outfit.texture_code)
            }
            storeEditor.setOutfits(type, sampleAvatar.avatarState.outfits.subtype, outfit.name, outfit.texture, outfit.texture_code)
            if (outfit.color !== null) {
              storeEditor.setOutfitsColor(type, outfit.color, outfit.initialColor)
            }

            if (outfit.logoUuid !== null && outfit.logoUuid !== undefined) {
              //console.log("outfit.logoUuid:", outfit.logoUuid)

              const logo = await fetch(getApiUrl(`outfit_logos/${outfit.logoUuid}/file/`), {
                method: "GET",
                cache: 'no-cache',
                credentials: 'same-origin',
                headers: getAuthorizationHeaders(),
                mode: 'cors',
              })
              .then(response => {
                //console.log("response", response)
                return response.blob()
              })

              //console.log("logo:", logo)

              const logoBlobUrl = URL.createObjectURL(logo)
              //console.log("logoBlobUrl:", logoBlobUrl)

              storeEditor.setOutfitLogo(type, logoBlobUrl, outfit.logoUuid)
            }
          }
        }
      }
    }
    else {
      for (const slot of ['complect', 'top', 'bottom', 'shoes']) {
        if (!sampleAvatar.outfits[slot]) continue
       
        const aiTextureUrl = sampleAvatar.outfits[slot].texture_code ? await this.downloadAITexture(sampleAvatar.outfits[slot].texture_code) : null

        storeEditor.setOutfits(
          slot,
          this.preSettings.gender,
          sampleAvatar.outfits[slot].name,
          aiTextureUrl,
          sampleAvatar.outfits[slot].texture_code || null,
        )

        if (sampleAvatar.outfits[slot].logo_code) {
          const logoUuid = sampleAvatar.outfits[slot].logo_code // "3e21be07-41ed-4f85-b7eb-b86299e73ffa"
          const logo = await fetch(getApiUrl(`outfit_logos/${logoUuid}/file/`), {
            method: "GET",
            cache: 'no-cache',
            credentials: 'same-origin',
            headers: getAuthorizationHeaders(),
            mode: 'cors',
          })
          .then(response => {
            //console.log("response", response)
            return response.blob()
          })

          //console.log("logo:", logo)

          const logoBlobUrl = URL.createObjectURL(logo)
          //console.log("logoBlobUrl:", logoBlobUrl)

          storeEditor.setOutfitLogo(slot, logoBlobUrl, logoUuid)
          if (sampleAvatar.outfits[slot].color) storeEditor.setOutfitsColor(slot, sampleAvatar.outfits[slot].color)
        }
      }
    }

    this.getAvatarFile(sampleAvatar.avatar_code, [{ code: sampleAvatar.export_code }])
  }

  submitPreSettings = async () => {
    this.setIsSendingPreSetting(true)
    this.avatarState = {
      age: this.preSettings.age,
      haircut: { name: null, color: null },
      glasses: { name: null },
      hat: { name: null },
      outfits: null,
      skinColor: null,
      lipsColor: null,
      eyebrowsColor: null,
      bodyShape: {},
      faceModifications: {},
    }
    
    try {
      if (this.preSettings.gender === 'without_specify') {
        // console.info('gender "without_specify" changed to "female"')
        this.preSettings.gender = 'female'
      }

      const body = {
        name: 'MetaPerson Creator Mobile',
        pipeline: 'metaperson_2.0',
        pipeline_subtype: this.preSettings.gender,
        parameters: JSON.stringify(avatarParameters()),
        export_parameters: JSON.stringify(exportParameters(this.preSettings.gender)),
        photo: this.preSettings.photo,
        export_comment: exportComment
      }

      console.info('body:', body)

      this.setProgress(0)

      //this.getExportsFile(null); return  // for sample avatars
      const data = await request(createAvatar.endpoint, createAvatar, body)

      // console.info('data:', data)

      storeEditor.setInitialOutfits(this.preSettings.gender)
      storeEditor.setInitialHaircut(this.preSettings.gender)
      storeEditor.setInitialGlasses(this.preSettings.gender)
      storeEditor.setInitialAnimation(this.preSettings.gender)

      this.polingAvatar(data.code)
      this.setAvatarId(data.code)
    } catch (error) {
      this.setIsSendingPreSetting(false)
      handleError(error)
    }
  }

  polingAvatar = async (avatarID: string) => {
    setTimeout(async () => {
      try {
        const data = await request(pollingCreatingAvatar.endpoint({ avatarID }), pollingCreatingAvatar)
        switch (data.status) {
          case 'Failed':
            this.setIsSendingPreSetting(false)
            toast('Face detection error', 'Please fix error', 'error')
            break
          case 'Completed':
            this.sendModelGeneratedEvent(avatarID, this.preSettings.gender, this.preSettings.photo)
            this.getExportsFile(avatarID)
            this.setProgress(data.progress - 1)
            break
          case 'Queued':
            this.polingAvatar(avatarID)
            break
          default:
            this.polingAvatar(avatarID)
            this.setProgress(data.progress)
            break
        }
      } catch (error) {
        this.setIsSendingPreSetting(false)
        handleError(error)
      }
    }, 2000)
  }

  exportComplete = (data: any, isNotBusiness: boolean) => {
    const link = isNotBusiness ? `${data?.files[0].file}/?access_token=${getAccessToken()}` : data?.public_file
    if (!isNotBusiness) {
      const eventType = 'model_exported'
      window.parent.postMessage(
        {
          url: link,
          gender: this.preSettings.gender, 
          avatarCode: this.avatarId,
          eventName: eventType,
          source: 'metaperson_creator',
        },
        '*',
      )
      this.sendAvatarGenerationEvent(true)

    } else {
      window.location.href = link
    }
  }

  exportingPollingReq = async (
    exportData: JSON | undefined,
    avatarId: string | undefined,
    exportCode: string | undefined,
    playerID: string,
    setIsOpenModal: any, 
    ) => {
    const res = await axios
      .get(getApiUrl(`avatars/${avatarId}/exports/${exportCode}/`), {
        headers: getAuthorizationHeaders(false, playerID),
      })
      .then((response) => {
        switch (response?.data?.status) {
          case 'Failed':
            this.setIsSendingPreSetting(false)
            toast('Export error', 'Please try one more time', 'error')
            break
          case 'Completed':
            this.setProgress(100)
            this.exportComplete(response.data, isEmptyObject(exportData))
            setIsOpenModal(false)
            break
          default:
            this.exportingPollingReq(exportData, avatarId, response?.data?.code, getPlayerID(), setIsOpenModal)
            this.progress += 5
            break
        }
      })
  }

  postAuthenticationStatus = (isAuthenticated: boolean, errorMessage: string) => {
    window.parent.postMessage(
      {
        source: 'metaperson_creator',
        eventName: 'authentication_status',
        isAuthenticated,
        errorMessage,
      },
      '*'
    )
  }

  getBusinessToken = async (clientId: string, clientSecret: string) => {
    try {
      const tokenRes = await axios.post(
        getApiUrl('o/token/'),
        {
          grant_type: 'client_credentials',
          client_id: clientId,
          client_secret: clientSecret,
        },
        { headers: { 'content-type': 'application/x-www-form-urlencoded' } },
      )

      if (tokenRes.data?.access_token !== undefined) {
        setBusinessAccessToken(tokenRes.data?.access_token)
      } 

      const exportTemplatesRes = await axios.get(getApiUrl('export_parameters/templates/'), {
        headers: { Authorization: 'Bearer ' + getBusinessAccessToken() },
      })
      setBusinessMode('active')
      this.postAuthenticationStatus(true, '');
    } catch (error) {
      setBusinessAccessToken('')
      setBusinessMode('error')
      this.postAuthenticationStatus(false, error.message);
    }
  }

  addHaircutColor = (js: Record<string, any>) => {
    const hex = this.avatarState.haircut.color
    if (hex !== null) {
      const rgb = hexToRgb(hex)
      js.haircuts.colors = {
        red: rgb.r,
        green: rgb.g,
        blue: rgb.b,
      }
    }
  }

  addSkinColor = (js: Record<string, any>) => {
    const hex = this.avatarState.skinColor
    const lipsColor = this.avatarState.lipsColor
    const eyebrowsColor = this.avatarState.eyebrowsColor

    if (hex !== null || lipsColor || eyebrowsColor) {
      js.avatar.colors = {}

      if (hex !== null) {
        const rgb = hexToRgb(hex)
        js.avatar.colors.skin = {
          red: rgb.r,
          green: rgb.g,
          blue: rgb.b,
        }
      }

      if (lipsColor !== null) {
        const rgb = hexToRgb(lipsColor)
        js.avatar.colors.lips = {
          red: rgb.r,
          green: rgb.g,
          blue: rgb.b,
        }
      }

      if (eyebrowsColor !== null) {
        const rgb = hexToRgb(eyebrowsColor)
        js.avatar.colors.eyebrows = {
          red: rgb.r,
          green: rgb.g,
          blue: rgb.b,
        }
      }
    }
  }

  addLipsColor = (js: Record<string, any>) => {
    const hex = this.avatarState.lipsColor
    if (hex !== null) {
      const rgb = hexToRgb(hex)
      js.avatar.lipsColor = {
        red: rgb.r,
        green: rgb.g,
        blue: rgb.b,
      }
    }
  }

  addEyebrowsColor = (js: Record<string, any>) => {
    const hex = this.avatarState.eyebrowsColor
    if (hex !== null) {
      const rgb = hexToRgb(hex)
      js.avatar.eyebrowsColor = {
        red: rgb.r,
        green: rgb.g,
        blue: rgb.b,
      }
    }
  }

  addOutfits = (js: JSON) => {
    const addOutfit = (outfitType, outfit) => {
    //console.log(`addOutfit('${outfitType}'), outfit`, outfit)

      js[outfitType] = {
        list: [outfit.name],
        embed: true,
        textures: {
          embed: true,
          list: ['Color', 'Normal', 'Roughness', 'Metallic'],
        },
      }

      if (outfit.texture_code) {
        js[outfitType]['textures']['generated'] = {
          [outfit.name]: outfit.texture_code,
        }
      }

      if (outfit.color)
      {
        const rgbColor = hexToRgb(outfit.color)
        js[outfitType]['color'] = {
          red: rgbColor?.r,
          green: rgbColor?.g,
          blue: rgbColor?.b,
        }
      }

      if (outfit.logoPlacement && outfit.logoPlacement.x!==0) {
        js[outfitType].textures.logo = {
          [outfit.name]: {
            file: outfit.logoUuid, //"3e21be07-41ed-4f85-b7eb-b86299e73ffa",
            rect: {
              left: outfit.logoPlacement[0],
              right: outfit.logoPlacement[1],
              top: outfit.logoPlacement[2],
              bottom: outfit.logoPlacement[3]
            }
          }
        }
      }

    }

//console.log("this.avatarState.outfits.complect:", this.avatarState.outfits?.complect)
//console.log("this.avatarState.outfits.top:", this.avatarState.outfits?.top)

    if (this.avatarState.outfits.complect) {
      addOutfit('outfits', this.avatarState.outfits.complect)
    }
    if (this.avatarState.outfits.top) {
      addOutfit('outfits_top', this.avatarState.outfits.top)
    }
    if (this.avatarState.outfits.bottom) {
      addOutfit('outfits_bottom', this.avatarState.outfits.bottom)
    }
    if (this.avatarState.outfits.shoes) {
      addOutfit('outfits_shoes', this.avatarState.outfits.shoes)
    }

    console.info('js:', js)
  }

  addBodyBlendshapes = (js: JSON) => {
    const bodyBlends = this.avatarState.bodyShape
    if (!js['blendshapes']['values']) {
      js['blendshapes']['values'] = { body_shape: {}, face_modifications: {} }
    }
    for (const key in bodyBlends) {
      js['blendshapes']['values']['body_shape'][key] = bodyBlends[key]
    }
  }

  addFaceBlendshapes = (js: JSON) => {
    const faceBlends = this.avatarState.faceModifications

//    if (!js['blendshapes']['values']) {
//      js['blendshapes']['values'] = { body_shape: {}, face_modifications: {} }
//    }

    if (!js['blendshapes']['values'])
      js['blendshapes']['values'] = {}

    if (!js['blendshapes']['values']['body_shape'])
      js['blendshapes']['values']['body_shape'] = {}

    if (!js['blendshapes']['values']['face_modifications'])
      js['blendshapes']['values']['face_modifications'] = {}

    for (const key in faceBlends) {
      js['blendshapes']['values']['face_modifications'][key] = faceBlends[key]
    }
  }

  generateExportJSON = (exportData: JSON) => {
    const allowedFormats = ["fbx", "glb", "gltf"];
    const fileFormat = allowedFormats.includes(exportData?.format) ? exportData?.format : 'fbx';
    const lodNumber = exportData?.lod ? exportData?.lod : 1
    const lod = lodNumber === 1 ? 'LOD1' : 'LOD2'
    const useZip = exportData?.hasOwnProperty('useZip') ? exportData?.useZip : true
    const profile = exportData?.textureProfile ? exportData?.textureProfile : '1K.jpg'
    const exportJSON = {
      format: fileFormat,
      lod: lod,
      finalize: true,
      make_public_direct: !useZip,
      make_public: !isEmptyObject(exportData),
      avatar: {
        list: [
          'AvatarBody',
          'AvatarHead',
          'AvatarEyelashes',
          'AvatarLeftCornea',
          'AvatarRightCornea',
          'AvatarLeftEyeball',
          'AvatarRightEyeball',
          'AvatarTeethLower',
          'AvatarTeethUpper',
        ],
        textures: {
          list: ['Color', 'Normal', 'Roughness'],
        },
      },
      textures: {
        embed: true,
        profile: profile,
      },
      haircuts: {
        list: [this.avatarState.haircut.name],
        embed: true,
        textures: {
          embed: true,
          list: ['AO', 'Color', 'Metallic', 'Normal', 'Roughness', 'Scalp'],
        },
      },
      blendshapes: {
        list: ['mobile_51', 'visemes_15'],
        embed: true,
        values: {
          body_shape: {
            Scale: storeEditor.body.height[0] / this.avatar.height,
            HeadScale: storeEditor.body.headScale[0]
          }
        }
      },
    }

    if (this.avatarState.glasses.name) {
      exportJSON.glasses = {
        list: [this.avatarState.glasses.name],
        embed: true,
        textures: {
          embed: true,
          list: ['Color', 'GltfMetallicRoughness', 'Metallic', 'Roughness'],
        },
      }
    }

    if (this.avatarState.hat.name !== undefined && this.avatarState.hat.name !== null) {
      exportJSON.hat = {
        list: [this.avatarState.hat.name],
        embed: true,
        textures: {
          embed: true,
          list: ['Color', 'GltfMetallicRoughness', 'Metallic', 'Roughness'],
        },
      }
    }

    //console.log("exportJSON:", exportJSON)

    const exportJSONOutfit = {
      format: fileFormat,
      lod: lod,
      finalize: true,
      make_public: !isEmptyObject(exportData),
      textures: {
        embed: true,
        profile: profile,
      },
      blendshapes: {
        embed: true,
      },
    }

    
    this.addHaircutColor(exportJSON)

    this.addSkinColor(exportJSON)
    //this.addLipsColor(exportJSON)
    //this.addEyebrowsColor(exportJSON)

    this.addOutfits(exportJSON)
    this.addBodyBlendshapes(exportJSON)
    this.addFaceBlendshapes(exportJSON)
    return exportJSON
  }

  private sendExportRequest(
    exportData: JSON | undefined,
    avatarID: string | undefined,
    setIsOpenModal: any) {
    setTimeout(async () => {
      try {
        let exportJSON = this.generateExportJSON(exportData)
        //console.log("exportJSON:", exportJSON)

        if (getPlayerID() === '') {
          const playerID = await axios.post(
            getApiUrl('players/'),
            {},
            { headers: getAuthorizationHeaders()},
          )
          setPlayerID(playerID.data.code)
        }

        const res = await axios.post(
          getApiUrl(`avatars/${avatarID}/exports/`),
          {
            parameters: JSON.stringify(exportJSON),
            export_comment: exportComment
          },
          {
            headers: getAuthorizationHeaders(false, getPlayerID()),
          },
        )

        switch (res.data?.status) {
          case 'Failed':
            this.setIsSendingPreSetting (false)
            toast('Error in export', 'Please try one more time', 'error')
            setIsOpenModal(false)
            break
          case 'Completed':
            this.setProgress(100)
            this.exportComplete(res.data, isEmptyObject(exportData))
            setIsOpenModal(false)
            break
          default:
            await this.exportingPollingReq(
              exportData,
              avatarID,
              res?.data?.code,
              getPlayerID(),
              setIsOpenModal,
            )
            this.progress += 5
            break
        }
      } catch (error) {
        this.setIsSendingPreSetting(false)
        handleError(error)
      }
    }, 1000)
  }

  exportingAvatar = async (avatarId: string | undefined, setIsOpenModal: any, exportData: JSON | undefined) => {
    // console.info('exportingAvatar()')
    // console.info('  avatarId:', avatarId)
    // console.info('  exportData:', exportData)

    this.setProgress(0)
    this.isSendingAvatar = true
    const avatarID = avatarId ? avatarId : this.avatarId
    this.sendExportRequest(exportData, avatarID, setIsOpenModal)
  }

  getExportsFile = async (avatarID: string) => {
    try {
      const data = await request(exportsFile.endpoint({ avatarID }), exportsFile)
      //console.log("  data", data)
      //console.log("  !!!!! avatar_code:", data[0].avatar_code)
      //console.log("  !!!!! export_code:", data[0].code)

      this.getAvatarFile(avatarID, data)
      return data
    } catch (error) {
      this.setIsSendingPreSetting(false)
      handleError(error)
    }
    return null
  }

  getAvatarFile = async (avatarID: string, exportsFile: [{ code: string }]) => {
    if (this.isPendingGetAvatar) {
      return
    }

    this.isPendingGetAvatar = true

    try {
      const zipList =
        await request(avatarFile.endpoint({ avatarID, exportsCodeID: exportsFile[0].code }), avatarFile)

      //saveAs(zipList, 'avatar.zip')
      this.setProgress(100)

      if (getBusinessMode() === 'active') {
        const baseData = btoa('{"id":' + Math.floor(Math.random() * 100) + ',"code":"' + avatarID + '"}')

        // console.log("bus_mode")
        const reportRes = await axios.post(
          getApiUrl('report/?p=3'),
          { data: baseData },
          {
            headers: {
              Authorization: 'Bearer ' + getBusinessAccessToken(),
              'content-type': 'application/x-www-form-urlencoded',
            },
          },
        )
      }

      this.avatar = {
        id: avatarID,
        exportsID: exportsFile ? exportsFile[0].code : 0,
        photo: this.preSettings.photo,
        subtype: this.preSettings.gender,
        zip: zipList,
        hairColorInitial: null,
        skinColorInitial: null,
        lipsColorInitial: null,
        eyebrowsColorInitial: null,
        isBald: false,
        skinColor: null,
        lipsColor: null,
        eyebrowsColor: null,
      }

      storeEditor.cleanViewerState(this.preSettings.gender)

      if (this.availableSections.includes('choice')) {
        this.toSection('choice')
      } else if (this.isSendingPreSetting) {
        this.toSection('editor')
      }
      this.setIsSendingPreSetting(false)
    } catch (error) {
      this.setIsSendingPreSetting(false)
      handleError(error)
    } finally {
      this.isPendingGetAvatar = false
    }
  }

  getHaircut = async (name: string) => {
    //console.info('getHaircut(), name:', name)

    const zip =
      await request(
          haircutFile.endpoint({ avatarID: this.avatar.id, exportsCodeID: this.avatar.exportsID, haircutName: name }),
          haircutFile,
        )

    //saveAs(zip, "haircut.zip")

    return zip
  }

  getGlasses = async (name: string) => {
    // console.info('getGlasses(), name:', name)

    const zip =
      await request(
          glassesFile.endpoint({ avatarID: this.avatar.id, exportsCodeID: this.avatar.exportsID, glassesName: name }),
          glassesFile,
        )
    //saveAs(zip, "glasses.zip")

    return zip
  }

  getHat = async (name: string) => {
    //console.info('getHat(), name:', name)

    const zip =
      await request(
          hatFile.endpoint({ avatarID: this.avatar.id, exportsCodeID: this.avatar.exportsID, hatName: name }),
          hatFile,
        )
    //saveAs(zip, "hat.zip")

    return zip
  }

  setAndNext = (key: string, value: string) => {
    this.setPreSettings(key, value)
    this.nextSection()
  }

  setAndTo = (key: string, value: string, section: string) => {
    this.setPreSettings(key, value)
    this.toSection(section)
  }

  /**
   * create AI generated outfit texture
   *
   * outfitName     - selected outfit
   * text           - prompt for AI texture generator
   * statusCallback - callback for process status
   */

  // create AI generated outfit texture

  createAIGeneratedOutfitTexture = async (outfitName: string, text: string, statusCallback: any) => {
    this.setProgress(0)
    // console.info('startCreateAIGeneratedOutfitTexture()')
    // console.info('  outfitName:', outfitName)
    // console.info('  prompt:', text)

    statusCallback({ status: 'Started', data: null })

    const headers = getAuthorizationHeaders(true)
    //console.log("headers:", headers)
    const data = {
      avatar: this.avatar.id,
      outfit: outfitName,
      prompt: text,
    }
    //console.log("data:", data)

    let res = await axios.post(getApiUrl('generated_textures/'), data, { headers })
    // console.info('res:', res)

    const code = res.data['code']
    // console.info('code:', code)

    while (res.data['status'] !== 'Completed') {
      await new Promise((resolve) => setTimeout(resolve, 1000))
      res = await axios.get(getApiUrl(`generated_textures/${code}`), { headers })
      // console.info('status:', res.data['status'])
      statusCallback({ status: res.data['status'], data: null })
      if (this.progress<90) this.setProgress(this.progress + 10)

      if (res.data['status'] === 'Failed') {
        return
      }
    }
    this.setProgress(90)
    // console.info('start download generated texture')

    //code = '79f06c6b-543a-400b-93b7-69bbc8aef40a'
    //code = '2567c485-8aa5-448d-b4c4-eaff71cb57b1'

    //console.log("endpoint:", getApiUrl(`generated_textures/${code}/file/`) )
    res = await axios.get(getApiUrl(`generated_textures/${code}/file/`), { headers: headers, responseType: 'blob' })
    // console.info('res:', res)

    const blob = new Blob([res.data])
    //console.log("blob:", blob)

    const url = URL.createObjectURL(blob)
    //console.log("url:", url)

    if (process.env.USE_SAVETEXTURE === '1') {
      const link = document.createElement('a')
      link.download = 'aiTexture.webp'
      link.href = url
      link.click()
      link.delete
    }
    this.setProgress(100)
    statusCallback({ status: 'Ready', data: url, texture_code: code })
  }

  createTextureFromImage = async (outfitName: string, frontImage: string, backImage, statusCallback: any) => {
    console.info('createTextureFromImage()')
    console.info('  outfitName:', outfitName)
    console.info('  frontImage:', frontImage)
    console.info('  backImage:', backImage)

    try {
      this.setProgress(0)
      statusCallback({ status: 'Started', progress: 0 })

      const headers = getAuthorizationHeaders(true)
      const front = await fetch(frontImage)
        .then(res => res.blob())

      const back = await fetch(backImage)
        .then(res => res.blob())

      const data = {
        avatar: this.avatar.id,
        outfit: outfitName,
        front: front,
        back: back
      }
      //console.log("data:", data)

      const formData = new FormData();
        formData.append('avatar', this.avatar.id)
        formData.append('outfit', outfitName)
        formData.append('front', front, "front.jpg");
        formData.append('back', back, "back.jpg");
      //console.log("formData:", formData)

      let res = await fetch(getApiUrl("generated_textures/"), {
        method: "POST",
        body: formData,
        cache: 'no-cache',
        credentials: 'same-origin',
        headers: getAuthorizationHeaders(),
        mode: 'cors',
      })
        .then(response => {
        //console.log("response", response)
          return response.json()
        })

//    let res = await axios.post(getApiUrl('generated_textures/'), data, { headers })
      //console.info('res:', res)

      if (res.front) {
        toast("Invalid front image", res.front, "error")
        statusCallback({ status: 'Error', data: res })
        return
      }

      if (res.back) {
        toast("Invalid back image", res.back, "error")
        statusCallback({ status: 'Error', data: res })
        return
      }

      //console.log("res:", res)

      const code = res['code']
      // console.info('code:', code)

      let status = res['status']

      while (status !== 'Completed') {
        await new Promise((resolve) => setTimeout(resolve, 1000))

        res = await axios.get(getApiUrl(`generated_textures/${code}`), { headers })
        status = res.data['status']

        if (status === 'Failed') {
          toast("Failed", "Invalid image", "error")
          statusCallback({ status: 'Failed' })
          return
        }

        //console.info('res.data:', res.data)

        this.setProgress(res.data['progress'])
        statusCallback({ status: status, progress: res.data['progress'], data: null })
      }

      this.setProgress(95)
      statusCallback({ status: status, progress: 95, data: null })
      // console.info('start download generated texture')

      res = await axios.get(getApiUrl(`generated_textures/${code}/file/`), { headers: headers, responseType: 'blob' })
      console.info('res:', res)

      const blob = new Blob([res.data])
      //console.log("blob:", blob)

      const url = URL.createObjectURL(blob)
      //console.log("url:", url)

      if (process.env.USE_SAVETEXTURE === '1') {
        const link = document.createElement('a')
        link.download = 'aiTexture.webp'
        link.href = url
        link.click()
        link.delete
      }

      this.setProgress(100)
      statusCallback({ status: 'Ready', data: url, texture_code: code })
    }
    catch(error) {
      console.error("Error in createTextureFromImage():", error);
      toast("Failed", "Invalid image", "error")
      statusCallback({ status: 'Error', data: error })
    }
  }

}

const storeGeneratorPipeline = new GeneratorPipeline()

export { storeGeneratorPipeline }
