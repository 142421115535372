import { makeAutoObservable, makeObservable, observable, action } from 'mobx'
import { clone } from 'ramda'

import { OutfitKey } from '@/helpers/types'
import { storeGeneratorPipeline, storeLocalModel } from './index'
import { toast } from '@/services/adapters/toast'

import { getFemaleAnimations, getMaleAnimations } from '@/helpers/animationList'

export interface SkinTone {
  color: string
}
export interface FaceInterface {
  headHeight: [number]
  upperHeadVolume: [number]
  upperHeadWidth: [number]
  lowerHeadWidth: [number]
  jawLine: [number]
  eyes: [number]
  nose: [number]
  lips: [number]
  jaw: [number]
}
export interface BodyInterface {
  height: [number]
  scale: [number]
  headScale: [number]

  chest: [number]
  forearms: [number]
  waist: [number]
  shoulders: [number]
  hips: [number]
  legs: [number]
}
export interface Viewer {
  changeModel: () => void
  changeOutfit: () => void
  changeFace: () => void
  changeBody: () => void
  changePosition: (arg?: string) => void
}

const defaultFace: FaceInterface = {
  headHeight: [10],
  upperHeadVolume: [10],
  upperHeadWidth: [10],
  lowerHeadWidth: [10],
  jawLine: [10],

  eyes: [10],
  nose: [5],
  lips: [5],
  jaw: [10],
}
const defaultBody: BodyInterface = {
  height: [140], // 140...210 cm
  scale: [1],
  headScale: [1],

  chest: [5],
  forearms: [5],
  waist: [5],
  shoulders: [5],
  hips: [5],
  legs: [5],
}
const defaultViewer = {
  changeOutfit: () => null,
  changeFace: () => null,
  changeBody: () => null,
  changePosition: () => null,
}

interface Outfit {
  subtype: string;
  complect: { name: string | null; texture: any | null; texture_code: any | null; color: any | null; colorInitial: any | null; logo: any | null, logoPlacement: any, logoUuid: any };
  top: { name: string | null; texture: any | null; texture_code: any | null; color: any | null; colorInitial: any | null; logo: any | null, logoPlacement: any, logoUuid: any };
  bottom: { name: string | null; texture: any | null; texture_code: any | null; color: any | null; colorInitial: any | null; logo: any | null, logoPlacement: any, logoUuid: any };
  shoes: { name: string | null; texture: any | null; texture_code: any | null; color: any | null; colorInitial: any | null; logo: any | null, logoPlacement: any, logoUuid: any };
}


class Editor {
  versionAvatar = 0
  versionUI = 0

  age = 'Adult'
  subtype = 'female'
  viewer: Viewer = defaultViewer
  isCamReady = false
  isOutfitReady = false
  isModelReady = false
  isHaircutReady = false
  isGlassesReady = true
  isHatReady = true
  isViewer = false
  face: FaceInterface = clone(defaultFace)
  body: BodyInterface = clone(defaultBody)
  isAvatarModified = false

  gotException = false

  outfitKey: OutfitKey = 'LORI'

  blacklist = []

  skippedViewerControls = []

  //  params = defaultParams

  haircut = {
    subtype: null,
    color: null,
    colorInitial: null,
    preset: null,
    renderPasses: 1,
  }

  allAnimationsNames = {
    femalePoses: getFemaleAnimations(true),
    femaleAnimations: getFemaleAnimations(false),
    malePoses: getMaleAnimations(true),
    maleAnimations: getMaleAnimations(false),
  }

  animationsNames = { ...this.allAnimationsNames }

  commonHaircuts = [
    'Haircut0',
    'Haircut1',
    'Haircut2',
    'Haircut3',
    'Haircut4',
    'Haircut5',
    'Haircut6',
    'Haircut7',
    'Haircut8',
    'Haircut9',
    'Haircut10',
    'Haircut11',
    'Haircut12',
    'Haircut13',
    'Haircut14',
    'Haircut15',
    'Haircut16',
    'Haircut17',
    'Haircut18',
    'Haircut19',
    'Haircut20',
  ]

  haircutNames = {
    female: [...this.commonHaircuts],
    male: [...this.commonHaircuts],
  }

  outfits: Outfit

  outfitNames = {
    female: {
      complect: ['ARPI', 'Akna', 'CHATIN', 'LORI', 'Urasar', 'dress_Shaki', 'saree_Aramazd'],
      top: ['hoodie_ARAS', 'jacket_Arteni', 'jacket_Tavush', 'polo_Jil', 't-shirt_ARPI', 't-shirt_Vardenis', 'top_Khustup', 'top_Lessing', 'top_Urasar'],
      bottom: ['jeans_AYGER', 'jeans_SWAN', 'jeans_VEDI', 'leggins_Khustup', 'pants_Arteni', 'shorts_Vedi', 'skirt_Lessing'],
      shoes: ['shoes_LORI', 'sneakers_ARPI', 'sneakers_AZAT', ],
      initial: {
        complect: 'ARPI',
        top: 'hoodie_ARAS',
        bottom: 'jeans_AYGER',
        shoes: 'sneakers_ARPI',
      },
    },
    male: {
      complect: ['ARARAT', 'KARI',  'Kandura_Artanish', 'Kandura_Lalvar', 'Parz', 'SEVAN'],
      top: ['hoodie_AZAT', 'jacket_Ergates', 'jacket_Oroklini', 'kurta_Tandzut', 'longsleeve_Debed', 'polo_Getik', 'shirt_Olympus', 't-shirt_Hakari', 't-shirt_KARI'],
      bottom: ['dhoti_Tandzut', 'jeans_AZAT', 'pants_Ergates', 'pants_SEVAN', 'shorts_Getar'],
      shoes: ['sandals_Parz', 'shoes_KARI', 'shoes_Tandzut', 'sneakers_AZAT', 'sneakers_SEVAN'],
      initial: {
        complect: 'KARI',
        top: 'hoodie_AZAT',
        bottom: 'jeans_AZAT',
        shoes: 'sneakers_AZAT',
      },
    },
  }

  animation = {
    name: null,
  }

  glasses = {
    name: null,
    subtype: null,
  }


  commonGlasses = [
    'glasses_00',
    'glasses_01',
    'glasses_02',
    'glasses_03',
    'glasses_04',
    'glasses_05',
    'glasses_06',
    'glasses_07',
    'glasses_08',
    'glasses_09',
    'glasses_10',
    'glasses_11',
    'glasses_12',
    'glasses_13',
    'glasses_14',
    'glasses_15',
  ]
  glassesNames = {
    female: [...this.commonGlasses],
    male: [...this.commonGlasses],
  }

  hat = {
    name: null,
    subtype: null,
  }

  hatsNames = {
    female: [],
    male: ['Ghutra'],
  }

  initialAnimationName = 'Bashful'

  constructor() {
    this.cleanOutfits('female')

    //makeAutoObservable(this)

    makeObservable(this, {
      versionUI: observable,
      versionAvatar: observable,
      needsUpdate: action
    });
  }

  needsUpdate(avatarAlso) {
    //console.log(`needsUpdate(${avatarAlso}), versionUI:`, this.versionUI)

    this.versionUI++

    //console.log(`isCamReady: ${this.isCamReady}, isModelReady: ${this.isModelReady}, isHaircutReady: ${this.isHaircutReady}, isGlassesReady: ${this.isGlassesReady}, isHatReady: ${this.isHatReady}, isOutfitReady: ${this.isOutfitReady}`)

    if (avatarAlso && this.isReady()) {
      this.versionAvatar++
      //console.log(`this.versionAvatar: ${this.versionAvatar}`)
    }
  }

  convertUIControls = (controlName) => {
    switch (controlName) {
        case 'outfits':
            return 'shirt';
        case 'hairstyles':
            return 'hair';
        case 'glasses':
            return 'glasses';
        case 'age':
            return 'agetypes';
        case 'head':
            return 'face';
        case 'body':
          return 'body';
        case 'animations':
          return 'animation';
        default:
            console.warn('Unexpected control name value:', controlName);
            return 'Unknown'; // or handle as needed
    }
  }

  setUIControls = (skippedViewerControls) => {
      this.skippedViewerControls = skippedViewerControls.map(this.convertUIControls)
  }

  setBlacklist = (blacklist) => {
    this.blacklist = blacklist
    function filterBlacklist(categoryList: string[]): string[] {
      return categoryList.filter(item => !blacklist.includes(item));
    }
    for (const gender in this.outfitNames) {
      if (Object.prototype.hasOwnProperty.call(this.outfitNames, gender)) {
        const outfits = this.outfitNames[gender];
        for (const category in outfits) {
          if (Object.prototype.hasOwnProperty.call(outfits, category) && category !== 'initial') {
            this.outfitNames[gender][category] = filterBlacklist(outfits[category]);
          }
        }
      }
    }  
  }

  setCamReady = (payload: boolean) => {
    //console.info('setCamReady():', payload)
    this.isCamReady = payload
    //this.needsUpdate(true)
  }

  setOutfitReady = (payload: boolean) => {
    //console.info('setOutfitReady():', payload)
    this.isOutfitReady = payload
    //this.needsUpdate(true)
  }

  setModelReady = (payload: boolean) => {
    //console.info('setModelReady():', payload)
    this.isModelReady = payload
    //this.needsUpdate(true)
  }

  setHaircutReady = (payload: boolean) => {
    //console.info('setHaircutReady():', payload)
    this.isHaircutReady = payload
    //this.needsUpdate(true)
  }

  setGlassesReady = (payload: boolean) => {
    //console.info('setGlassesReady():', payload)
    this.isGlassesReady = payload
    //this.needsUpdate(true)
  }

  setHatReady = (payload: boolean) => {
    //console.info('setHatReady():', payload)
    this.isHatReady = payload
    //this.needsUpdate(true)
  }

  isReady = () => {
    return this.isCamReady && this.isModelReady && this.isHaircutReady && this.isGlassesReady && this.isHatReady && this.isOutfitReady
  }

  resetAll = () => {
    //console.info('resetAll')
    this.setAge(storeGeneratorPipeline.preSettings.age)

    this.setBody({ height: [storeGeneratorPipeline.avatar.height] })

    this.setBody({ scale: defaultBody.scale })
    //this.setBody({ headScale: defaultBody.headScale })

    this.setBody({ shoulders: defaultBody.shoulders })
    this.setBody({ forearms: defaultBody.forearms })
    this.setBody({ chest: defaultBody.chest })
    this.setBody({ waist: defaultBody.waist })
    this.setBody({ hips: defaultBody.hips })
    this.setBody({ legs: defaultBody.legs })

    this.setFace({ eyes: defaultFace.eyes })
    this.setFace({ nose: defaultFace.nose })
    this.setFace({ lips: defaultFace.lips })
    this.setFace({ jaw: defaultFace.jaw })

    storeGeneratorPipeline.setSkinColor(storeGeneratorPipeline.avatar.skinColorInitial)
    storeGeneratorPipeline.setLipsColor(storeGeneratorPipeline.avatar.lipsColorInitial)
    storeGeneratorPipeline.setEyebrowsColor(storeGeneratorPipeline.avatar.eyebrowsColorInitial)

    this.setHaircut(this.haircut.subtype, 'HaircutGenerated')
    this.setHaircutColor(this.haircut.colorInitial)

    this.setGlasses(this.glasses.subtype, 'None')

    //this.setHat(this.glasses.subtype, 'None')

    for (const section of ['complect', 'top', 'bottom', 'shoes']) {
      this.setOutfitsColor(section, this.outfits[section].colorInitial)
      this.setOutfitLogo(section, null)
      this.outfits[section].texture = null
      this.outfits[section].texture_code = null
    }

    this.setInitialAnimation(storeGeneratorPipeline.preSettings.gender)

    this.isAvatarModified = false
  }

  cleanViewerState = (subtype) => {
    //console.info('!!!!!!!!!! cleanViewerState, subtype:', subtype)

    this.isCamReady = false
    this.isOutfitReady = false
    this.isModelReady = false
    this.isHaircutReady = false
    this.isGlassesReady = true
    this.isHatReady = true
    this.viewer = defaultViewer
    this.face = clone(defaultFace)
    this.body = clone(defaultBody)

    this.subtype = subtype
    //this.age = storeGeneratorPipeline.preSettings.age
    this.age = "Adult"

    this.body.height[0] = storeGeneratorPipeline.avatar.height

    this.isAvatarModified = false
  }

  setViewer = (payload: Viewer) => {
    if (payload) {
      this.isViewer = true
      this.viewer = payload
    }
  }

  changePosition = (position) => {
    //console.info('changePosition(), position:', position)
    //this.params.camera = { ...this.params.camera, ...position }
    this.viewer.changePosition(position)
  }

  prepareParams = (first: string, second: string, value, isBody) => {
    const res = {
      [first]: value >= 0.5 ? 0 : (0.5 - value) * 2,
      [second]: value >= 0.5 ? (value - 0.5) * 2 : 0,
    }
    if (isBody) {
      storeGeneratorPipeline.setBodyShape(first, second, res)
    } else {
      storeGeneratorPipeline.setFaceShape(first, second, res)
    }
    return res
  }

  setFace = (payload: FaceInterface) => {
    //console.log("setFace(), payload:", payload)

    const [data] = Object.entries(payload)
    const facePart = data[0]
    const value = data[1][0]/10
    const invValue = 1 - value
    let blendshapes = {}

    switch (facePart) {
      case 'headHeight': {
        blendshapes = { Head_Height_Dn: invValue }
        storeGeneratorPipeline.setFaceBlendshape("Head_Height_Dn", invValue )
        break
      }
      case 'upperHeadVolume': {
        blendshapes = { Head_Vol_Dn: invValue }
        storeGeneratorPipeline.setFaceBlendshape("Head_Vol_Dn", invValue )
        break
      }
      case 'upperHeadWidth': {
        blendshapes = { Head_Width_Dn: invValue }
        storeGeneratorPipeline.setFaceBlendshape("Head_Width_Dn", invValue )
        break
      }
      case 'lowerHeadWidth': {
        blendshapes = { JawLine_Narrow: invValue }
        storeGeneratorPipeline.setFaceBlendshape("JawLine_Narrow", invValue )
        break
      }
      case 'jawLine': {
        blendshapes = { Jaw_Narrow: invValue }
        storeGeneratorPipeline.setFaceBlendshape("Jaw_Narrow", invValue )
        break
      }

      case 'eyes': {
        blendshapes = { Eye_SquintH_L: invValue, Eye_SquintH_R: invValue }
        storeGeneratorPipeline.setEyeSquint(invValue)
        break
      }
      case 'nose': {
        blendshapes = this.prepareParams('Nose_Sharp', 'Nose_Large', value, false)
        break
      }
      case 'lips': {
        blendshapes = this.prepareParams('Lips_Narrow', 'Lips_Large', value, false)
        break
      }
      case 'jaw': {
        const val = 1 - value
        blendshapes = { Jaw_Narrow: invValue }
        storeGeneratorPipeline.setJawNarrow(invValue)
        break
      }
      default:
        break
    }
    this.viewer.changeBlendshapes(blendshapes)

    this.face = { ...this.face, ...payload }

    this.isAvatarModified = true

    this.needsUpdate(true)
  }

  scalesForAge = (age: string) => {
    if (age==="Teen_12")
      return { scale: 0.8, headScale: 1.15 }

    if (age==="Teen_15")
      return { scale: 0.9, headScale: 1.05 }

    return { scale: 1., headScale: 1. }
  }

  setAge = (age: string) => {
    //console.log(`setAge(${age}, this.age=${this.age})`)

    if (age===this.age) return

    this.age = age
    storeGeneratorPipeline.setAvatarAge(age)

    const newScales = this.scalesForAge(age)

    //console.log("  this.body.scale:", this.body.scale)
    //console.log("  newScales.scale:", newScales.scale)

    this.setBody({ headScale: [newScales.headScale]    })
    this.setBody({ height:    [Math.round(this.body.scale * newScales.scale * storeGeneratorPipeline.avatar.height)] })
  }

  setBody = (payload: BodyInterface) => {
    //console.log("setBody(), payload:", payload)

    const [data] = Object.entries(payload)
    const bodyPart = data[0]
    const value = data[1][0]

    let blendshapes = {}

    switch (bodyPart) {

      case 'height':
        blendshapes = { 'height': value }

        const curScales = this.scalesForAge(this.age)
        let newScale = value / storeGeneratorPipeline.avatar.height / curScales.scale
        newScale = Math.round(newScale*100)/100

        storeGeneratorPipeline.setAvatarScale(newScale)
        this.body = { ...this.body, scale: newScale }
        break

     case 'scale':
        const newHeight = Math.round(value * this.scalesForAge(this.age).scale * storeGeneratorPipeline.avatar.height)
        //blendshapes = { 'scale': value }
        blendshapes = { 'height': newHeight }

        this.body = { ...this.body, height: [newHeight] }

        storeGeneratorPipeline.setAvatarScale(value)
        break

     case 'headScale':
        blendshapes = { 'headScale': value }
        break

      case 'chest':
        blendshapes = this.prepareParams('Chest-', 'Chest+', value/10, true)
        break
      case 'forearms':
        blendshapes = this.prepareParams('Forearms-', 'Forearms+', value/10, true)
        break
      case 'shoulders':
        blendshapes = this.prepareParams('Shoulders-', 'Shoulders+', value/10, true)
        break
      case 'waist':
        blendshapes = this.prepareParams('Waist-', 'Waist+', value/10, true)
        break
      case 'hips':
        blendshapes = this.prepareParams('Hips-', 'Hips+', value/10, true)
        break
      case 'legs':
        blendshapes = this.prepareParams('Legs-', 'Legs+', value/10, true)
        break
      default:
        break
    }

    this.viewer.changeBlendshapes(blendshapes)

    this.body = { ...this.body, ...payload }

    this.isAvatarModified = true

    this.needsUpdate(true)
  }

  setInitialOutfit = (payload: OutfitKey) => {
    this.outfitKey = payload
  }

  setOutfit = (payload: OutfitKey) => {
    this.outfitKey = payload
    this.isOutfitReady = false
    storeLocalModel.getDefaultOutfit()
  }

  changeModel = () => {
    if (this.viewer) {
      // console.info('Avatar changeModel')

      const gender = storeGeneratorPipeline.preSettings.gender
      this.referenceModelURL = storeGeneratorPipeline.avatarFiles?.model
      this.viewer.changeModel({ ...storeLocalModel.model.data[gender], ...storeGeneratorPipeline.avatarFiles })
      this.setAge(storeGeneratorPipeline.preSettings.age)
    }
  }

  changeOutfit = () => {
    if (storeLocalModel.outfit.data[this.outfitKey]) {
      // console.info('Avatar changeOutfit')
      this.viewer.changeOutfit({ ...storeLocalModel.outfit.data[this.outfitKey] })
    }
  }

  setHaircutColor = (color: string) => {
    //console.log('setHaircutColor()')
    //console.log('  color:', color)

    if (this.haircut.preset === 'Bald') {
      return
    }

    storeGeneratorPipeline.setHaircutColor(color)
    this.haircut = { ...this.haircut, color: color }
    this.isAvatarModified = true

    this.needsUpdate(true)
  }

  setHaircut = (subtype: string, name: string) => {
    //console.log(`setHaircut(), name: ${name}`)

    if (this.haircut?.preset && name===this.haircut?.preset) return
    if (!this.haircut?.preset && name==="HaircutGenerated") return

    storeGeneratorPipeline.setHaircutName(name)

    if (name !== 'HaircutGenerated') {
      this.isAvatarModified = true
    }

    this.haircut = {
      ...this.haircut,
      subtype: subtype,
      preset: name !== 'HaircutGenerated' ? name : null,
      //renderPasses: 3,
    }

    this.needsUpdate(true)
    this.setHaircutReady(false)
  }

  setInitialHaircut = (subtype: string) => {
    storeGeneratorPipeline.avatarState.haircut.name = 'HaircutGenerated'
    this.haircut = {
      color: null,
      subtype: subtype,
      preset: null,
      renderPasses: 1,
    }
  }

  setHaircutRenderPasses = (renderPasses: number) => {
    //console.info('setHaircutRenderPasses(), renderPasses:', renderPasses)

    this.haircut = { ...this.haircut, renderPasses: renderPasses }
  }

  setInitialGlasses = (subtype: string) => {
    storeGeneratorPipeline.avatarState.glasses.name = null
    this.glasses.name = null
  }

  setInitialHat = (subtype: string) => {
    storeGeneratorPipeline.avatarState.hat.name = null
    this.hat.name = null
  }

  setInitialAnimationName = (name: string) => {
    this.initialAnimationName = name
    this.animationsNames = { ...this.allAnimationsNames }
    if (name === 'Bashful') {
      this.animationsNames.femaleAnimations = this.animationsNames.femaleAnimations.filter(item => !item.includes('Female_Idle'));
      this.animationsNames.maleAnimations = this.animationsNames.maleAnimations.filter(item => !item.includes('Male_Idle'));
    }
  }

  setInitialAnimation = (subtype: string) => {
    this.setAnimation(subtype, this.initialAnimationName)
  }

  setGlasses = (subtype: string, name: string) => {
    //console.log(`setGlasses(${subtype}, ${name})`)

    if (name===this.glasses.name || (name==='None' && this.glasses.name===null) ) return

    storeGeneratorPipeline.setGlassesName(name !== 'None' ? name : null)

    this.glasses = {
      name: name !== 'None' ? name : null,
      subtype: subtype
    }

    if (name !== 'None') {
      this.isAvatarModified = true
    }

    this.needsUpdate(true)
    this.setGlassesReady(false)
  }

  setHat = (subtype: string, name: string) => {
    //console.log(`setHat('${subtype}','${name}')`)
    //console.log("this.outfits['hat']:", this.outfits['hat'])
    if (name===this.outfits.hat?.name) return

    storeGeneratorPipeline.setHatName(name !== 'None' ? name : null)

    this.hat = {
      preset: name !== 'None' ? name : null,
      name: name !== 'None' ? name : null,
      subtype: subtype
    }

    if (name==='Ghutra' && this.outfits['complect'].name!=='Kandura_Artanish' && this.outfits['complect'].name!=='Kandura_Lalvar' )
      this.setOutfits('complect', subtype, 'Kandura_Artanish')

    if (name !== 'None') {
      this.isAvatarModified = true
    }

    this.needsUpdate(true)
    this.setHatReady(false)
  }

  setAnimation = (subtype: string, name: string) => {
    // console.log('setAnimation(), name:', name)
    this.animation = {
      name: name !== 'None' ? name : null,
    }

    if (name !== 'None') {
      this.isAvatarModified = true
    }

    this.needsUpdate(true)
  }

  animationsPresets = (subtype, isPose) => {
    const res = [] // isPose ? [{ id: 'None', img: `/animations/${subtype}/animation_none.png` }] : []

    const animationType = isPose ? 'Poses' : 'Animations'
    const animationList = this.animationsNames[subtype + animationType]

    if (animationList) {
      animationList.forEach((val) => {

        //console.log(`${val}, ${this.outfits['complect'].name}, ${this.outfits['top'].name}, ${this.outfits['bottom'].name}`)

        if ( ['Male_Sitting_Pose', 'Male_Standing_Pose_2', 'Male_002', 'Male_005', 'Bboy_Uprock', 'samba_dancing', 'Standard_Walk'].includes(val) &&
             ['Kandura_Artanish',  'Kandura_Lalvar'].includes(this.outfits['complect'].name)
           ) return

        if ( ['Male_Sitting_Pose', 'Male_002', 'Male_005', 'Bboy_Uprock', 'Standard_Walk'].includes(val) &&
             ['Parz'].includes(this.outfits['complect'].name)
           ) return

        if ( ['Backflip', 'Male_Sitting_Pose', 'Male_002', 'Male_003', 'Male_004', 'Male_005', 'Bboy_Uprock', 'samba_dancing', 'Standard_Walk'].includes(val) &&
             ['kurta_Tandzut'].includes(this.outfits['top'].name)
           ) return


        if ( ['Female_Standing_Pose', 'Female_Sitting_Pose', 'Female_001', 'Female_004', 'Female_005', 'Bashful', 'samba_dancing', 'Standard_Walk'].includes(val) &&
             ['saree_Aramazd'].includes(this.outfits['complect'].name)
           ) return

        if ( ['Female_Standing_Pose', 'Female_001', 'Female_004', 'Female_005'].includes(val) &&
             ['Akna', 'dress_Shaki'].includes(this.outfits['complect'].name)
           ) return

        res.push({ id: val, img: `/animations/${subtype}/${val}.png` })
      })
    }

    return res
  }

  haircutPresets = (subtype: string) => {
    const res = []
    res.push({ id: 'Bald', img: '/haircuts/Bald.png' })

    if (!storeGeneratorPipeline.avatar.isBald) {
      res.push({ id: 'HaircutGenerated', img: '/haircuts/HaircutGenerated.png' })
    }

    this.haircutNames[subtype].forEach((val) => {
      res.push({ id: val, img: '/haircuts/' + val + '.png' })
    })

    return res
  }

  glassesPresets = (subtype: string) => {
    const res = []
    res.push({ id: 'None', img: '/glasses/glasses_none.png' })
    this.glassesNames[subtype].forEach((val) => {
      res.push({ id: val, img: '/glasses/' + val + '.png' })
    })
    return res
  }

  presets = (subtype: string, section: string) => {
    const res = []
    this.outfitNames[subtype][section].forEach((val) => {
      //console.log(`${val}, ${this.outfits['top'].name}, ${this.outfits['bottom'].name}`)

      if (val==='kurta_Tandzut' && this.outfits['bottom'].name==='pants_Ergates') return
      if (val==='pants_Ergates' && this.outfits['top'].name==='kurta_Tandzut') return

      res.push({ id: val, img: '/outfits/' + subtype + '/' + val + '.png' })
    })

    //console.log("res:", res)

    return res
  }

  hatPresets = (subtype: string) => {
    const res = []
//    res.push({ id: 'None', img: '/hats/hat_none.png' })
    this.hatsNames[subtype].forEach((val) => {
      res.push({ id: val, img: '/hats/' + val + '.png' })
    })
    return res
  }

  setInitialOutfits = (subtype: string) => {
    //console.log("!!!!!!!!!! setInitialOutfits()", subtype)

    this.setOutfits('complect', subtype, this.outfitNames[subtype].initial['complect'])
  }

  private cleanOutfits(subtype: string): void {
    this.outfits = this.createOutfitStructure(subtype);
  }

  private fillOutfits(subtype: string, complect: string, top: string, bottom: string, shoes: string): void {
      this.outfits = this.createOutfitStructure(subtype, complect, top, bottom, shoes);
  }

  private createOutfitStructure(subtype: string, complect: string | null = null, top: string | null = null, bottom: string | null = null, shoes: string | null = null): Outfit {
    return {
        subtype: subtype,
        complect: { name: complect, texture: null, texture_code: null, color: null, colorInitial: null, logo: null, logoPlacement: null, logoUuid: null },
        top: { name: top, texture: null, texture_code: null, color: null, colorInitial: null, logo: null, logoPlacement: null, logoUuid: null },
        bottom: { name: bottom, texture: null, texture_code: null, color: null, colorInitial: null, logo: null, logoPlacement: null, logoUuid: null },
        shoes: { name: shoes, texture: null, texture_code: null, color: null, colorInitial: null, logo: null, logoPlacement: null, logoUuid: null },
    };
}

  setOutfitLogo = async (section: string, logo: any | null, logoUuid: string | null = null) => {
    //console.log(`setOutfitLogo(${section}, ${logo}, ${logoUuid}`)

    this.needsUpdate(true)
    this.setOutfitReady(false)

    if (logoUuid==null && logo!=null){
      logoUuid = await storeGeneratorPipeline.uploadOutfitLogo(logo)
      if (logoUuid === null) {
        this.setOutfitReady(true)
        return
      }
    }

    if (logo!==this.outfits[section].logo || logoUuid!==this.outfits[section].logoUuid)
    {
      this.outfits = {
        ...this.outfits,
        [section]: { ...this.outfits[section], logo: logo, logoUuid: logoUuid },
      }

      storeGeneratorPipeline.setOutfits(this.outfits)
    }
    //console.log("this.outfits:", this.outfits)

    this.setOutfitReady(true)
    this.needsUpdate(true)
  }

  setOutfits = (section: string, subtype: string, name: string, texture: any = null, texture_code: string = null) => {
    //console.log('setOutfits()', section, subtype, name, texture, texture_code)

    //this.setOutfitReady(false)

    if (this.blacklist.includes(name)) {  
      if (section !== 'complect') {
        if (this.outfitNames[subtype][section].length > 0) {
          this.setOutfits(section, subtype, this.outfitNames[subtype][section][0], null, null)
        } else if (this.outfitNames[subtype]['complect'].length > 0) {
          this.fillOutfits(subtype, this.outfitNames[subtype]['complect'][0], null, null, null)
        }
        else { 
          if (!this.gotException) {
            toast('Couldn\'t load outfit', 'Please check outfits', 'error')
            this.gotException = true
          }
        }
      }
      else { 
        if (this.outfitNames[subtype][section].length > 0) {
          this.setOutfits(section, subtype, this.outfitNames[subtype][section][0], null, null)
        } else
        if (this.outfitNames[subtype]['top'].length > 0 && this.outfitNames[subtype]['bottom'].length > 0 && this.outfitNames[subtype]['shoes'].length > 0) {
          this.fillOutfits(subtype, null, this.outfitNames[subtype]['top'][0], this.outfitNames[subtype]['bottom'][0], this.outfitNames[subtype]['shoes'][0])
        }
        else {
          if (!this.gotException) {
            toast('Couldn\'t load outfit', 'Please check outfits', 'error')
            this.gotException = true
          }
        }
      }

      this.needsUpdate(true)
      return
    }

    if (section === 'complect') {
      const logoPlacement = this.outfits["complect"].logoPlacement
      const useLogo = this.outfits["complect"].useLogo
      this.cleanOutfits(subtype)
      this.outfits = {
        ...this.outfits,
        ['complect']: { name: name, texture: texture, texture_code: texture_code, color: null, colorInitial: null, useLogo: useLogo, logo: null, logoPlacement: logoPlacement, logoUuid: null },
      }
    } else {
      if (this.outfits.top.name === null || this.outfits.bottom.name === null || this.outfits.shoes.name === null) {
        this.fillOutfits(subtype, null, section === 'top' ? name : subtype === 'male' ? 'hoodie_AZAT' : 'hoodie_ARAS', section === 'bottom' ? name : subtype === 'male' ? 'jeans_AZAT' : 'jeans_AYGER', section === 'shoes' ? name : subtype === 'male' ? 'shoes_KARI' : 'sneakers_ARPI')
      }

      const logoPlacement = this.outfits[section].logoPlacement
      const useLogo = this.outfits[section].useLogo
      const useTextureFromFile = this.outfits[section].useTextureFromFile

      this.outfits = {
        ...this.outfits,
        [section]: { name: name, texture: texture, texture_code: texture_code, color: null, colorInitial: null, useLogo: useLogo, logo: null, logoPlacement: logoPlacement, logoUuid: null, useTextureFromFile: useTextureFromFile },
      }

      this.needsUpdate(true)
      this.setOutfitReady(false)
    }

    storeGeneratorPipeline.setOutfits(this.outfits)

    if ( ['Female_Standing_Pose', 'Female_001', 'Female_004', 'Female_005'].includes(this.animation.name) &&
      ['Akna', 'dress_Shaki'].includes(this.outfits['complect'].name)
    ) {
      this.setAnimation('female', 'Female_Idle')
    }

    if ( ['Female_Standing_Pose', 'Female_Sitting_Pose', 'Female_001', 'Female_004', 'Female_005', 'Standard_Walk', 'Bashful', 'samba_dancing'].includes(this.animation.name) &&
      ['saree_Aramazd'].includes(this.outfits['complect'].name)
    ) {
      this.setAnimation('female', 'Female_Idle')
    }

    if ( ['Male_Sitting_Pose', 'Male_Standing_Pose_2', 'Male_002', 'Male_005', 'Bboy_Uprock', 'samba_dancing', 'Standard_Walk'].includes(this.animation.name) &&
      ['Kandura_Artanish',  'Kandura_Lalvar'].includes(this.outfits['complect'].name)
    ) {
      this.setAnimation('male', 'Male_Idle')
    }

    if ( ['Male_Sitting_Pose', 'Male_002', 'Male_005', 'Bboy_Uprock', 'Standard_Walk'].includes(this.animation.name) &&
      ['Parz'].includes(this.outfits['complect'].name)
    )
      this.setAnimation('male', 'Male_Idle')


    if ( ['Backflip', 'Male_Sitting_Pose', 'Male_002', 'Male_003', 'Male_004', 'Male_005', 'Bboy_Uprock', 'samba_dancing', 'Standard_Walk'].includes(this.animation.name) &&
      ['kurta_Tandzut'].includes(this.outfits['top'].name)
    )
      this.setAnimation('male', 'Male_Idle')

    if (['Kandura_Artanish',  'Kandura_Lalvar'].includes(this.outfits['complect'].name)) {
      if (this.hat.name!=='Ghutra') this.setHat(subtype, 'Ghutra')
    } else {
      if (this.hat.name==='Ghutra') this.setHat(subtype, null)
    }

    if (name==='kurta_Tandzut') {
      if (this.outfits['bottom'].name!=='dhoti_Tandzut') this.setOutfits('bottom', subtype, 'dhoti_Tandzut')
      if (this.outfits['shoes'].name!=='shoes_Tandzut') this.setOutfits('shoes', subtype, 'shoes_Tandzut')
    }

    if (name==='dhoti_Tandzut') {
      if (this.outfits['top'].name!=='kurta_Tandzut') this.setOutfits('top', subtype, 'kurta_Tandzut')
      if (this.outfits['shoes'].name!=='shoes_Tandzut') this.setOutfits('shoes', subtype, 'shoes_Tandzut')
    }

    if (name==='shoes_Tandzut') {
      if (this.outfits['top'].name!=='kurta_Tandzut') this.setOutfits('top', subtype, 'kurta_Tandzut')
      if (this.outfits['bottom'].name!=='dhoti_Tandzut') this.setOutfits('bottom', subtype, 'dhoti_Tandzut')
    }


    this.needsUpdate(true)
    this.setOutfitReady(false)
  }

  setOutfitsColor = (section: string, color: string, initialColor: string|null = null) => {
    //console.info(`\nsetOutfitsColor("${section}", "${color}")`)

    this.outfits = {
      ...this.outfits,
      [section]: { ...this.outfits[section], color: color },
    }

    if (initialColor !== null) {
      this.outfits[section].initialColor = initialColor
    }

    storeGeneratorPipeline.setOutfits(this.outfits)
    // console.log("this.outfits", this.outfits)

    this.needsUpdate(true)
  }
}

const storeEditor = new Editor()

export { storeEditor }
