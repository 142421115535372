// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Login-Form-module--cls1--KSVjB { /*!*/ }\n\n.Login-Form-module--cls2--XIEyY {\n  margin-top: 32px;\n  margin-left: auto;\n  margin-right: auto;\n  margin-bottom: 36px;\n}", "",{"version":3,"sources":["webpack://./src/views/page/auth/Login-Form.module.scss"],"names":[],"mappings":"AAAA,kCAAA,IAAA,EAAA;;AACA;EAGA,gBAAA;EACA,iBAAA;EACA,kBAAA;EACA,mBAAA;AACA","sourcesContent":[".cls1 { /*!*/ }\n.cls2 {\ncomposes: cls1;\n\nmargin-top: 32px;\nmargin-left: auto;\nmargin-right: auto;\nmargin-bottom: 36px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"cls1": "Login-Form-module--cls1--KSVjB",
	"cls2": "Login-Form-module--cls2--XIEyY Login-Form-module--cls1--KSVjB"
};
export default ___CSS_LOADER_EXPORT___;
