import React from 'react'
import styled from 'astroturf/react'
import { useForm } from 'react-hook-form'
import { handleError, toast } from '@/services/adapters/toast'
import { Typography, Textfield, Button } from '@/components/ui'
import { Social } from '@/views/components/common'
import { useNavigate } from "react-router-dom"
import { useState,useEffect } from 'react'
import { ArrowLeft } from '@/components/ui/icons'
import { color } from 'html2canvas/dist/types/css/types/color'
import * as FormData from 'form-data';
import axios from 'axios'
import { getAccessToken } from '@/services/adapters/req/config'


export const Login = () => {
  const [isMode, setIsMode] = useState(true);
  const [isDisabled, setIsDisabled] = useState(false);
  const [countdown, setCountdown] = useState(0);
  const [userData, setUserData] = useState('');
  const [isButtonDisabled, setButtonDisabled] = useState(false);
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm()
  
  useEffect(() => {
    if (navigator.userAgent.indexOf('iPhone') > -1) {
      document
        .querySelector("[name=viewport]")
        .setAttribute("content","width=device-width, initial-scale=1, maximum-scale=1");
    }
  }, []);

  const onSubmit = async (data: any) => {
    const email = data.login
    if (!email) {
      toast('Empty email address','Please type an address', 'error')
      return
    }
    const regex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(". +"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    if (!regex.test(email)) {
      toast('Invalid email address','Please check entered address', 'error')
      return
    }
    else {
      localStorage.setItem('UserObj', `${data.login}`);
      const storeData = localStorage.getItem('UserObj');
      setUserData(storeData);
      const token = getAccessToken()

      let formValue = new FormData.default();
      formValue.append('email', email);
      formValue.append('token', token);

      let config = {
        method: 'post',
        maxBodyLength: Infinity,
        url: 'https://metaperson-api.avatarsdk.com/auth/',
        headers: {
          'Content-Type': 'multipart/form-data',
          ...formValue.getHeaders
        },
        data: formValue
      };

      axios.request(config)
        .then((response) => {
          // console.log(JSON.stringify(response.data));
          if (response.data.success) {
          }
        })
        .catch((error) => {
          // console.log(error);
        });
        // toast('Login was successful','Email with password was sent to your account', 'success')
    }
    // navigate("/auth/verificaion");
    // console.info(data)
     setIsMode(false);
     startCountdown(null,false);
  }
  const resendCode = async (data: any) => {
    const storeData = localStorage.getItem('UserObj');
    const email = storeData
    const token = getAccessToken()
      let formValue = new FormData.default();
      formValue.append('email', email);
      formValue.append('token', token);
      let config = {
        method: 'post',
        maxBodyLength: Infinity,
        url: 'https://metaperson-api.avatarsdk.com/auth/',
        headers: { 
          'Content-Type': 'multipart/form-data',
          ...formValue.getHeaders
        },
        data : formValue
      };

      axios.request(config)
      .then((response) => {
        // console.log(JSON.stringify(response.data));
        if(response.data.success){
        }
      })
      .catch((error) => {
        // console.log(error);
      });
  } 
  const onVerified = async (data: any) => {
    setButtonDisabled(true);
    const code = data.codeforverification
    if (!code) {
      toast('Empty verification code','Please enter the code from the email', 'error')
      setButtonDisabled(false);
      return
    }
    const storeData = localStorage.getItem('UserObj');
    const token = getAccessToken()

      let formValue = new FormData.default();
      formValue.append('email', data.login);
      formValue.append('token', token);
      formValue.append('code', code);
      // console.log(formValue, formValue.getHeaders);
      

      let config = {
        method: 'post',
        maxBodyLength: Infinity,
        url: 'https://metaperson-api.avatarsdk.com/auth/',
        headers: { 
          'Content-Type': 'multipart/form-data',
          ...formValue.getHeaders
        },
        data : formValue
      };

      axios.request(config)
      .then((response) => {
        // console.log(JSON.stringify(response.data));
        if(response.data.success){
          localStorage.setItem('VerifiedUserObj', `${storeData}`);
          navigate('/generator')
        }
        toast('You successfully signed in!','', 'success')
      })
      .catch((error) => {
        // console.log(error);
        toast('Incorrect verification code','Please enter the code from the email', 'error')
        setButtonDisabled(false);
      });
  }
  const navigate = useNavigate()
  const navigateTo = () => {
    navigate('/generator')
  }
  const startCountdown = (data: any, shouldResend: boolean) => {
    setButtonDisabled(false);
    setIsDisabled(true);   
    if (shouldResend) {
      resendCode(data);
    }
    setCountdown(60);
    var countdownInterval = setInterval(function() {
      setCountdown(countdown => {
        const result = countdown - 1;
        // console.log(result);
        if (countdown === 1) {
          setIsDisabled(false);
          clearInterval(countdownInterval);
        }
        return result;
      });
    }, 1000);
  }
  return (
    <>
     <Header>
        <Button isOnlyIcon variant='surface' onClick={navigateTo}>
          <ArrowLeft />
          </Button>
      </Header>    
    {isMode ? (
    <>
    <MiddleContent>
      <Typography variant='h2' as='h1'>
        Login to MetaPerson
      </Typography>
      <Typography variant='h4' as='h3'>
      We will send a confirmation code to your email
      </Typography>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Textfield
          wSize='full'
          placeholder='Email address'
          {...register('login')}
        />
        <Button type='submit' wSize='full'>
          Continue with email
        </Button>
      </Form>
      <Social />
    </MiddleContent>
    <Msg>By pressing «Continue with email» you agree to the <a href="https://avatarsdk.com/privacy-policy/" target="_blank">Privacy Policy</a> and our <a href="https://avatarsdk.com/eula/" target="_blank">Terms</a></Msg>
  </>
    ): (
      <>
    <MiddleContent>
      <Typography variant='h2' as='h1'>
      Login to MetaPerson
      </Typography>
      <Typography variant='h4' as='h3'>
      An email with a verification code was sent to
      </Typography>
      <Typography variant='h4' as='h3'>
      {userData}
      </Typography>
      <Form onSubmit={handleSubmit(onVerified)}>
        <div style={{ display: 'flex' }}>
        <Textfield
        {...register("codeforverification")} 
          wSize='full'
          placeholder='Enter code'
        />
        {isDisabled ? (
          <span style={{ marginLeft: '10px' , width: '175px'}} id='countdown'>
            Resend code in {countdown} sec.
          </span>
        ) : (
          <span style={{ marginLeft: '10px'}}>
          <Button onClick={() => startCountdown(null,true)} >
          <div style={{ width: '65px'}}>Resend</div>
          </Button>
          </span>
        )}
        </div>
        <Button type='submit' disabled={isButtonDisabled}  wSize='full'>
          Verify
        </Button>
      </Form>
    </MiddleContent>
      </>
    )}
    </>
  )
}

const MiddleContent = styled.div`
  margin-top: auto;
  margin-bottom: auto;
  max-width: 360px;
  width: 100%;
`

const Form = styled.form`
  margin-top: 32px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 36px;
`

const Msg = styled.p`
  color: var(--color-text-gray);
  font-size: 13px;
  padding-bottom: 40px;
  text-align: center;
  font-weight: 500;
  font-size: 13px;
  line-height: 150%;
  max-width: 270px;
  margin-left: auto;
  margin-right: auto;
`

const Header = styled.div`
  display: flex;
  width: calc(100% - 64px);
  margin-left: auto;
  margin-right: auto;
  padding-top: 32px;
  position: absolute;
  justify-content: space-between;
  top: 0;
  left: 32px;
  z-index: 5;
`