// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Result-Action-module--cls1--f6Eb1 { /*!*/ }\n\n.Result-Action-module--cls2--YHRT8 {\n}\n.Result-Action-module--cls2--YHRT8:not(:last-child) {\n  margin-bottom: 12px;\n}", "",{"version":3,"sources":["webpack://./src/views/page/generator/sections/Result-Action.module.scss"],"names":[],"mappings":"AAAA,qCAAA,IAAA,EAAA;;AACA;AAGA;AAAA;EACA,mBAAA;AAEA","sourcesContent":[".cls1 { /*!*/ }\n.cls2 {\ncomposes: cls1;\n\n&:not(:last-child) {\nmargin-bottom: 12px;\n}\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"cls1": "Result-Action-module--cls1--f6Eb1",
	"cls2": "Result-Action-module--cls2--YHRT8 Result-Action-module--cls1--f6Eb1"
};
export default ___CSS_LOADER_EXPORT___;
