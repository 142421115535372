import React, { FC } from 'react'
import { observer } from 'mobx-react-lite'

import { SliderPicker } from 'react-color'

import { useLayoutContext } from '@/views/components/core/LayoutProvider'
import { storeEditor, storeGeneratorPipeline } from '@/services/store'
import { Presets, WrapBox, Box } from '@/views/page/generator/sections/components'

export const EditorHair: FC = observer(() => {
  const versionUI = storeEditor.versionUI  // to rerender after observable versionUI increment

  const { haircut, setHaircut, setHaircutColor, haircutPresets } = storeEditor
  const {
    preSettings: { gender },
  } = storeGeneratorPipeline

  const { mediaQueries } = useLayoutContext()

  //console.log("haircut:", haircut)

  return (
    <div style={mediaQueries.isTablet === false ? {} : { height: '75%', overflowY: 'scroll' }}>
      <WrapBox>
        <Box title={'Haircut color'}>
          <SliderPicker
            color={haircut.color ? haircut.color : '#ffffff'}
            onChangeComplete={(color) => setHaircutColor(color.hex)}
          />
        </Box>
        <Box title={'Hair'}>
          <Presets
            disabled={!storeEditor.isReady()}
            presets={haircutPresets(gender)}
            value={haircut.preset || 'HaircutGenerated'}
            setValue={(preset) => {
              setHaircut(gender, preset)
              if (preset) storeEditor.setHat(gender, null)
            }}
          />
        </Box>
      </WrapBox>
    </div>
  )
})

EditorHair.displayName = 'EditorHair'
