// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".index-Core-module--cls1--XoVvD { /*!*/ }\n\n.index-Core-module--cls2--J72ld {\n}\n.index-Core-module--cls2--J72ld.index-Core-module--variant-mobile--TPkoK {\n  display: flex;\n  flex-direction: column;\n  justify-content: flex-end;\n  height: var(--app-height);\n}", "",{"version":3,"sources":["webpack://./src/views/page/generator/sections/Editor/index-Core.module.scss"],"names":[],"mappings":"AAAA,kCAAA,IAAA,EAAA;;AACA;AAGA;AAAA;EACA,aAAA;EACA,sBAAA;EACA,yBAAA;EACA,yBAAA;AAEA","sourcesContent":[".cls1 { /*!*/ }\n.cls2 {\ncomposes: cls1;\n\n&.variant-mobile {\ndisplay: flex;\nflex-direction: column;\njustify-content: flex-end;\nheight: var(--app-height);\n}\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"cls1": "index-Core-module--cls1--XoVvD",
	"cls2": "index-Core-module--cls2--J72ld index-Core-module--cls1--XoVvD",
	"variant-mobile": "index-Core-module--variant-mobile--TPkoK"
};
export default ___CSS_LOADER_EXPORT___;
