// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Empty-AvatarPreview-module--cls1--uDmHG { /*!*/ }\n\n.Empty-AvatarPreview-module--cls2--DpMkU {\n  bottom: 0;\n  right: 0;\n  width: 385px;\n  height: auto;\n  object-fit: contain;\n}", "",{"version":3,"sources":["webpack://./src/views/page/gallery/components/Empty-AvatarPreview.module.scss"],"names":[],"mappings":"AAAA,2CAAA,IAAA,EAAA;;AACA;EAGA,SAAA;EACA,QAAA;EACA,YAAA;EACA,YAAA;EACA,mBAAA;AACA","sourcesContent":[".cls1 { /*!*/ }\n.cls2 {\ncomposes: cls1;\n\nbottom: 0;\nright: 0;\nwidth: 385px;\nheight: auto;\nobject-fit: contain;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"cls1": "Empty-AvatarPreview-module--cls1--uDmHG",
	"cls2": "Empty-AvatarPreview-module--cls2--DpMkU Empty-AvatarPreview-module--cls1--uDmHG"
};
export default ___CSS_LOADER_EXPORT___;
