import React from 'react'
import { ArrowLeft } from '@/components/ui/icons'
import styled from 'astroturf/react'
import { Typography, Button } from '@/components/ui'
import { useNavigate } from "react-router-dom";
import { useState,useEffect } from 'react'



export const ProfileDetails = () => {
  const [userData, setUserData] = useState('');
  const navigate = useNavigate();
  const navigateTo = () => {
    navigate('/generator')
  }
  const handleLogout = () => {
    localStorage.removeItem("VerifiedUserObj");
    localStorage.removeItem("UserOBj");
    navigate('/auth')
  }
  useEffect(() => {
    const storedData = localStorage.getItem("VerifiedUserObj");
    if (storedData) {
      setUserData(storedData);
    }
  }, []);
  return (
    <>
      <Header>
        <Button isOnlyIcon variant='surface' onClick={navigateTo}>
        <ArrowLeft />
        </Button>
      </Header>    
      <MiddleContent>
        <Typography variant='h2' as='h1'>
            Login to MetaPerson
        </Typography>
        <div style={{ marginTop: '10px' , marginBottom: '10px'}} >
        <Typography variant='h4' as='h1' >
        You are logged in as: {userData}
        </Typography>
        </div>
        <Button onClick={handleLogout}>
            Logout
        </Button> 
      </MiddleContent>
    </>
  )
}

const Header = styled.div`
  display: flex;
  width: calc(100% - 64px);
  margin-left: auto;
  margin-right: auto;
  padding-top: 32px;
  position: absolute;
  justify-content: space-between;
  top: 0;
  left: 32px;
  z-index: 5;
`

const MiddleContent = styled.div`
  margin-top: auto;
  margin-bottom: auto;
  max-width: 360px;
  width: 100%;
`