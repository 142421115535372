// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".EditorShirt-Box_Without_BG-module--cls1--tzKq5 { /*!*/ }\n\n.EditorShirt-Box_Without_BG-module--cls2--bM6xK {\n  margin-bottom: 14px;\n  display: flex;\n  justify-content: center;\n  flex-wrap: wrap;\n}", "",{"version":3,"sources":["webpack://./src/views/page/generator/sections/Editor/components/EditorShirt-Box_Without_BG.module.scss"],"names":[],"mappings":"AAAA,kDAAA,IAAA,EAAA;;AACA;EAGA,mBAAA;EACA,aAAA;EACA,uBAAA;EACA,eAAA;AACA","sourcesContent":[".cls1 { /*!*/ }\n.cls2 {\ncomposes: cls1;\n\nmargin-bottom: 14px;\ndisplay: flex;\njustify-content: center;\nflex-wrap: wrap;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"cls1": "EditorShirt-Box_Without_BG-module--cls1--tzKq5",
	"cls2": "EditorShirt-Box_Without_BG-module--cls2--bM6xK EditorShirt-Box_Without_BG-module--cls1--tzKq5"
};
export default ___CSS_LOADER_EXPORT___;
