// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Choice-AvatarList-module--cls1--yxmsY { /*!*/ }\n\n.Choice-AvatarList-module--cls2--DvWet {\n  margin-top: 24px;\n}", "",{"version":3,"sources":["webpack://./src/views/page/generator/sections/Choice-AvatarList.module.scss"],"names":[],"mappings":"AAAA,yCAAA,IAAA,EAAA;;AACA;EAGA,gBAAA;AACA","sourcesContent":[".cls1 { /*!*/ }\n.cls2 {\ncomposes: cls1;\n\nmargin-top: 24px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"cls1": "Choice-AvatarList-module--cls1--yxmsY",
	"cls2": "Choice-AvatarList-module--cls2--DvWet Choice-AvatarList-module--cls1--yxmsY"
};
export default ___CSS_LOADER_EXPORT___;
