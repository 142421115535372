import React, { FC } from 'react'
import styled from 'astroturf/react'
import { Cover } from './Cover'
import { Loader } from '@/components/ui'

export interface AvatarPreviewProps {
  img: string
  width: number
  height: number
  gradient?: boolean
  handleClick?: () => void
}

export const AvatarPreview: FC<AvatarPreviewProps> = ({ img, width, height, gradient = false, handleClick }) => {
  return (
    <>
      <Cover width={width} height={height} />
      <Core gradient={gradient} onClick={handleClick && handleClick}>
        <Loader spinner='dots' />
        <Img src={img} />
      </Core>
    </>
  )
}

export interface CoreProps {
  gradient: boolean
}
const Core = styled.div<CoreProps>`
  width: 100%;
  height: 100%;
  background-color: var(--color-bg-box);
  border-radius: var(--size-radius);
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  align-items: flex-end;
  justify-content: center;

  &.gradient {
    &:before {
      opacity: 1;
    }
  }

  &:hover {
    &:before {
      opacity: 1;
    }
  }

  &:before {
    content: '';
    @import '../../assets/styles/mixins.scss';
    @include anim(opacity);
    border-radius: var(--size-radius);
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-image: linear-gradient(102.67deg, #7d1bf5 0%, #e32dd1 99.48%);
    opacity: 0;
  }
`

const Img = styled.img`
  position: relative;
  display: block;
  max-width: 100%;
`
