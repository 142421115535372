// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".SpinnerInfinite-Outline-module--cls1--wNSw9 { /*!*/ }\n\n.SpinnerInfinite-Outline-module--cls2--mdlNf {\n  stroke-dasharray: 2.42777px, 242.77666px;\n  stroke-dashoffset: 0;\n  animation: SpinnerInfinite-Outline-module--anim--TiBrE 1.6s linear infinite;\n  stroke: var(--color-base);\n}\n@keyframes SpinnerInfinite-Outline-module--anim--TiBrE {\n  12.5% {\n    stroke-dasharray: 33.98873px, 242.77666px;\n    stroke-dashoffset: -0.70543px;\n  }\n  43.75% {\n    stroke-dasharray: 84.97183px, 242.77666px;\n    stroke-dashoffset: -84.97183px;\n  }\n  100% {\n    stroke-dasharray: 2.42777px, 242.77666px;\n    stroke-dashoffset: -240.34889px;\n  }\n}", "",{"version":3,"sources":["webpack://./src/components/ui/SpinnerInfinite-Outline.module.scss"],"names":[],"mappings":"AAAA,+CAAA,IAAA,EAAA;;AACA;EAGA,wCAAA;EACA,oBAAA;EACA,2EAAA;EACA,yBAAA;AACA;AACA;EACA;IACA,yCAAA;IACA,6BAAA;EACE;EACF;IACA,yCAAA;IACA,8BAAA;EACE;EACF;IACA,wCAAA;IACA,+BAAA;EACE;AACF","sourcesContent":[".cls1 { /*!*/ }\n.cls2 {\ncomposes: cls1;\n\nstroke-dasharray: 2.42777px, 242.77666px;\nstroke-dashoffset: 0;\nanimation: anim 1.6s linear infinite;\nstroke: var(--color-base);\n\n@keyframes anim {\n12.5% {\nstroke-dasharray: 33.98873px, 242.77666px;\nstroke-dashoffset: -0.70543px;\n}\n43.75% {\nstroke-dasharray: 84.97183px, 242.77666px;\nstroke-dashoffset: -84.97183px;\n}\n100% {\nstroke-dasharray: 2.42777px, 242.77666px;\nstroke-dashoffset: -240.34889px;\n}\n}\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"cls1": "SpinnerInfinite-Outline-module--cls1--wNSw9",
	"cls2": "SpinnerInfinite-Outline-module--cls2--mdlNf SpinnerInfinite-Outline-module--cls1--wNSw9",
	"anim": "SpinnerInfinite-Outline-module--anim--TiBrE"
};
export default ___CSS_LOADER_EXPORT___;
