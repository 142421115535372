import React from 'react'
import styled from 'astroturf/react'
import { Row, Col } from 'react-grid-system'

import { useLayoutContext } from '@/views/components/core/LayoutProvider'
import { SharingAvatar } from '@/views/components/common'
import { Typography, Button } from '@/components/ui'
import { Modal } from '@/components/wrapper'
import { Plus } from '@/components/ui/icons'

import { GalleryCard } from './components'

import avatarPreview from '@/assets/demo/avatar-gallery.png'
import avatarSharing from '@/assets/demo/avatar-sharing.png'

export const List = () => {
  const { setIsOpenModal } = useLayoutContext()

  return (
    <Core>
      <Head>
        <Typography as='h1' variant='h2'>
          Your avatars
        </Typography>
        <Action>Create new avatar</Action>
      </Head>
      <Row>
        <ColCard sm={4}>
          <AddAvatar>
            <Plus />
          </AddAvatar>
        </ColCard>
        <ColCard sm={4}>
          <GalleryCard handleClick={() => setIsOpenModal(true)} img={avatarPreview} />
        </ColCard>
        <ColCard sm={4}>
          <GalleryCard handleClick={() => setIsOpenModal(true)} img={avatarPreview} />
        </ColCard>
        <ColCard sm={4}>
          <GalleryCard handleClick={() => setIsOpenModal(true)} img={avatarPreview} />
        </ColCard>
        <ColCard sm={4}>
          <GalleryCard handleClick={() => setIsOpenModal(true)} img={avatarPreview} />
        </ColCard>
        <ColCard sm={4}>
          <GalleryCard handleClick={() => setIsOpenModal(true)} img={avatarPreview} />
        </ColCard>
      </Row>

      <Modal>
        <SharingAvatar img={avatarSharing} link={'metaperson.com/axfsdfsdfsdk.html'} />
      </Modal>
    </Core>
  )
}

const Core = styled.div``

const Head = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 28px;
`

const ColCard = styled(Col)`
  margin-top: 24px;
`

const Action = styled(Button)``

const AddAvatar = styled.button`
  @import '../../../../../assets/styles/mixins.scss';
  @include anim(background-color);
  width: 100%;
  height: 100%;
  border-radius: var(--size-radius);
  border: 1px solid var(--color-bg-filed);
  fill: var(--color-base);

  &:hover {
    background-color: var(--color-bg-filed);
  }
`
