// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".GeneratorPageLayout-LoadingMSG-module--cls1--ytZvQ { /*!*/ }\n\n.GeneratorPageLayout-LoadingMSG-module--cls2--aTBnf {\n  margin-top: 4px;\n  text-align: center;\n  max-width: 240px;\n}", "",{"version":3,"sources":["webpack://./src/views/components/layouts/GeneratorPageLayout-LoadingMSG.module.scss"],"names":[],"mappings":"AAAA,sDAAA,IAAA,EAAA;;AACA;EAGA,eAAA;EACA,kBAAA;EACA,gBAAA;AACA","sourcesContent":[".cls1 { /*!*/ }\n.cls2 {\ncomposes: cls1;\n\nmargin-top: 4px;\ntext-align: center;\nmax-width: 240px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"cls1": "GeneratorPageLayout-LoadingMSG-module--cls1--ytZvQ",
	"cls2": "GeneratorPageLayout-LoadingMSG-module--cls2--aTBnf GeneratorPageLayout-LoadingMSG-module--cls1--ytZvQ"
};
export default ___CSS_LOADER_EXPORT___;
