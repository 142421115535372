// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".index-ZoomBox-module--cls1--YIUmP { /*!*/ }\n\n.index-ZoomBox-module--cls2--PWOES {\n  width: 48px;\n  background-color: var(--color-bg-box);\n  margin-right: 20px;\n  border-radius: var(--size-radius-large);\n  padding-top: 12px;\n  padding-bottom: 12px;\n}", "",{"version":3,"sources":["webpack://./src/views/page/generator/sections/Editor/index-ZoomBox.module.scss"],"names":[],"mappings":"AAAA,qCAAA,IAAA,EAAA;;AACA;EAGA,WAAA;EACA,qCAAA;EACA,kBAAA;EACA,uCAAA;EACA,iBAAA;EACA,oBAAA;AACA","sourcesContent":[".cls1 { /*!*/ }\n.cls2 {\ncomposes: cls1;\n\nwidth: 48px;\nbackground-color: var(--color-bg-box);\nmargin-right: 20px;\nborder-radius: var(--size-radius-large);\npadding-top: 12px;\npadding-bottom: 12px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"cls1": "index-ZoomBox-module--cls1--YIUmP",
	"cls2": "index-ZoomBox-module--cls2--PWOES index-ZoomBox-module--cls1--YIUmP"
};
export default ___CSS_LOADER_EXPORT___;
