// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".PhotoHandler-Icon-module--cls1--sZ77K { /*!*/ }\n\n.PhotoHandler-Icon-module--cls2--wdlkp {\n  position: absolute;\n  top: 50%;\n  left: 50%;\n  margin-top: -24px;\n  margin-left: -24px;\n}", "",{"version":3,"sources":["webpack://./src/views/page/generator/sections/components/PhotoHandler-Icon.module.scss"],"names":[],"mappings":"AAAA,yCAAA,IAAA,EAAA;;AACA;EAGA,kBAAA;EACA,QAAA;EACA,SAAA;EACA,iBAAA;EACA,kBAAA;AACA","sourcesContent":[".cls1 { /*!*/ }\n.cls2 {\ncomposes: cls1;\n\nposition: absolute;\ntop: 50%;\nleft: 50%;\nmargin-top: -24px;\nmargin-left: -24px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"cls1": "PhotoHandler-Icon-module--cls1--sZ77K",
	"cls2": "PhotoHandler-Icon-module--cls2--wdlkp PhotoHandler-Icon-module--cls1--sZ77K"
};
export default ___CSS_LOADER_EXPORT___;
