import { BlobReader, ZipReader, BlobWriter, Entry } from '@zip.js/zip.js'
import { loadAsync } from './jszip-3.7.1.min.js'

export async function proceedZip(zip) {
  //  console.log('proceedZip():', zip)

  const res = await loadAsync(zip).then((z) => {
    let promises = []

    for (const item of Object.values(z.files)) {
      if (item.dir) continue

      let filename = item.name.split('/')
      filename = filename[filename.length - 1]
      let p = item.async('blob').then((blob) => {
        let url = {}
        url[filename] = URL.createObjectURL(blob)
        return url
      })

      promises.push(p)
    }

    return Promise.all(promises).then((blobs) => {
      return blobs.reduce((a, i) => {
        Object.assign(a, i)
        return a
      }, {})
    })
  })

  return res
}

export function modelHeight(subtype) {
  return subtype === 'female' ? 172 : 178
}
