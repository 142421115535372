// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".SharingAvatar-CopyText-module--cls1--qXob6 { /*!*/ }\n\n.SharingAvatar-CopyText-module--cls2--k1OQ1 {\n  opacity: 0.5;\n  text-overflow: ellipsis;\n  overflow: hidden;\n  white-space: nowrap;\n  width: calc(100% - 60px);\n}", "",{"version":3,"sources":["webpack://./src/views/components/common/SharingAvatar-CopyText.module.scss"],"names":[],"mappings":"AAAA,8CAAA,IAAA,EAAA;;AACA;EAGA,YAAA;EACA,uBAAA;EACA,gBAAA;EACA,mBAAA;EACA,wBAAA;AACA","sourcesContent":[".cls1 { /*!*/ }\n.cls2 {\ncomposes: cls1;\n\nopacity: 0.5;\ntext-overflow: ellipsis;\noverflow: hidden;\nwhite-space: nowrap;\nwidth: calc(100% - 60px);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"cls1": "SharingAvatar-CopyText-module--cls1--qXob6",
	"cls2": "SharingAvatar-CopyText-module--cls2--k1OQ1 SharingAvatar-CopyText-module--cls1--qXob6"
};
export default ___CSS_LOADER_EXPORT___;
