import * as THREE from 'three'

function calcTargetColor(color) {
  const r = color ? '0x' + color[1] + color[2] : '0'
  const g = color ? '0x' + color[3] + color[4] : '0'
  const b = color ? '0x' + color[5] + color[6] : '0'

  return { r: r / 255, g: g / 255, b: b / 255 }
}

export function prepareHaircutedHeadTexture(haircutGenerated, haircutColor, onLoad) {
  //  console.log('prepareHaircutedHeadTexture()')
  //  console.log('  haircutGenerated:', haircutGenerated)
  //  console.log('  haircutColor:', haircutColor)

  const tintCoeff = 0.8
  const threshold = 0.2

  const avgColor = {
    r: haircutGenerated.hair_color.red / 255,
    g: haircutGenerated.hair_color.green / 255,
    b: haircutGenerated.hair_color.blue / 255,
  }

  const targetColor = calcTargetColor(haircutColor)
  const useRecoloring = haircutColor === null ? false : true

  const maxTargetChannel = Math.max(targetColor.r, targetColor.g, targetColor.b)
  const darkeningCoeff = maxTargetChannel < threshold ? Math.min(0.85, (threshold - maxTargetChannel) / threshold) : 0

  //  console.log('avgColor:', avgColor)
  //  console.log('targetColor:', targetColor)

  //  console.log('maxTargetChannel:', maxTargetChannel)
  //  console.log('darkeningCoeff:', darkeningCoeff)

  const material = new THREE.ShaderMaterial({
    uniforms: {
      uHead: { value: haircutGenerated.headTexture },
      uHaircut: { value: haircutGenerated.haircutTexture },

      uUseRecoloring: { value: useRecoloring },
      uColorTint: {
        value: new THREE.Vector3(targetColor.r - avgColor.r, targetColor.g - avgColor.g, targetColor.b - avgColor.b),
      },
      uTintCoeff: { value: tintCoeff },
      uDarkeningCoeff: { value: darkeningCoeff },
    },
    vertexShader: `
      precision mediump float;
      varying vec2 vUv;
      void main() {
        vUv = uv;
        gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.0);
      }
    `,
    fragmentShader: `
      precision mediump float;

      uniform sampler2D uHead;
      uniform sampler2D uHaircut;

      uniform bool  uUseRecoloring;
      uniform vec3  uColorTint;
      uniform float uTintCoeff;
      uniform float uDarkeningCoeff;

      varying vec2 vUv;

      void main() {
        vec4 head    = texture2D(uHead,    vUv);
        vec4 haircut = texture2D(uHaircut, vUv);

        vec3 color3 = uUseRecoloring ?
          mix(haircut.rgb + uTintCoeff*uColorTint, haircut.rgb, uDarkeningCoeff) :
          haircut.rgb;

//        gl_FragColor = head;
//        gl_FragColor = haircut;

        gl_FragColor = vec4( mix(head.rgb, color3, haircut.a), 1.);
      }
    `,
  })

  const geometry = new THREE.PlaneGeometry(2, 2)

  const bufferScene = new THREE.Scene()
  const mesh = new THREE.Mesh(geometry, material)
  bufferScene.add(mesh)

  const camera = new THREE.OrthographicCamera(-1, 1, 1, -1, -1, 1)

  const renderer = new THREE.WebGLRenderer()
  renderer.setSize(haircutGenerated.headTexture.source.data.width, haircutGenerated.headTexture.source.data.height)

  //  console.log('prepareHaircutedHeadTexture() start render...')

  renderer.render(bufferScene, camera)

  //  console.log('prepareHaircutedHeadTexture() start load texture...')

  const image = renderer.domElement.toDataURL('image/png')

  material.dispose()
  geometry.dispose()
  renderer.dispose()
  renderer.forceContextLoss()

  const loader = new THREE.TextureLoader()
  const texture = loader.load(image, onLoad)

  return texture
}

export async function prepareBodyVisibilityMask(maskTop, maskBottom, maskShoes) {
  //console.log("maskTop:",    maskTop)
  //console.log("maskBottom:", maskBottom)
  //console.log("maskShoes:",  maskShoes)

  if (!maskTop && !maskBottom && !maskShoes) return null

  const material = new THREE.ShaderMaterial({
    uniforms: {
      uMaskTop: { value: maskTop },
      uMaskBottom: { value: maskBottom },
      uMaskShoes: { value: maskShoes },
      uThreshold: { value: (64 + 255 * 2) / 256 },
    },
    vertexShader: `
      precision mediump float;
      varying vec2 vUv;
      void main() {
        vUv = uv;
        gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.0);
      }
    `,
    fragmentShader: `
      precision mediump float;

      uniform sampler2D uMaskTop;
      uniform sampler2D uMaskBottom;
      uniform sampler2D uMaskShoes;
      uniform float     uThreshold;

      varying vec2 vUv;

      void main() {
        vec4 values = vec4( texture2D(uMaskTop,vUv).r, texture2D(uMaskBottom, vUv).r, texture2D(uMaskShoes, vUv).r, 0.);
        float sum = dot(values, vec4(1., 1., 1., 0.));
        gl_FragColor = vec4( vec3(step(uThreshold,sum)), 1.);
      }
    `,
  })

  const camera = new THREE.OrthographicCamera(-1, 1, 1, -1, -1, 1)
  const geometry = new THREE.PlaneGeometry(2, 2)
  const bufferScene = new THREE.Scene()
  const planeObject = new THREE.Mesh(geometry, material)
  bufferScene.add(planeObject)

  // renderer.setSize(maskTop.source.data.width, maskTop.source.data.height)
  // let renderTarget = new THREE.WebGLRenderTarget(maskTop.source.data.width, maskTop.source.data.height)
  // renderer.render(bufferScene, camera, renderTarget)
  // res = new THREE.Texture(renderTarget.texture.image)
  // console.log("res:",    res)

  const renderer = new THREE.WebGLRenderer()
  if (maskTop) renderer.setSize(maskTop.source.data.width, maskTop.source.data.height)
  else renderer.setSize(2048, 2048) // FIX IT - no visibility masks

  renderer.render(bufferScene, camera)

  const textureLoader = new THREE.TextureLoader()
  const res = await textureLoader.loadAsync(renderer.domElement.toDataURL('image/png'))
  //  const res = textureLoader.load( renderer.domElement.toDataURL("image/png") )

  material.dispose()
  geometry.dispose()
  renderer.dispose()
  renderer.forceContextLoss()

  return res
}
