// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Confirmation-Form-module--cls1--Z3Pql { /*!*/ }\n\n.Confirmation-Form-module--cls2--o50b4 {\n  margin-top: 32px;\n  margin-left: auto;\n  margin-right: auto;\n}", "",{"version":3,"sources":["webpack://./src/views/page/auth/Confirmation-Form.module.scss"],"names":[],"mappings":"AAAA,yCAAA,IAAA,EAAA;;AACA;EAGA,gBAAA;EACA,iBAAA;EACA,kBAAA;AACA","sourcesContent":[".cls1 { /*!*/ }\n.cls2 {\ncomposes: cls1;\n\nmargin-top: 32px;\nmargin-left: auto;\nmargin-right: auto;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"cls1": "Confirmation-Form-module--cls1--Z3Pql",
	"cls2": "Confirmation-Form-module--cls2--o50b4 Confirmation-Form-module--cls1--Z3Pql"
};
export default ___CSS_LOADER_EXPORT___;
