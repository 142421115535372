import React, { FC } from 'react'
import { Outlet } from 'react-router-dom'
import styled from 'astroturf/react'

import { Container } from '@/components/ui'
import { useLayoutContext } from '@/views/components/core/LayoutProvider'
import { Sidebar } from './components'

import bg from '@/assets/img/bg.png'

export const PrivatePageLayout: FC = () => {
  const { displayBG, mediaQueries } = useLayoutContext()

  return (
    <>
      {displayBG && mediaQueries.isTablet && <Bg src={bg} alt='Background' tabIndex={0} />}
      {mediaQueries.isTablet ? <Sidebar /> : <></>}
      <PrivatePageContainer>
        <Outlet />
      </PrivatePageContainer>
    </>
  )
}

const Bg = styled.img`
  position: fixed;
  top: 0;
  right: 0;
  height: var(--app-height);
  width: 100%;
  object-fit: cover;
  z-index: -1;
`

const PrivatePageContainer = styled(Container)`
  padding-top: var(--content-base-offset);
  position: relative;
`
