import React, { FC } from 'react'
import { observer } from 'mobx-react-lite'
import { HexColorPicker, HexColorInput } from 'react-colorful'
import { CirclePicker, CompactPicker, SliderPicker } from 'react-color'

import { ColorPicker, WrapBox, Box } from '@/views/page/generator/sections/components'
import { storeEditor } from '@/services/store'

export const EditorHairColor: FC = observer(() => {
  const versionUI = storeEditor.versionUI  // to rerender after observable versionUI increment

  const { haircut, setHaircutColor } = storeEditor

  const customWidth = '190px' // custom width value
  const hexColorPickerStyle = {
    width: '150px',
    height: '150px',
  }

  /*
        <CirclePicker
          width={customWidth}
          colors={[
            haircut.color
          ]}
          color={haircut.color}
          onChangeComplete={(color) => setHaircut(color?.hex)}
        />
        <SliderPicker
          width={customWidth}
          color={haircut.color}
          onChangeComplete={(color) => setHaircut(color?.hex)}
        />
*/

  /*
        <HexColorPicker
          color={haircut.color ? haircut.color : '#ffffff'}
          onChange={(color) => setHaircutColor(color)}
          style={hexColorPickerStyle}
        />

*/

  /*
        <CirclePicker
          width={customWidth}
          colors={[
            haircut.colorInitial
          ]}
          color={haircut.color ? haircut.color : '#ffffff'}
          onChangeComplete={(color) => setHaircutColor(color.hex)}
        />
*/

  return (
    <WrapBox>
      <Box title={'Haircut color'} customWidth={customWidth}>
        <SliderPicker
          width={customWidth}
          color={haircut.color ? haircut.color : '#ffffff'}
          onChangeComplete={(color) => setHaircutColor(color.hex)}
        />
      </Box>
    </WrapBox>
  )
})

EditorHairColor.displayName = 'EditorHairColor'
