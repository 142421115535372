import React, { FC, useCallback, useEffect, useMemo, useState, useRef } from 'react'
import ReactDOM from 'react-dom'
import { Helmet } from 'react-helmet'
import { observer } from 'mobx-react-lite'
import styled from 'astroturf/react'
import { useRQ, getSetting, createPlayerID, request, getQRFile, setDescription, pollingCreatingAvatar, exportsFile } from '@/services/adapters/req'
import { Typography, Loader, Button, Logo } from '@/components/ui'
import { LogoPic, Profile, Home, ArrowLeft, Reset } from '@/components/ui/icons'
import { Modal } from '@/components/wrapper'
import { Container } from '@/components/wrapper'
import { SECTIONS, Setting, Section } from './_helper'
import { storeAuth, storeGeneratorPipeline, storeEditor } from '@/services/store'
import { redirect, useNavigate } from "react-router-dom";
import { useLayoutContext } from '@/views/components/core/LayoutProvider'
import html2canvas from 'html2canvas'
import { getAccessToken, getBusinessAccessToken, setBusinessAccessToken, setBusinessMode, getBusinessMode } from '@/services/adapters/req/config'
import { sampleAvatars } from './sections/NoPhoto'
import { toast } from '@/services/adapters/toast'
import { cameraPositions } from './sections/Editor/cameraPositions'
import { set } from 'ramda'

export interface GeneratorProps {
  clientID: string
  clientSecret: string
}

export const ProgressBar = ({ value, max }) => {
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    setProgress((value / max) * 100);
  }, [value, max]);

  return (
    <ProgressContainer>
      <ProgressDiv
        style={{ width: `${progress}%` }}
      ></ProgressDiv>
    </ProgressContainer>
  );
};

const ConfirmationModalWindow: FC = (props) => {
  //console.info('ConfirmationModalWindow:, props:', props)

  const ref = useRef()

  useEffect(() => {
    ref.current.focus()
  })

  return ReactDOM.createPortal(
    <CoreModal ref={ref} animation={'opening'} tabIndex="0" onKeyDown={() => props.onChoice('cancel')}>
      <Content>
        <Body align="center" >
          <h2 align="center">Non-Commercial Usage Confirmation</h2><br/>
          <h3 align="center">
            This model is intended for trial or non-commercial<br/>
            usage only. By clicking 'Confirm' you agree to our<br/>
            <a href="https://avatarsdk.com/eula/">EULA</a> and <a href="https://avatarsdk.com/privacy-policy/">Privacy Policy</a><br/><br/>
          </h3>
          <Button variant='accent' onClick={() => props.onChoice('confirm') }>Confirm</Button>&nbsp;&nbsp;
          <Button variant='surface' onClick={() => props.onChoice('cancel') }>Cancel</Button>
        </Body>
      </Content>
    </CoreModal>,
    document.getElementById('portal') as HTMLElement
  )
}

export const Generator: FC<GeneratorProps> = observer(({ clientID, clientSecret }) => {
  const versionAvatar = storeEditor.versionAvatar  // to rerender after observable versionAvatar increment

  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);
  const [isLoadingFake, setIsLoadingFake] = useState(true)
  const {setIsOpenModal, setTheme, mediaQueries} = useLayoutContext()
  const [userData, setUserData] = useState('');
  const [showConfirmationWindow, setShowConfirmationWindow] = useState(false);

  const {isAuthorization, isLoading: isLoadingAuth } = storeAuth
  const {
    sectionIndex,
    setAvailableSections,
    setSectionIndex,
    isSendingPreSetting,
    toSection,
    startStatePhoto,
    progress,
    cleanPipeline,
    avatarId,
    getExportsFile,
    exportingAvatar,
    getBusinessToken,
    postAuthenticationStatus,
    sendAvatarGenerationEvent,
    isLoadingAvatar,
  } = storeGeneratorPipeline
  const { cleanViewerState } = storeEditor
  const {
    isLoading: isLoadingSetting,
    data: {
      availableSections,
      texts: { sections, general },
    },
  } = useRQ<Setting>(getSetting)

  var filteredSections = availableSections
  const { isLoading, data } = useRQ(createPlayerID, { comment: 'curl sample' })
  const [showAdditionalsButton, setShowAdditionalsButton] = useState(false);
  const [externalAuthenticateData, setExternalAuthenticateData] = useState({})
  const [externalExportData, setExternalExportData] = useState({})
  const [UIData, setUIData] = useState({})
  const [AvatarData, setAvatarData] = useState({})
  const [GenerateData, setGenerateData] = useState({})

  
  const [exportButtonText, setExportButtonText] = useState<string>(general.save)
  const [showExportButton, setShowExportButton] = useState(true)
  const [showScreenshotButton, setShowScreenshotButton] = useState(true)
  const [showLoginButton, setShowLoginButton] = useState(true)
  const [genderSelected, setGenderSelected] = useState(false)
  const [ageSelected, setAgeSelected] = useState(false)

  const [shouldExport, setShouldExport] = useState(false)
  storeGeneratorPipeline.setSkipSampleAvatars(UIData?.isNoPhotoVisible == false ?? false)

  const makeScreenshot = (data) => {
    // console.log("makeScreenshot(), data:", data)

    const camPos = cameraPositions("HEAD", false)

    const dataUrl = storeEditor.viewer.makeScreenshot(data.width, data.height, {
      fov: 27,
      position: { x: 0, y: camPos.y, z: camPos.z},
      lookAt:   { x: 0, y: camPos.a, z: 0 }
    })

    window.parent.postMessage({
      source: 'metaperson_creator',
      eventName: 'model_screenshot',
      screenshotUrl: dataUrl,
    }, '*')
  }

  const getExternalData = useCallback((e) => {
    const { data } = e;
    const { eventName } = data;

    switch (eventName) {
        case 'authenticate':
            setExternalAuthenticateData(data);
            break;
        case 'set_export_parameters':
            setExternalExportData(data);
            break;
        case 'set_ui_parameters':
            setUIData(data);
            break;
        case 'export_avatar':
            setShouldExport(true);
            break;
        case 'make_screenshot':
            makeScreenshot(data);
            break;
        case 'show_avatar':
            setAvatarData(data);
            break;
        case 'generate_avatar':
            setGenerateData(data);
            break;
        default:
            break;
    }
}, []);

  useEffect(() => {
    window.addEventListener('message', getExternalData)
    return () => window.removeEventListener('message', getExternalData)
  }, [])


  if (!ageSelected && genderSelected) {
    filteredSections = availableSections.filter(section => section !== 'gender')
  }
  else 
  {
    filteredSections = availableSections
  }
  setAvailableSections(filteredSections)

  // console.log(filteredSections)
  const navigateTo = () => {
    // console.log("navigateTo")
    storeGeneratorPipeline.isLoadingAvatar = false
    if (ageSelected && genderSelected) {
      setSectionIndex(2)
    }
    else if (ageSelected) {
      setSectionIndex(1)
    }
    else {
      setSectionIndex(0)
    }
    navigate('/generator')
  }

  useEffect(() => {
        window.parent.postMessage(
        {
          eventName: 'mobile_loaded',
          source: 'metaperson_creator'
        }, '*')
        window.parent.postMessage(
          {
            eventName: 'metaperson_creator_loaded',
            source: 'metaperson_creator'
          }, '*')
        sendAvatarGenerationEvent(true)
      }, []);

  useEffect(() => {
    sendAvatarGenerationEvent(!isSendingPreSetting)
  }, [isSendingPreSetting])
      
  useEffect(() => {
    window.parent.postMessage(
      {
        isAvailable: storeEditor.isReady(),
        eventName: "action_availability_changed",
        actionName: "avatar_export",
        source: 'metaperson_creator',
      },
      '*',
    )
    window.parent.postMessage(
      {
        isAvailable: storeEditor.isReady(),
        eventName: "action_availability_changed",
        actionName: "avatar_screenshot",
        source: 'metaperson_creator',
      },
      '*',
    )
  }, [storeEditor.isCamReady, storeEditor.isModelReady, storeEditor.isHaircutReady, storeEditor.isGlassesReady, storeEditor.isOutfitReady])

  useEffect(() => {
    // console.log("clientID:", externalAuthenticateData.clientId)

    if (externalAuthenticateData.clientId !== undefined && externalAuthenticateData.clientSecret !== undefined && externalAuthenticateData.clientId !=='estemXiDUiJOpAPsxI8k5qBVdTiWayAz6f2RYNgc')
    {
      // console.log("externalAuthenticateData.clientId:", externalAuthenticateData.clientId)
      getBusinessToken(externalAuthenticateData.clientId, externalAuthenticateData.clientSecret)
    }
    else 
    {
      setBusinessAccessToken('')
      setBusinessMode('')
      if (externalAuthenticateData.clientId !== undefined && externalAuthenticateData.clientSecret !== undefined)
      {
        postAuthenticationStatus(false, 'Incorrect clientID or clientSecret')
      }
    }
  }, [externalAuthenticateData])

  function convertAgeValue(age) {
    switch (age) {
        case 'adult':
            return 'Adult';
        case 'teen15':
            return 'Teen_15';
        case 'teen12':
            return 'Teen_12';
        default:
            console.warn('Unexpected age value:', age);
            return 'Unknown'; // or handle as needed
    }
  }

  useEffect(() => {
    setExportButtonText(UIData?.exportButtonText ?? exportButtonText);
    setShowExportButton(UIData?.isExportButtonVisible ?? showExportButton);
    setShowScreenshotButton(UIData?.isScreenshotButtonVisible ?? showScreenshotButton);
    setShowLoginButton(UIData?.isLoginButtonVisible ?? showLoginButton);
    storeEditor.setBlacklist(UIData?.outfitsBlackList ?? [])
    storeEditor.setUIControls(UIData?.skipViewerControls ?? [])
    if (UIData?.theme !== undefined)
    {
      document.setIsLightTheme(UIData?.theme === 'light')
      setTheme(UIData?.theme)
    }
    if (UIData?.gender !== undefined)
    {
      storeGeneratorPipeline.preSettings.gender = UIData?.gender == 'male' ? 'male' : 'female'
      setGenderSelected(true)
    }
    if (UIData?.age !== undefined)
    {
      const ageValue = UIData?.age
      const convertedAgeValue = convertAgeValue(ageValue)
      if (convertedAgeValue !== 'Unknown') {
          storeGeneratorPipeline.preSettings.age = convertedAgeValue
          storeGeneratorPipeline.sectionIndex = 1
          setAgeSelected(true)
      } 
    }
    if (UIData?.gender !== undefined && UIData?.age !== undefined)
    {
      storeGeneratorPipeline.sectionIndex = 2
    }
  }, [UIData]);
  
  const { submitPreSettingsForSampleAvatar, submitPreSettings, setPreSettings } = storeGeneratorPipeline
  const getDescriptionData = async (avatarID: string) => {
    try {
      const data = await request(pollingCreatingAvatar.endpoint({ avatarID: avatarID }), pollingCreatingAvatar);
      return data;
    } catch (error) {
      return null; // Return null in case of an error
    }
  };
  
  // Deserialize function to convert JSON to object
  function deserializeAvatarState(jsonString) {
    try {
      return JSON.parse(jsonString);
    } catch (error) {
      // console.error("Error occurred during deserialization:", error);
      return null;
    }
  }

  async function getAvatar(AvatarData: {}) {
    //console.log("getAvatar()")

    let authAttempts = parseInt(localStorage.getItem('AuthAttempts')) || 0
    const incrementCounter = () => {
        authAttempts++
        localStorage.setItem('AuthAttempts', authAttempts.toString())
        return authAttempts
    };

    const avatarID:string = AvatarData?.avatarCode
    const isAvatarCodeInAny = Object.values(sampleAvatars).flat().some(
      (avatar) => avatar.avatar_code === avatarID
    )
    if (isAvatarCodeInAny) //if it is a sample avatar just show it
    {
      const avatarInAny = Object.values(sampleAvatars)
        .flat()
        .find((avatar) => avatar.avatar_code === avatarID)
      submitPreSettingsForSampleAvatar(avatarInAny)
      setIsLoadingFake(false)
    } 
  
    else {
      //try to get avatar's description
      const descriptionData = await getDescriptionData(avatarID)
      const description = descriptionData?.description || '';
      const gender = descriptionData?.pipeline_subtype
      const pipeline = descriptionData?.pipeline
      if (description === null || pipeline !== "metaperson_2.0") {
        toast('Error in avatar loading', 'Incorrect avatar code', 'error')
      }
      else
      {
        //console.log("desc: ", description)
        //try to set avatar's description
      
        const body = {
          description: description,
        }
        const data = await request(setDescription.endpoint({ avatarID }), setDescription, body)
        const descriptionData = await getDescriptionData(avatarID)
        const updatedDescription = descriptionData?.description || '';
        // console.log("updatedDesc: ", updatedDescription)
        if (description !== updatedDescription) //can't update
        {
          if (userData) //user logged in
          {
            toast('Error in avatar loading', 'Can\'t load the avatar for the logged-in user', 'error')
          }
          else 
          {
            incrementCounter()
            if (authAttempts === 1) {
                // Navigate to the authentication page only if it's the first attempt
                navigate('/auth')
            }
            else 
            {
              toast('Error in avatar loading', 'Please log in to load this avatar', 'error')
            }
          }
          return
        }
        else 
        {
          //todo: implement
        }

        //console.log("description:", description)
        const avatarState = deserializeAvatarState(description)
        if (avatarState !== null)
        {
          // console.log(avatarState)
          if (avatarState.hasOwnProperty('version'))
          {
            toast('Error in avatar loading', 'Can\'t load this avatar', 'error')
            return
          }
          storeGeneratorPipeline.avatarState = avatarState
        }

        try {
          const data = await request(exportsFile.endpoint({ avatarID }), exportsFile)
          // console.log('exports:', data)
          storeGeneratorPipeline.preSettings.gender = gender
          

          // try {
          //   // Deserialize the JSON back to the avatarState structure
          //   const deserializedAvatarState = JSON.parse(description);
          //   console.log("Deserialized avatarState:", deserializedAvatarState);
          // } catch (error) {
          //     console.error("Error parsing JSON:", error);
          // }
          setIsLoadingFake(false)
          submitPreSettingsForSampleAvatar(
            {
              gender: gender,
              preview: 'sample_avatar_7_preview.png',
              avatar_code: avatarID,
              export_code: data[data.length-1].code,
              texture_code: null,
              avatarState: avatarState,
            })
        }
        catch (error) {
          toast('Error in avatar loading', 'Incorrect avatar code', 'error')
        }
      }
  
    }
  }
  
  useEffect(() => {
    setIsLoadingFake(true)
    if (AvatarData?.avatarCode !== undefined)
    {
        const intervalId = setInterval(() => {
          const token = getAccessToken();
          // console.log("token: ", token)
          if (token !== '') {
            getAvatar(AvatarData)
            clearInterval(intervalId); 
          }
      }, 500);
    }
  }, [AvatarData]);

  function dataURLtoFile(dataurl, filename) {
    var arr = dataurl.split(','),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[arr.length - 1]), 
        n = bstr.length, 
        u8arr = new Uint8Array(n);
    while(n--){
        u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, {type:mime});
}

  useEffect(() => {
    if (GenerateData?.image !== undefined)
    {
      const genderFromRequest = GenerateData?.gender !== undefined ? GenerateData?.gender : "female"
      const gender = genderFromRequest === "male" ? "male" : "female";

      const ageValue = GenerateData?.age
      const convertedAgeValue = convertAgeValue(ageValue)
      if (convertedAgeValue !== 'Unknown') {
          storeGeneratorPipeline.preSettings.age = convertedAgeValue
      } 

      const intervalId = setInterval(() => {
        const token = getAccessToken();
        if (token !== '') {
          storeGeneratorPipeline.preSettings.gender = gender
          if (convertedAgeValue !== 'Unknown') {
            storeGeneratorPipeline.preSettings.age = convertedAgeValue
          }  
          storeGeneratorPipeline.isLoadingAvatar = false
          setPreSettings('photo', dataURLtoFile(GenerateData?.image, "image.png"))
          submitPreSettings()
          clearInterval(intervalId); 
        }
      }, 500);
    }
  }, [GenerateData]);

  
  useEffect(() => {
    if (shouldExport === true) {
      handleFBXExportClick()
      setShouldExport(false)
    }
  }, [shouldExport]);
  
  useEffect(() => {
    if (clientID && clientSecret) {
      storeAuth.send({
        client_id: clientID,
        client_secret: clientSecret,
      })
    }
    setTimeout(() => {
      setIsLoadingFake(false)
    }, 0)
  }, [clientID, clientSecret])

  const {
    component: Component,
    settings,
    componentID,
  } = useMemo(() => {
    if (isLoadingSetting) {
      return {}
    }
    return SECTIONS[filteredSections[sectionIndex]]
  }, [filteredSections, isLoadingSetting, sectionIndex]) as Section

  const prevPosition = useCallback(() => {
    if (filteredSections[sectionIndex] === 'photo' && startStatePhoto) {
      startStatePhoto()
      return
    }
    setSectionIndex(sectionIndex - 1)
  }, [filteredSections, sectionIndex, startStatePhoto])

  useEffect(() => {
    const storedData = localStorage.getItem("VerifiedUserObj");
    if (storedData) {
      setUserData(storedData);
    }
  }, []);

  const handleClick = () => {
    setIsOpen(!isOpen)
  };
  const closeIt = () => {
    setIsOpen(false)
  }
 const redirectToProfile = () => {
// console.log("redirectToProfile()")
  navigate('/auth/profile')
 }
  const cleanState = useCallback(() => {
// console.log("cleanState()")
    navigate('/auth')
    storeGeneratorPipeline.isLoadingAvatar = false
    setSectionIndex(0)
    cleanPipeline()
    cleanViewerState()
  }, [])

  const handleExportClick = async () => {
// console.log("handleExportClick()")
// console.log("userData:", userData)

    if (!userData && getBusinessMode() !== "active") {
      // console.log("before navigate('/auth')")
      navigate('/auth')
      // console.log("after navigate('/auth')")
    }

    if (!showScreenshotButton) {
      handleFBXExportClick()
      return
    }
    setShowAdditionalsButton(!showAdditionalsButton);
  }

  const handleLogoPicClick = () => {
    window.open("https://avatarsdk.com/", '_blank')
  }

  const handleIntegrateClick = () => {
    window.open("https://avatarsdk.com/metaperson-creator", '_blank')
  }

  const handleDocsClick = () => {
    window.open("https://docs.metaperson.avatarsdk.com/", '_blank')
  }

  useEffect(() => {
    if (storeEditor.gotException) {
      storeEditor.gotException = false
      storeGeneratorPipeline.isSendingPreSetting = false

      navigateTo()
      storeEditor.cleanViewerState()
      storeEditor.setViewer(null)
    }
  }, [storeEditor.gotException]);

  const handleFBXExportClick = async () => {
    // console.log("handleFBXExportClick()")
    if (getBusinessMode() !== "active") {
      setShowConfirmationWindow(true)
    }
    else {
      if (avatarId !== undefined) {
        setIsOpenModal(true);
        exportingAvatar(avatarId, setIsOpenModal, externalExportData);
      }
      setShowAdditionalsButton(false)
      sendAvatarGenerationEvent(false)
    }
  }

  const handlePNGExportClick = async () => {
    const canvas = await html2canvas(document.getElementById('AvatarViewerCanvas'));
    const ctx = canvas.getContext('2d');
    ctx.webkitImageSmoothingEnabled = false;
    ctx.mozImageSmoothingEnabled = false;
    ctx.imageSmoothingEnabled = false;
    // Load the logo image
    const logo = new Image();
    logo.src = "logo.png";
      
    await new Promise((resolve) => {
      logo.onload = resolve;
    });
    ctx.setTransform(1, 0, 0, 1, 0, 0);
    ctx.drawImage(logo, 0, 0);
    ctx.fillStyle = '#101E74'
    ctx.textBaseline = 'top'
    ctx.font = 'bold 31px serif'
    ctx.fillText('https://metaperson.avatarsdk.com', 15, logo.height)

    const dataUrl = canvas.toDataURL('image/png');
    
    if (!navigator.canShare) {
      const link = document.createElement('a');
      link.setAttribute('download', 'My_MetaPerson_Avatar.png');
      link.setAttribute('href', dataUrl);
      link.click();
      setShowAdditionalsButton(false);
      return;
    }
  
    const blob = await (await fetch(dataUrl)).blob();
  
    const shareData = {
      title: 'My MetaPerson Avatar',
      text: '',
      files: [
        new File([blob], 'My_MetaPerson_Avatar.png', {
          type: blob.type,
          lastModified: new Date().getTime(),
        }),
      ],
    };
  
    navigator
      .share(shareData)
      .then(() => {
      })
      .catch((e) => {

      })
  
    setShowAdditionalsButton(false);    
  };
  

  const variant = mediaQueries.isTablet ? 'not-mobile' : 'mobile'
  const allowedFormats = ["fbx", "glb", "gltf"]

  if (isLoadingFake || isLoadingSetting || (isLoadingAuth && isAuthorization)) {
    return (
      <Start>
        <Helmet>
          <title>Avatar Creator</title>
        </Helmet>
        <Logo width={80} height={64} />
        <NameCompany variant='h3'>{general.company_name}</NameCompany>
        <Loader spinner='circle' variant='overlay' />
        <div className="profile-icon">
    {/* <img src="https://example.com/profile-picture.png" alt="Profile Picture" /> */}
  </div>
      </Start>
    )
  }

  if (isSendingPreSetting && !storeEditor.gotException) {
    return (
      <Container variant='center'>
        <Loader spinner='infinite' variant='content' />
        <LoadingMSG>{storeGeneratorPipeline.isLoadingAvatar ? general.waiting_for_loading : general.waiting_for_generation}</LoadingMSG>
        <Progress>
          {storeGeneratorPipeline.isLoadingAvatar ? general.progress3 : `${general.progress}${progress}${general.progress2}`}
        </Progress>
      </Container>
    );
  }

  const handleConfirmationModalWindowChoice = (choice) => {
    // console.log(`handleConfirmationModalWindowChoice("${choice}")`)

    setShowConfirmationWindow(false)

    if (choice==="cancel")
      return

    if (avatarId !== undefined) {
      setIsOpenModal(true);
      exportingAvatar(avatarId, setIsOpenModal, externalExportData);
    }
    setShowAdditionalsButton(false)
    sendAvatarGenerationEvent(false)
  }

  return (
    <>
      <Helmet>
        <title>{sections[componentID].page_title}</title>
      </Helmet>
      <Core>
        <Header>
          {settings.withStartNav && (
            <>
            {userData ? (
            <> 
            {(sectionIndex === 0 || (sectionIndex === 1 && ageSelected) || (sectionIndex === 2 && ageSelected && genderSelected)) && showLoginButton ? (
            <Button isOnlyIcon variant='surface' onClick={redirectToProfile}>
            <Profile />
            </Button>) : (
              <Button isOnlyIcon variant='surface' onClick={navigateTo}>
              <Home />
              </Button>
            )}
            </>
            ) : (
            <>
            {(sectionIndex === 0 || (sectionIndex === 1 && ageSelected) || (sectionIndex === 2 && ageSelected && genderSelected)) && showLoginButton ? (
            <Button isOnlyIcon variant='surface' onClick={cleanState}>
            <Profile />
            </Button>) : (
              <Button isOnlyIcon variant='surface' onClick={navigateTo}>
              <Home />
              </Button>
            )}
            </>
            )}
            </>
          )}
          
          <div style={{ display: 'flex', flexDirection: 'row' }}>

          {settings.withSaveAvatar && (<ResetButton
            variant='surface'
            disabled={!storeEditor.isReady()}
            onClick={storeEditor.resetAll}
          >
            <Reset />
          </ResetButton>
          )}
          
          {settings.withSaveAvatar && getBusinessMode() !== "error" && showExportButton &&
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <Button onClick={handleExportClick}>{exportButtonText === undefined ? general.save : exportButtonText}</Button>
              {showAdditionalsButton && (
                <>
                  <AddExportButton onClick={handleFBXExportClick}>{allowedFormats.includes(externalExportData?.format) ? externalExportData?.format.toUpperCase() : 'FBX'}</AddExportButton> 
                  <AddExportButton onClick={handlePNGExportClick}>PNG</AddExportButton> 
                </>
              )}
            </div>
          }       

          {sectionIndex !== 0 && settings.withPrevNav && !(sectionIndex == 1 && ageSelected) && !(sectionIndex == 2 && ageSelected && genderSelected) && !(sectionIndex == 1 && ageSelected && !genderSelected) && (
            <>
            <Button isOnlyIcon variant='surface' onClick={prevPosition}>
              <ArrowLeft />
            </Button>
            </>
          )}
          </div>
        </Header>
        {Component && <Component />}
        { sectionIndex === 0 && (window.self === window.top) && <>
          <LogoPicButton isOnlyIcon variant='surface' onClick={handleLogoPicClick}> <LogoPic/> </LogoPicButton>
          <IntegrateButton variant='surface' wSize='auto' onClick={handleIntegrateClick}> Integrate</IntegrateButton>
          <DocsButton variant='surface' wSize='auto' onClick={handleDocsClick}>Docs</DocsButton>
        </>}
      </Core>
      <Modal>
        <Progress>Exporting the avatar ...</Progress>
        <ProgressBar value={progress} max={100} />
      </Modal>
      {showConfirmationWindow &&  <ConfirmationModalWindow onChoice={handleConfirmationModalWindowChoice}/> }
    </>
  )
})

const ResetButton = styled(Button)`
  width: 48px !important;
  background-color: var(--color-bg-box);
  margin-right: 8px;
  border-radius: var(--size-radius-large);
  fill: var(--color-base);

  svg {
    fill: var(--color-base);
  }
`

const AddExportButton = styled(Button)`
  margin-top: 8px;
  z-index: 289;
`

const Start = styled.div`
  min-height: var(--app-height);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding-top: 32vh;
  padding-bottom: 120px;

  .profile-icon {
    width: 64px;
    height: 64px;
    margin-top: 22px;
    margin-bottom: auto;
  }

  @media (prefers-color-scheme: dark) {
    .profile-icon {
      background-color: black;
    }
  }
`

const NameCompany = styled(Typography)`
  margin-top: 22px;
  margin-bottom: auto;
`

const Progress = styled(Typography)`
  text-align: center;
  margin-bottom: 12px;
`

const ProgressContainer = styled.div`
  width: 100%;
  height: 20px;
  background-color: #f2f2f2;
  border-radius: 10px;
  overflow: hidden;
`
const ProgressDiv = styled.div`
  height: 100%;
  background-image: linear-gradient(102.67deg, #7d1bf5 0%, #e32dd1 99.48%);
  transition: width 0.3s ease-in-out;
`

const LoadingMSG = styled(Typography)`
  margin-top: 4px;
  text-align: center;
  max-width: 240px;
`

const Core = styled.div`
  min-height: var(--app-height);
`
const CoreOne = styled.div`
position: fixed;
top: 0;
right: 0;

`
const Header = styled.div`
  display: flex;
  width: calc(100% - 64px);
  margin-left: auto;
  margin-right: auto;
  padding-top: 32px;
  position: absolute;
  justify-content: space-between;
  top: 0;
  left: 32px;
  z-index: 5;
`
const Footer = styled.div`
  display: flex;
  position: absolute;
  bottom: 40px;
`
const LogoPicButton = styled(Button)`
  position: absolute;
  left: 32px;
  bottom: 40px;
`
const IntegrateButton = styled(Button)`
  position: absolute;
  left: 90px;
  bottom: 40px;
`
const DocsButton = styled(Button)`
  position: absolute;
  right: 32px;
  bottom: 40px;
`

const CoreModal = styled.div`
  position: fixed;
  z-index: 999;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(#000, 0);

  animation-duration: 1s;
  animation-fill-mode: forwards;
  opacity: 0;
  font-size: 10;
  overflow-y: auto;
  text-align: center;

  &::before {
    content: '';
    width: 0;
    height: 100%;
    display: inline-block;
    vertical-align: middle;
  }

  &.animation-opening {
    opacity: 0;
    animation-name: opening;
  }

  &.animation-closing {
    opacity: 1;
    animation-name: closing;
  }

  @keyframes opening {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  @keyframes closing {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
`


const Content = styled.div`
  padding-top: 40px;
  padding-bottom: 40px;

  position: relative;
  text-align: left;
  display: inline-block;
  vertical-align: middle;
`

const Body = styled.div`
  align: center;
  border: 2px solid gray;
  color: var(--color-base);
  background-color: var(--color-bg-dark);
  border-radius: var(--size-radius);
  padding: 20px;
`
