export const haircut_fragment1 = `

//#define COLORSPACE_GAMMA

  precision mediump float;

  uniform sampler2D uAO;
  uniform sampler2D uAlpha;
  uniform sampler2D uColor;
  uniform sampler2D uDepth;
  uniform sampler2D uRoot;
  uniform sampler2D uShade;
  uniform sampler2D uUniqueID;

  uniform vec4  uTargetColor;
  uniform vec4  uRootsColor;
  uniform bool  uRecoloring;

  uniform float uAOImpact;
  uniform float uDepthImpact;
  uniform float uIDsImpact;

  vec4 blendOverlay(vec4 base, vec4 blend) {
    base.r = base.r > 0.5 ? 1. - 2. * (1. - base.r)*(1. - blend.r) : 2. * base.r * blend.r;
    base.g = base.g > 0.5 ? 1. - 2. * (1. - base.g)*(1. - blend.g) : 2. * base.g * blend.g;
    base.b = base.b > 0.5 ? 1. - 2. * (1. - base.b)*(1. - blend.b) : 2. * base.b * blend.b;
    return base;
  }

  vec4 blendSoftLight(vec4 base, vec4 blend) {
    base.rgb = (1. - base.rgb) * base.rgb * sqrt(blend.rgb) + base.rgb * (1. - base.rgb * blend.rgb);
    return base;
  }

  vec4 blendColorDodge(vec4 base, vec4 blend) {
    return base.rgba / (1. - blend.rgba);
  }

#ifdef COLORSPACE_GAMMA
  vec4 gammaToLinear(vec4 c) {
    return pow(c, vec4(2.2));
  }

  vec4 linearToGamma(vec4 c) {
    return pow(c, vec4(1./2.2));
  }
#endif
`
