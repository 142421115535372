// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".SharingAvatar-Copy-module--cls1--tlp5y { /*!*/ }\n\n.SharingAvatar-Copy-module--cls2--txEmN {\n  height: var(--size-field-height);\n  padding-left: var(--size-field-padding);\n  padding-right: var(--size-field-padding);\n  background-color: var(--color-bg-filed);\n  font-weight: 700;\n  font-size: 14px;\n  line-height: 150%;\n  border-radius: 16px;\n  border-width: 0;\n  color: var(--color-base);\n  padding-bottom: 3px;\n  margin-bottom: 12px;\n  text-align: left;\n  margin-bottom: 32px;\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n  margin-top: 12px;\n}", "",{"version":3,"sources":["webpack://./src/views/components/common/SharingAvatar-Copy.module.scss"],"names":[],"mappings":"AAAA,0CAAA,IAAA,EAAA;;AACA;EAGA,gCAAA;EACA,uCAAA;EACA,wCAAA;EACA,uCAAA;EACA,gBAAA;EACA,eAAA;EACA,iBAAA;EACA,mBAAA;EACA,eAAA;EACA,wBAAA;EACA,mBAAA;EACA,mBAAA;EACA,gBAAA;EACA,mBAAA;EACA,aAAA;EACA,mBAAA;EACA,8BAAA;EACA,gBAAA;AACA","sourcesContent":[".cls1 { /*!*/ }\n.cls2 {\ncomposes: cls1;\n\nheight: var(--size-field-height);\npadding-left: var(--size-field-padding);\npadding-right: var(--size-field-padding);\nbackground-color: var(--color-bg-filed);\nfont-weight: 700;\nfont-size: 14px;\nline-height: 150%;\nborder-radius: 16px;\nborder-width: 0;\ncolor: var(--color-base);\npadding-bottom: 3px;\nmargin-bottom: 12px;\ntext-align: left;\nmargin-bottom: 32px;\ndisplay: flex;\nalign-items: center;\njustify-content: space-between;\nmargin-top: 12px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"cls1": "SharingAvatar-Copy-module--cls1--tlp5y",
	"cls2": "SharingAvatar-Copy-module--cls2--txEmN SharingAvatar-Copy-module--cls1--tlp5y"
};
export default ___CSS_LOADER_EXPORT___;
