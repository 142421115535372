// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".TitleSection-Descr-module--cls1--g4pOz { /*!*/ }\n\n.TitleSection-Descr-module--cls2--LtGt5 {\n  opacity: 0.5;\n  max-width: 440px;\n  margin-top: 8px;\n}\n.TitleSection-Descr-module--cls2--LtGt5 a {\n  color: #7d1bf5;\n  transition: opacity 0.15s ease-in-out;\n}\n.TitleSection-Descr-module--cls2--LtGt5 a:hover {\n  opacity: 0.7;\n}", "",{"version":3,"sources":["webpack://./src/views/components/common/TitleSection-Descr.module.scss"],"names":[],"mappings":"AAAA,0CAAA,IAAA,EAAA;;AACA;EAGA,YAAA;EACA,gBAAA;EACA,eAAA;AACA;AACA;EACA,cAAA;EACA,qCAAA;AACA;AACA;EACA,YAAA;AACA","sourcesContent":[".cls1 { /*!*/ }\n.cls2 {\ncomposes: cls1;\n\nopacity: 0.5;\nmax-width: 440px;\nmargin-top: 8px;\n\na {\ncolor: #7d1bf5;\ntransition: opacity 0.15s ease-in-out;\n\n&:hover {\nopacity: 0.7;\n}\n}\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"cls1": "TitleSection-Descr-module--cls1--g4pOz",
	"cls2": "TitleSection-Descr-module--cls2--LtGt5 TitleSection-Descr-module--cls1--g4pOz"
};
export default ___CSS_LOADER_EXPORT___;
