// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".index-Title-module--cls1--uA7Cm { /*!*/ }\n\n.index-Title-module--cls2--P_3j2 {\n  font-weight: 700;\n  font-size: 13px;\n  line-height: 14px;\n  color: var(--color-base);\n}", "",{"version":3,"sources":["webpack://./src/services/adapters/toast/index-Title.module.scss"],"names":[],"mappings":"AAAA,mCAAA,IAAA,EAAA;;AACA;EAGA,gBAAA;EACA,eAAA;EACA,iBAAA;EACA,wBAAA;AACA","sourcesContent":[".cls1 { /*!*/ }\n.cls2 {\ncomposes: cls1;\n\nfont-weight: 700;\nfont-size: 13px;\nline-height: 14px;\ncolor: var(--color-base);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"cls1": "index-Title-module--cls1--uA7Cm",
	"cls2": "index-Title-module--cls2--P_3j2 index-Title-module--cls1--uA7Cm"
};
export default ___CSS_LOADER_EXPORT___;
