import React, { FC } from 'react'
import styled from 'astroturf/react'
import { NavLink } from 'react-router-dom'

import { ChevronLeft } from '@/components/ui/icons'

export interface BackLinkProps {
  to: string
}

export const BackLink: FC<BackLinkProps> = ({ to }) => {
  return (
    <Core to={to}>
      <ChevronLeft />
    </Core>
  )
}

const Core = styled(NavLink)`
  @import '../../../../assets/styles/mixins.scss';
  @include anim(opacity);
  width: 44px;
  height: 36px;
  fill: var(--color-base);
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: -44px;

  &:hover {
    opacity: 0.8;
  }
`
