import React from 'react'

import * as THREE from 'three'
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader'

import { proceedZip, modelHeight } from '../helpers/prepareModel'

class Glasses {
  ref = null

  subtype = null
  name = null

  height = 0
  scale = 1
  headScale = 1

  scene = null
  hips = null
  mesh = null

  version = 0
  busy = false

  /**
   * Clear model
   */

  clearModel = () => {
    //console.log('clearModel()')

    if (this.mesh?.material) {
      for (const [key, value] of Object.entries(this.mesh.material)) {
        if (this.mesh.material[key]?.isTexture) {
          //console.log(`Key: ${key}:`, this.mesh.material[key]);
          this.mesh.material[key].dispose()
        }
      }

      this.mesh.material.dispose()
      this.mesh.skeleton.dispose()
    }

    this.scene = null
    this.hips = null
    this.mesh = null

    this.subtype = null
    this.name = null
    this.height = 0
    this.scale = 1
    this.headScale = 1
  }

  /**
   * Prepare Glasses
   *
   * param glasses            - object with glasses name
   * param onDownloadResource - callback to load .zip and absend in .zip textures
   */

  prepareGlasses = async (glasses, onDownloadResource) => {
    //console.log('prepareGlasses()')
    //console.log('  glasses.name:', glasses.name)
    //console.log('  this.name:', this.name)

    //console.log('  performance.memory.usedJSHeapSize, MB:', performance.memory.usedJSHeapSize / 1048576)

    if (glasses.name === null) {
      this.clearModel()
      this.version++
      return
    }

    this.busy = true

    const zip = await onDownloadResource('glasses', glasses.name)
    //console.log('zip:', zip)

    const blobs = await proceedZip(zip)
    //console.log('blobs:', blobs)

    const model = await fetch(blobs['model.gltf'])
      .then((res) => res.text())
      .then((data) => JSON.parse(data))

    if (blobs['model.bin']) model.buffers[0].uri = blobs['model.bin']
    if (blobs['animations.bin']) model.buffers[1].uri = blobs['animations.bin']

    //console.log('model.images:', model.images)

    const textureLoader = new THREE.TextureLoader()

    for (let i = 0; i < model.images?.length; ++i) {
      //console.log('model.images:', i, model.images[i])

      const uri = model.images[i].uri
      model.images[i].uri = blobs[model.images[i].uri]
        ? blobs[model.images[i].uri]
        : URL.createObjectURL(await onDownloadResource('texture', model.images[i].uri))
    }
    //console.log('model:', model)

    const url = URL.createObjectURL(new Blob([JSON.stringify(model, null, 2)], { type: 'text/plain' }))
    //console.log('url:', url)

    const gltfLoader = new GLTFLoader()
    const gltf = await gltfLoader.loadAsync(url)

    //console.log('data loaded')

    this.clearModel()

    this.scene = gltf.scene
    this.scene.name = 'Scene'

    const avatarRoot = this.scene.children[0]
    this.hips = avatarRoot.children.find((element) => element.name === 'Hips')
    this.mesh = avatarRoot.children.find((element) => element.type === 'SkinnedMesh')

    this.mesh.material.side = THREE.DoubleSide
    this.mesh.material.depthWrite = true

    this.subtype = glasses.subtype
    this.name = glasses.name

    this.height = modelHeight(glasses.subtype)

    this.version++
    this.busy = false
  }

  /**
   * Glasses jsx
   *
   * param ref          - react.js useRef()
   */

  jsxGlasses(ref, params) {
    //console.log('jsxGlasses()')
    //console.log('  this:', this)

    if (!this.name) return null

    this.ref = ref

    //console.log('  this.headScale:', this.headScale)

    const bone = this.mesh.skeleton.bones.find((element) => element.name === 'Head')
    bone.scale.x = bone.scale.y = bone.scale.z = this.headScale

    return (
      <group dispose={null} ref={this.ref} scale={[this.scale, this.scale, this.scale]}>
        <primitive object={this.hips} />
        <skinnedMesh
          renderOrder={1}
          name={this.name}
          geometry={this.mesh.geometry}
          material={this.mesh.material}
          skeleton={this.mesh.skeleton}
        />
      </group>
    )
  }

  /**
   * Change Blandshapes
   *
   * param bodyParams   - body parameters
   */

  changeBlendshapes = (blendshapes) => {
    //console.log('changeBlendshapes()', blendshapes)

    if (this.hips === null) return

    if (blendshapes['height']) {
      //      const bone = this.hips
      //      bone.scale.x = bone.scale.y = bone.scale.z = blendshapes['height'] / this.height
      //      this.hips.parent.position.y = (blendshapes['height'] / this.height - 1) * this.hips.position.y

      this.scale = blendshapes['height'] / this.height
    }

    if (blendshapes['headScale']) {
      this.headScale = blendshapes['headScale']
      //const bone = this.mesh.skeleton.bones.find((element) => element.name === 'Head')
      //bone.scale.x = bone.scale.y = bone.scale.z = blendshapes['headScale']
    }
  }

  /**
   * Update Blendshapes
   *
   * param storeAvatarBody - avatar body data
   */

  updateBlendshapes = (storeAvatarBody) => {
    //console.log('updateBlendshapes()')

    if (this.hips === null) return

    const bodyMesh = storeAvatarBody.nodes['AvatarBody']
    //const bodyBone = bodyMesh.skeleton.bones.find((element) => element.name === 'Hips') // bones[0]

    //const bone = this.hips
    //bone.scale.x = bodyBone.scale.x
    //bone.scale.y = bodyBone.scale.y
    //bone.scale.z = bodyBone.scale.z

    //bone.parent.position.y = bodyBone.parent.position.y

    const bodyBoneHead = bodyMesh.skeleton.bones.find((element) => element.name === 'Head')
    const boneHead = this.mesh.skeleton.bones.find((element) => element.name === 'Head')

    //boneHead.scale.x = boneHead.scale.y = boneHead.scale.z = bodyBoneHead.scale.x
    this.headScale = bodyBoneHead.scale.x

    this.scale = storeAvatarBody.scale
  }
}

const storeGlasses = new Glasses()

export { storeGlasses }
