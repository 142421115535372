// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".AppsCard-Core-module--cls1--Th72i { /*!*/ }\n\n.AppsCard-Core-module--cls2--Xaw_5 {\n  border-radius: var(--size-radius);\n  background-color: var(--color-bg-filed);\n  display: block;\n  padding: 36px;\n  color: var(--color-base);\n}", "",{"version":3,"sources":["webpack://./src/views/page/apps/components/AppsCard-Core.module.scss"],"names":[],"mappings":"AAAA,qCAAA,IAAA,EAAA;;AACA;EAGA,iCAAA;EACA,uCAAA;EACA,cAAA;EACA,aAAA;EACA,wBAAA;AACA","sourcesContent":[".cls1 { /*!*/ }\n.cls2 {\ncomposes: cls1;\n\nborder-radius: var(--size-radius);\nbackground-color: var(--color-bg-filed);\ndisplay: block;\npadding: 36px;\ncolor: var(--color-base);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"cls1": "AppsCard-Core-module--cls1--Th72i",
	"cls2": "AppsCard-Core-module--cls2--Xaw_5 AppsCard-Core-module--cls1--Th72i"
};
export default ___CSS_LOADER_EXPORT___;
