// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Gender-Action-module--cls1--GqpwG { /*!*/ }\n\n.Gender-Action-module--cls2--C6Gm5 {\n  text-align: left;\n  justify-content: flex-start;\n}\n.Gender-Action-module--cls2--C6Gm5:not(:last-child) {\n  margin-bottom: 12px;\n}", "",{"version":3,"sources":["webpack://./src/views/page/generator/sections/Gender-Action.module.scss"],"names":[],"mappings":"AAAA,qCAAA,IAAA,EAAA;;AACA;EAGA,gBAAA;EACA,2BAAA;AACA;AACA;EACA,mBAAA;AACA","sourcesContent":[".cls1 { /*!*/ }\n.cls2 {\ncomposes: cls1;\n\ntext-align: left;\njustify-content: flex-start;\n\n&:not(:last-child) {\nmargin-bottom: 12px;\n}\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"cls1": "Gender-Action-module--cls1--GqpwG",
	"cls2": "Gender-Action-module--cls2--C6Gm5 Gender-Action-module--cls1--GqpwG"
};
export default ___CSS_LOADER_EXPORT___;
