// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".MPresets-Core-module--cls1--TWLqi { /*!*/ }\n\n.MPresets-Core-module--cls2--YpzMB {\n  gap: 12px;\n}\n.MPresets-Core-module--cls2--YpzMB.MPresets-Core-module--variant-mobile--n9HUe {\n  display: flex;\n  overflow-x: auto;\n  width: 100%;\n}\n.MPresets-Core-module--cls2--YpzMB.MPresets-Core-module--variant-not-mobile--my76i {\n  display: grid;\n  grid-template-columns: repeat(2, 1fr);\n}", "",{"version":3,"sources":["webpack://./src/views/page/generator/sections/components/MPresets-Core.module.scss"],"names":[],"mappings":"AAAA,qCAAA,IAAA,EAAA;;AACA;EAGA,SAAA;AACA;AAAA;EACA,aAAA;EACA,gBAAA;EACA,WAAA;AAEA;AACA;EACA,aAAA;EACA,qCAAA;AACA","sourcesContent":[".cls1 { /*!*/ }\n.cls2 {\ncomposes: cls1;\n\ngap: 12px;\n&.variant-mobile {\ndisplay: flex;\noverflow-x: auto;\nwidth: 100%;\n}\n\n&.variant-not-mobile {\ndisplay: grid;\ngrid-template-columns: repeat(2, 1fr);\n}\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"cls1": "MPresets-Core-module--cls1--TWLqi",
	"cls2": "MPresets-Core-module--cls2--YpzMB MPresets-Core-module--cls1--TWLqi",
	"variant-mobile": "MPresets-Core-module--variant-mobile--n9HUe",
	"variant-not-mobile": "MPresets-Core-module--variant-not-mobile--my76i"
};
export default ___CSS_LOADER_EXPORT___;
