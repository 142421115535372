// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Sidebar-Core-module--cls1--XI8XN { /*!*/ }\n\n.Sidebar-Core-module--cls2--uEjiu {\n  padding-left: 20px;\n  position: absolute;\n  top: 0;\n  left: 0;\n  height: var(--app-height);\n  width: 92px;\n  display: flex;\n  justify-content: space-between;\n  align-items: flex-start;\n  flex-direction: column;\n  padding-top: 32px;\n  padding-bottom: 32px;\n  z-index: 50;\n}", "",{"version":3,"sources":["webpack://./src/views/components/layouts/PrivatePageLayout/components/Sidebar-Core.module.scss"],"names":[],"mappings":"AAAA,oCAAA,IAAA,EAAA;;AACA;EAGA,kBAAA;EACA,kBAAA;EACA,MAAA;EACA,OAAA;EACA,yBAAA;EACA,WAAA;EACA,aAAA;EACA,8BAAA;EACA,uBAAA;EACA,sBAAA;EACA,iBAAA;EACA,oBAAA;EACA,WAAA;AACA","sourcesContent":[".cls1 { /*!*/ }\n.cls2 {\ncomposes: cls1;\n\npadding-left: 20px;\nposition: absolute;\ntop: 0;\nleft: 0;\nheight: var(--app-height);\nwidth: 92px;\ndisplay: flex;\njustify-content: space-between;\nalign-items: flex-start;\nflex-direction: column;\npadding-top: 32px;\npadding-bottom: 32px;\nz-index: 50;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"cls1": "Sidebar-Core-module--cls1--XI8XN",
	"cls2": "Sidebar-Core-module--cls2--uEjiu Sidebar-Core-module--cls1--XI8XN"
};
export default ___CSS_LOADER_EXPORT___;
