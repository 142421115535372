import React, { FC } from 'react'
import styled from 'astroturf/react'
import { NavLink } from 'react-router-dom'
import { Button, Logo as LogoDefault } from '@/components/ui'
import { Logout, GalleryIcon, AppIcon, SupportIcon } from '@/components/ui/icons'

export const Sidebar: FC = () => {
  return (
    <Core>
      <Logo width={48} height={40} />
      <Menu>
        <Link to='/gallery'>
          <GalleryIcon />
          <Text>Avatars</Text>
        </Link>
        <Link to='/apps'>
          <AppIcon />
          <Text>Applications</Text>
        </Link>
        <Link to='/support'>
          <SupportIcon />
          <Text>Support</Text>
        </Link>
      </Menu>
      <LogoutAction isOnlyIcon variant='surface'>
        <Logout />
      </LogoutAction>
    </Core>
  )
}

const Core = styled.div`
  padding-left: 20px;
  position: absolute;
  top: 0;
  left: 0;
  height: var(--app-height);
  width: 92px;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-direction: column;
  padding-top: 32px;
  padding-bottom: 32px;
  z-index: 50;
`

const Logo = styled(LogoDefault)`
  margin-left: 12px;
`

const Menu = styled.nav`
  @import '../../../../../assets/styles/mixins.scss';
  @include anim(width);
  background-color: var(--color-bg-box);
  padding-top: 14px;
  padding-bottom: 14px;
  width: 72px;
  border-radius: var(--size-radius);

  &:hover {
    width: 175px;
  }
`

const Link = styled(NavLink)`
  @import '../../../../../assets/styles/mixins.scss';
  @include anim(fill, color);
  padding-left: 24px;
  padding-right: 24px;
  fill: currentColor;
  color: var(--color-text-inactive);
  width: 100%;
  height: 48px;
  display: flex;
  align-items: center;
  position: relative;

  &[class*='active'],
  &:hover {
    color: var(--color-base);
  }
`

const Text = styled.span`
  @import '../../../../../assets/styles/mixins.scss';
  @include anim(opacity);
  position: absolute;
  left: 64px;
  opacity: 0;
  pointer-events: none;

  ${Menu}:hover & {
    opacity: 1;
    pointer-events: auto;
  }
`

const LogoutAction = styled(Button)`
  margin-left: 12px;
`
