// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".PhotoHandler-Actions-module--cls1--JU7_D { /*!*/ }\n\n.PhotoHandler-Actions-module--cls2--mtlPC {\n  max-width: 240px;\n  width: 100%;\n  margin-top: 32px;\n}", "",{"version":3,"sources":["webpack://./src/views/page/generator/sections/components/PhotoHandler-Actions.module.scss"],"names":[],"mappings":"AAAA,4CAAA,IAAA,EAAA;;AACA;EAGA,gBAAA;EACA,WAAA;EACA,gBAAA;AACA","sourcesContent":[".cls1 { /*!*/ }\n.cls2 {\ncomposes: cls1;\n\nmax-width: 240px;\nwidth: 100%;\nmargin-top: 32px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"cls1": "PhotoHandler-Actions-module--cls1--JU7_D",
	"cls2": "PhotoHandler-Actions-module--cls2--mtlPC PhotoHandler-Actions-module--cls1--JU7_D"
};
export default ___CSS_LOADER_EXPORT___;
