import React, { FC, PropsWithChildren } from 'react'
import styled from 'astroturf/react'
import { useLayoutContext } from '@/views/components/core/LayoutProvider'

export interface TypographyProps {
  as?: 'p' | 'h1' | 'h2' | 'h3' | 'span' | 'div'
  variant?: 'h1' | 'h1-small' | 'h2' | 'h3' | 'h4' | 'h5' | 'text' | 'label' | 'small' 
  opacity?: number
  className?: string
  html?: { __html: string }
}
export const Typography: FC<PropsWithChildren<TypographyProps>> = ({
  children,
  as = 'p',
  variant = 'text',
  className,
  html
}) => {
  const { mediaQueries } = useLayoutContext()

  return (
    <Text as={as} variant={variant} className={className} mobile={!mediaQueries.isTablet} dangerouslySetInnerHTML={html}>
      {children}
    </Text>
  )
}

export interface TextfieldProps {
  as?: 'p' | 'h1' | 'h2' | 'h3' | 'span' | 'div'
  variant: 'h1' | 'h1-small' | 'h2' | 'h3' | 'h4' | 'h5' | 'text' | 'label' | 'small' 
  mobile: boolean
}

const Text = styled.p<TextfieldProps>`
  &.variant-text {
    font-weight: 500;
    font-size: 16px;
    line-height: 140%;

    &.mobile {
      font-size: 14px;
    }
  }

  &.variant-h1 {
    font-weight: 700;
    font-size: 48px;
    line-height: 110%;
  }

  &.variant-h1-small {
    font-weight: 700;
    font-size: 40px;
    line-height: 110%;
  }

  &.variant-h2 {
    font-weight: 700;
    font-size: 28px;
    line-height: 150%;
  }

  &.variant-h3 {
    font-weight: 700;
    font-size: 24px;
    line-height: 150%;
  }

  &.variant-h4 {
    font-weight: 700;
    font-size: 16px;
    line-height: 150%;
  }
  &.variant-h5 {
    font-weight: 500;
    font-size: 14px;
    line-height: 130%;
  }

  &.variant-widthbtn {
    width: 190px;
   }
 

  &.variant-small {
    font-weight: 500;
    font-size: 14px;
    line-height: 150%;
  }

  &.variant-label {
    font-weight: 500;
    font-size: 13px;
    line-height: 150%;
  }
`
