import React, { useState, useEffect } from 'react'

import styled from 'astroturf/react'

export const ProgressBar = ({ value, max }) => {

  const [progress, setProgress] = useState(0)

  useEffect(() => {
    setProgress((value / max) * 100)
  }, [value, max])

  return (
    <ProgressContainer>
      <ProgressDiv style={{ width: `${progress}%` }}></ProgressDiv>
    </ProgressContainer>
  )
}

const ProgressContainer = styled.div`
  width: 100%;
  height: 20px;
  background-color: #f2f2f2;
  border-radius: 10px;
  overflow: hidden;
  margin-bottom: 12px;
`
const ProgressDiv = styled.div`
  height: 100%;
  background-image: linear-gradient(102.67deg, #7d1bf5 0%, #e32dd1 99.48%);
  transition: width 0.3s ease-in-out;
`

