// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Choice-AvatarCard-module--cls1--_Unlk { /*!*/ }\n\n.Choice-AvatarCard-module--cls2--pOgt1 {\n  position: relative;\n}", "",{"version":3,"sources":["webpack://./src/views/page/generator/sections/Choice-AvatarCard.module.scss"],"names":[],"mappings":"AAEA,yCAAA,IAAA,EAAA;;AACA;EAGA,kBAAA;AADA","sourcesContent":["@import '../../../../assets/styles/mixins.scss';\n\n.cls1 { /*!*/ }\n.cls2 {\ncomposes: cls1;\n\nposition: relative;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"cls1": "Choice-AvatarCard-module--cls1--_Unlk",
	"cls2": "Choice-AvatarCard-module--cls2--pOgt1 Choice-AvatarCard-module--cls1--_Unlk"
};
export default ___CSS_LOADER_EXPORT___;
