import React, { FC } from 'react'
import styled from 'astroturf/react'
import { SpinnerDots, SpinnerCircle, SpinnerInfinite } from '@/components/ui'

export interface LoaderProps {
  className?: string
  spinner: 'dots' | 'circle' | 'infinite'
  variant?: 'overlay' | 'content'
}

const spinners = {
  dots: SpinnerDots,
  circle: SpinnerCircle,
  infinite: SpinnerInfinite,
}

export const Loader: FC<LoaderProps> = ({ spinner, className, variant = 'overlay' }) => {
  const Spinner = spinners[spinner]
  return (
    <Core className={className} variant={variant}>
      <Spinner />
    </Core>
  )
}

export interface CoreProps {
  variant?: 'overlay' | 'content'
}

const Core = styled.div<CoreProps>`
  display: flex;
  align-items: center;
  justify-content: center;

  &.variant-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
`
