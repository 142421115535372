import React from 'react'
import styled from 'astroturf/react'
import { css } from 'astroturf'
import { useNavigate } from 'react-router-dom'
import { Typography, Video, Button } from '@/components/ui'
import { AvatarCard, BackLink } from './components'
import { useLayoutContext } from '@/views/components/core/LayoutProvider'
import { Close } from '@/components/ui/icons'

export const Article = () => {
  const navigate = useNavigate()
  const { selectedAvatars, setSelectedAvatars } = useLayoutContext()
  return (
    <>
      <Head>
        <BackLink to='/apps' />
        <Typography as='h1' variant='h2'>
          Mozilla Hubs
        </Typography>
      </Head>
      <Box>
        <Content>
          <Text as='div'>
            <p>
              The Nemesis is a new generation metaverse with a mission to make web3 relevant for the mass market. Its
              sleek UX and UI available on mobile and web browser, provides casual gamers around the world with tons of
              live features, a fun social environment to hang out, exclusive branded engagement experiences, and an
              opportunity for creators to self-express and cash on their work.
            </p>

            <p>
              With Ready Player Me’s technology that allows users to explore virtual worlds with one consistent
              identity, The Nemesis users can travel across the platform’s various games, events, and worlds as an
              avatar.
            </p>

            <p>
              Building a 3D avatar is as simple as taking a selfie - further enhancement and customization can be
              achieved through accessorizing with unique wearables.
            </p>
          </Text>
          <Video
            coverSize={{ w: 512, h: 280 }}
            id='OkqGP3t1a6g'
            css={css`
              margin-top: 40px;
            `}
          />
        </Content>
        <Sidebar>
          <Button wSize='full' variant='accent'>
            Download app
          </Button>
          <Button
            wSize='full'
            variant='surface'
            css={css`
              margin-top: 12px;
            `}
            onClick={() => navigate('avatar-selection')}
          >
            Connect avatar
          </Button>

          {!!selectedAvatars.length && (
            <>
              <Separator />
              {selectedAvatars.map((item) => (
                <AvatarCard
                  key={item.id}
                  css={css`
                    margin-top: 12px;
                  `}
                  img={item.img}
                  icon={Close}
                  onClick={() => setSelectedAvatars(selectedAvatars.filter(({ id }) => id !== item.id))}
                />
              ))}
            </>
          )}
        </Sidebar>
      </Box>
    </>
  )
}

const Head = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  margin-bottom: 28px;
`

const Box = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
`

const Content = styled.div`
  max-width: 510px;
`

const Text = styled(Typography)`
  opacity: 0.5;
  p {
    font-weight: 500;

    &:not(:last-child) {
      margin-bottom: 20px;
    }
  }
`

const Sidebar = styled.div`
  width: 300px;
`

const Separator = styled.div`
  width: 100%;
  height: 1px;
  background-color: var(--color-base);
  opacity: 0.15;
  margin-top: 32px;
  margin-bottom: 32px;
`
