// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Presets-Img-module--cls1--u_yP_ { /*!*/ }\n\n.Presets-Img-module--cls2--vbvxA {\n  position: absolute;\n  width: 100%;\n  height: 100%;\n  border-radius: var(--size-radius-small);\n  top: 0;\n  left: 0;\n}", "",{"version":3,"sources":["webpack://./src/views/page/generator/sections/components/Presets-Img.module.scss"],"names":[],"mappings":"AAAA,mCAAA,IAAA,EAAA;;AACA;EAGA,kBAAA;EACA,WAAA;EACA,YAAA;EACA,uCAAA;EACA,MAAA;EACA,OAAA;AACA","sourcesContent":[".cls1 { /*!*/ }\n.cls2 {\ncomposes: cls1;\n\nposition: absolute;\nwidth: 100%;\nheight: 100%;\nborder-radius: var(--size-radius-small);\ntop: 0;\nleft: 0;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"cls1": "Presets-Img-module--cls1--u_yP_",
	"cls2": "Presets-Img-module--cls2--vbvxA Presets-Img-module--cls1--u_yP_"
};
export default ___CSS_LOADER_EXPORT___;
