import React from 'react'
import styled from 'astroturf/react'
import { Typography, Button } from '@/components/ui'
import { css } from 'astroturf'

import avatarPreview from '@/assets/img/avatar-preview.png'
import avatarPreview2x from '@/assets/img/avatar-preview@2x.png'

export const Empty = () => {
  return (
    <Core>
      <TextBox>
        <Typography as='h1' variant='h1-small'>
          Create your amazing avatar
        </Typography>
        <Descr
          variant='text'
          css={css`
            opacity: 0.5;
          `}
        >
          By clicking "Take a photo!" or "Continue without a photo" you confirm you have read and accept our
        </Descr>
        <Action>Create first avatar</Action>
      </TextBox>
      <AvatarBox>
        <AvatarPreview src={avatarPreview} srcSet={`${avatarPreview2x} 2x`} />
      </AvatarBox>
    </Core>
  )
}

const Core = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  padding-top: 12px;
  max-height: calc(var(--app-height) - var(--content-base-offset));
  overflow: hidden;
`

const TextBox = styled.div`
  padding-top: 16vh;
  max-width: 325px;
`

const Descr = styled(Typography)`
  margin-top: 16px;
`

const Action = styled(Button)`
  margin-top: 46px;
`

const AvatarBox = styled.div``

const AvatarPreview = styled.img`
  bottom: 0;
  right: 0;
  width: 385px;
  height: auto;
  object-fit: contain;
`
