import React, { FC, memo } from 'react'
import styled from 'astroturf/react'
import { Minus, Plus } from '@/components/ui/icons'
import { useLayoutContext } from '@/views/components/core/LayoutProvider'
import { SVGIcon, ApiKeysUnion } from '@/helpers/types'
import { Menu, ViewerWrap } from './components'

import { storeEditor } from '@/services/store'
import { cameraPositions } from './cameraPositions'

export type Components = 'Color' | 'Face' | 'Body' | 'Hairs' | 'Glasses' | 'Shirt'

export interface Acc {
  apiKey: ApiKeysUnion
  icon: SVGIcon
  component: FC
}

export const Editor = memo(() => {
  const { mediaQueries } = useLayoutContext()
  const variant = mediaQueries.isTablet ? 'not-mobile' : 'mobile'

  return (
    <Core variant={variant}>
      <ViewerWrap variant={variant} />
      <Menu />

      {mediaQueries.isTablet && (
        <Zoom>
          <ZoomBox>
            <ZoomAction onClick={() => storeEditor.changePosition(cameraPositions('HEAD', mediaQueries.isTablet))}>
              <Plus />
            </ZoomAction>
            <ZoomAction onClick={() => storeEditor.changePosition(cameraPositions('BODY', mediaQueries.isTablet))}>
              <Minus />
            </ZoomAction>
          </ZoomBox>
        </Zoom>
      )}
    </Core>
  )
})

export interface Variant {
  variant: 'not-mobile' | 'mobile'
}

const Core = styled.div<Variant>`
  &.variant-mobile {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    height: var(--app-height);
  }
`

const Zoom = styled.div`
  position: absolute;
  height: 100%;
  width: 0;
  right: 0;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: flex-end;
`

const ZoomBox = styled.div`
  width: 48px;
  background-color: var(--color-bg-box);
  margin-right: 20px;
  border-radius: var(--size-radius-large);
  padding-top: 12px;
  padding-bottom: 12px;
`

const ZoomAction = styled.button`
  @import '../../../../../assets/styles/mixins.scss';
  @include anim(opacity);
  width: 48px;
  height: 24px;
  fill: var(--color-base);

  &.current,
  &:hover {
    opacity: 0.7;
  }

  svg {
    display: block;
    margin-left: auto;
    margin-right: auto;
  }
`
