// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".GeneratorPageLayout-Header-module--cls1--j48bu { /*!*/ }\n\n.GeneratorPageLayout-Header-module--cls2--IpDBV {\n  display: flex;\n  width: calc(100% - 64px);\n  margin-left: auto;\n  margin-right: auto;\n  padding-top: 32px;\n  position: absolute;\n  top: 0;\n  left: 32px;\n}", "",{"version":3,"sources":["webpack://./src/views/components/layouts/GeneratorPageLayout-Header.module.scss"],"names":[],"mappings":"AAAA,kDAAA,IAAA,EAAA;;AACA;EAGA,aAAA;EACA,wBAAA;EACA,iBAAA;EACA,kBAAA;EACA,iBAAA;EACA,kBAAA;EACA,MAAA;EACA,UAAA;AACA","sourcesContent":[".cls1 { /*!*/ }\n.cls2 {\ncomposes: cls1;\n\ndisplay: flex;\nwidth: calc(100% - 64px);\nmargin-left: auto;\nmargin-right: auto;\npadding-top: 32px;\nposition: absolute;\ntop: 0;\nleft: 32px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"cls1": "GeneratorPageLayout-Header-module--cls1--j48bu",
	"cls2": "GeneratorPageLayout-Header-module--cls2--IpDBV GeneratorPageLayout-Header-module--cls1--j48bu"
};
export default ___CSS_LOADER_EXPORT___;
