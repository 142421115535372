import React, { useState, FC } from 'react';
import { Box, WrapBox } from '@/views/page/generator/sections/components';
import { observer } from 'mobx-react-lite';
import { storeEditor } from '@/services/store';
import { useLayoutContext } from '@/views/components/core/LayoutProvider';
import { cameraPositions } from '../cameraPositions'

export const EditorAge: FC = observer(() => {
  //console.log("")
  //console.log("EditorAge()")

  const versionUI = storeEditor.versionUI  // to rerender after versionUI increment

  const { mediaQueries } = useLayoutContext();
  //const [selectedVariant, setSelectedVariant] = useState(storeEditor.age);
  const selectedVariant = storeEditor.age
  const customWidth = mediaQueries.isTablet ? 170 : window.screen.width - 50

  const handleVariantChange = (variant) => {
    //console.log("handleVariantChange, variant:", variant)
    //setSelectedVariant(variant);
    storeEditor.setAge(variant);
    //storeEditor.changePosition({ ...cameraPositions(mediaQueries.isTablet ? 'BODY' : 'EditorAge', mediaQueries.isTablet), noAnimate: true });
  };

  const ageRadioButtons = [
    { id: 'Adult', label: '16+' },
    { id: 'Teen_15', label: '12-16' },
    { id: 'Teen_12', label: '10-12' }
  ];

  const radioContainerStyle = {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between', 
  };

  const radioStyle = {
    marginRight: '0.5em',
    marginBottom: '0.2em',
    accentColor: '#7d1bf5',
    width: '28px',
    height: '28px',
  };

  const containerStyle = {
    display: 'flex',
    alignItems: 'center',
  };

  //console.log("selectedVariant:", selectedVariant)

  const renderRadioButtons = () => {
    return ageRadioButtons.map(({ id, label }) => (
      <React.Fragment key={id}>
        <div style={containerStyle}>
          <input
            type="radio"
            id={id}
            name="variant"
            value={id}
            checked={selectedVariant === id}
            onChange={() => handleVariantChange(id)}
            style={radioStyle}
          />
          <label htmlFor={id} style={{ fontSize: '16px', color: selectedVariant === id ? '#7d1bf5' : 'inherit' }}>{label}</label>
        </div>
      </React.Fragment>
    ));
  };

  if (mediaQueries.isTablet) {
    return (
      <WrapBox height={15}>
      <Box title={'Age'} customWidth={customWidth}>
        {renderRadioButtons()}
      </Box>
      </WrapBox>
    )
  }
  return (
    <WrapBox height={15}>
        <Box title={'Age'} customWidth={customWidth}>
        <div style={radioContainerStyle}>{renderRadioButtons()}</div>
        </Box>
    </WrapBox>  
  )
})

EditorAge.displayName = 'EditorAge';