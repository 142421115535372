// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Modal-Overlay-module--cls1--Asunj { /*!*/ }\n\n.Modal-Overlay-module--cls2--bPTkw {\n  position: fixed;\n  top: 0;\n  left: 0;\n  width: 100%;\n  height: 100%;\n}", "",{"version":3,"sources":["webpack://./src/components/wrapper/Modal-Overlay.module.scss"],"names":[],"mappings":"AAAA,qCAAA,IAAA,EAAA;;AACA;EAGA,eAAA;EACA,MAAA;EACA,OAAA;EACA,WAAA;EACA,YAAA;AACA","sourcesContent":[".cls1 { /*!*/ }\n.cls2 {\ncomposes: cls1;\n\nposition: fixed;\ntop: 0;\nleft: 0;\nwidth: 100%;\nheight: 100%;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"cls1": "Modal-Overlay-module--cls1--Asunj",
	"cls2": "Modal-Overlay-module--cls2--bPTkw Modal-Overlay-module--cls1--Asunj"
};
export default ___CSS_LOADER_EXPORT___;
