// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".EditorShirt-BodyTable-module--cls1--KqqHx { /*!*/ }\n\n.EditorShirt-BodyTable-module--cls2--IY1ih {\n  display: block;\n  width: 100%;\n  overflow-y: auto;\n  overflow-x: hidden;\n}\n.EditorShirt-BodyTable-module--cls2--IY1ih::-webkit-scrollbar-track {\n  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);\n  border-radius: 16px;\n  background-color: grey;\n}\n.EditorShirt-BodyTable-module--cls2--IY1ih::-webkit-scrollbar {\n  border-radius: 16px;\n  width: 5px;\n  background-color: grey;\n}\n.EditorShirt-BodyTable-module--cls2--IY1ih::-webkit-scrollbar-thumb {\n  border-radius: 16px;\n  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);\n  background-color: var(--color-base);\n}", "",{"version":3,"sources":["webpack://./src/views/page/generator/sections/Editor/components/EditorShirt-BodyTable.module.scss"],"names":[],"mappings":"AAAA,6CAAA,IAAA,EAAA;;AACA;EAGA,cAAA;EACA,WAAA;EACA,gBAAA;EACA,kBAAA;AACA;AACA;EACA,oDAAA;EACA,mBAAA;EACA,sBAAA;AACA;AAEA;EACA,mBAAA;EACA,UAAA;EACA,sBAAA;AAAA;AAGA;EACA,mBAAA;EACA,oDAAA;EACA,mCAAA;AADA","sourcesContent":[".cls1 { /*!*/ }\n.cls2 {\ncomposes: cls1;\n\ndisplay: block;\nwidth: 100%;\noverflow-y: auto;\noverflow-x: hidden;\n\n&::-webkit-scrollbar-track {\n-webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);\nborder-radius: 16px;\nbackground-color: grey;\n}\n\n&::-webkit-scrollbar {\nborder-radius: 16px;\nwidth: 5px;\nbackground-color: grey;\n}\n\n&::-webkit-scrollbar-thumb {\nborder-radius: 16px;\n-webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);\nbackground-color: var(--color-base);\n}\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"cls1": "EditorShirt-BodyTable-module--cls1--KqqHx",
	"cls2": "EditorShirt-BodyTable-module--cls2--IY1ih EditorShirt-BodyTable-module--cls1--KqqHx"
};
export default ___CSS_LOADER_EXPORT___;
