import * as THREE from 'three'

export function prepareR (initialColor, targetColor) {
  //console.log('prepareR()')
  //console.log('  initialColor:', initialColor)
  //console.log('  targetColor:', targetColor)

  const R = new THREE.Matrix3(1, 0, 0, 0, 1, 0, 0, 0, 1)

  const initialNorm = initialColor.length()
  //console.log('initialNorm:', initialNorm)
  if (initialNorm === 0) return R

  const targetNorm = targetColor.length()
  //console.log('targetNorm:', targetNorm)
  if (targetNorm === 0) {
    for (let i = 0; i < 9; i++) R.elements[i] = 0
    return R
  }

  const scale = targetNorm / initialNorm
  //console.log('scale:', scale)

  const r = new THREE.Vector3().crossVectors(initialColor, targetColor)
  //console.log('r:', r)

  const rNorm = r.length()
  //console.log('rNorm:', rNorm)

  if (rNorm > 0.0017 * initialNorm * targetNorm) {
    // sin(0.1 gradus)
    const c = initialColor.dot(targetColor) / (initialNorm * targetNorm)
    const s = Math.sqrt(1.0 - c * c)

    //console.log('c:', c)
    //console.log('s:', s)

    r.divideScalar(rNorm)
    //console.log('r/norm(r):', r.x, r.y, r.z)

    const rrt = new THREE.Matrix3()
    rrt.set(r.x * r.x, r.x * r.y, r.x * r.z, r.y * r.x, r.y * r.y, r.y * r.z, r.z * r.x, r.z * r.y, r.z * r.z)
    //console.log('rrt:', rrt)

    const r_x = new THREE.Matrix3()
    r_x.set(0, r.z, -r.y, -r.z, 0, r.x, r.y, -r.x, 0)
    //console.log('r_x:', r_x)

    for (let i = 0; i < 9; i++)
      R.elements[i] = scale * (c * R.elements[i] + (1.0 - c) * rrt.elements[i] + s * r_x.elements[i])
  } else {
    for (let i = 0; i < 9; i++) R.elements[i] *= scale
  }

  return R
}
