// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Links-Nav-module--cls1--AhJql { /*!*/ }\n\n.Links-Nav-module--cls2--nvvCE {\n}\n.Links-Nav-module--cls2--nvvCE a {\n  color: var(--color-base);\n  margin-right: 10px;\n}", "",{"version":3,"sources":["webpack://./src/helpers/Links-Nav.module.scss"],"names":[],"mappings":"AAAA,iCAAA,IAAA,EAAA;;AACA;AAGA;AAAA;EACA,wBAAA;EACA,kBAAA;AAEA","sourcesContent":[".cls1 { /*!*/ }\n.cls2 {\ncomposes: cls1;\n\na {\ncolor: var(--color-base);\nmargin-right: 10px;\n}\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"cls1": "Links-Nav-module--cls1--AhJql",
	"cls2": "Links-Nav-module--cls2--nvvCE Links-Nav-module--cls1--AhJql"
};
export default ___CSS_LOADER_EXPORT___;
