import React, { FC } from 'react'
import styled from 'astroturf/react'
import { Typography } from '@/components/ui'

export interface TitleSectionProps {
  title: string
  descr: string
}

export const TitleSection: FC<TitleSectionProps> = ({ title, descr }) => {
  return (
    <>
      <Typography variant='h2' as='h1'>
        {title}
      </Typography>
      <Descr variant='text' html={{ __html: descr }}></Descr>
    </>
  )
}

const Descr = styled(Typography)`
  opacity: 0.5;
  max-width: 440px;
  margin-top: 8px;

  a {
    color: #7d1bf5;
    transition: opacity 0.15s ease-in-out;

    &:hover {
      opacity: 0.7;
    }
  }
`
