// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Generator-Start-module--cls1--HQBIV { /*!*/ }\n\n.Generator-Start-module--cls2--MG4zc {\n  min-height: var(--app-height);\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  justify-content: space-between;\n  padding-top: 32vh;\n  padding-bottom: 120px;\n}\n.Generator-Start-module--cls2--MG4zc .Generator-Start-module--profile-icon--Bn5gD {\n  width: 64px;\n  height: 64px;\n  margin-top: 22px;\n  margin-bottom: auto;\n}\n@media (prefers-color-scheme: dark) {\n  .Generator-Start-module--cls2--MG4zc .Generator-Start-module--profile-icon--Bn5gD {\n    background-color: black;\n  }\n}", "",{"version":3,"sources":["webpack://./src/views/page/generator/Generator-Start.module.scss"],"names":[],"mappings":"AAAA,uCAAA,IAAA,EAAA;;AACA;EAGA,6BAAA;EACA,aAAA;EACA,sBAAA;EACA,mBAAA;EACA,8BAAA;EACA,iBAAA;EACA,qBAAA;AACA;AACA;EACA,WAAA;EACA,YAAA;EACA,gBAAA;EACA,mBAAA;AACA;AAEA;EACA;IACA,uBAAA;EAAE;AACF","sourcesContent":[".cls1 { /*!*/ }\n.cls2 {\ncomposes: cls1;\n\nmin-height: var(--app-height);\ndisplay: flex;\nflex-direction: column;\nalign-items: center;\njustify-content: space-between;\npadding-top: 32vh;\npadding-bottom: 120px;\n\n.profile-icon {\nwidth: 64px;\nheight: 64px;\nmargin-top: 22px;\nmargin-bottom: auto;\n}\n\n@media (prefers-color-scheme: dark) {\n.profile-icon {\nbackground-color: black;\n}\n}\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"cls1": "Generator-Start-module--cls1--HQBIV",
	"cls2": "Generator-Start-module--cls2--MG4zc Generator-Start-module--cls1--HQBIV",
	"profile-icon": "Generator-Start-module--profile-icon--Bn5gD"
};
export default ___CSS_LOADER_EXPORT___;
