// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Generator-AddExportButton-module--cls1--pkP8A { /*!*/ }\n\n.Generator-AddExportButton-module--cls2--yG7_U {\n  margin-top: 8px;\n  z-index: 289;\n}", "",{"version":3,"sources":["webpack://./src/views/page/generator/Generator-AddExportButton.module.scss"],"names":[],"mappings":"AAAA,iDAAA,IAAA,EAAA;;AACA;EAGA,eAAA;EACA,YAAA;AACA","sourcesContent":[".cls1 { /*!*/ }\n.cls2 {\ncomposes: cls1;\n\nmargin-top: 8px;\nz-index: 289;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"cls1": "Generator-AddExportButton-module--cls1--pkP8A",
	"cls2": "Generator-AddExportButton-module--cls2--yG7_U Generator-AddExportButton-module--cls1--pkP8A"
};
export default ___CSS_LOADER_EXPORT___;
