import React, { FC, useCallback } from 'react'
import styled from 'astroturf/react'
import copy from 'copy-text-to-clipboard'
import { AvatarPreview, Typography } from '@/components/ui'
import { Social } from './Social'

export interface SharingAvatarProps {
  img: string
  link: string
}

export const SharingAvatar: FC<SharingAvatarProps> = ({ img, link }) => {
  const makeCopy = useCallback(() => {
    copy(link)
  }, [link])

  return (
    <>
      <Preview>
        <AvatarPreview img={img} width={360} height={320} gradient />
      </Preview>
      <Copy>
        <CopyText variant='small'>{link}</CopyText>
        <CopyAction onClick={makeCopy}>Copy link</CopyAction>
      </Copy>
      <Social />
    </>
  )
}

const Preview = styled.div`
  position: relative;
`
const Copy = styled.div`
  height: var(--size-field-height);
  padding-left: var(--size-field-padding);
  padding-right: var(--size-field-padding);
  background-color: var(--color-bg-filed);
  font-weight: 700;
  font-size: 14px;
  line-height: 150%;
  border-radius: 16px;
  border-width: 0;
  color: var(--color-base);
  padding-bottom: 3px;
  margin-bottom: 12px;
  text-align: left;
  margin-bottom: 32px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 12px;
`

const CopyText = styled(Typography)`
  opacity: 0.5;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  width: calc(100% - 60px);
`

const CopyAction = styled.button`
  @import '../../../assets/styles/mixins.scss';
  @include anim(opacity);
  color: var(--color-base);
  &:hover {
    opacity: 0.8;
  }
`
