// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".FieldWrap-Core-module--cls1--mmpTO { /*!*/ }\n\n.FieldWrap-Core-module--cls2--UEiuF {\n  display: block;\n  margin-bottom: 12px;\n  text-align: left;\n}\n.FieldWrap-Core-module--cls2--UEiuF.FieldWrap-Core-module--wSize-full--J0gxM {\n  width: 100%;\n}\n.FieldWrap-Core-module--cls2--UEiuF.FieldWrap-Core-module--wSize-auto--YKIEG {\n  width: auto;\n}", "",{"version":3,"sources":["webpack://./src/components/ui/FieldWrap-Core.module.scss"],"names":[],"mappings":"AAAA,sCAAA,IAAA,EAAA;;AACA;EAGA,cAAA;EACA,mBAAA;EACA,gBAAA;AACA;AACA;EACA,WAAA;AACA;AAEA;EACA,WAAA;AAAA","sourcesContent":[".cls1 { /*!*/ }\n.cls2 {\ncomposes: cls1;\n\ndisplay: block;\nmargin-bottom: 12px;\ntext-align: left;\n\n&.wSize-full {\nwidth: 100%;\n}\n\n&.wSize-auto {\nwidth: auto;\n}\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"cls1": "FieldWrap-Core-module--cls1--mmpTO",
	"cls2": "FieldWrap-Core-module--cls2--UEiuF FieldWrap-Core-module--cls1--mmpTO",
	"wSize-full": "FieldWrap-Core-module--wSize-full--J0gxM",
	"wSize-auto": "FieldWrap-Core-module--wSize-auto--YKIEG"
};
export default ___CSS_LOADER_EXPORT___;
