import React, { FC, useState, useEffect } from 'react'
import ReactDOM from 'react-dom'

import { observer } from 'mobx-react-lite'
import styled from 'astroturf/react'

import { useLayoutContext } from '@/views/components/core/LayoutProvider'
import { storeEditor, storeGeneratorPipeline } from '@/services/store'
import { Box, WrapBox, Presets, MPresets } from '@/views/page/generator/sections/components'
import { CoreProps } from '../../components/Box'
import { Button } from '@/components/ui'
import { any, none } from 'ramda'
import { Typography } from '@/components/ui'
import { handleError, toast } from '@/services/adapters/toast'
import { CirclePicker, CompactPicker, SliderPicker } from 'react-color'

import { TextureFromImageDialog } from './TextureFromImageDialog'

export const ProgressBar = ({ value, max }) => {
  const [progress, setProgress] = useState(0)
  useEffect(() => {
    setProgress((value / max) * 100)
  }, [value, max])
  return (
    <ProgressContainer>
      <ProgressDiv style={{ width: `${progress}%` }}></ProgressDiv>
    </ProgressContainer>
  )
}

const LogoPromptModalWindow: FC = (props) => {
  //console.info('LogoPromptModalWindow, props:', props)

  const [file, setFile] = useState()

  function handleChange(event) {
    setFile(event.target.files[0])
    props.onSubmit(event.target.files[0])
  }

  const style = {
    position: 'absolute', top: props.top, right: props.right,
    fontWeight: 700,
    fontSize: '14px',
    background: 'linear-gradient(100deg, #7D1BF5 0%, #E32DD1 100%)',
    WebkitTextFillColor: 'transparent',
    WebkitBackgroundClip: 'text',
    border: 'none',
    cursor: 'pointer'
  }

  if (props.chose)
    return (
      <div className="LogoPrompt" align="center" style={style}>
        <div onClick={() => props.onSubmit(null)}>Remove Logo</div>
        <br />
      </div>
    )

  return (
    <div className="LogoPrompt" style={style}>
      <form>
        <label htmlFor="chooseLogo">Choose Logo</label>
        <input type="file" accept="image/png, image/jpeg" id="chooseLogo" style={{ display: "none" }} onChange={handleChange} />
      </form>
      <br />
    </div>
  )
}



const AiPromptModalWindow: FC = (props) => {
  //console.info('AiPromptModalWindow, props:', props)

  const [slot, setSlot] = useState(props.outfits['complect'] ? 'complect' : 'top')
  const [text, setText] = useState('')
  const [isShown, setIsShown] = useState(false)

  const onButtonClick = (text) => {
    // console.info('onButtonClick(), text:', text)
    if (text !== null && text.length !== 0) {
      setIsShown(true)
    }
    props.onSubmit(slot, text)
  }

  const onChangeSlot = () => {
    const slot = document.getElementById('selectSlot').value

    // console.info('onChangeSlot():', slot)
    setSlot(slot)
  }

  const style = (bgColor) => {
    return { fontSize: '16px', border: true, padding: '10px 0px', backgroundColor: bgColor, color: 'var(--color-base)' }
  }

  const styleTableElements = {
    border: '1px solid',
    padding: '5px 20px',
    color: 'var(--color-base)',
    borderRadius: '16px',
    fontSize: '16px',
  }

  const styleTableButtons = (borderColor, bgImage) => {
    return {
      border: '1px solid',
      padding: '5px 0',
      color: 'var(--color-base)',
      borderRadius: '16px',
      borderColor: borderColor,
      backgroundImage: bgImage,
      width: '100px',
      cursor: 'pointer',
    }
  }

  const styleTableRow = {
    display: 'table',
    width: '100%',
    margin: '5px 0',
  }

  const presets = ['hawaiian shirt pattern', 'polka dot pattern', 'geometric patern', 'abstract art']

  return ReactDOM.createPortal(
    <Core animation={'opening'}>
      <Content>
        <Body>
          <table width={'100%'}>
            <thead style={{ display: 'table', width: '100%' }}>
              <tr>
                <td width={'50%'} style={style(none)}>
                  <b>
                    <select
                      id='selectSlot'
                      style={{ fontSize: '16px', border: '0', padding: '5px 0' }}
                      onChange={onChangeSlot}
                    >
                      {props.outfits['complect'] && <option value='complect'>Complect</option>}
                      {props.outfits['top'] && <option value='top'>Top</option>}
                      {props.outfits['bottom'] && <option value='bottom'>Bottom</option>}
                    </select>
                  </b>
                </td>
                <td width={'50%'} align={'right'}>
                  <button hidden={isShown} type='button' style={style(none)} onClick={(e) => onButtonClick(null)}>
                    {' '}
                    X{' '}
                  </button>
                </td>
              </tr>
              <tr height='5'>
                <td colSpan={'2'}>
                  <hr />
                </td>
              </tr>
            </thead>
            <BodyTable>
              {presets.map((item, idx) => {
                return (
                  <tr key={idx} style={styleTableRow}>
                    <td style={{ color: 'var(--color-base)' }}>{item}</td>
                    <td align={'right'}>
                      <button
                        type='button'
                        style={styleTableElements}
                        disabled={isShown}
                        onClick={(e) => onButtonClick(item)}
                      >
                        {' '}
                        Try it{' '}
                      </button>
                    </td>
                  </tr>
                )
              })}
            </BodyTable>
            <tfoot style={{ display: 'table', width: '100%' }}>
              <tr height='10'>
                <td colSpan={'2'}></td>
              </tr>
              {!isShown && (
                <tr>
                  <td colSpan={'2'}>
                    <input
                      style={{ fontSize: '16px', width: '100%', borderRadius: '16px', padding: '5px 10px' }}
                      maxLength={256}
                      width={'100%'}
                      type='text'
                      placeholder='Provide a text description for a texture'
                      value={text}
                      onChange={(e) => setText(e.target.value.replace(/[\u0080-\uFFFF]/g, ''))}
                    />{' '}
                  </td>
                </tr>
              )}
              {isShown && (
                <tr height='18'>
                  <td colSpan={'2'}></td>
                </tr>
              )}
              <tr height='10'>
                <td colSpan={'2'}>
                  {isShown && <Progress>Generating texture...</Progress>}
                  {isShown && <ProgressBar value={storeGeneratorPipeline.progress} max={100} />}
                </td>
              </tr>
              {!isShown && (
                <tr>
                  <td align={'left'}>
                    {!props.isDefaultButtonHidden[slot] && (
                    <Button 
                      wSize='auto'
                      variant='surface'
                      type='button'
                      onClick={(e) => {
                        onButtonClick('--default')
                      }}
                    >
                      Use Default
                    </Button>
                   )}
                   </td>
                  <td align={'right'}>
                    <Button
                      wSize='auto'
                      type='button'
                      onClick={(e) => {
                        onButtonClick(text)
                      }}
                    >
                      Generate
                    </Button>
                  </td>
                </tr>
              )}
            </tfoot>
          </table>
        </Body>
      </Content>
    </Core>,
    document.getElementById('portal') as HTMLElement,
  )
}

export const EditorShirt: FC = observer(() => {
  //console.info('EditorShirt()')
  const versionUI = storeEditor.versionUI  // to rerender after observable versionUI increment

  const { mediaQueries } = useLayoutContext()
  const variant = mediaQueries.isTablet ? 'not-mobile' : 'mobile'

  const { outfits, outfitNames, setOutfits, presets, hatPresets, hat, isOutfitReady, isHatReady } = storeEditor
  const {
    progress,
    preSettings: { gender },
  } = storeGeneratorPipeline

  const [showAiPrompt, setShowAiPrompt] = useState(null)
  const [showTextureFromImageDialog, setShowTextureFromImageDialog] = useState(null)

  const onSubmitLogo = (logo, section) => {
    //console.info("onSubmitLogo(), logo:", logo)

    if (logo === null || logo == undefined || storeEditor.outfits[section].logo !== null) {
      storeEditor.setOutfitLogo(section, null)
      return
    }

    const reader = new FileReader();
    reader.addEventListener("load", () => {
      const dataUrl = reader.result

      const arr = dataUrl.split(',')
      const mime = arr[0].match(/:(.*?);/)[1]
      const bstr = atob(arr[1])
      let n = bstr.length
      const u8arr = new Uint8Array(n)
      while (n--)
        u8arr[n] = bstr.charCodeAt(n);
      const blob = new Blob([u8arr], { type: mime });

      storeEditor.setOutfitLogo(section, URL.createObjectURL(blob))
    }, false);

    if (logo) {
      reader.readAsDataURL(logo);
    }

    //setShowLogoPrompt(false)
  }

  const onSubmit = (slot, text) => {
    //console.info('onSubmit()')
    //console.info('  slot:', slot)
    //console.info('  text:', text)

    if (text === null) {
      setShowAiPrompt(null)
      return
    } else if (text.length === 0) {
      toast('AI Generated Textures', 'Decription should be provided', 'error')
      // console.info('  error:', text)
      return
    }

    if (text !== '--default') {
      storeGeneratorPipeline.createAIGeneratedOutfitTexture(outfits[slot].name, text, (res) => {
        if (res.status !== 'Ready') {
          //console.info('status:', res.status)
          return
        }

        setOutfits(slot, outfits.subtype, outfits[slot].name, res.data, res.texture_code)
        setShowAiPrompt(null)
      })
    } else {
      setOutfits(slot, outfits.subtype, outfits[slot].name, null, null)
      setShowAiPrompt(null)
    }
  }

  const isAiButtonEnabled = outfits['complect'].name !== 'ARARAT' && outfits['complect'].name !== 'LORI' ? true : false

  const renderTextureButton = (outfitKey, rightPosition) => (
    storeEditor.outfits[outfitKey].name && storeEditor.outfits[outfitKey].useTextureFromFile && (
      <span style={{ position: 'absolute', top: 15, right: rightPosition }}>
        <div
          disabled={!isAiButtonEnabled}
          onClick={() => setShowTextureFromImageDialog(outfitKey)}
          style={{
            fontWeight: 700,
            fontSize: '14px',
            background: 'linear-gradient(100deg, #7D1BF5 0%, #E32DD1 100%)',
            WebkitTextFillColor: 'transparent',
            WebkitBackgroundClip: 'text',
            border: 'none',
            cursor: isAiButtonEnabled ? 'pointer' : 'not-allowed',
            opacity: isAiButtonEnabled ? 1 : 0.5
          }}
        >
          Texture From Image
        </div>
      </span>
    )
  );

  const isLogoPromptModalWindowEnabled = (outfitKey) => 
    storeEditor.outfits[outfitKey].useLogo && storeEditor.outfits[outfitKey].texture_code === null;
  
  const renderLogoPromptModalWindow = (outfitKey, rightPosition) => (
    isLogoPromptModalWindowEnabled(outfitKey) ? (
      <LogoPromptModalWindow
        top={15}
        right={rightPosition}
        chose={storeEditor.outfits[outfitKey].logo !== null}
        onSubmit={(logo) => onSubmitLogo(logo, outfitKey)}
      />
    ) : null
  );
  
  const isLogoButtonEnabled = isLogoPromptModalWindowEnabled('complect') || isLogoPromptModalWindowEnabled('top');

  //console.log("showAiPrompt:", showAiPrompt)
  //if (showAiPrompt) console.log("outfits[showAiPrompt].texture", outfits[showAiPrompt].texture)

  if (variant === 'not-mobile') {
    return (
      <div style={mediaQueries.isTablet === false ? { height: '30vh' } : { height: '75%', overflowY: 'scroll' }}>
        {showAiPrompt ? (
          <AiPromptModalWindow
            isDefaultButtonHidden={{'complect': outfits['complect'].texture===null, 'top': outfits['top'].texture===null, 'bottom': outfits['bottom'].texture===null, 'shoes': outfits['shoes'].texture===null,}}
            slot={showAiPrompt}
            outfit={outfits[showAiPrompt].name}
            outfits={{
              complect: outfits['complect'].name,
              top: outfits['top'].name,
              bottom: outfits['bottom'].name,
            }}
            setShow={setShowAiPrompt}
            onSubmit={onSubmit}
          />
        ) : null}

        {showTextureFromImageDialog ? (
          <TextureFromImageDialog slot={showTextureFromImageDialog} variant={variant} onClose={() => setShowTextureFromImageDialog(null)} />
        ) : null}

        <WrapBox height={20}>
          {isAiButtonEnabled && (
            <Box_Without_BG>
              <Button type='button' disabled={!isAiButtonEnabled} onClick={(e) => setShowAiPrompt('complect')}>
                AI Generated Textures
              </Button>
            </Box_Without_BG>
          )}

          {hatPresets(gender).length > 0 && (
          <Box title='Hats'>
            <Presets
              presets={hatPresets(gender)}
              value={hat.name || 'None'}
              setValue={(hatName) => {
                  //console.log("hatName:", hatName)
                  //console.log("hat.name:", hat.name)
                  //console.log("isHatReady:", isHatReady)

//                  if (hatName !== hat.name && isHatReady) storeEditor.setHat(gender, hatName)
//                  if (isHatReady) {
                    storeEditor.setHat(gender, storeEditor.hat.name===null ? hatName : "None")
                    //if (storeEditor.hat.name) storeEditor.setHaircut(gender, null)
//                  }
                }
              }
            />
          </Box>)}

          {outfitNames[gender]['complect'].length > 0 && (
            <div style={{ position: 'relative', marginTop: 10 }}>
              <Box title='Complect'>
                {storeEditor.outfits["complect"].useLogo && storeEditor.outfits["complect"].texture_code === null ? (
                  <LogoPromptModalWindow top={20} right={20} chose={storeEditor.outfits["complect"].logo !== null} onSubmit={(logo) => onSubmitLogo(logo, "complect")} />
                ) : null}

                <Presets
                  presets={presets(gender, 'complect')}
                  value={outfits['complect'].name}
                  setValue={(outfitName) =>
                    outfitName !== outfits['complect'].name && isOutfitReady && setOutfits('complect', gender, outfitName)
                  }
                />

                <br />

                {storeEditor.outfits['complect'].name && storeEditor.outfits['complect'].texture_code === null && storeEditor.outfits['complect'].colorInitial !== '#000000' && (
                  <SliderPicker
                    color={storeEditor.outfits['complect'].color || '#ffffff'}
                    onChangeComplete={(color) => storeEditor.setOutfitsColor('complect', color.hex)}
                  />
                )}
              </Box>
            </div>)
          }

          {storeEditor.outfits['complect'].name && storeEditor.outfits['complect'].useTextureFromFile && (
            <div align="center" style={{ position: 'relative', marginTop: 10 }}>
              <Button type='button' onClick={(e) => setShowTextureFromImageDialog('complect')}>
                Texture From Image
              </Button>
            </div>
          )}

          {storeEditor.outfits['top'].name && storeEditor.outfits['top'].useTextureFromFile && (
            <div align="center" style={{ marginTop: 10 }}>
              <Button type='button' onClick={(e) => setShowTextureFromImageDialog('top')}>
                Texture From Image
              </Button>
            </div>
          )}

          {outfitNames[gender]['top'].length > 0 && (
            <div style={{ position: 'relative', marginTop: 10 }}>
              <Box title='Top'>
                {storeEditor.outfits["top"].useLogo && storeEditor.outfits["top"].texture_code === null ? (
                  <LogoPromptModalWindow top={20} right={20} chose={storeEditor.outfits["top"].logo !== null} onSubmit={(logo) => onSubmitLogo(logo, "top")} />
                ) : null}

                <Presets
                  presets={presets(gender, 'top')}
                  value={outfits['top'].name}
                  setValue={(outfitName) =>
                    outfitName !== outfits['top'].name && isOutfitReady && setOutfits('top', gender, outfitName)
                  }
                />

                <br />

                {storeEditor.outfits['top'].name && storeEditor.outfits['top'].texture_code === null && storeEditor.outfits['top'].colorInitial !== '#000000' && (
                  <SliderPicker
                    color={storeEditor.outfits['top'].color || '#ffffff'}
                    onChangeComplete={(color) => storeEditor.setOutfitsColor('top', color.hex)}
                  />
                )}
              </Box>
            </div>
          )}

          {outfitNames[gender]['bottom'].length > 0 && (
            <div style={{ position: 'relative', marginTop: 10 }}>
            <Box title='Bottom'>
              <Presets
                presets={presets(gender, 'bottom')}
                value={outfits['bottom'].name}
                setValue={(outfitName) =>
                  outfitName !== outfits['bottom'].name && isOutfitReady && setOutfits('bottom', gender, outfitName)
                }
              />

              <br />

              {storeEditor.outfits['bottom'].name && storeEditor.outfits['bottom'].texture_code === null && storeEditor.outfits['bottom'].colorInitial !== '#000000' && (
                <SliderPicker
                  color={storeEditor.outfits['bottom'].color || '#ffffff'}
                  onChangeComplete={(color) => storeEditor.setOutfitsColor('bottom', color.hex)}
                />
              )}
            </Box>
            </div>)}

          {outfitNames[gender]['shoes'].length > 0 && (
            <div style={{ position: 'relative', marginTop: 10 }}>
            <Box title='Shoes'>
              <Presets
                presets={presets(gender, 'shoes')}
                value={outfits['shoes'].name}
                setValue={(outfitName) =>
                  outfitName !== outfits['shoes'].name && isOutfitReady && setOutfits('shoes', gender, outfitName)
                }
              />

              <br />

              {storeEditor.outfits['shoes'].name && storeEditor.outfits['shoes'].texture_code === null && storeEditor.outfits['shoes'].colorInitial !== '#000000' && (
                <SliderPicker
                  color={storeEditor.outfits['shoes'].color || '#ffffff'}
                  onChangeComplete={(color) => storeEditor.setOutfitsColor('shoes', color.hex)}
                />
              )}
            </Box>
          </div>)}
        </WrapBox>
      </div>
    )
  }

  return (
    <div>
      {showAiPrompt ? (
        <AiPromptModalWindow
          isDefaultButtonHidden={{'complect': outfits['complect'].texture===null, 'top': outfits['top'].texture===null, 'bottom': outfits['bottom'].texture===null, 'shoes': outfits['shoes'].texture===null,}}
          slot={showAiPrompt}
          outfit={outfits[showAiPrompt].name}
          outfits={{
            complect: outfits['complect'].name,
            top: outfits['top'].name,
            bottom: outfits['bottom'].name,
          }}
          setShow={setShowAiPrompt}
          onSubmit={onSubmit}
        />
      ) : null}


      {showTextureFromImageDialog ? (
        <TextureFromImageDialog slot={showTextureFromImageDialog} variant={variant} onClose={() => setShowTextureFromImageDialog(null)} />
      ) : null}

      <WrapBox>
        <div style={{ position: 'relative' }}>
          <Box title={ isAiButtonEnabled && isLogoButtonEnabled && (storeEditor.outfits['complect'].useTextureFromFile || storeEditor.outfits['top'].useTextureFromFile) ? ' ' : 'Clothing' }>
            {renderLogoPromptModalWindow('complect', 130)}
            {renderLogoPromptModalWindow('top', 130)}

            {isAiButtonEnabled && (
              <span style={{ position: 'absolute', top: 15, right: 32 }}>
                <div
                  disabled={!isAiButtonEnabled}
                  onClick={(e) => setShowAiPrompt('complect')}
                  style={{
                    fontWeight: 700,
                    fontSize: '14px',
                    background: 'linear-gradient(100deg, #7D1BF5 0%, #E32DD1 100%)',
                    WebkitTextFillColor: 'transparent',
                    WebkitBackgroundClip: 'text',
                    border: 'none',
                    cursor: isAiButtonEnabled ? 'pointer' : 'not-allowed',
                    opacity: isAiButtonEnabled ? 1 : 0.5
                  }}
                >
                  Generate AI
                </div>
              </span>
            )}

            {renderTextureButton('complect', 132)}
            {renderTextureButton('top', isLogoButtonEnabled ? 239 : 130)}

            <MPresets
              presetsHat={hatPresets(gender)}
              presetsComplect={presets(gender, 'complect')}
              presetsTop={presets(gender, 'top')}
              presetsBottom={presets(gender, 'bottom')}
              presetsShoes={presets(gender, 'shoes')}
              value={[outfits['complect'].name, outfits['top'].name, outfits['bottom'].name, outfits['shoes'].name, hat.name || 'None']}
              setValue={(outfitName, cl) => {
                if (cl!=='hat')
                  outfitName !== outfits[cl].name && isOutfitReady && setOutfits(cl, gender, outfitName)
                else

//                  if (isHatReady)
//                    storeEditor.setHat(gender, storeEditor.hat.name===null ? outfitName : "None")

//                  if (isHatReady) {
                    //storeEditor.setHat(gender, storeEditor.hat.name===null ? outfitName : "None")
                    storeEditor.setHat(gender, storeEditor.hat.name===null ? hatName : "None")
//                    if (storeEditor.hat.name) storeEditor.setHaircut(gender, null)
//                  }

              }}
            />
          </Box>
        </div>
      </WrapBox>
    </div>
  )
})

EditorShirt.displayName = 'EditorShirt'

const Core = styled.div<Core>`
  position: fixed;
  z-index: 999;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(#000, 0);

  animation-duration: 1s;
  animation-fill-mode: forwards;
  opacity: 0;
  font-size: 10;
  overflow-y: auto;
  text-align: center;

  &::before {
    content: '';
    width: 0;
    height: 100%;
    display: inline-block;
    vertical-align: middle;
  }

  &.animation-opening {
    opacity: 0;
    animation-name: opening;
  }

  &.animation-closing {
    opacity: 1;
    animation-name: closing;
  }

  @keyframes opening {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  @keyframes closing {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
`

const Content = styled.div`
  width: calc(100% - 90px);
  padding-top: 40px;
  padding-bottom: 40px;
  max-width: 440px;

  position: relative;
  text-align: left;
  display: inline-block;
  vertical-align: middle;
`

const Body = styled.div`
  align: center;
  border: 2px solid gray;
  color: rgba(#000, 1);
  background-color: var(--color-bg-dark);
  border-radius: var(--size-radius);
  padding: 20px;
`

const Box_Without_BG = styled.div<CoreProps>`
  margin-bottom: 14px;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
`

const BodyTable = styled.tbody`
  display: block;
  width: 100%;
  overflow-y: auto;
  overflow-x: hidden;

  &::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    border-radius: 16px;
    background-color: grey;
  }

  &::-webkit-scrollbar {
    border-radius: 16px;
    width: 5px;
    background-color: grey;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 16px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: var(--color-base);
  }
`
const Progress = styled.div`
  text-align: center;
  margin-bottom: 12px;
  color: var(--color-base);
  margin-top: 12px;
`

const ProgressContainer = styled.div`
  width: 100%;
  height: 20px;
  background-color: #f2f2f2;
  border-radius: 10px;
  overflow: hidden;
  margin-bottom: 12px;
`
const ProgressDiv = styled.div`
  height: 100%;
  background-image: linear-gradient(102.67deg, #7d1bf5 0%, #e32dd1 99.48%);
  transition: width 0.3s ease-in-out;
`
