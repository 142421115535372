// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Article-Head-module--cls1--sb0aR { /*!*/ }\n\n.Article-Head-module--cls2--PjKb2 {\n  display: flex;\n  align-items: center;\n  justify-content: flex-start;\n  width: 100%;\n  margin-bottom: 28px;\n}", "",{"version":3,"sources":["webpack://./src/views/page/apps/Article-Head.module.scss"],"names":[],"mappings":"AAAA,oCAAA,IAAA,EAAA;;AACA;EAGA,aAAA;EACA,mBAAA;EACA,2BAAA;EACA,WAAA;EACA,mBAAA;AACA","sourcesContent":[".cls1 { /*!*/ }\n.cls2 {\ncomposes: cls1;\n\ndisplay: flex;\nalign-items: center;\njustify-content: flex-start;\nwidth: 100%;\nmargin-bottom: 28px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"cls1": "Article-Head-module--cls1--sb0aR",
	"cls2": "Article-Head-module--cls2--PjKb2 Article-Head-module--cls1--sb0aR"
};
export default ___CSS_LOADER_EXPORT___;
