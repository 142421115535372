// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".NoPhoto-Avatar-module--cls1--zgcje { /*!*/ }\n\n.NoPhoto-Avatar-module--cls2--_OF0l {\n  position: relative;\n  width: 100%;\n  cursor: pointer;\n}", "",{"version":3,"sources":["webpack://./src/views/page/generator/sections/NoPhoto-Avatar.module.scss"],"names":[],"mappings":"AAAA,sCAAA,IAAA,EAAA;;AACA;EAGA,kBAAA;EACA,WAAA;EACA,eAAA;AACA","sourcesContent":[".cls1 { /*!*/ }\n.cls2 {\ncomposes: cls1;\n\nposition: relative;\nwidth: 100%;\ncursor: pointer;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"cls1": "NoPhoto-Avatar-module--cls1--zgcje",
	"cls2": "NoPhoto-Avatar-module--cls2--_OF0l NoPhoto-Avatar-module--cls1--zgcje"
};
export default ___CSS_LOADER_EXPORT___;
