export const avatarHead_fragment2 = `
  vec2 vUv = vMapUv;

  vec4 head    = texture2D(uHead,    vUv);
  vec4 haircut = texture2D(uHaircut, vUv);
  vec4 scalp   = texture2D(uScalp,   vUv);
  vec4 masks   = texture2D(uHeadMasks,   vUv);

  mat3 R = masks.r * uLipsR + masks.g * uEyebrowsR + (1. - masks.r - masks.g)*uR;
  head.rgb = clamp(head.rgb*R, 0., 1.);

  if (uUseGeneratedHaircut) {
    if (uUseGeneratedHaircutRecoloring)
      haircut.rgb = clamp( mix(haircut.rgb + uTintCoeff*uColorTint, haircut.rgb, uDarkeningCoeff), 0., 1.);

    diffuseColor = vec4( mix(head.rgb, haircut.rgb, haircut.a), 1.);
  } else {

    float scalpAlpha = scalp.a;

    if (uUseScalpRecoloring) {
      scalp.rgb *= uScalpColor;
      scalp.rgb = 1. - (1. - scalp.rgb) * (1. - uScalpColor*0.1);
    }

    diffuseColor = vec4( mix(head.rgb, scalp.rgb, scalpAlpha), head.a);
  }
`
