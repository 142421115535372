import React, { FC, useState } from 'react'
import styled from 'astroturf/react'
import { css } from 'astroturf'
import { HexColorPicker } from 'react-colorful'
import { useLayoutContext } from '@/views/components/core/LayoutProvider'

export interface ColorPickerProps {
  color?: string
  setColor: (arg: string) => void
  presets: string[]
}

export const ColorPicker: FC<ColorPickerProps> = ({ setColor, color, presets = [] }) => {
  const [openPicker, setOpenPicker] = useState(false)
  const { mediaQueries } = useLayoutContext()
  const variant = mediaQueries.isTablet ? 'not-mobile' : 'mobile'
  return (
    <Core variant={variant}>
      <Presets variant={variant}>
        {presets.map((item) => (
          <Preset
            key={item}
            active={item === color && !openPicker}
            onClick={() => {
              setOpenPicker(() => false)
              setColor(item)
            }}
            css={css`
              background-color: ${item};
            `}
          />
        ))}
        <Preset
          onClick={() => setOpenPicker(() => true)}
          active={openPicker}
          css={css`
            background-image: linear-gradient(102.67deg, #1bf5ce 0%, #a9e32d 99.48%);
          `}
        />
      </Presets>
      {openPicker && <HexColorPicker color={color} onChange={setColor} />}
    </Core>
  )
}

export interface Variant {
  variant: 'not-mobile' | 'mobile'
}

const Core = styled.div<Variant>`
  width: 100%;
  &.variant-mobile {
    display: flex;
    flex-direction: column-reverse;

    :global(.react-colorful) {
      margin-bottom: 16px;
      margin-top: 16px;
    }
  }
  &.variant-not-mobile {
    :global(.react-colorful) {
      margin-top: 16px;
    }
  }

  :global(.react-colorful) {
    width: 100%;
    display: block;
    height: auto;
  }

  :global(.react-colorful__alpha-gradient),
  :global(.react-colorful__saturation) {
    border-radius: var(--size-radius-small);
    height: 108px;
    box-shadow: none;
  }

  :global(.react-colorful__hue) {
    margin-top: 12px;
    height: 16px;
    border-radius: 8px;
  }

  :global(.react-colorful__hue-pointer) {
    width: 16px;
    height: 16px;
  }

  :global(.react-colorful__saturation-pointer) {
    width: 16px;
    height: 16px;
  }
`

const Presets = styled.div<Variant>`
  gap: 8px;

  &.variant-mobile {
    display: flex;
    overflow-x: auto;
    width: 100%;
  }

  &.variant-not-mobile {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
  }
`
export interface PresetsProps {
  active: boolean
}

const Preset = styled.button<PresetsProps>`
  @import '../../../../../assets/styles/mixins.scss';
  width: 24px;
  height: 24px;
  flex-shrink: 0;
  border-radius: 100%;
  @include anim(box-shadow);

  &.active {
    box-shadow: inset 0 0 0 2px var(--color-base), inset 0 0 0 6px var(--color-bg-main);
  }
`
