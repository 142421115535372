// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".index-Msg-module--cls1--Kfv6v { /*!*/ }\n\n.index-Msg-module--cls2--AHG4d {\n  color: var(--color-base);\n  font-weight: 500;\n  font-size: 12px;\n  line-height: 12px;\n  margin-top: 6px;\n  opacity: 0.5;\n}", "",{"version":3,"sources":["webpack://./src/services/adapters/toast/index-Msg.module.scss"],"names":[],"mappings":"AAAA,iCAAA,IAAA,EAAA;;AACA;EAGA,wBAAA;EACA,gBAAA;EACA,eAAA;EACA,iBAAA;EACA,eAAA;EACA,YAAA;AACA","sourcesContent":[".cls1 { /*!*/ }\n.cls2 {\ncomposes: cls1;\n\ncolor: var(--color-base);\nfont-weight: 500;\nfont-size: 12px;\nline-height: 12px;\nmargin-top: 6px;\nopacity: 0.5;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"cls1": "index-Msg-module--cls1--Kfv6v",
	"cls2": "index-Msg-module--cls2--AHG4d index-Msg-module--cls1--Kfv6v"
};
export default ___CSS_LOADER_EXPORT___;
