// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Age-Form-module--cls1--gi3IK { /*!*/ }\n\n.Age-Form-module--cls2--Won91 {\n  margin-top: 32px;\n  max-width: 360px;\n  width: 100%;\n  margin-left: auto;\n  margin-right: auto;\n}", "",{"version":3,"sources":["webpack://./src/views/page/generator/sections/Age-Form.module.scss"],"names":[],"mappings":"AAAA,gCAAA,IAAA,EAAA;;AACA;EAGA,gBAAA;EACA,gBAAA;EACA,WAAA;EACA,iBAAA;EACA,kBAAA;AACA","sourcesContent":[".cls1 { /*!*/ }\n.cls2 {\ncomposes: cls1;\n\nmargin-top: 32px;\nmax-width: 360px;\nwidth: 100%;\nmargin-left: auto;\nmargin-right: auto;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"cls1": "Age-Form-module--cls1--gi3IK",
	"cls2": "Age-Form-module--cls2--Won91 Age-Form-module--cls1--gi3IK"
};
export default ___CSS_LOADER_EXPORT___;
