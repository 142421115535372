// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ProgressBar-ProgressDiv-module--cls1--UbLhz { /*!*/ }\n\n.ProgressBar-ProgressDiv-module--cls2--YSGtG {\n  height: 100%;\n  background-image: linear-gradient(102.67deg, #7d1bf5 0%, #e32dd1 99.48%);\n  transition: width 0.3s ease-in-out;\n}", "",{"version":3,"sources":["webpack://./src/components/ui/ProgressBar-ProgressDiv.module.scss"],"names":[],"mappings":"AAAA,+CAAA,IAAA,EAAA;;AACA;EAGA,YAAA;EACA,wEAAA;EACA,kCAAA;AACA","sourcesContent":[".cls1 { /*!*/ }\n.cls2 {\ncomposes: cls1;\n\nheight: 100%;\nbackground-image: linear-gradient(102.67deg, #7d1bf5 0%, #e32dd1 99.48%);\ntransition: width 0.3s ease-in-out;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"cls1": "ProgressBar-ProgressDiv-module--cls1--UbLhz",
	"cls2": "ProgressBar-ProgressDiv-module--cls2--YSGtG ProgressBar-ProgressDiv-module--cls1--UbLhz"
};
export default ___CSS_LOADER_EXPORT___;
