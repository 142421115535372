// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".RangeSlider-Plus-module--cls1--YLFJI { /*!*/ }\n\n.RangeSlider-Plus-module--cls2--h11Ya {\n  margin-left: 10px;\n  margin-top: 1px;\n  color: var(--color-base);\n}", "",{"version":3,"sources":["webpack://./src/views/page/generator/sections/components/RangeSlider-Plus.module.scss"],"names":[],"mappings":"AAAA,wCAAA,IAAA,EAAA;;AACA;EAGA,iBAAA;EACA,eAAA;EACA,wBAAA;AACA","sourcesContent":[".cls1 { /*!*/ }\n.cls2 {\ncomposes: cls1;\n\nmargin-left: 10px;\nmargin-top: 1px;\ncolor: var(--color-base);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"cls1": "RangeSlider-Plus-module--cls1--YLFJI",
	"cls2": "RangeSlider-Plus-module--cls2--h11Ya RangeSlider-Plus-module--cls1--YLFJI"
};
export default ___CSS_LOADER_EXPORT___;
