import React, { FC, PropsWithChildren } from 'react'
import styled from 'astroturf/react'
import { useLayoutContext } from '@/views/components/core/LayoutProvider'


export const WrapBox: FC<PropsWithChildren<unknown>> = ({ children, height = 45 }) => {
  const { mediaQueries } = useLayoutContext()
  const variant = mediaQueries.isTablet ? 'not-mobile' : 'mobile'
  return <Core variant={variant} maxheight={height}>{children}</Core>
}

export interface CoreProps {
  variant: 'not-mobile' | 'mobile'
  maxheight?: string
}

const Core = styled.div<CoreProps>`
  &.variant-mobile {
    max-height: ${props => props.maxheight || '45'}vh;
    overflow-y: auto;
  }
`