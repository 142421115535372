import React, { FC, useState, useRef, useEffect, useCallback } from 'react'
import styled from 'astroturf/react'

import { AvatarViewer, defaultParams } from '@/avatarViewer/index.js'
import { SVGIcon, ApiKeysUnion } from '@/helpers/types'
import { Loader } from '@/components/ui'
import { storeGeneratorPipeline } from '@/services/store/generatorPipeline'
import { cameraPositions } from '../cameraPositions'
import { FpsCounter } from '@/views/page/generator/sections/components/FpsCounter.js'

import { storeEditor } from '@/services/store'
import { observer } from 'mobx-react-lite'

import { Button } from '@/components/ui'

export type Components = 'Color' | 'Face' | 'Body' | 'Hairs' | 'Glasses' | 'Shirt'

export interface Acc {
  apiKey: ApiKeysUnion
  icon: SVGIcon
  component: FC
}

export interface ViewerWrapProps {
  variant: 'not-mobile' | 'mobile'
}

export const ViewerWrap: FC<ViewerWrapProps> = observer(({ variant }) => {
  //console.log("!!! ViewerWrap()")
  const versionAvatar = storeEditor.versionAvatar  // to rerender after observable versionAvatar increment

  const viewerRef = useRef(null)

  const {
    setCamReady,
    setModelReady,
    setHaircutReady,
    setGlassesReady,
    setHatReady,
    setOutfitReady,
    isCamReady,
    isModelReady,
    isHaircutReady,
    isGlassesReady,
    isHatReady,
    isOutfitReady,
    isReady,
    setViewer,
  } = storeEditor

  const { avatar } = storeGeneratorPipeline
  const { haircut, outfits, glasses, hat, animation } = storeEditor

  const haircut2 = {...haircut}

  //if (hat.name==="Ghutra")
    //haircut2.preset = "Bald"

  //console.log("hat:", hat)
  //console.log("haircut2:", haircut2)

  const [params, setParams] = useState(() => {
    const params = {
      ...defaultParams,
      camera: { ...defaultParams.camera, ...cameraPositions('BODY', variant === 'not-mobile') },
    }
    return params
  })

  let [displayFpsCounter, setDisplayFpsCounter] = useState(process.env.USE_DEBUG === '1' ? true : false)

  useEffect(() => {
    //console.log('useEffect() - addEventListener()')

    addEventListener('keydown', (event) => {
      //console.log('Key pressed, event.code:', event.code)

      if (event.code === 'KeyD' && event.shiftKey) {
        //console.log(`setDisplayFpsCounter() from ${displayFpsCounter} to ${!displayFpsCounter}`)
        setDisplayFpsCounter(!displayFpsCounter)
        displayFpsCounter = !displayFpsCounter
      }

      if (process.env.USE_MULTIPASS_HAIRCUT_RENDER === '1' && process.env.USE_DEBUG === '1') {
        if (event.code === 'Digit1') {
          storeEditor.setHaircutRenderPasses(1)
        }

        if (event.code === 'Digit2') {
          storeEditor.setHaircutRenderPasses(2)
        }

        if (event.code === 'Digit3') {
          storeEditor.setHaircutRenderPasses(3)
        }
      }
    })
  }, [])

  useEffect(() => {
    if (viewerRef?.current) {
      setViewer(viewerRef.current)
    }
  }, [viewerRef?.current])

  //  useEffect(() => {
  //console.log("!!!!!!!!!! ViewerWrap useEffect()")
  //  }, [])

  const onDownloadResource = useCallback(async (type: string, name: string) => {
    //console.log('onDownloadResource():', type, name)

    try {
      //if (type==='hat') console.log('/' + type + 's/' + name)

      if (type === 'haircut') {
        return storeGeneratorPipeline.getHaircut(name)
      }

      if (type === 'glasses') {
        return storeGeneratorPipeline.getGlasses(name)
      }

      if (type === 'hat') {
        return storeGeneratorPipeline.getHat(name)
      }

      const res = await fetch('/' + type + 's/' + name)
        .then((r) => r.blob())
        .catch((error) => {
          'ERROR: fetch()', error
        })

      //if (type==='hat') console.log("res:", res)
      return res
    } catch (error) {
      // console.info('ERROR: onDownloadResource(), error:', error)
    }
    return null
  }, [])

  const setReady = (item, isReady, data = null) => {
    //console.log("setReady():", item, isReady, data)

    switch (item) {
      case 'camera':
        setCamReady(isReady)
        break

      case 'avatar':
        if (data) {
          storeEditor.setInitialAnimationName(data.animationInitial)
          storeEditor.setAnimation(storeGeneratorPipeline.preSettings.gender, storeEditor.initialAnimationName)
        }

        haircut.color = haircut.colorInitial = data ? data.hairColorInitial : null

        if (data) {
          storeGeneratorPipeline.avatar.skinColor = storeGeneratorPipeline.avatar.skinColorInitial = data.skinColorInitial
          storeGeneratorPipeline.avatar.eyebrowsColor = storeGeneratorPipeline.avatar.eyebrowsColorInitial = data.eyebrowsColorInitial
          storeGeneratorPipeline.avatar.lipsColor = storeGeneratorPipeline.avatar.lipsColorInitial = data.lipsColorInitial
        }

        if (data?.isBald) {
          storeEditor.haircut.preset = 'Bald'
        }

        if (data?.height) {
          storeGeneratorPipeline.avatar.height = data.height

          const scale = storeGeneratorPipeline.avatarState.scale

          if (scale===undefined) {
            storeGeneratorPipeline.setAvatarHeight(data.height)
            storeEditor.setBody({ height: [data.height] })
          } else {
            storeEditor.setBody({ scale: [scale] })
          }

          storeEditor.setAge(storeGeneratorPipeline.avatarState.age)
          storeEditor.changePosition({ ...cameraPositions(variant === 'not-mobile' ? 'BODY' : 'EditorAge', variant === 'not-mobile'), noAnimate: true });

          if (storeGeneratorPipeline.avatarState.age!=="Adult") {
            storeEditor.setBody({'shoulders': [0]})
            storeEditor.setBody({'chest': [0]})
            storeEditor.setBody({'hips': [0]})
          }
        }

        setModelReady(isReady)
        storeEditor.needsUpdate(true)

        break

      case 'haircut':
        //console.log(`setHaircutReady(${isReady})`)
        setHaircutReady(isReady)
        storeEditor.needsUpdate(true)
        break

      case 'glasses':
        setGlassesReady(isReady)
        storeEditor.needsUpdate(true)
        break

      case 'hat':
        setHatReady(isReady)
        storeEditor.needsUpdate(true)
        break

      case 'outfit':
        //console.log('data:', data)

        if (data) {
          for (const section of ['complect', 'top', 'bottom', 'shoes']) {
            if (data.colors && data.colors[section])
              storeEditor.outfits[section].color = data.colors[section]

            storeEditor.outfits[section].colorInitial = data.infos[section] ? data.infos[section].colorInitial : null
            storeEditor.outfits[section].useLogo = data.infos[section] ? data.infos[section].useLogo : false
            storeEditor.outfits[section].logoPlacement = data.infos[section] ? data.infos[section].logoPlacement : [0, 0, 0, 0]
            storeEditor.outfits[section].useTextureFromFile = data.infos[section] ? data.infos[section].useTextureFromFile : false
          }
        }

        setOutfitReady(isReady)
        storeEditor.needsUpdate(true)
        break
    }
  }

  //console.log("!!! ViewerWrapProps render")
  //console.log("  avatar=",  avatar)
  //console.log("  outfits=", outfits)
  //console.log("  glasses=", glasses)
  //console.log("  iscamReady:", isCamReady)
  //console.log("  isOutfitReady:", isOutfitReady)

  //const toggleAnimation = () => {
  //  console.log("toggleAnimation()")
  //  setAnimation()
  //}

  //<Button onClick={toggleAnimation}> Animation  </Button>

  //      <ExtendedViewer isHidden={!isCamReady || !isOutfitReady}>

  //console.log("isCamReady:", isCamReady, "isModelReady:", isModelReady, "isHaircutReady:", isHaircutReady, "isGlassesReady:", isGlassesReady, "isHatReady:", isHatReady, "isOutfitReady:", isOutfitReady)

  //const isReady = isCamReady && isModelReady && isHaircutReady && isGlassesReady && isOutfitReady

  //console.log("  displayFpsCounter:", displayFpsCounter)

  return (
    <Core variant={variant}>
      {(process.env.USE_MULTIPASS_HAIRCUT_RENDER === '1') && (
        <FpsCounter
          displayFpsCounter={displayFpsCounter}
          variant={variant}
          qualityLevel={haircut.renderPasses}
          setQualityLevel={(newQualityLevel) => {
            storeEditor.setHaircutRenderPasses(newQualityLevel)
          }}
        />
      )}

      <AvatarViewer
        ref={viewerRef}
        avatar={avatar}
        haircut={haircut2}
        glasses={glasses}
        hat={hat}
        outfits={outfits}
        animation={animation}
        params={params}
        onDownloadResource={onDownloadResource}
        setReady={setReady}
      />
      {!isReady() && <Loader spinner='circle' variant='overlay' />}
    </Core>
  )
})

export interface Variant {
  variant: 'not-mobile' | 'mobile'
}

const Core = styled.div<Variant>`
  width: 100%;
  &.variant-mobile {
    height: var(--app-height);
  }

  &.variant-not-mobile {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
  }
`

export interface ExtendedViewer {
  isHidden: boolean
}

const ExtendedViewer = styled.div<ExtendedViewer>`
  @import '../../../../../../assets/styles/mixins.scss';
  @include anim(opacity);
  height: 100%;

  &.isHidden {
    opacity: 0;
  }
`
