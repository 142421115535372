import React from 'react'
import styled from 'astroturf/react'

import { Textfield, Button } from '@/components/ui'
import { TitleSection } from '@/views/components/common'
import { useForm } from 'react-hook-form'
import { Home } from '@/components/ui/icons'
import { useNavigate } from "react-router-dom";


export const Confirmation = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm()
  const navigate = useNavigate();
  const navigateTo = () => {
    navigate('/')
  }
  const onSubmit = (data) => console.info(data)
  return (
    <>
    <Header>
        <Button isOnlyIcon variant='surface' onClick={navigateTo}>
          <Home />
          </Button>
      </Header>
      <MiddleContent>
        <TitleSection
          title='Confirm your Email'
          descr="Confirm your email address so you don't lose access to your account. And to verify."
        />
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Textfield
            wSize='full'
            error={errors}
            placeholder='Email Address'
            {...register('login', { required: 'Email Address is required' })}
          />
          <Button type='submit' wSize='full'>
            Continue with Email
          </Button>
        </Form>
      </MiddleContent>
      <Msg>By pressing «Continue with Email» you agree to the Privacy Policy and our Terms</Msg>
    </>
  )
}

const MiddleContent = styled.div`
  margin-top: auto;
  margin-bottom: auto;
  max-width: 360px;
  width: 100%;
`

const Form = styled.form`
  margin-top: 32px;
  margin-left: auto;
  margin-right: auto;
`

const Msg = styled.p`
  color: var(--color-text-gray);
  font-size: 13px;
  padding-bottom: 40px;
  text-align: center;
  font-weight: 500;
  font-size: 13px;
  line-height: 150%;
  max-width: 270px;
  margin-left: auto;
  margin-right: auto;
`
const Header = styled.div`
  display: flex;
  width: calc(100% - 64px);
  margin-left: auto;
  margin-right: auto;
  padding-top: 32px;
  position: absolute;
  justify-content: space-between;
  top: 0;
  left: 32px;
  z-index: 5;
`