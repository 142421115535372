import React, { useEffect } from 'react'
import styled from 'astroturf/react'
import { Typography } from '@/components/ui'
import { AppsCard } from './components'
import { Row, Col } from 'react-grid-system'

import icon1 from '@/assets/demo/apps-1.svg'
import icon2 from '@/assets/demo/apps-2.svg'

import { useLayoutContext } from '@/views/components/core/LayoutProvider'

export const Apps = () => {
  const { setDisplayBG } = useLayoutContext()

  useEffect(() => {
    setDisplayBG(true)
    return () => setDisplayBG(false)
  }, [])

  return (
    <>
      <Head>
        <Typography as='h1' variant='h2'>
          Discover apps
        </Typography>
      </Head>
      <AppsCard
        icon={icon1}
        title={'Mozilla Hubs'}
        description={'VRChat makes it easy to create and explore virtual reality together'}
      ></AppsCard>
      <Row>
        <ColWithOffset lg={6}>
          <AppsCard
            icon={icon2}
            title={'Mozilla Hubs'}
            description={'VRChat makes it easy to create and explore virtual reality together'}
          ></AppsCard>
        </ColWithOffset>
        <ColWithOffset lg={6}>
          <AppsCard
            icon={icon2}
            title={'Mozilla Hubs'}
            description={'VRChat makes it easy to create and explore virtual reality together'}
          ></AppsCard>
        </ColWithOffset>
      </Row>
    </>
  )
}

const Head = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 28px;
`

const ColWithOffset = styled(Col)`
  margin-top: 24px;
`
