/**
 * @file working with AvatarBody model
 */

import React from 'react'

import * as THREE from 'three'
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader'

import { proceedZip, modelHeight } from '../helpers/prepareModel'

import { avatarHead_fragment1 } from '../shaders/avatarHead_fragment1.glsl'
import { avatarHead_fragment2 } from '../shaders/avatarHead_fragment2.glsl'

import { avatarBody_fragment1 } from '../shaders/avatarBody_fragment1.glsl'
import { avatarBody_fragment2 } from '../shaders/avatarBody_fragment2.glsl'

class AvatarBody {
  ref = null

  id = 0
  zip = null

  info = null
  nodes = null
  materials = null
  animations = []

  needToApplyVisibilityMasks = false

  useGeneratedHaircut = false

  haircutColor = null
  scalpColor = null
  skinColor = null
  lipsColor = null
  eyebrowsColor = null

  height = 0
  scale = 1
  headScale = 1

  uniforms = {}

  version = 0
  busy = false

  /**
   * Constructor
   */

  constructor() {
    this.uniforms.uBody = { value: null }
    this.uniforms.uHead = { value: null }
    this.uniforms.uHaircut = { value: null }
    this.uniforms.uScalp = { value: null }
    this.uniforms.uHeadMasks = { value: null }

    this.uniforms.uUseGeneratedHaircut = { value: false }

    this.uniforms.uUseGeneratedHaircutRecoloring = { value: false }
    this.uniforms.uColorTint = { value: new THREE.Vector3(0, 0, 0) }
    this.uniforms.uTintCoeff = { value: 0.8 }
    this.uniforms.uDarkeningCoeff = { value: 0.0 }

    this.uniforms.uUseScalpRecoloring = { value: false }
    this.uniforms.uScalpColor = { value: new THREE.Vector3(0, 0, 0) }

    this.uniforms.uUseSkinRecoloring = { value: false }
    //this.uniforms.uSkinColor = { value: new THREE.Vector3(0, 0, 0) }
    this.uniforms.uR = { value: new THREE.Matrix3(1, 0, 0, 0, 1, 0, 0, 0, 1) }
    this.uniforms.uScale = { value: 1 }

    this.uniforms.uUseLipsRecoloring = { value: false }
    this.uniforms.uLipsColor = { value: new THREE.Vector3(0, 0, 0) }
    this.uniforms.uLipsR = { value: new THREE.Matrix3(1, 0, 0, 0, 1, 0, 0, 0, 1) }
    this.uniforms.uLipsScale = { value: 1 }

    this.uniforms.uUseEyebrowsRecoloring = { value: false }
    this.uniforms.uEyebrowsColor = { value: new THREE.Vector3(0, 0, 0) }
    this.uniforms.uEyebrowsR = { value: new THREE.Matrix3(1, 0, 0, 0, 1, 0, 0, 0, 1) }
    this.uniforms.uEyebrowsScale = { value: 1 }
  }

  /**
   * Clear model
   */

  clearModel = () => {
    //console.log("clearModel()")

    if (this.nodes)
      Object.entries(this.nodes).forEach(([key, value]) => {
        if (value.geometry) value.geometry.dispose()
      })

    if (this.materials)
      Object.entries(this.materials).forEach(([key, value]) => {
        value.dispose()
      })

    for (const texture_name of ['uBody', 'uHead', 'uHaircut']) {
      if (this.uniforms[texture_name].value) this.uniforms[texture_name].value.dispose()
      this.uniforms[texture_name].value = null
    }

    this.nodes = null
    this.materials = null
    this.animations = []

    this.selectedAnimation = null

    this.haircutColor = null
    this.scalpColor = null
    this.skinColor = null
    this.lipsColor = null
    this.eyebrowsColor = null

    this.useGeneratedHaircut = false

    this.uniforms.uUseScalpRecoloring = { value: false }

    this.uniforms.uR = { value: new THREE.Matrix3(1, 0, 0, 0, 1, 0, 0, 0, 1) }
    this.uniforms.uLipsR = { value: new THREE.Matrix3(1, 0, 0, 0, 1, 0, 0, 0, 1) }
    this.uniforms.uEyebrowsR = { value: new THREE.Matrix3(1, 0, 0, 0, 1, 0, 0, 0, 1) }

    this.id = 0
    this.zip = null

    this.scale = 1
    this.headScale = 1
  }

  /**
   * Prepare AvatarBody
   *
   * param avatar             - .zip blob uri with model and some textures data
   * param onDownloadResource - callback to load absend in .zip textures data
   * param haircutColor       - color to recoloring generated haircut
   */

  getInitialAnimation() {
    const animationKey = this.info.pipeline_subtype === 'male' ? 'Male_Idle' : 'Female_Idle'
    if (this.animations.hasOwnProperty(animationKey)) {
      return animationKey
    }
    return 'Bashful'
  }

  getAnimation() {
    const animationKey = this.getInitialAnimation(this.info.pipeline_subtype)
    return this.animations[animationKey]
  }

  cloneHead() {
    const head1 = this.nodes['AvatarHead']

    const head0 = head1.clone()
    head0.geometry = head1.geometry.clone()
    this.nodes['AvatarHead1'] = head1
    this.nodes['AvatarHead'] = head0

    head1.name = 'AvatarHead1'
    head1.visible = false

    const position = head1.geometry.getAttribute('position')
    const normal = head1.geometry.getAttribute('normal')

    for (let i = 0; i < head1.geometry.attributes.position.array.length; ++i) {
      position.array[i] += head1.geometry.morphAttributes.position[0].array[i]
      if (head1?.geometry?.morphAttributes?.normal) normal.array[i] += head1.geometry.morphAttributes.normal[0].array[i]
    }
    const animation = this.getAnimation()
    const tracks = animation.tracks

    const track = tracks.find((elem) => elem.name === 'AvatarHead.morphTargetInfluences')
    if (track) {
      const newTrack = track.clone()
      newTrack.name = 'AvatarHead1.morphTargetInfluences'
      tracks.push(newTrack)
    }
  }

  prepareAvatarBody = async (avatar, onDownloadResource, haircutColor) => {
    //console.log('prepareAvatarBody()')
    //console.log('avatar:', avatar)
    //console.log('haircutColor:', haircutColor)
    //console.log('  performance.memory.usedJSHeapSize, MB:', performance.memory.usedJSHeapSize / 1048576)

    this.busy = true

    this.clearModel()
    this.id = avatar.id
    this.zip = avatar.zip

    this.height = modelHeight(avatar.subtype)

    const blobs = await proceedZip(avatar.zip)
    //console.log('blobs:', blobs)

    this.info = await fetch(blobs['model.json'])
      .then((res) => res.text())
      .then((data) => {
        return JSON.parse(data)
      })

    const model = await fetch(blobs['model.gltf'])
      .then((res) => res.text())
      .then((data) => {
        return JSON.parse(data)
      })

    if (blobs['model.bin']) model.buffers[0].uri = blobs['model.bin']
    if (blobs['animations.bin']) model.buffers[1].uri = blobs['animations.bin']

    const loader = new THREE.TextureLoader()

    for (let i = 0; i < model.images.length; ++i) {
      const uri = model.images[i].uri
      //console.log('uri:', uri)

      if (uri.includes('AvatarBody') && uri.includes('Color')) this.uniforms.uBody.value = loader.load(blobs[uri])
      if (uri.includes('AvatarHead') && uri.includes('Color')) this.uniforms.uHead.value = loader.load(blobs[uri])
      if (uri.includes('AvatarHead_Masks')) this.uniforms.uHeadMasks.value = loader.load(blobs[uri])

      if (uri.includes('HaircutGenerated') && uri.includes('Color'))
        this.uniforms.uHaircut.value = loader.load(blobs[uri])

      model.images[i].uri = blobs[model.images[i].uri]
        ? blobs[model.images[i].uri]
        : URL.createObjectURL(await onDownloadResource('texture', model.images[i].uri))
    }

    //console.log('model.images:', model.images)

    //console.log('this.uniforms.uHaircut.value:', this.uniforms.uHaircut.value)

    for (const texture_name of ['uBody', 'uHead', 'uHaircut']) {
      if (this.uniforms[texture_name].value) {
        avatar.isBald = false
        this.uniforms[texture_name].value.flipY = false
        //        this.uniforms[texture_name].value.encoding = THREE.sRGBEncoding
        this.uniforms[texture_name].value.colorSpace = THREE.SRGBColorSpace
      } else {
        avatar.isBald = true
      }
    }

    if (this.uniforms['uHeadMasks'].value) {
      this.uniforms['uHeadMasks'].value.flipY = false
      //      this.uniforms['uHeadMasks'].value.encoding = THREE.sRGBEncoding
    }

    //this.uniforms['uHeadMasks'].value = this.uniforms['uHead'].value

    this.recoloringScalp(null)
    this.uniforms.uScalp.value = null

    this.useGeneratedHaircut = false
    this.uniforms.uUseGeneratedHaircut.value = false
    this.uniforms.uUseGeneratedHaircutRecoloring.value = false

    this.uniforms.uUseSkinRecoloring.value = false

    // prepare model

    const url = URL.createObjectURL(new Blob([JSON.stringify(model, null, 2)], { type: 'text/plain' }))

    var gltfLoader = new GLTFLoader()
    const gltf = await gltfLoader.loadAsync(url)
    //console.log('gltf:', gltf)

    const nodes = await gltf.parser.getDependencies('node')
    const materials = await gltf.parser.getDependencies('material')

    this.nodes = {}
    nodes.forEach((item) => {
      this.nodes[item.name] = item
    })

    this.materials = {}
    materials.forEach((material) => {
      if (material.name === 'AvatarHead' || material.name === 'AvatarBody') {
        material.onBeforeCompile = (shader) => {
          shader.fragmentShader = shader.fragmentShader
            .replace(
              '#include <clipping_planes_pars_fragment>',
              '#include <clipping_planes_pars_fragment>\n' +
                (material.name === 'AvatarHead' ? avatarHead_fragment1 : avatarBody_fragment1),
            )
            .replace(
              'vec4 diffuseColor = vec4( diffuse, opacity );',
              'vec4 diffuseColor = vec4( diffuse, opacity );\n' +
                (material.name === 'AvatarHead' ? avatarHead_fragment2 : avatarBody_fragment2),
            )
            .replace('#include <map_fragment>', '//#include <map_fragment>')

          shader.uniforms = { ...shader.uniforms, ...this.uniforms }

          //console.log('shader:', shader)
        }
      }

      this.materials[material.name] = material
    })

    this.animations = {}
    gltf.animations.forEach((item) => {
      this.animations[item.name] = item
    })

    this.cloneHead()

    this.setHaircutedHeadTexture(true)

    if (process.env.USE_ARTIFICIALEYES === '1') {
      // change corneas of eyes

      const curMaterial = new THREE.MeshPhysicalMaterial({
        roughness: 0.05,
        envMapIntensity: 0.9,
        clearcoat: 1,
        transparent: true,
        opacity: 0.75,
        reflectivity: 0.7,
        ior: 0.95,
        specularColor: 0xffffff,
        color: 0xffffff,
        transmission: 1,
        metalness: 0.1,
      })

      this.materials['AvatarLeftCornea'] = curMaterial
      this.materials['AvatarRightCornea'] = curMaterial
    }

    this.materials['AvatarHead'].side = THREE.DoubleSide
    this.materials['AvatarEyelashes'].side = THREE.DoubleSide

    this.materials['AvatarLeftCornea'].depthWrite = true
    this.materials['AvatarRightCornea'].depthWrite = true

    //    const avatarHead1 = this.nodes['AvatarHead1'].geometry
    //    const avatarLeftCornea = this.nodes['AvatarLeftCornea'].geometry
    //    const avatarRightCornea = this.nodes['AvatarRightCornea'].geometry

    //    console.log("avatarHead1:", avatarHead1)
    //    console.log("avatarLeftCornea:", avatarLeftCornea)

    //    avatarLeftCornea.boundingSphere.radius = 2
    //    avatarLeftCornea.boundingBox.min.y = 0
    //    avatarLeftCornea.boundingBox.max.y = 2

    //    model.children[2].computeBoundingSphere(); // AvatarLeftCornea
    //    model.children[4].computeBoundingSphere(); // AvatarRightCornea

    //console.log('prepareAvatarBody() finished')
    //console.log('  info:, this.info', this.info)
    //console.log('  uniforms:', this.uniforms)
    //console.log('  this.useGeneratedHaircut:', this.useGeneratedHaircut)

    this.version++
    this.busy = false

    //console.log('prepareAvatarBody() finished, this:', this)

    //for (const [key, value] of Object.entries(this.nodes['AvatarHead'].morphTargetDictionary))
    //console.log(`${key}: ${value}`);
  }

  /**
   * Apply Body Visibility masks
   *
   * param visibilityMasks - body & head visibility masks
   */

  applyVisibilityMasks = (visibilityMasks) => {
    //console.log('applyVisibilityMasks()')
    //console.log('  visibilityMasks:', visibilityMasks)

    if (!this.needToApplyVisibilityMasks || !this.materials) return

    this.needToApplyVisibilityMasks = false

    const headMeshMaterial = this.materials['AvatarHead']
    if (visibilityMasks['head']) {
      headMeshMaterial.alphaMap = visibilityMasks['head']
      headMeshMaterial.alphaTest = 0.5
    } else {
      headMeshMaterial.alphaMap = null
      headMeshMaterial.alphaTest = 0
    }

    const bodyMeshMaterial = this.materials['AvatarBody']
    if (visibilityMasks['complect'] || visibilityMasks['detailed']) {
      bodyMeshMaterial.alphaMap = visibilityMasks['complect'] ? visibilityMasks['complect'] : visibilityMasks['detailed']
      bodyMeshMaterial.alphaTest = 0.5
    } else {
      bodyMeshMaterial.alphaMap = null
      bodyMeshMaterial.alphaTest = 0
    }
  }

  /**
   * AvatarBody jsx
   *
   * param ref          - react.js useref
   * param params       - params
   */

  jsxAvatarBody = (refAvatarBody, params, onClick) => {
    //console.log('jsxAvatarBody()')
    //console.log('  this:', this)

    if (!this.nodes || !this.materials) return null

    this.ref = refAvatarBody

    const boneHead = this.nodes['Head']
    if (boneHead)
      boneHead.scale.x = boneHead.scale.y = boneHead.scale.z = this.headScale

    return (
      <group {...params} dispose={null} ref={this.ref} scale={[this.scale, this.scale, this.scale]}>
        <primitive object={this.nodes['Hips']} />

        <skinnedMesh
          name='AvatarBody'
          geometry={this.nodes['AvatarBody'].geometry}
          skeleton={this.nodes['AvatarBody'].skeleton}
          material={this.materials['AvatarBody']}
          morphTargetDictionary={this.nodes['AvatarBody'].morphTargetDictionary}
          morphTargetInfluences={this.nodes['AvatarBody'].morphTargetInfluences}
        />

        <skinnedMesh
          name='AvatarHead'
          geometry={this.nodes['AvatarHead'].geometry}
          skeleton={this.nodes['AvatarHead'].skeleton}
          material={this.materials['AvatarHead']}
          morphTargetDictionary={this.nodes['AvatarHead'].morphTargetDictionary}
          morphTargetInfluences={this.nodes['AvatarHead'].morphTargetInfluences}
          visible={this.nodes['AvatarHead'].visible}
        />

        <skinnedMesh
          name='AvatarHead1'
          geometry={this.nodes['AvatarHead1'].geometry}
          skeleton={this.nodes['AvatarHead1'].skeleton}
          material={this.materials['AvatarHead']}
          morphTargetDictionary={this.nodes['AvatarHead1'].morphTargetDictionary}
          morphTargetInfluences={this.nodes['AvatarHead1'].morphTargetInfluences}
          visible={this.nodes['AvatarHead1'].visible}
        />

        {process.env.USE_ARTIFICIALEYES === '1' && (
          <>
            <skinnedMesh
              name='AvatarLeftEyeball'
              geometry={this.nodes['AvatarLeftEyeball'].geometry}
              material={this.materials['AvatarLeftEyeball']}
              skeleton={this.nodes['AvatarLeftEyeball'].skeleton}
            />

            <skinnedMesh
              name='AvatarRightEyeball'
              geometry={this.nodes['AvatarRightEyeball'].geometry}
              material={this.materials['AvatarRightEyeball']}
              skeleton={this.nodes['AvatarRightEyeball'].skeleton}
            />
          </>
        )}

        <skinnedMesh
          name='AvatarLeftCornea'
          geometry={this.nodes['AvatarLeftCornea'].geometry}
          material={this.materials['AvatarLeftCornea']}
          skeleton={this.nodes['AvatarLeftCornea'].skeleton}
        />

        <skinnedMesh
          name='AvatarRightCornea'
          geometry={this.nodes['AvatarRightCornea'].geometry}
          material={this.materials['AvatarRightCornea']}
          skeleton={this.nodes['AvatarRightCornea'].skeleton}
        />

        <skinnedMesh
          name='AvatarEyelashes'
          geometry={this.nodes['AvatarEyelashes'].geometry}
          material={this.materials['AvatarEyelashes']}
          skeleton={this.nodes['AvatarEyelashes'].skeleton}
          morphTargetDictionary={this.nodes['AvatarEyelashes'].morphTargetDictionary}
          morphTargetInfluences={this.nodes['AvatarEyelashes'].morphTargetInfluences}
        />

        <skinnedMesh
          name='AvatarTeethUpper'
          geometry={this.nodes['AvatarTeethUpper'].geometry}
          material={this.materials['AvatarTeethUpper']}
          skeleton={this.nodes['AvatarTeethUpper'].skeleton}
        />

        <skinnedMesh
          name='AvatarTeethLower'
          geometry={this.nodes['AvatarTeethLower'].geometry}
          material={this.materials['AvatarTeethLower']}
          skeleton={this.nodes['AvatarTeethLower'].skeleton}
          morphTargetDictionary={this.nodes['AvatarTeethLower'].morphTargetDictionary}
          morphTargetInfluences={this.nodes['AvatarTeethLower'].morphTargetInfluences}
        />
      </group>
    )
  }

  /**
   * Change Blendshapes
   *
   * param blendshapes   - body blendshapes
   */

  changeBlendshapes = (blendshapes) => {
    //console.log('changeBlendshapes()', blendshapes)
    //console.log(' this.nodes:', this.nodes)

    if (blendshapes['height']) {
      //const bone = this.nodes['Hips']
      //bone.scale.x = bone.scale.y = bone.scale.z = blendshapes['height'] / this.height
      //bone.parent.position.y = (blendshapes['height'] / this.height - 1) * bone.position.y

      this.scale = blendshapes['height'] / this.height
    }

    if (blendshapes['headScale'] && this.nodes) {
      this.headScale = blendshapes['headScale']

//      const boneHead = this.nodes['Head']
//      if (boneHead) {
//        boneHead.scale.x = boneHead.scale.y = boneHead.scale.z = blendshapes['headScale']
//      }
    }

    if (this.ref?.current) {
      const bodyMesh = this.ref.current.children.find((item) => item.name === 'AvatarBody')
      const headMesh = this.ref.current.children.find((item) => item.name === 'AvatarHead')
      const head1Mesh = this.ref.current.children.find((item) => item.name === 'AvatarHead1')
      const eyelashesMesh = this.ref.current.children.find((item) => item.name === 'AvatarEyelashes')

      for (const prop in blendshapes) {
        if (isNaN(blendshapes[prop])) continue

        if (bodyMesh.morphTargetDictionary[prop] !== undefined)
          bodyMesh.morphTargetInfluences[bodyMesh.morphTargetDictionary[prop]] = blendshapes[prop]
        if (headMesh.morphTargetDictionary[prop] !== undefined)
          headMesh.morphTargetInfluences[headMesh.morphTargetDictionary[prop]] = blendshapes[prop]
        if (head1Mesh.morphTargetDictionary[prop] !== undefined)
          head1Mesh.morphTargetInfluences[head1Mesh.morphTargetDictionary[prop]] = blendshapes[prop]
        if (eyelashesMesh.morphTargetDictionary[prop] !== undefined)
          eyelashesMesh.morphTargetInfluences[eyelashesMesh.morphTargetDictionary[prop]] = blendshapes[prop]

        const animation = this.getAnimation()
        if (animation !== undefined) {
          const idx = headMesh.morphTargetDictionary[prop]
          this.adaptMorphAnimation(animation, idx, headMesh.morphTargetInfluences.length, blendshapes[prop])
        }
      }
    }
  }

  /**
   * Change Avatar Eyes parameters
   *
   * param ref          - react.js useref
   * param eyesParams   - eyes parameters
   */

  changeAvatarEyesParameters = (bodyMaterials, eyesParams) => {
    //console.log("corneal params changed", eyesParams)

    if (bodyMaterials) {
      bodyMaterials.AvatarLeftCornea.opacity = eyesParams.cornealOpacity
      bodyMaterials.AvatarRightCornea.opacity = eyesParams.cornealOpacity

      bodyMaterials.AvatarLeftCornea.metalness = eyesParams.cornealMetallness
      bodyMaterials.AvatarRightCornea.metalness = eyesParams.cornealMetallness
    }
  }

  adaptMorphAnimation = (animation, morphTargetIdx, morphTargetsAmount, value) => {
    //console.log(`adaptMorphAnimation(${animation.name}, ${morphTargetIdx}, ${morphTargetsAmount}, ${value})`)
    //console.log('  animation:', animation)

    const track = animation.tracks.find((elem) => elem.name === 'AvatarHead.morphTargetInfluences')
    //console.log('track:', track)

    if (track !== undefined)
      for (let i = 0; i < track.times.length; ++i) track.values[morphTargetsAmount * i + morphTargetIdx] = value

    const track1 = animation.tracks.find((elem) => elem.name === 'AvatarHead1.morphTargetInfluences')
    //console.log('track1:', track1)

    if (track1 !== undefined)
      for (let i = 0; i < track1.times.length; ++i) track1.values[morphTargetsAmount * i + morphTargetIdx] = value
  }

  /**
   * Change Haircuted Head texture
   *
   * param isOn - true for Generated haircut
   */

  setHaircutedHeadTexture = (isOn) => {
    //console.log(`setHaircutedHeadTexture(${isOn})`)
    //console.log('  this.useGeneratedHaircut:', this.useGeneratedHaircut)

    if (isOn === this.useGeneratedHaircut) return

    //console.log('this.nodes:', this.nodes)

    const head = this.nodes?.AvatarHead
    const head1 = this.nodes?.AvatarHead1

    //console.log('head', head)
    //console.log('head1', head1)

    if (head !== undefined && head1 !== undefined) {
      head.visible = !isOn
      head1.visible = isOn
    }
    if (this.ref?.current) {
      const headMesh = this.ref.current.children.find((item) => item.name === 'AvatarHead')
      const head1Mesh = this.ref.current.children.find((item) => item.name === 'AvatarHead1')

      //console.log("headMesh:", headMesh)
      //console.log("head1Mesh:", head1Mesh)

      headMesh.visible = !isOn
      head1Mesh.visible = isOn
    }

    this.useGeneratedHaircut = isOn
    this.uniforms.uUseGeneratedHaircut.value = isOn
  }

  /**
   * Recoloring Generated Haircut to specified color
   *
   * param color - specified color
   * param params - params for proceeding (AOImpact, DepthImpact, IDsImpact)
   */

  recoloringHaircut = (color, params) => {
    //console.log('recoloringHaircut()')
    //console.log('  color:', color)
    //console.log('  this.haircutColor:', this.haircutColor)
    //console.log('  params:', params)

    this.haircutColor = color

    this.setHaircutedHeadTexture(true)

    if (this.haircutColor === null) {
      this.uniforms.uUseGeneratedHaircutRecoloring.value = false
      return
    }

    this.uniforms.uUseGeneratedHaircutRecoloring.value = true

    this.uniforms.uColorTint.value.x =
      ('0x' + this.haircutColor[1] + this.haircutColor[2] - this.info.hair_color.red) / 255
    this.uniforms.uColorTint.value.y =
      ('0x' + this.haircutColor[3] + this.haircutColor[4] - this.info.hair_color.green) / 255
    this.uniforms.uColorTint.value.z =
      ('0x' + this.haircutColor[5] + this.haircutColor[6] - this.info.hair_color.blue) / 255

    //    this.uniforms.uTintCoeff.value = params.TintCoeff
    //    this.uniforms.uDarkeningCoeff.value = param.DarkeningCoeff

    //console.log('this.uniforms:', this.uniforms)
  }

  /**
   * Recoloring Skin to specified color
   *
   * param color - specified color
   */

  saveHeadTexture = (filename) => {
    // console.log('saveHeadTexture(), filename:', filename)

    const material = new THREE.ShaderMaterial({
      uniforms: this.uniforms,

      vertexShader: `
        precision mediump float;
        varying vec2 vMapUv;
        void main() {
          vMapUv = uv;
          gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.0);
        }
      `,

      fragmentShader: `
        ${avatarHead_fragment1}

        varying vec2 vMapUv;

        void main() {

          vec4 diffuseColor = vec4(0., 0., 1., 1.);
          ${avatarHead_fragment2}

          gl_FragColor = diffuseColor;
        }
      `,
    })

    //this.uniforms.uHead.value.encoding = THREE.LinearEncoding
    this.uniforms[texture_name].value.colorSpace = THREE.LinearSRGBColorSpace

    const geometry = new THREE.PlaneGeometry(2, 2)

    const bufferScene = new THREE.Scene()
    //    bufferScene.add(new THREE.AmbientLight("rgb(255,255,255)", 1));

    const mesh = new THREE.Mesh(geometry, material)
    mesh.scale.set(1, -1, 1) // flip
    bufferScene.add(mesh)

    const camera = new THREE.OrthographicCamera(-1, 1, 1, -1, -1, 1)

    const renderer = new THREE.WebGLRenderer()
    renderer.setSize(this.uniforms.uHead.value.source.data.width, this.uniforms.uHead.value.source.data.height)

    renderer.render(bufferScene, camera)

    const image = renderer.domElement.toDataURL('image/png')
    //console.log("image:", image)

    const link = document.createElement('a')
    link.download = filename
    link.href = image
    link.click()
    link.delete

    material.dispose()
    geometry.dispose()
    renderer.dispose()
    renderer.forceContextLoss()

    //this.uniforms.uHead.value.encoding = THREE.sRGBEncoding
    this.uniforms[texture_name].value.colorSpace = THREE.SRGBColorSpace
  }

  /**
   * Update Scalp texture
   *
   * texture - specified scalp texture
   */

  updateScalpTexture = (texture) => {
    //console.log('updateScalpTexture()')
    //console.log('  texture:', texture)

    this.uniforms.uScalp.value = texture
    //this.uniforms.uUseGeneratedHaircut.value = false
    //this.useGeneratedHaircut = false

    //console.log('this.uniforms.uScalp:', this.uniforms.uScalp)
    //console.log('this.uniforms:', this.uniforms)

    if (process.env.USE_SAVETEXTURE === '1' && texture) this.saveHeadTexture('scalp.png')
  }

  /**
   * Recoloring Scalp to specified color
   *
   * color - specified color
   */

  recoloringScalp = (color) => {
    //console.log('recoloringScalp()')
    //console.log('  color:', color)

    this.scalpColor = color

    if (this.scalpColor === null) {
      // no recoloring
      this.uniforms.uUseScalpRecoloring.value = false
      return
    }

    this.uniforms.uUseScalpRecoloring.value = true

    this.uniforms.uScalpColor.value.x = ('0x' + this.scalpColor[1] + this.scalpColor[2]) / 255
    this.uniforms.uScalpColor.value.y = ('0x' + this.scalpColor[3] + this.scalpColor[4]) / 255
    this.uniforms.uScalpColor.value.z = ('0x' + this.scalpColor[5] + this.scalpColor[6]) / 255

    //console.log('this.uniforms:', this.uniforms)

    if (process.env.USE_SAVETEXTURE === '1') this.saveHeadTexture('scalp_' + color + '.png')
  }

  /**
   * Recoloring Skin to specified color
   *
   * color - specified color
   */

  calcNorm(vec3) {
    return Math.sqrt(vec3.x * vec3.x + vec3.y * vec3.y + vec3.z * vec3.z)
  }

  prepareR = (initialColor, targetColor) => {
    //console.log('prepareR()')
    //console.log('  initialColor:', initialColor)
    //console.log('  targetColor:', targetColor)

    const R = new THREE.Matrix3(1, 0, 0, 0, 1, 0, 0, 0, 1)

    const initialNorm = this.calcNorm(initialColor)
    //console.log('initialNorm:', initialNorm)
    if (initialNorm === 0) return null

    const targetNorm = this.calcNorm(targetColor)
    //console.log('targetNorm:', targetNorm)
    if (targetNorm === 0) {
      for (let i = 0; i < 9; i++) R.elements[i] = 0
      return R
    }

    const scale = targetNorm / initialNorm
    //console.log('scale:', scale)

    const r = new THREE.Vector3().crossVectors(initialColor, targetColor)
    //console.log('r:', r)

    const rNorm = this.calcNorm(r)
    //console.log('rNorm:', rNorm)

    if (rNorm > 0.0017 * initialNorm * targetNorm) {
      // sin(0.1 gradus)
      const c = initialColor.dot(targetColor) / (initialNorm * targetNorm)
      const s = Math.sqrt(1.0 - c * c)

      //console.log('c:', c)
      //console.log('s:', s)

      r.divideScalar(rNorm)
      //console.log('r/norm(r):', r.x, r.y, r.z)

      const rrt = new THREE.Matrix3()
      rrt.set(r.x * r.x, r.x * r.y, r.x * r.z, r.y * r.x, r.y * r.y, r.y * r.z, r.z * r.x, r.z * r.y, r.z * r.z)
      //console.log('rrt:', rrt)

      const r_x = new THREE.Matrix3()
      r_x.set(0, r.z, -r.y, -r.z, 0, r.x, r.y, -r.x, 0)
      //console.log('r_x:', r_x)

      for (let i = 0; i < 9; i++)
        R.elements[i] = scale * (c * R.elements[i] + (1.0 - c) * rrt.elements[i] + s * r_x.elements[i])
    } else {
      for (let i = 0; i < 9; i++) R.elements[i] *= scale
    }

    return R
  }

  recoloringSkin = (color) => {
    //console.log('recoloringSkin()')
    //console.log('  color:', color)

    if (!this.info) {
      console.error('recoloringSkin failed: this.info is not initialized');
      return;
    }

    this.skinColor = color

    if (this.skinColor === null) {
      // no recoloring
      this.uniforms.uUseSkinRecoloring.value = false
      return
    }

    this.uniforms.uUseSkinRecoloring.value = true

    const initialColor = new THREE.Vector3(
      this.info.skin_color.red / 255, // r
      this.info.skin_color.green / 255, // g
      this.info.skin_color.blue / 255, // b
    )
    //console.log('initialColor:', initialColor)

    const targetColor = new THREE.Vector3(
      ('0x' + this.skinColor[1] + this.skinColor[2]) / 255, // r
      ('0x' + this.skinColor[3] + this.skinColor[4]) / 255, // g
      ('0x' + this.skinColor[5] + this.skinColor[6]) / 255, // b
    )
    //console.log('targetColor:', targetColor)

    const R = this.prepareR(initialColor, targetColor)

    if (R !== null) for (let i = 0; i < 9; i++) this.uniforms.uR.value.elements[i] = R.elements[i]

    //console.log('this.uniforms.uR.value.elements:', this.uniforms.uR.value.elements)
    //console.log('this.uniforms:', this.uniforms)

    if (process.env.USE_SAVETEXTURE === '1') this.saveHeadTexture('skin_' + color + '.png')
  }

  /**
   * Recoloring Lips to specified color
   *
   * color - specified color
   */

  recoloringLips = (color) => {
    // console.log('recoloringLips()')
    // console.log('  color:', color)

    this.lipsColor = color

    if (this.lipsColor === null) {
      // no recoloring
      this.uniforms.uUseLipsRecoloring.value = false
      return
    }

    this.uniforms.uUseLipsRecoloring.value = true

    this.uniforms.uLipsColor.value.x = ('0x' + this.lipsColor[1] + this.lipsColor[2]) / 255
    this.uniforms.uLipsColor.value.y = ('0x' + this.lipsColor[3] + this.lipsColor[4]) / 255
    this.uniforms.uLipsColor.value.z = ('0x' + this.lipsColor[5] + this.lipsColor[6]) / 255

    const initialColor = new THREE.Vector3(
      this.info.lips_color.red / 255, // r
      this.info.lips_color.green / 255, // g
      this.info.lips_color.blue / 255, // b
    )
    //console.log('initialColor:', initialColor)

    const targetColor = new THREE.Vector3(
      ('0x' + this.lipsColor[1] + this.lipsColor[2]) / 255, // r
      ('0x' + this.lipsColor[3] + this.lipsColor[4]) / 255, // g
      ('0x' + this.lipsColor[5] + this.lipsColor[6]) / 255, // b
    )
    //console.log('targetColor:', targetColor)

    const R = this.prepareR(initialColor, targetColor)

    if (R !== null) for (let i = 0; i < 9; i++) this.uniforms.uLipsR.value.elements[i] = R.elements[i]

    //console.log('this.uniforms.uR.value.elements:', this.uniforms.uR.value.elements)
    //console.log('this.uniforms:', this.uniforms)

    //console.log('this.uniforms:', this.uniforms)

    if (process.env.USE_SAVETEXTURE === '1') this.saveHeadTexture('lips_' + color + '.png')
  }

  /**
   * Recoloring Eyebrows to specified color
   *
   * color - specified color
   */

  recoloringEyebrows = (color) => {
    // console.log('recoloringEyebrows()')
    // console.log('  color:', color)

    this.eyebrowsColor = color

    if (this.eyebrowsColor === null) {
      // no recoloring
      this.uniforms.uUseEyebrowsRecoloring.value = false
      return
    }

    this.uniforms.uUseEyebrowsRecoloring.value = true

    this.uniforms.uEyebrowsColor.value.x = ('0x' + this.eyebrowsColor[1] + this.eyebrowsColor[2]) / 255
    this.uniforms.uEyebrowsColor.value.y = ('0x' + this.eyebrowsColor[3] + this.eyebrowsColor[4]) / 255
    this.uniforms.uEyebrowsColor.value.z = ('0x' + this.eyebrowsColor[5] + this.eyebrowsColor[6]) / 255

    const initialColor = new THREE.Vector3(
      this.info.eyebrows_color.red / 255, // r
      this.info.eyebrows_color.green / 255, // g
      this.info.eyebrows_color.blue / 255, // b
    )
    //console.log('initialColor:', initialColor)

    const targetColor = new THREE.Vector3(
      ('0x' + this.eyebrowsColor[1] + this.eyebrowsColor[2]) / 255, // r
      ('0x' + this.eyebrowsColor[3] + this.eyebrowsColor[4]) / 255, // g
      ('0x' + this.eyebrowsColor[5] + this.eyebrowsColor[6]) / 255, // b
    )
    //console.log('targetColor:', targetColor)

    const R = this.prepareR(initialColor, targetColor)
    //console.log('R:', R)

    if (R !== null) for (let i = 0; i < 9; i++) this.uniforms.uEyebrowsR.value.elements[i] = R.elements[i]

    //console.log('this.uniforms.uR.value.elements:', this.uniforms.uR.value.elements)
    //console.log('this.uniforms:', this.uniforms)

    if (process.env.USE_SAVETEXTURE === '1') this.saveHeadTexture('eyebrows_' + color + '.png')
  }

  /**
   * Apply AvatarBody animation to asset
   *
   * ref - asset ref
   * animationName - animation name
   */

  applyAnimationTo = (ref, animationName, mixer) => {
    // console.log('applyAnimationTo(), animationName:', animationName)

    const animation = this.animations[animationName]

    // console.log('  mixer:', mixer)

    const action = mixer.clipAction(animation, ref.current)
    if (action) {
      action.time = mixer.clipAction(animation, this.ref.current).time
      action.play()
    }

    // console.log('  mixer._actions.length:', mixer._actions.length)
  }
} // class AvatarBody

const storeAvatarBody = new AvatarBody()

export { storeAvatarBody }
