import * as THREE from 'three'

export class AnimationController {
  mixer = null
  animations = null
  selectedAnimation = null
  refs = null

  /**
   * Constructor
   */

  constructor() {
    //console.log("constructor()")
    this.mixer = new THREE.AnimationMixer()

    //console.log("this:", this)
  }

  /**
   * Select animation
   *
   * animationName - animation name
   */

  selectAnimation(animationName) {
    //console.log(`selectAnimation("${animationName}")`)

    this.selectedAnimation = this.animations[animationName] ? this.animations[animationName] : null

    return this.selectedAnimation
  }

  /**
   * Apply animation to asset
   *
   * ref - asset ref
   * animationName - animation name
   */

  applyAnimationTo = (ref, refAvatarBody) => {
    //console.log("applyAnimationTo(), ref:", ref)
    //console.log("  this.mixer:", this.mixer)

    if (!this.selectedAnimation || !ref?.current) return

    const action = this.mixer.clipAction(this.selectedAnimation, ref.current)
    if (action) {
      action.time = this.mixer.clipAction(this.selectedAnimation, refAvatarBody.current).time
      action.play()
    }

    //console.log('  this.mixer._actions.length:', this.mixer._actions.length)
    //console.log('  this.mixer._actions', this.mixer._actions)
  }

  /**
   * Stop Animation
   */

  stopAnimationFor(ref) {
    //console.log("stopAnimationFor(), ref:", ref)

    if (!this.selectedAnimation || !ref) return

    const action = this.mixer.existingAction(this.selectedAnimation, ref)
    if (action) {
      action.stop()
      this.mixer.uncacheAction(this.selectedAnimation, ref)
    }

    //console.log('  this.mixer._actions.length:', this.mixer._actions.length)
    //console.log('  this.mixer._actions', this.mixer._actions)
  }

  /**
   * Stop Outfit's Animation
   */

  stopOutfitAnimation(animation, ref) {
    //console.log("stopOutfitAnimation(), animation:", animation)

    const action = animationController.mixer.existingAction(animation, ref)
    if (action) {
      action.stop()
      animationController.mixer.uncacheAction(animation, ref)
    }

    //console.log('  this.mixer._actions.length:', this.mixer._actions.length)
    //console.log('  this.mixer._actions', this.mixer._actions)
  }

  /**
   * Apply Outfit's Animation
   */

  applyOutfitAnimation(animation, ref) {
    //console.log("applyOutfitAnimation(), animation:", animation)

    const action = this.mixer.clipAction(animation, ref.current)
    if (action) action.play()

    //console.log('  this.mixer._actions.length:', this.mixer._actions.length)
    //console.log('  this.mixer._actions', this.mixer._actions)
  }

  /**
   * Start animation
   */

  startAnimation(animationName) {
    //console.log(`startAnimation("${animationName}")`)
    //console.log('  this.mixer._actions.length before:', this.mixer._actions.length)

    if (!this.selectAnimation(animationName)) return null

    Object.values(this.refs).forEach((ref) => {
      if (ref.current) this.applyAnimationTo(ref, this.refs['avatarBody'])
    })

    //console.log('  this.mixer._actions.length after:', this.mixer._actions.length)
    //console.log('  this.mixer._actions', this.mixer._actions)

    return this.selectedAnimation
  }

  /**
   * Stop animation
   */

  stopAnimation() {
    //console.log('stopAnimation')
    //console.log('  this.mixer._actions.length before:', this.mixer._actions.length)

    Object.values(this.refs).forEach((ref) => {
      if (ref?.current) this.stopAnimationFor(ref.current)
    })

    this.selectAnimation(null)

    //console.log('  this.mixer._actions.length after:', this.mixer._actions.length)
    //console.log('  this.mixer._actions', this.mixer._actions)
  }

  computeBoundingSphere() {
    //console.log("computeBoundingSphere()")

    const leftCornea = this.refs['avatarBody'].current?.children.find((element) => element.name === 'AvatarLeftCornea')
    const rightCornea = this.refs['avatarBody'].current?.children.find(
      (element) => element.name === 'AvatarRightCornea',
    )

    //console.log("leftCornea:", leftCornea)
    //console.log("rightCornea:", rightCornea)

    if (leftCornea) leftCornea.computeBoundingSphere()
    if (rightCornea) rightCornea.computeBoundingSphere()
  }
}

const animationController = new AnimationController()

export { animationController }
