// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".RangeSlider-RenderTrack-module--cls1--rc4rB { /*!*/ }\n\n.RangeSlider-RenderTrack-module--cls2--C1TlV {\n  height: 1px;\n  position: relative;\n  width: 100%;\n  top: 9px;\n  background-color: var(--color-base);\n  opacity: 0.5;\n}", "",{"version":3,"sources":["webpack://./src/views/page/generator/sections/components/RangeSlider-RenderTrack.module.scss"],"names":[],"mappings":"AAAA,+CAAA,IAAA,EAAA;;AACA;EAGA,WAAA;EACA,kBAAA;EACA,WAAA;EACA,QAAA;EACA,mCAAA;EACA,YAAA;AACA","sourcesContent":[".cls1 { /*!*/ }\n.cls2 {\ncomposes: cls1;\n\nheight: 1px;\nposition: relative;\nwidth: 100%;\ntop: 9px;\nbackground-color: var(--color-base);\nopacity: 0.5;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"cls1": "RangeSlider-RenderTrack-module--cls1--rc4rB",
	"cls2": "RangeSlider-RenderTrack-module--cls2--C1TlV RangeSlider-RenderTrack-module--cls1--rc4rB"
};
export default ___CSS_LOADER_EXPORT___;
