import React, { FC } from 'react'
import styled from 'astroturf/react'
import { Spinner } from '@/components/ui/icons'

export interface SpinnerCircleProps {
  className?: string
}

export const SpinnerCircle: FC<SpinnerCircleProps> = ({ className }) => {
  return <Core className={className}></Core>
}

const Core = styled(Spinner)`
  width: 38px;
  height: 38px;
  border-radius: 38px;
  stroke: var(--color-base);
  animation: rotate 0.9s infinite linear;
  animation-delay: 0.3s;
  stroke-width: 5px;
  stroke-linecap: round;
  transform: rotate(0);

  @keyframes rotate {
    from {
      transform: rotate(0);
    }
    to {
      transform: rotate(360deg);
    }
  }
`
