import React, { useEffect } from 'react'
import { Empty, List } from './components'
import { useLayoutContext } from '@/views/components/core/LayoutProvider'

export const Gallery = () => {
  const { setDisplayBG } = useLayoutContext()

  useEffect(() => {
    setDisplayBG(true)
    return () => setDisplayBG(false)
  }, [])

  return <List />
}
