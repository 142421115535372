// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Menu-MenuBox-module--cls1--nDEIn { /*!*/ }\n\n.Menu-MenuBox-module--cls2--H6aRZ {\n  background-color: var(--color-bg-box);\n}\n.Menu-MenuBox-module--cls2--H6aRZ.Menu-MenuBox-module--variant-mobile--SmFQp {\n  padding: 12px 34px;\n  display: flex;\n  width: 100%;\n  justify-content: space-between;\n  margin-top: 2px;\n}\n.Menu-MenuBox-module--cls2--H6aRZ.Menu-MenuBox-module--variant-not-mobile--yHHku {\n  margin-left: 20px;\n  margin-right: 20px;\n  border-radius: var(--size-radius-large);\n  width: 72px;\n  padding: 12px;\n}", "",{"version":3,"sources":["webpack://./src/views/page/generator/sections/Editor/components/Menu-MenuBox.module.scss"],"names":[],"mappings":"AAAA,oCAAA,IAAA,EAAA;;AACA;EAGA,qCAAA;AACA;AAAA;EACA,kBAAA;EACA,aAAA;EACA,WAAA;EACA,8BAAA;EACA,eAAA;AAEA;AACA;EACA,iBAAA;EACA,kBAAA;EACA,uCAAA;EACA,WAAA;EACA,aAAA;AACA","sourcesContent":[".cls1 { /*!*/ }\n.cls2 {\ncomposes: cls1;\n\nbackground-color: var(--color-bg-box);\n&.variant-mobile {\npadding: 12px 34px;\ndisplay: flex;\nwidth: 100%;\njustify-content: space-between;\nmargin-top: 2px;\n}\n\n&.variant-not-mobile {\nmargin-left: 20px;\nmargin-right: 20px;\nborder-radius: var(--size-radius-large);\nwidth: 72px;\npadding: 12px;\n}\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"cls1": "Menu-MenuBox-module--cls1--nDEIn",
	"cls2": "Menu-MenuBox-module--cls2--H6aRZ Menu-MenuBox-module--cls1--nDEIn",
	"variant-mobile": "Menu-MenuBox-module--variant-mobile--SmFQp",
	"variant-not-mobile": "Menu-MenuBox-module--variant-not-mobile--yHHku"
};
export default ___CSS_LOADER_EXPORT___;
