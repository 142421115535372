// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".NoPhoto-ColCard-module--cls1--KULoC { /*!*/ }\n\n.NoPhoto-ColCard-module--cls2--AjLgq {\n  margin-top: 16px;\n  padding-left: 8px !important;\n  padding-right: 8px !important;\n}", "",{"version":3,"sources":["webpack://./src/views/page/generator/sections/NoPhoto-ColCard.module.scss"],"names":[],"mappings":"AAAA,uCAAA,IAAA,EAAA;;AACA;EAGA,gBAAA;EACA,4BAAA;EACA,6BAAA;AACA","sourcesContent":[".cls1 { /*!*/ }\n.cls2 {\ncomposes: cls1;\n\nmargin-top: 16px;\npadding-left: 8px !important;\npadding-right: 8px !important;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"cls1": "NoPhoto-ColCard-module--cls1--KULoC",
	"cls2": "NoPhoto-ColCard-module--cls2--AjLgq NoPhoto-ColCard-module--cls1--KULoC"
};
export default ___CSS_LOADER_EXPORT___;
