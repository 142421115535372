// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".FieldWrap-Error-module--cls1--e8SrV { /*!*/ }\n\n.FieldWrap-Error-module--cls2--AnWcg {\n  color: var(--color-error);\n  font-size: 12px;\n  font-weight: 500;\n  padding-top: 8px;\n}", "",{"version":3,"sources":["webpack://./src/components/ui/FieldWrap-Error.module.scss"],"names":[],"mappings":"AAAA,uCAAA,IAAA,EAAA;;AACA;EAGA,yBAAA;EACA,eAAA;EACA,gBAAA;EACA,gBAAA;AACA","sourcesContent":[".cls1 { /*!*/ }\n.cls2 {\ncomposes: cls1;\n\ncolor: var(--color-error);\nfont-size: 12px;\nfont-weight: 500;\npadding-top: 8px;\n// height: 0;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"cls1": "FieldWrap-Error-module--cls1--e8SrV",
	"cls2": "FieldWrap-Error-module--cls2--AnWcg FieldWrap-Error-module--cls1--e8SrV"
};
export default ___CSS_LOADER_EXPORT___;
