import React, { FC } from 'react'
import { observer } from 'mobx-react-lite'

import { useLayoutContext } from '@/views/components/core/LayoutProvider'
import { Presets, WrapBox, Box } from '@/views/page/generator/sections/components'
import { storeEditor, storeGeneratorPipeline } from '@/services/store'

export const EditorAnimation: FC = observer(() => {
  const versionUI = storeEditor.versionUI  // to rerender after observable versionUI increment

  const {
    preSettings: { gender },
  } = storeGeneratorPipeline

  const { animation, setAnimation, animationsPresets } = storeEditor
  const { mediaQueries } = useLayoutContext()
  const variant = mediaQueries.isTablet ? 'not-mobile' : 'mobile'

  if (variant === 'not-mobile') {
    return (
      <div style={mediaQueries.isTablet === false ? { } : { height: '75%', overflowY: 'scroll' }}>
        <WrapBox>
          <Box title='Poses'>
            <Presets
              disabled={!storeEditor.isReady()}
              presets={animationsPresets(gender, true)}
              value={animation.name || 'None'}
              setValue={(preset) => setAnimation(gender, preset)}
            />
          </Box>
          <Box title='Animations'>
            <Presets
              disabled={!storeEditor.isReady()}
              presets={animationsPresets(gender, false)}
              value={animation.name || 'None'}
              setValue={(preset) => setAnimation(gender, preset)}
            />
          </Box>
        </WrapBox>
      </div>
    )
  }
  return (
    <div style={mediaQueries.isTablet === false ? { } : { height: '75%', overflowY: 'scroll' }}>
      <WrapBox>
        <Box title='Animations'>
          <Presets
            disabled={!storeEditor.isReady()}
            presets={animationsPresets(gender, true).concat(animationsPresets(gender, false))}
            value={animation.name || 'None'}
            setValue={(preset) => setAnimation(gender, preset)}
          />
        </Box>
      </WrapBox>
    </div>
  )
})

EditorAnimation.displayName = 'EditorAnimation'
