// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ColorPicker-CssProp1_Preset-module--cls1--u0P6h { /*!*/ }\n\n.ColorPicker-CssProp1_Preset-module--cls2--cdEPd {\n  background-color: var(--aig06mp);\n}", "",{"version":3,"sources":["webpack://./src/views/page/generator/sections/components/ColorPicker-CssProp1_Preset.module.scss"],"names":[],"mappings":"AAAA,mDAAA,IAAA,EAAA;;AACA;EAGA,gCAAA;AACA","sourcesContent":[".cls1 { /*!*/ }\n.cls2 {\ncomposes: cls1;\n\nbackground-color: var(--aig06mp);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"cls1": "ColorPicker-CssProp1_Preset-module--cls1--u0P6h",
	"cls2": "ColorPicker-CssProp1_Preset-module--cls2--cdEPd ColorPicker-CssProp1_Preset-module--cls1--u0P6h"
};
export default ___CSS_LOADER_EXPORT___;
