import React, { FC, SVGProps } from 'react'
import styled from 'astroturf/react'
import { AvatarPreview } from '@/components/ui'

export interface AvatarCardProps {
  img: string
  icon: SVGProps<SVGSVGElement>
  onClick: () => void
}
export const AvatarCard: FC<AvatarCardProps> = ({ img, onClick, icon: Icon }) => {
  return (
    <Core>
      <AvatarPreview img={img} width={300} height={245} />
      <Disconnect onClick={onClick}>
        <Icon />
      </Disconnect>
    </Core>
  )
}

const Core = styled.div`
  position: relative;
  margin-top: 12px;
`

const Disconnect = styled.button`
  @import '../../../../assets/styles/mixins.scss';
  @include anim(opacity);
  position: absolute;
  width: 54px;
  height: 54px;
  top: 0;
  right: 0;
  fill: var(--color-base);
  opacity: 0;

  ${Core}:hover & {
    opacity: 1;
  }
`
