import { getMaleAnimations, getFemaleAnimations } from "./animationList";
// exportParameters for metaperson_2.0 (.webp)

export const avatarParameters = () => {
  return {
    avatar_modifications: {
      remove_smile: true,
      remove_glasses: true,
    },
  }
}

export const exportParameters = (type: 'male' | 'female') => {
  let animationsList: string[] = [];

  // Conditionally set animations based on the type parameter
  if (type === 'male') {
    animationsList = getMaleAnimations().concat(getMaleAnimations(false));
  } else {
    animationsList = getFemaleAnimations().concat(getFemaleAnimations(false));
  }


  return [
    //
    {
      format: 'gltf',
      lod: 'LOD2',
      textures: {
        profile: '1K.webp',
      },

      blendshapes: {
        list: ['face_modifications', 'body_shape', 'webdemo'],
        embed: true,
      },

      avatar: {
        list:
          process.env.USE_ARTIFICIALEYES === '1'
            ? [
              'AvatarBody',
              'AvatarEyelashes',
              'AvatarHead',
              'AvatarLeftCornea',
              'AvatarLeftEyeball',
              'AvatarRightCornea',
              'AvatarRightEyeball',
              'AvatarTeethLower',
              'AvatarTeethUpper',
            ]
            : [
              'AvatarBody',
              'AvatarEyelashes',
              'AvatarHead',
              'AvatarLeftCornea',
              'AvatarRightCornea',
              'AvatarTeethLower',
              'AvatarTeethUpper',
            ],
        textures: {
          list: ['Color'],
          with_recoloring_masks: true,
        },
      },

      animations: {
        list: animationsList,
      },

      haircuts: {
        list: [
          'HaircutGenerated',
          'Haircut0',
          'Haircut1',
          'Haircut2',
          'Haircut3',
          'Haircut4',
          'Haircut5',
          'Haircut6',
          'Haircut7',
          'Haircut8',
          'Haircut9',
          'Haircut10',
          'Haircut11',
          'Haircut12',
          'Haircut13',
          'Haircut14',
          'Haircut15',
          'Haircut16',
          'Haircut17',
          'Haircut18',
          'Haircut19',
          'Haircut20',
        ],
        embed: false,
        textures: {
          embed: false,
          list: [
            'AO',
            'Alpha',
            'Color',
            'Depth',
            'Root',
            'Scalp',
            'Shade',
            'UniqueID',
          ],
        },
      },

      glasses: {
        embed: false,
        list: [
          'glasses_00',
          'glasses_01',
          'glasses_02',
          'glasses_03',
          'glasses_04',
          'glasses_05',
          'glasses_06',
          'glasses_07',
          'glasses_08',
          'glasses_09',
          'glasses_10',
          'glasses_11',
          'glasses_12',
          'glasses_13',
          'glasses_14',
          'glasses_15',
        ],
        textures: {
          embed: false,
          list: [
            'Color',
            'GltfMetallicRoughness',
            'Metallic',
            'Roughness',
          ],
        },
      },

      hats: {
        embed: false,
        list: [
          'Ghutra',
        ],
        textures: {
          embed: false,
          list: [
            'Color',
            'GltfMetallicRoughness',
            'Metallic',
            'Roughness',
          ],
        },
      },



    },
    //
  ]
}
