import React, { FC } from 'react'
import styled from 'astroturf/react'

export interface SpinnerInfiniteProps {
  className?: string
}

export const SpinnerInfinite: FC<SpinnerInfiniteProps> = ({ className }) => {
  return (
    <div className={className}>
      <svg width='65' height='64' viewBox='0 0 65 64' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <Outline
          d='M25.8211 39.5415L24.9853 40.4853C23.307 42.1635 21.1689 43.3064 18.8411 43.7694C16.5133 44.2325 14.1005 43.9948 11.9078 43.0866C9.71508 42.1783 7.84094 40.6402 6.52236 38.6668C5.20379 36.6935 4.5 34.3734 4.5 32C4.5 29.6266 5.20379 27.3066 6.52236 25.3332C7.84094 23.3598 9.71508 21.8217 11.9078 20.9135C14.1005 20.0052 16.5133 19.7676 18.8411 20.2306C21.1689 20.6936 23.307 21.8365 24.9853 23.5147L40.0147 40.4853C41.6929 42.1635 43.8311 43.3064 46.1589 43.7694C48.4867 44.2325 50.8995 43.9948 53.0922 43.0866C55.2849 42.1783 57.1591 40.6402 58.4776 38.6668C59.7962 36.6935 60.5 34.3734 60.5 32C60.5 29.6266 59.7962 27.3066 58.4776 25.3332C57.1591 23.3598 55.2849 21.8217 53.0922 20.9135C50.8995 20.0052 48.4867 19.7676 46.1589 20.2306C43.8311 20.6936 41.6929 21.8365 40.0147 23.5147L39.1789 24.4585'
          strokeWidth='5'
          strokeLinecap='round'
          strokeLinejoin='round'
          strokeMiterlimit='10'
        />
        <OutlineBg
          d='M25.8211 39.5415L24.9853 40.4853C23.307 42.1635 21.1689 43.3064 18.8411 43.7694C16.5133 44.2325 14.1005 43.9948 11.9078 43.0866C9.71508 42.1783 7.84094 40.6402 6.52236 38.6668C5.20379 36.6935 4.5 34.3734 4.5 32C4.5 29.6266 5.20379 27.3066 6.52236 25.3332C7.84094 23.3598 9.71508 21.8217 11.9078 20.9135C14.1005 20.0052 16.5133 19.7676 18.8411 20.2306C21.1689 20.6936 23.307 21.8365 24.9853 23.5147L40.0147 40.4853C41.6929 42.1635 43.8311 43.3064 46.1589 43.7694C48.4867 44.2325 50.8995 43.9948 53.0922 43.0866C55.2849 42.1783 57.1591 40.6402 58.4776 38.6668C59.7962 36.6935 60.5 34.3734 60.5 32C60.5 29.6266 59.7962 27.3066 58.4776 25.3332C57.1591 23.3598 55.2849 21.8217 53.0922 20.9135C50.8995 20.0052 48.4867 19.7676 46.1589 20.2306C43.8311 20.6936 41.6929 21.8365 40.0147 23.5147L39.1789 24.4585'
          stroke='white'
          strokeWidth='5'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
      </svg>
    </div>
  )
}

const Outline = styled.path`
  stroke-dasharray: 2.42777px, 242.77666px;
  stroke-dashoffset: 0;
  animation: anim 1.6s linear infinite;
  stroke: var(--color-base);

  @keyframes anim {
    12.5% {
      stroke-dasharray: 33.98873px, 242.77666px;
      stroke-dashoffset: -0.70543px;
    }
    43.75% {
      stroke-dasharray: 84.97183px, 242.77666px;
      stroke-dashoffset: -84.97183px;
    }
    100% {
      stroke-dasharray: 2.42777px, 242.77666px;
      stroke-dashoffset: -240.34889px;
    }
  }
`
const OutlineBg = styled.path`
  stroke: var(--color-base);
  opacity: 0.2;
`
