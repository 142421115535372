// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".index-Zoom-module--cls1--l6aT9 { /*!*/ }\n\n.index-Zoom-module--cls2--ZZokl {\n  position: absolute;\n  height: 100%;\n  width: 0;\n  right: 0;\n  top: 0;\n  display: flex;\n  align-items: center;\n  justify-content: flex-end;\n}", "",{"version":3,"sources":["webpack://./src/views/page/generator/sections/Editor/index-Zoom.module.scss"],"names":[],"mappings":"AAAA,kCAAA,IAAA,EAAA;;AACA;EAGA,kBAAA;EACA,YAAA;EACA,QAAA;EACA,QAAA;EACA,MAAA;EACA,aAAA;EACA,mBAAA;EACA,yBAAA;AACA","sourcesContent":[".cls1 { /*!*/ }\n.cls2 {\ncomposes: cls1;\n\nposition: absolute;\nheight: 100%;\nwidth: 0;\nright: 0;\ntop: 0;\ndisplay: flex;\nalign-items: center;\njustify-content: flex-end;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"cls1": "index-Zoom-module--cls1--l6aT9",
	"cls2": "index-Zoom-module--cls2--ZZokl index-Zoom-module--cls1--l6aT9"
};
export default ___CSS_LOADER_EXPORT___;
