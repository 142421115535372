// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ViewerWrap-Core-module--cls1--EWGW9 { /*!*/ }\n\n.ViewerWrap-Core-module--cls2--aKAS8 {\n  width: 100%;\n}\n.ViewerWrap-Core-module--cls2--aKAS8.ViewerWrap-Core-module--variant-mobile--IJ4WP {\n  height: var(--app-height);\n}\n.ViewerWrap-Core-module--cls2--aKAS8.ViewerWrap-Core-module--variant-not-mobile--mHdoE {\n  position: absolute;\n  top: 0;\n  left: 0;\n  height: 100%;\n}", "",{"version":3,"sources":["webpack://./src/views/page/generator/sections/Editor/components/ViewerWrap-Core.module.scss"],"names":[],"mappings":"AAAA,uCAAA,IAAA,EAAA;;AACA;EAGA,WAAA;AACA;AAAA;EACA,yBAAA;AAEA;AACA;EACA,kBAAA;EACA,MAAA;EACA,OAAA;EACA,YAAA;AACA","sourcesContent":[".cls1 { /*!*/ }\n.cls2 {\ncomposes: cls1;\n\nwidth: 100%;\n&.variant-mobile {\nheight: var(--app-height);\n}\n\n&.variant-not-mobile {\nposition: absolute;\ntop: 0;\nleft: 0;\nheight: 100%;\n}\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"cls1": "ViewerWrap-Core-module--cls1--EWGW9",
	"cls2": "ViewerWrap-Core-module--cls2--aKAS8 ViewerWrap-Core-module--cls1--EWGW9",
	"variant-mobile": "ViewerWrap-Core-module--variant-mobile--IJ4WP",
	"variant-not-mobile": "ViewerWrap-Core-module--variant-not-mobile--mHdoE"
};
export default ___CSS_LOADER_EXPORT___;
