import React, { FC, useState } from 'react'
import styled from 'astroturf/react'
import { css } from 'astroturf'
import { Row, Col } from 'react-grid-system'
import { Container } from '@/components/wrapper'
import { Button, Cover } from '@/components/ui'
import { CheckCircle, CheckCircleGradient } from '@/components/ui/icons'
import { TitleSection, SharingAvatar } from '@/views/components/common'
import { Modal } from '@/components/wrapper'
import { storeGeneratorPipeline } from '@/services/store'
import { Swiper, SwiperSlide } from 'swiper/react'
import { useLayoutContext } from '@/views/components/core/LayoutProvider'

import { useRQ, getSetting } from '@/services/adapters/req'
import { Setting } from '../_helper'

import '@/assets/styles/swiper.scss'

import avatarSharing from '@/assets/demo/avatar-sharing.png'
export interface AvatarCardCompProps {
  img: string
  isActive?: boolean
  isMobile?: boolean
}

const AvatarCardComp: FC<AvatarCardCompProps> = ({ img, isActive, isMobile }) => {
  const { toSection } = storeGeneratorPipeline
  const {
    data: {
      texts: {
        sections: { choice: PAGE },
      },
    },
  } = useRQ<Setting>(getSetting)
  return (
    <AvatarCard>
      <ImgBox active={isActive} mobile={isMobile}>
        <Cover width={240} height={280} />
        <Img src={img} />
        {isMobile ? <CheckGradient active={isActive} /> : <Check />}
      </ImgBox>
      <Actions active={isActive} mobile={isMobile}>
        <Button
          onClick={() => toSection('editor')}
          wSize='full'
          css={css`
            margin-bottom: 10px;
          `}
        >
          {PAGE.actions.edit}
        </Button>
        <Button wSize='full' variant='surface' onClick={() => toSection('result')}>
          {PAGE.actions.save}
        </Button>
      </Actions>
    </AvatarCard>
  )
}

export const Choice = () => {
  const {
    mediaQueries: { isTablet },
  } = useLayoutContext()
  const [realIndex, setRealIndex] = useState<number>(0)
  return (
    <>
      <Container variant='center-big'>
        <TitleSection title='Select an avatar' descr='Please select an avatar you like more' />
        {isTablet ? (
          <AvatarList>
            <Col lg={4}>
              <AvatarCardComp img={'/demo/Mask group-1.png'} />
            </Col>
            <Col lg={4}>
              <AvatarCardComp img={'/demo/Mask group.png'} />
            </Col>
            <Col lg={4}>
              <AvatarCardComp img={'/demo/Mask group-2.png'} />
            </Col>
          </AvatarList>
        ) : (
          <SliderWrap>
            <Swiper
              spaceBetween={16}
              slidesPerView={1}
              onSlideChange={(e) => setRealIndex(e.realIndex)}
              loop={true}
              className='swiper-theme-cards'
            >
              <SwiperSlide>
                <AvatarCardComp isMobile isActive={realIndex === 0} img={'/demo/Mask group-1.png'} />
              </SwiperSlide>
              <SwiperSlide>
                <AvatarCardComp isMobile isActive={realIndex === 1} img={'/demo/Mask group.png'} />
              </SwiperSlide>
              <SwiperSlide>
                <AvatarCardComp isMobile isActive={realIndex === 2} img={'/demo/Mask group-2.png'} />
              </SwiperSlide>
            </Swiper>
          </SliderWrap>
        )}
      </Container>
      <Modal>
        <SharingAvatar img={avatarSharing} link={'metaperson.com/axfsdfsdfsdk.html'} />
      </Modal>
    </>
  )
}

const AvatarList = styled(Row)`
  margin-top: 24px;
`

const AvatarCard = styled.div`
  @import '../../../../assets/styles/mixins.scss';
  position: relative;
`

const SliderWrap = styled.div`
  width: calc(100% + 72px);
  padding-left: 68px;
  padding-right: 68px;
  overflow: hidden;
  margin-top: 30px;
  padding-top: 2px;
`

export interface Props {
  active?: boolean
  mobile?: boolean
}

const ImgBox = styled.div<Props>`
  @import '../../../../assets/styles/mixins.scss';
  @include anim(background-color);
  width: 100%;
  height: 100%;
  background-color: var(--color-bg-box);
  border-radius: var(--size-radius);
  position: relative;
  display: flex;
  align-items: flex-end;
  justify-content: center;

  &:not(.mobile):hover {
    &:before {
      opacity: 1;
    }
  }

  &:before {
    content: '';
    @import '../../../../assets/styles/mixins.scss';
    @include anim(opacity);
    border-radius: var(--size-radius);
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-image: linear-gradient(102.67deg, #7d1bf5 0%, #e32dd1 99.48%);
    opacity: 0;
  }

  &.mobile {
    &:before {
      width: calc(100% + 4px);
      height: calc(100% + 4px);
      top: -2px;
      left: -2px;
    }
    &:after {
      content: '';
      @import '../../../../assets/styles/mixins.scss';
      @include anim(opacity);
      border-radius: var(--size-radius);
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      background-color: var(--color-bg-main);
      opacity: 0;
    }
  }

  &.mobile.active {
    background-color: transparent;
    &:after,
    &:before {
      opacity: 1;
    }
  }
`
const Check = styled(CheckCircle)`
  @import '../../../../assets/styles/mixins.scss';
  @include anim(opacity);
  position: absolute;
  top: 16px;
  right: 16px;
  fill: #fff;
  opacity: 0;
  ${AvatarCard}:hover & {
    opacity: 1;
  }
`

const CheckGradient = styled(CheckCircleGradient)<Pick<Props, 'active'>>`
  @import '../../../../assets/styles/mixins.scss';
  @include anim(opacity);
  position: absolute;
  top: 16px;
  right: 16px;
  opacity: 0;
  z-index: 1;
  &.active {
    opacity: 1;
  }
`

const Actions = styled.div<Props>`
  @import '../../../../assets/styles/mixins.scss';
  @include anim(height, opacity);
  margin-top: 20px;
  opacity: 0;

  &:not(.mobile) {
    height: 0;
    overflow: hidden;
  }

  ${AvatarList}:hover & {
    height: 108px;
  }

  ${AvatarCard}:hover & {
    opacity: 1;
  }

  &.active.mobile {
    opacity: 1;
  }
`

const Img = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  width: 100%;
  z-index: 1;
`
