// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Empty-AvatarBox-module--cls1--sN6h1 { /*!*/ }\n\n.Empty-AvatarBox-module--cls2--WCIfP {\n}", "",{"version":3,"sources":["webpack://./src/views/page/gallery/components/Empty-AvatarBox.module.scss"],"names":[],"mappings":"AAAA,uCAAA,IAAA,EAAA;;AACA;AAGA","sourcesContent":[".cls1 { /*!*/ }\n.cls2 {\ncomposes: cls1;\n\n\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"cls1": "Empty-AvatarBox-module--cls1--sN6h1",
	"cls2": "Empty-AvatarBox-module--cls2--WCIfP Empty-AvatarBox-module--cls1--sN6h1"
};
export default ___CSS_LOADER_EXPORT___;
