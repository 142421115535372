import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import styled from 'astroturf/react'
import { Button } from '@/components/ui'
import { useLayoutContext } from '@/views/components/core/LayoutProvider'

export const Support = () => {
  const {
    mediaQueries: { isTablet },
  } = useLayoutContext()
  const [isHide, setIsHide] = useState(true)
  if (!isTablet) {
    return <></>
  }
  return <></>
  // return (
  //   <Core>
  //     {!isHide && (
  //       <Nav as='div'>
  //         <Link to='/auth'>Login</Link>
  //         <Link to='/auth/developer'>Developer</Link>
  //         <Link to='/gallery'>Gallery</Link>
  //         <Link to='/generator'>App</Link>
  //         <br />
  //         <Link to='/generator'>Generator</Link>
  //       </Nav>
  //     )}
  //     <Button onClick={() => setIsHide(!isHide)}>Navigation</Button>
  //   </Core>
  // )
}

const Core = styled.div`
  position: fixed;
  bottom: 10px;
  right: 0;
  z-index: 10000;
  margin-right: 32px;
`
const Nav = styled('nav')`
  a {
    color: var(--color-base);
    margin-right: 10px;
  }
`
