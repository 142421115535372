import React, { FC, PropsWithChildren, useCallback, useEffect, useState } from 'react'
import ReactDOM from 'react-dom'
import styled from 'astroturf/react'
import { Close } from '../ui/icons'

import { useLayoutContext } from '@/views/components/core/LayoutProvider'

export const Modal: FC<PropsWithChildren<unknown>> = ({ children }) => {
  const {isOpenModal, setIsOpenModal } = useLayoutContext()
  const [animation, setAnimation] = useState<null | 'opening' | 'closing'>(null)
  const [isOpen, setIsOpen] = useState<boolean>(false)

  const [isUseKeyboard, setIsUseKeyboard] = useState<boolean>(false)
  const [isSkipClose, setIsSkipClose] = useState<boolean>(true)
  const [isSkipClick, setIsSkipClick] = useState<boolean>(true)

  const handlingKeyPress = useCallback((e: KeyboardEvent) => {
    if (isUseKeyboard) {
      e.key === 'Escape' && setIsOpenModal(false)
    }
  }, [])

  useEffect(() => {
    setAnimation(isOpenModal ? 'opening' : 'closing')
    if (isOpenModal) {
      window.addEventListener('keydown', handlingKeyPress)
    } else {
      window.removeEventListener('keydown', handlingKeyPress)
    }

    if (!isOpenModal && isOpen) {
      const timer = setTimeout(() => setIsOpen(false), 300)
      return () => {
        clearTimeout(timer)
        window.removeEventListener('keydown', handlingKeyPress)
      }
    }
    setIsOpen(isOpenModal)

    return () => window.removeEventListener('keydown', handlingKeyPress)
  }, [isOpenModal])

  if (!isOpen) {
    return null
  }
  return ReactDOM.createPortal(
    <Core animation={animation}>
      {!isSkipClose && (
        <Overlay onClick={() => setIsOpenModal(false)} />
      )}
      <Content>
      {!isSkipClose && (
          <ActionClose onClick={() => setIsOpenModal(false)}>
            <Close />
          </ActionClose>
        )}
        <Body>{children}</Body>
      </Content>
    </Core>,
    document.getElementById('portal') as HTMLElement,
  )
}

export interface Core {
  animation: null | 'opening' | 'closing'
}

const Core = styled.div<Core>`
  position: fixed;
  z-index: 999;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(#000, 0.75);
  animation-duration: 0.3s;
  animation-fill-mode: forwards;
  opacity: 0;
  font-size: 0;
  overflow-y: auto;
  text-align: center;

  &::before {
    content: '';
    width: 0;
    height: 100%;
    display: inline-block;
    vertical-align: middle;
  }

  &.animation-opening {
    opacity: 0;
    animation-name: opening;
  }

  &.animation-closing {
    opacity: 1;
    animation-name: closing;
  }
  @keyframes opening {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  @keyframes closing {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
`

const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`

const Content = styled.div`
  width: calc(100% - 90px);
  padding-top: 40px;
  padding-bottom: 40px;
  max-width: 440px;

  position: relative;
  text-align: left;
  display: inline-block;
  vertical-align: middle;
`

const ActionClose = styled.button`
  @import '../../assets/styles/mixins.scss';
  @include anim(opacity);
  position: absolute;
  left: 100%;
  top: 40px;
  width: 54px;
  height: 40px;
  fill: #fff;

  svg {
    display: block;
    margin-left: auto;
    margin-right: auto;
  }

  &:hover {
    opacity: 0.8;
  }
`

const Body = styled.div`
  background-color: var(--color-bg-dark);
  border-radius: var(--size-radius);
  padding: 40px;
`
