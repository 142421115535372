import React from 'react'
import styled from 'astroturf/react'
import { Container } from '@/components/wrapper'
import { Button } from '@/components/ui'
import { useLayoutContext } from '@/views/components/core/LayoutProvider'
import { PhotoHandler } from './components'
import { SharingAvatar } from '@/views/components/common'
import { Modal } from '@/components/wrapper'
import { useRQ, getSetting } from '@/services/adapters/req'
import { storeGeneratorPipeline } from '@/services/store'
import { Setting } from '../_helper'

import avatarSharing from '@/assets/demo/avatar-sharing.png'

export const Result = () => {
  const { setIsOpenModal } = useLayoutContext()
  const { setSectionIndex } = storeGeneratorPipeline
  const {
    data: {
      texts: {
        sections: { result: PAGE },
      },
    },
  } = useRQ<Setting>(getSetting)
  return (
    <Container variant='center'>
      <PhotoHandler title={PAGE.title} descr={PAGE.descr}>
        <Action wSize='full' onClick={() => setIsOpenModal(true)}>
          {PAGE.actions.share}
        </Action>
        <Action wSize='full' variant='surface' onClick={() => setSectionIndex(0)}>
          {PAGE.actions.to_home}
        </Action>
      </PhotoHandler>
      <Modal>
        <SharingAvatar img={avatarSharing} link={'metaperson.com/axfsdfsdfsdk.html'} />
      </Modal>
    </Container>
  )
}

const Action = styled(Button)`
  &:not(:last-child) {
    margin-bottom: 12px;
  }
`
