// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Generator-CoreOne-module--cls1--vVW2K { /*!*/ }\n\n.Generator-CoreOne-module--cls2--FZwjn {\n  position: fixed;\n  top: 0;\n  right: 0;\n}", "",{"version":3,"sources":["webpack://./src/views/page/generator/Generator-CoreOne.module.scss"],"names":[],"mappings":"AAAA,yCAAA,IAAA,EAAA;;AACA;EAGA,eAAA;EACA,MAAA;EACA,QAAA;AACA","sourcesContent":[".cls1 { /*!*/ }\n.cls2 {\ncomposes: cls1;\n\nposition: fixed;\ntop: 0;\nright: 0;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"cls1": "Generator-CoreOne-module--cls1--vVW2K",
	"cls2": "Generator-CoreOne-module--cls2--FZwjn Generator-CoreOne-module--cls1--vVW2K"
};
export default ___CSS_LOADER_EXPORT___;
