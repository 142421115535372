import React, { FC } from 'react'
import { observer } from 'mobx-react-lite'
import { HexColorPicker, HexColorInput } from 'react-colorful'
import { CirclePicker, CompactPicker, SliderPicker, GithubPicker } from 'react-color'
import { useLayoutContext } from '@/views/components/core/LayoutProvider'

import { storeGeneratorPipeline } from '@/services/store'
import { storeEditor } from '@/services/store'
import { Box, WrapBox, RangeSlider } from '@/views/page/generator/sections/components'

export const EditorFace: FC = observer(() => {
  const versionUI = storeEditor.versionUI  // to rerender after observable versionUI increment

  const { face, setFace } = storeEditor
  const { mediaQueries } = useLayoutContext()

  // console.info('window.screen.width:', window.screen.width)

  //  const customWidth = mediaQueries.isTablet ? 170 : -1
  const customWidth = mediaQueries.isTablet ? 170 : window.screen.width - 50

  const hexColorPickerStyle = {
    width: '150px',
    height: '150px',
  }

  //  const lipsColorPicker = ( <Box title={'Lips color'} customWidth={customWidth}>       <HexColorPicker
  //           color={storeGeneratorPipeline.avatar.lipsColor ? storeGeneratorPipeline.avatar.lipsColor : '#ffffff'}
  //           onChange={(color) => storeGeneratorPipeline.setLipsColor(color)}
  //           style={hexColorPickerStyle}
  //         /></Box>)

  const eyebrowsColorPicker = (
    <Box title={'Eyebrows color'} customWidth={customWidth}>
      {' '}
      <CirclePicker
        width={customWidth}
        colors={[
          storeGeneratorPipeline.avatar.eyebrowsColorInitial,
          '#BCA888',
          '#AC9880',
          '#9C8979',
          '#877767',
          '#726556',
          '#705948',
          '#6F4E3B',
          '#614939',
          '#514437',
          '#38322B',
          '#202020',
        ]}
        color={storeGeneratorPipeline.avatar.eyebrowsColor}
        onChangeComplete={(color) => storeGeneratorPipeline.setEyebrowsColor(color.hex)}
      />{' '}
    </Box>
  )

  const lipsColorPicker = (
    <Box title={'Lips color'} customWidth={customWidth}>
      {' '}
      <CirclePicker
        width={customWidth}
        colors={[
          storeGeneratorPipeline.avatar.lipsColorInitial,
          '#EED1C6',
          '#E5C9C1',
          '#C6A095',
          '#B27C74',
          '#99606D',
          '#E5867E',
          '#D08A87',
          '#BD796A',
          '#BA6B59',
          '#863035',
          '#611008',
        ]}
        color={storeGeneratorPipeline.avatar.lipsColor}
        onChangeComplete={(color) => storeGeneratorPipeline.setLipsColor(color.hex)}
      />{' '}
    </Box>
  )

  // console.log("EyebrowColor(), color:", storeGeneratorPipeline.avatar.eyebrowsColor)
  // console.log("lipsColor(), color:", storeGeneratorPipeline.avatar.lipsColor)

  // const eyebrowsColorPicker = (
  //   <Box title={'Eyebrows color'} customWidth={customWidth}>
  //     <SliderPicker
  //       width={customWidth}
  //       color={storeGeneratorPipeline.avatar.eyebrowsColor ? storeGeneratorPipeline.avatar.eyebrowsColor : '#ffffff'}
  //       onChangeComplete={(color) => storeGeneratorPipeline.setEyebrowsColor(color.hex)}
  //     />
  //   </Box>
  // )

  // const lipsColorPicker = (
  //   <Box title={'Lips color'} customWidth={customWidth}>
  //     <SliderPicker
  //       width={customWidth}
  //       color={storeGeneratorPipeline.avatar.lipsColor ? storeGeneratorPipeline.avatar.lipsColor : '#ffffff'}
  //       onChangeComplete={(color) => storeGeneratorPipeline.setLipsColor(color.hex)}
  //     />
  //   </Box>
  // )

  return (
    <div style={mediaQueries.isTablet === false ? {} : { height: '75%', overflowY: 'scroll' }}>
      <WrapBox height={15}>
        {storeGeneratorPipeline.avatar.eyebrowsColorInitial && eyebrowsColorPicker}
        {storeGeneratorPipeline.avatar.lipsColorInitial && lipsColorPicker}

        <Box title={'Head Height'}>
          <RangeSlider max={10} min={0} step={1} values={face.headHeight} setValue={(headHeight) => setFace({ headHeight })} />
        </Box>

        <Box title={'Upper Head Volume'}>
          <RangeSlider max={10} min={0} step={1} values={face.upperHeadVolume} setValue={(upperHeadVolume) => setFace({ upperHeadVolume })} />
        </Box>

        <Box title={'Upper Head Width'}>
          <RangeSlider max={10} min={0} step={1} values={face.upperHeadWidth} setValue={(upperHeadWidth) => setFace({ upperHeadWidth })} />
        </Box>

        <Box title={'Lower Head Width'}>
          <RangeSlider max={10} min={0} step={1} values={face.lowerHeadWidth} setValue={(lowerHeadWidth) => setFace({ lowerHeadWidth })} />
        </Box>

        <Box title={'Jaw Line'}>
          <RangeSlider max={10} min={0} step={1} values={face.jawLine} setValue={(jawLine) => setFace({ jawLine })} />
        </Box>

        <Box title={'Eyes'}>
          <RangeSlider max={10} min={0} step={1} values={face.eyes} setValue={(eyes) => setFace({ eyes })} />
        </Box>
        <Box title={'Nose'}>
          <RangeSlider max={10} min={0} step={1} values={face.nose} setValue={(nose) => setFace({ nose })} />
        </Box>
        <Box title={'Lips'}>
          <RangeSlider max={10} min={0} step={1} values={face.lips} setValue={(lips) => setFace({ lips })} />
        </Box>
      </WrapBox>
    </div>
  )
})

EditorFace.displayName = 'EditorFace'
