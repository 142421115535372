import React, { FunctionComponent } from 'react'
import { useEffect, useState } from 'react'

//const DATA_SIZE = 100
//const data = new Array(DATA_SIZE)
//export let idx = DATA_SIZE

export const FpsCounter = ({ variant, qualityLevel, setQualityLevel, displayFpsCounter }) => {
  //console.log('FpsCounter(), qualityLevel:', qualityLevel)
  //console.log('  variant:', variant)

  const [frameState, setFrameState] = useState({
    prevStamp: Date.now(),
    lastStamp: Date.now(),
    framesCount: 0,

    delta: 16,
    avgDelta: 16,
    avgDelta2: 16,
    averDelta: 16,

    sumDelta: 0, // summa of delta
    sumDelta100: 0, // summa of delta>100 mc
    sumDelta40: 0, // summa of delta<40 mc
  })

  useEffect(() => {
    let timeout = null

    requestAnimationFrame(() => {
      timeout = setTimeout(() => {
        const currentStamp = Date.now()
        const delta = currentStamp - frameState.prevStamp

/*
        if (idx < DATA_SIZE) {
          data[idx++] = delta

          if (idx == DATA_SIZE) {
            // console.log('Save data')

            let content = ''
            for (let i = 0; i < DATA_SIZE; ++i) content += `${i}, ${data[i]}\n`

            const link = document.createElement('a')
            const file = new Blob([content], { type: 'text/plain' })
            link.href = URL.createObjectURL(file)
            link.download = 'sample.txt'
            link.click()
            URL.revokeObjectURL(link.href)
          }
        }
*/
        let count10 = delta >= 100 ? frameState.count10 + 1 : 0
        let count25 = delta <= 40 ? frameState.count25 + 1 : 0

        let sumDelta40 = delta <= 40 ? frameState.sumDelta40 + delta : 0
        let sumDelta100 = delta >= 100 ? frameState.sumDelta100 + delta : 0

        if (variant === 'not-mobile') {
          if (sumDelta100 >= 3000) {
            // 3 s
            if (qualityLevel > 1) setQualityLevel(qualityLevel - 1)

            count10 = 0
            sumDelta100 = 0
          }

          if (sumDelta40 >= 3000) {
            // 3 s
            if (qualityLevel < 3) {
              setQualityLevel(qualityLevel + 1)
            }

            sumDelta40 = 0
            count25 = 0
          }
        } else {
          // if averFps<10 decrease quality level
          //          if (frameState.avgDelta2 > 100 && qualityLevel > 1)
          if (frameState.averDelta > 100 && qualityLevel > 1) setQualityLevel(qualityLevel - 1)

          // if averFps>25	 increase quality level
          //          if (frameState.avgDelta2 < 40 && qualityLevel < 3)
          if (frameState.averDelta < 40 && qualityLevel < 3) setQualityLevel(qualityLevel + 1)
        }

        const avgDelta = frameState.avgDelta + (delta - frameState.avgDelta) * 0.1

        if (currentStamp - frameState.lastStamp > 1000) {
          setFrameState({
            framesCount: 0,
            prevStamp: currentStamp,
            lastStamp: currentStamp,
            delta: delta,
            avgDelta: avgDelta,
            avgDelta2: avgDelta,
            averDelta: frameState.framesCount > 0 ? frameState.sumDelta / frameState.framesCount : 70,
            sumDelta: 0,
            sumDelta40: sumDelta40,
            sumDelta100: sumDelta100,
          })
        } else {
          setFrameState({
            ...frameState,
            framesCount: frameState.framesCount + 1,
            prevStamp: currentStamp,
            delta: delta,
            avgDelta: avgDelta,
            sumDelta: frameState.sumDelta + delta,
            sumDelta40: sumDelta40,
            sumDelta100: sumDelta100,
          })
        }
      }, 0)
    })
    return () => {
      if (timeout) {
        clearTimeout(timeout)
      }
    }
  }, [frameState])

/*
  useEffect(() => {
    //console.log('useEffect() - addEventListener()')

    addEventListener('keydown', (event) => {
      //console.log('Key pressed, event.code:', event.code)

      if (event.code === 'KeyS') {
        // console.log('keyS pressed')
        idx = 0
      }
    })
  }, [])
*/

  //        Delta: {frameState.delta.toFixed(2)},
  //        <br /> sumDelta40: {frameState.sumDelta40}
  //        <br /> sumDelta100: {frameState.sumDelta100}

  //<button onClick={() => {console.log('Recording...'); idx=0; }}>Record</button>

  const fps = 1000 / frameState.delta
  const averFps = 1000 / frameState.avgDelta2
  const averDelta = frameState.averDelta

  const bottom = variant === 'not-mobile' ? '20px' : '270px'

  return displayFpsCounter && (
    <div style={{ position: 'absolute', zIndex: '1', bottom: bottom, right: '20px' }}>
      <div>
        FPS: {fps.toFixed(2)}
        <br /> Average Delta: {averDelta.toFixed(2)}
        <br /> Average FPS: {averFps.toFixed(2)}
        <br /> Quality level: {qualityLevel}
      </div>
    </div>
  )
}
