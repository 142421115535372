import React, { FC, SVGProps } from 'react'
import styled from 'astroturf/react'

export interface CoverProps {
  width: number
  height: number
  props?: SVGProps<SVGSVGElement>
}

export const Cover: FC<CoverProps> = ({ width, height, ...props }) => {
  return (
    <Core viewBox={`0 0 ${width} ${height}`} fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <rect width={width} height={height} />
    </Core>
  )
}

const Core = styled.svg`
  width: 100%;
  display: block;
`
