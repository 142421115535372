import React, { FC, useState, useEffect } from 'react'
import ReactDOM from 'react-dom'

import styled from 'astroturf/react'

import { storeEditor, storeGeneratorPipeline } from '@/services/store'

import { Button } from '@/components/ui'
import { ProgressBar } from '@/components/ui'


export const TextureFromImageDialog: FC = (props) => {
  //console.info("TextureFromImageDialog, props:", props)

  const isLight = window.matchMedia('(prefers-color-scheme: light)').matches
  //console.log("isLight:", isLight)

  const textColor = isLight ? "black" : "white"
  const imageWidth = props.variant==="not-mobile"? 240 : 120

  const [front, setFront] = useState(null)
  const [back, setBack] = useState(null)

  const [progress, setProgress] = useState(0)

  const [isProgressShown, setIsProgressShown] = useState(false)

  function handleChange(type, event) {
    if (isProgressShown) return

    //console.log("handleChange(), type:", type)

    const reader = new FileReader();
    reader.addEventListener("load", () => {
      const dataUrl = reader.result

      const arr = dataUrl.split(',')
      const mime = arr[0].match(/:(.*?);/)[1]
      const bstr = atob(arr[1])
      let n = bstr.length
      const u8arr = new Uint8Array(n)
      while(n--)
        u8arr[n] = bstr.charCodeAt(n);
      const blob = new Blob([u8arr], {type:mime});

      if (type==='front')
        setFront(URL.createObjectURL(blob))
      else
        setBack(URL.createObjectURL(blob))
    }  , false)

    if (event.target.files[0]) {
      reader.readAsDataURL(event.target.files[0]);
    }
  }

  const handleSubmitImagesForTexture = (front, back) => {
    if (isProgressShown) return

    //console.info("handleSubmitImagesForTexture()", front, back)

    const slot = props.slot
    //console.log("  slot:", props.slot)

    setIsProgressShown(true)

    if (front!==null && back!==null) {
      storeEditor.setOutfitReady(false)
      storeGeneratorPipeline.createTextureFromImage(storeEditor.outfits[slot].name, front, back, (res) => {
        //console.log("res:", res)

        if (res.status === "Error" || res.status === "Failed") {
          //console.log("Error, res:", res)
          storeEditor.setOutfitReady(true)
          storeEditor.needsUpdate(true) 
          props.onClose()
          return
        }

        if (res.status !== 'Ready') {
          setProgress( res.status==="Queued" ? 1 : (res.progress>0 ? res.progress : 2) )
          return
        }

        storeEditor.setOutfits(slot, storeEditor.outfits.subtype, storeEditor.outfits[slot].name, res.data, res.texture_code)
        storeEditor.setOutfitReady(true)
        storeEditor.needsUpdate(true)
        props.onClose()
      })
    } else {
      storeEditor.setOutfitReady(false)
      storeEditor.setOutfits(slot, storeEditor.outfits.subtype, storeEditor.outfits[slot].name, null, null)
      storeEditor.setOutfitReady(true)
      storeEditor.needsUpdate(true)
      props.onClose()
    }
  }

  return ReactDOM.createPortal(
    <Core animation={'opening'}>
      <Content>
        <Body style={{color: textColor}}>
          <b>
            <table width="100%"><tbody><tr>
              <td align="left">Generate t-shirt texture from photo</td>
              <td align="right"><button disabled={isProgressShown} type='button' onClick={() => props.onClose()}><span style={{color: textColor}}>X</span> </button></td>
            </tr></tbody></table>
          </b><hr /><br/>
          {props.variant==="not-mobile" && <div>
            <p>1. Use a regular T-short (not a polo).</p>
            <p>2. Lay the flat on a surface, avoid folds.</p>
            <p>3. Background can be arbitrary but preferably uniform.</p>
            <p>4. Ensure the T-short is centered and parallel to the camera plain.</p>
            <p>5. Well-lit environment with uniform lightings, same for both front and back photos .</p>
            <p>6. Take two photos: front and back of the T-shirt (in PNG or JPG formats only).</p>
          </div>}
          <div align="center"><table style={{borderSpacing: "20px" }}><tbody><tr>
            <td align="center">
              <label htmlFor="chooseFront"><img border="1" width={imageWidth} height={1.33*imageWidth} src={front ? front : (isLight ? '/front-g.svg' : '/front.svg')}/></label><br/>
              <input disabled={isProgressShown} type="file" accept="image/png, image/jpeg" id="chooseFront" style={{ display: "none" }} onChange={(event) => handleChange('front', event) } />
            </td>
            <td align="center">
              <label htmlFor="chooseBack"><img border="1" width={imageWidth} height={1.33*imageWidth} src={back ? back : (isLight ? '/back-g.svg' : '/back.svg')} /></label><br/>
              <input disabled={isProgressShown} type="file" accept="image/png, image/jpeg" id="chooseBack" style={{ display: "none" }} onChange={(event) => handleChange('back', event)} />
            </td>
          </tr>

          {!isProgressShown && (<tr>
            <td align="left">
              <Button wSize='auto' variant='surface' type='button' onClick = { () => { handleSubmitImagesForTexture(null, null) } }>
                Use Default
              </Button>
            </td>
            <td align="right">
              <Button align="center" disabled={front===null || back===null} type='button' onClick = { () => { if (front!==null && back!==null) handleSubmitImagesForTexture(front, back) }}>
                Upload
              </Button>
            </td>
          </tr> )}

          </tbody></table></div>

          {isProgressShown && <ProgressBar value={progress} max={100} />}
 
        </Body>
      </Content>
    </Core>,
    document.getElementById('portal') as HTMLElement,
  )
}

const Core = styled.div<Core>`
  position: fixed;
  z-index: 999;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(#000, 0);

  animation-duration: 1s;
  animation-fill-mode: forwards;
  opacity: 0;
  font-size: 10;
  overflow-y: auto;
  text-align: center;

  &::before {
    content: '';
    width: 0;
    height: 100%;
    display: inline-block;
    vertical-align: middle;
  }

  &.animation-opening {
    opacity: 0;
    animation-name: opening;
  }

  &.animation-closing {
    opacity: 1;
    animation-name: closing;
  }

  @keyframes opening {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  @keyframes closing {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
`

const Content = styled.div`
  //width: calc(100% - 90px);
  padding-top: 40px;
  padding-bottom: 40px;
  //max-width: 600px;

  position: relative;
  text-align: left;
  display: inline-block;
  vertical-align: middle;
`

const Body = styled.div`
  font-family: sans-serif;
  align: center;
  border: 2px solid gray;
  color: rgba(#000, 1);
  background-color: var(--color-bg-dark);
  border-radius: var(--size-radius);
  padding: 20px;
`
