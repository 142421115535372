// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Links-Core-module--cls1--Wxktq { /*!*/ }\n\n.Links-Core-module--cls2--WBKhK {\n  position: fixed;\n  bottom: 10px;\n  right: 0;\n  z-index: 10000;\n  margin-right: 32px;\n}", "",{"version":3,"sources":["webpack://./src/helpers/Links-Core.module.scss"],"names":[],"mappings":"AAAA,kCAAA,IAAA,EAAA;;AACA;EAGA,eAAA;EACA,YAAA;EACA,QAAA;EACA,cAAA;EACA,kBAAA;AACA","sourcesContent":[".cls1 { /*!*/ }\n.cls2 {\ncomposes: cls1;\n\nposition: fixed;\nbottom: 10px;\nright: 0;\nz-index: 10000;\nmargin-right: 32px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"cls1": "Links-Core-module--cls1--Wxktq",
	"cls2": "Links-Core-module--cls2--WBKhK Links-Core-module--cls1--Wxktq"
};
export default ___CSS_LOADER_EXPORT___;
