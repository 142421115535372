// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".PrivatePageLayout-PrivatePageContainer-module--cls1--VoAGp { /*!*/ }\n\n.PrivatePageLayout-PrivatePageContainer-module--cls2--iGt0p {\n  padding-top: var(--content-base-offset);\n  position: relative;\n}", "",{"version":3,"sources":["webpack://./src/views/components/layouts/PrivatePageLayout/PrivatePageLayout-PrivatePageContainer.module.scss"],"names":[],"mappings":"AAAA,8DAAA,IAAA,EAAA;;AACA;EAGA,uCAAA;EACA,kBAAA;AACA","sourcesContent":[".cls1 { /*!*/ }\n.cls2 {\ncomposes: cls1;\n\npadding-top: var(--content-base-offset);\nposition: relative;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"cls1": "PrivatePageLayout-PrivatePageContainer-module--cls1--VoAGp",
	"cls2": "PrivatePageLayout-PrivatePageContainer-module--cls2--iGt0p PrivatePageLayout-PrivatePageContainer-module--cls1--VoAGp"
};
export default ___CSS_LOADER_EXPORT___;
