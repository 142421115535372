// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Generator-Content-module--cls1--J_yPQ { /*!*/ }\n\n.Generator-Content-module--cls2--NtBDl {\n  padding-top: 40px;\n  padding-bottom: 40px;\n  position: relative;\n  text-align: left;\n  display: inline-block;\n  vertical-align: middle;\n}", "",{"version":3,"sources":["webpack://./src/views/page/generator/Generator-Content.module.scss"],"names":[],"mappings":"AAAA,yCAAA,IAAA,EAAA;;AACA;EAGA,iBAAA;EACA,oBAAA;EAEA,kBAAA;EACA,gBAAA;EACA,qBAAA;EACA,sBAAA;AAAA","sourcesContent":[".cls1 { /*!*/ }\n.cls2 {\ncomposes: cls1;\n\npadding-top: 40px;\npadding-bottom: 40px;\n\nposition: relative;\ntext-align: left;\ndisplay: inline-block;\nvertical-align: middle;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"cls1": "Generator-Content-module--cls1--J_yPQ",
	"cls2": "Generator-Content-module--cls2--NtBDl Generator-Content-module--cls1--J_yPQ"
};
export default ___CSS_LOADER_EXPORT___;
