// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".AppsCard-Icon-module--cls1--OkNWc { /*!*/ }\n\n.AppsCard-Icon-module--cls2--taY4a {\n}", "",{"version":3,"sources":["webpack://./src/views/page/apps/components/AppsCard-Icon.module.scss"],"names":[],"mappings":"AAAA,qCAAA,IAAA,EAAA;;AACA;AAGA","sourcesContent":[".cls1 { /*!*/ }\n.cls2 {\ncomposes: cls1;\n\n\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"cls1": "AppsCard-Icon-module--cls1--OkNWc",
	"cls2": "AppsCard-Icon-module--cls2--taY4a AppsCard-Icon-module--cls1--OkNWc"
};
export default ___CSS_LOADER_EXPORT___;
