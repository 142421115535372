// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Sidebar-LogoutAction-module--cls1--mtXM7 { /*!*/ }\n\n.Sidebar-LogoutAction-module--cls2--OnoOW {\n  margin-left: 12px;\n}", "",{"version":3,"sources":["webpack://./src/views/components/layouts/PrivatePageLayout/components/Sidebar-LogoutAction.module.scss"],"names":[],"mappings":"AAAA,4CAAA,IAAA,EAAA;;AACA;EAGA,iBAAA;AACA","sourcesContent":[".cls1 { /*!*/ }\n.cls2 {\ncomposes: cls1;\n\nmargin-left: 12px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"cls1": "Sidebar-LogoutAction-module--cls1--mtXM7",
	"cls2": "Sidebar-LogoutAction-module--cls2--OnoOW Sidebar-LogoutAction-module--cls1--mtXM7"
};
export default ___CSS_LOADER_EXPORT___;
