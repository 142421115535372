// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ColorPicker-Presets-module--cls1--ggGAI { /*!*/ }\n\n.ColorPicker-Presets-module--cls2--rlAUu {\n  gap: 8px;\n}\n.ColorPicker-Presets-module--cls2--rlAUu.ColorPicker-Presets-module--variant-mobile--zSUZm {\n  display: flex;\n  overflow-x: auto;\n  width: 100%;\n}\n.ColorPicker-Presets-module--cls2--rlAUu.ColorPicker-Presets-module--variant-not-mobile--MtQnR {\n  display: grid;\n  grid-template-columns: repeat(6, 1fr);\n}", "",{"version":3,"sources":["webpack://./src/views/page/generator/sections/components/ColorPicker-Presets.module.scss"],"names":[],"mappings":"AAAA,2CAAA,IAAA,EAAA;;AACA;EAGA,QAAA;AACA;AACA;EACA,aAAA;EACA,gBAAA;EACA,WAAA;AACA;AAEA;EACA,aAAA;EACA,qCAAA;AAAA","sourcesContent":[".cls1 { /*!*/ }\n.cls2 {\ncomposes: cls1;\n\ngap: 8px;\n\n&.variant-mobile {\ndisplay: flex;\noverflow-x: auto;\nwidth: 100%;\n}\n\n&.variant-not-mobile {\ndisplay: grid;\ngrid-template-columns: repeat(6, 1fr);\n}\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"cls1": "ColorPicker-Presets-module--cls1--ggGAI",
	"cls2": "ColorPicker-Presets-module--cls2--rlAUu ColorPicker-Presets-module--cls1--ggGAI",
	"variant-mobile": "ColorPicker-Presets-module--variant-mobile--zSUZm",
	"variant-not-mobile": "ColorPicker-Presets-module--variant-not-mobile--MtQnR"
};
export default ___CSS_LOADER_EXPORT___;
