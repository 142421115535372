// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".AvatarSelection-CssProp1_AvatarCard-module--cls1--RFyu5 { /*!*/ }\n\n.AvatarSelection-CssProp1_AvatarCard-module--cls2--BHwIU {\n  margin-top: 12px;\n}", "",{"version":3,"sources":["webpack://./src/views/page/apps/AvatarSelection-CssProp1_AvatarCard.module.scss"],"names":[],"mappings":"AAAA,2DAAA,IAAA,EAAA;;AACA;EAGA,gBAAA;AACA","sourcesContent":[".cls1 { /*!*/ }\n.cls2 {\ncomposes: cls1;\n\nmargin-top: 12px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"cls1": "AvatarSelection-CssProp1_AvatarCard-module--cls1--RFyu5",
	"cls2": "AvatarSelection-CssProp1_AvatarCard-module--cls2--BHwIU AvatarSelection-CssProp1_AvatarCard-module--cls1--RFyu5"
};
export default ___CSS_LOADER_EXPORT___;
