// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".MPresets-Img-module--cls1--oPCau { /*!*/ }\n\n.MPresets-Img-module--cls2--hXH5C {\n  position: absolute;\n  width: 100%;\n  height: 100%;\n  border-radius: var(--size-radius-small);\n  top: 0;\n  left: 0;\n}", "",{"version":3,"sources":["webpack://./src/views/page/generator/sections/components/MPresets-Img.module.scss"],"names":[],"mappings":"AAAA,oCAAA,IAAA,EAAA;;AACA;EAGA,kBAAA;EACA,WAAA;EACA,YAAA;EACA,uCAAA;EACA,MAAA;EACA,OAAA;AACA","sourcesContent":[".cls1 { /*!*/ }\n.cls2 {\ncomposes: cls1;\n\nposition: absolute;\nwidth: 100%;\nheight: 100%;\nborder-radius: var(--size-radius-small);\ntop: 0;\nleft: 0;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"cls1": "MPresets-Img-module--cls1--oPCau",
	"cls2": "MPresets-Img-module--cls2--hXH5C MPresets-Img-module--cls1--oPCau"
};
export default ___CSS_LOADER_EXPORT___;
