// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".WrapBox-Core-module--cls1--qM4uk { /*!*/ }\n\n.WrapBox-Core-module--cls2--LPuoy {\n}\n.WrapBox-Core-module--cls2--LPuoy.WrapBox-Core-module--variant-mobile--cAet7 {\n  max-height: var(--a1qr6ui0);\n  overflow-y: auto;\n}", "",{"version":3,"sources":["webpack://./src/views/page/generator/sections/components/WrapBox-Core.module.scss"],"names":[],"mappings":"AAAA,oCAAA,IAAA,EAAA;;AACA;AAGA;AAAA;EACA,2BAAA;EACA,gBAAA;AAEA","sourcesContent":[".cls1 { /*!*/ }\n.cls2 {\ncomposes: cls1;\n\n&.variant-mobile {\nmax-height: var(--a1qr6ui0);\noverflow-y: auto;\n}\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"cls1": "WrapBox-Core-module--cls1--qM4uk",
	"cls2": "WrapBox-Core-module--cls2--LPuoy WrapBox-Core-module--cls1--qM4uk",
	"variant-mobile": "WrapBox-Core-module--variant-mobile--cAet7"
};
export default ___CSS_LOADER_EXPORT___;
