// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".List-Action-module--cls1--w2o0n { /*!*/ }\n\n.List-Action-module--cls2--dnJAA {\n}", "",{"version":3,"sources":["webpack://./src/views/page/gallery/components/List/List-Action.module.scss"],"names":[],"mappings":"AAAA,mCAAA,IAAA,EAAA;;AACA;AAGA","sourcesContent":[".cls1 { /*!*/ }\n.cls2 {\ncomposes: cls1;\n\n\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"cls1": "List-Action-module--cls1--w2o0n",
	"cls2": "List-Action-module--cls2--dnJAA List-Action-module--cls1--w2o0n"
};
export default ___CSS_LOADER_EXPORT___;
