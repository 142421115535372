import React from 'react'
import { ReactComponent as Logo } from '@/assets/img/logo.svg'
import { Home } from '@/components/ui/icons'
import styled from 'astroturf/react'
import { Button } from '@/components/ui'
import { useNavigate } from "react-router-dom";



export const WaitingConfirmation = () => {
  const navigate = useNavigate();
  const navigateTo = () => {
    navigate('/')
  }
  return (
    <>
    <Header>
        <Button isOnlyIcon variant='surface' onClick={navigateTo}>
          <Home />
          </Button>
      </Header>    
      <MiddleContent>
    <div className='app'>
      <Logo />
      <h2>MetaPerson</h2>
    </div>
      </MiddleContent>
    </>
  )
}


const Header = styled.div`
  display: flex;
  width: calc(100% - 64px);
  margin-left: auto;
  margin-right: auto;
  padding-top: 32px;
  position: absolute;
  justify-content: space-between;
  top: 0;
  left: 32px;
  z-index: 5;
`

const MiddleContent = styled.div`
  margin-top: auto;
  margin-bottom: auto;
  max-width: 360px;
  width: 100%;
`