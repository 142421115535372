import React, { FC, useState } from 'react'
import styled from 'astroturf/react'

import { AvatarPreview } from '@/components/ui'
import { Star, StarFill, KebabMenu } from '@/components/ui/icons'

export interface GalleryCardProps {
  img: string
  handleClick: () => void
}

export const GalleryCard: FC<GalleryCardProps> = ({ img, handleClick }) => {
  const [isShow, setIsShow] = useState(false)

  return (
    <Core>
      <AvatarPreview handleClick={handleClick} img={img} width={300} height={245} />
      <Favourite onClick={() => setIsShow((prev) => !prev)}>
        <Star />
        <StarFillIcon isShow={isShow} />
      </Favourite>
      <Menu>
        <KebabMenu />
      </Menu>
    </Core>
  )
}

const Core = styled.div`
  position: relative;
  display: block;
  cursor: pointer;
`

const Favourite = styled.button`
  @import '../../../../../../assets/styles/mixins.scss';
  @include anim(opacity);
  position: absolute;
  width: 54px;
  height: 54px;
  top: 0;
  left: 0;
  fill: var(--color-base);
  opacity: 0;

  ${Core}:hover & {
    opacity: 1;
  }
`

export interface StarFillProps {
  isShow?: boolean
}

const StarFillIcon = styled(StarFill)<StarFillProps>`
  @import '../../../../../../assets/styles/mixins.scss';
  @include anim(opacity);
  position: absolute;
  top: 50%;
  left: 50%;
  opacity: 0;
  transform: translate(-50%, -50%);

  &.isShow {
    opacity: 1;
  }
`

const Menu = styled.button`
  @import '../../../../../../assets/styles/mixins.scss';
  @include anim(opacity);
  position: absolute;
  fill: var(--color-base);
  width: 54px;
  height: 54px;
  top: 0;
  right: 0;
  opacity: 0;

  ${Core}:hover & {
    opacity: 1;
  }
`
