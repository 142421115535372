// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Generator-ProgressContainer-module--cls1--I6UHk { /*!*/ }\n\n.Generator-ProgressContainer-module--cls2--mEbhd {\n  width: 100%;\n  height: 20px;\n  background-color: #f2f2f2;\n  border-radius: 10px;\n  overflow: hidden;\n}", "",{"version":3,"sources":["webpack://./src/views/page/generator/Generator-ProgressContainer.module.scss"],"names":[],"mappings":"AAAA,mDAAA,IAAA,EAAA;;AACA;EAGA,WAAA;EACA,YAAA;EACA,yBAAA;EACA,mBAAA;EACA,gBAAA;AACA","sourcesContent":[".cls1 { /*!*/ }\n.cls2 {\ncomposes: cls1;\n\nwidth: 100%;\nheight: 20px;\nbackground-color: #f2f2f2;\nborder-radius: 10px;\noverflow: hidden;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"cls1": "Generator-ProgressContainer-module--cls1--I6UHk",
	"cls2": "Generator-ProgressContainer-module--cls2--mEbhd Generator-ProgressContainer-module--cls1--I6UHk"
};
export default ___CSS_LOADER_EXPORT___;
