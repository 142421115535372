// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".index-Icons-module--cls1--aUWs8 { /*!*/ }\n\n.index-Icons-module--cls2--zPW7V {\n  width: 32px;\n  height: 32px;\n  flex-shrink: 0;\n  margin-right: 12px;\n}", "",{"version":3,"sources":["webpack://./src/services/adapters/toast/index-Icons.module.scss"],"names":[],"mappings":"AAAA,mCAAA,IAAA,EAAA;;AACA;EAGA,WAAA;EACA,YAAA;EACA,cAAA;EACA,kBAAA;AACA","sourcesContent":[".cls1 { /*!*/ }\n.cls2 {\ncomposes: cls1;\n\nwidth: 32px;\nheight: 32px;\nflex-shrink: 0;\nmargin-right: 12px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"cls1": "index-Icons-module--cls1--aUWs8",
	"cls2": "index-Icons-module--cls2--zPW7V index-Icons-module--cls1--aUWs8"
};
export default ___CSS_LOADER_EXPORT___;
