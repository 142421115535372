import { storeGeneratorPipeline } from '@/services/store/generatorPipeline'
import { storeEditor } from '@/services/store/editor'

export const cameraPositions = (position, isNotTablet) => {
  //console.log(`cameraPosition(${position}, ${isNotTablet})`)

  const subtype = storeGeneratorPipeline.avatar.subtype
  const bodyHeight = storeEditor.body.height[0]
  //console.log("bodyHeight:", bodyHeight)

  /* eslint-disable no-fallthrough */
  switch (position) {
    case 'BODY':
      return { x: 0, y: bodyHeight / 100 - 0.2, z: isNotTablet ? 5.1 : 5.5, a: isNotTablet ? 1.05 : 1.1, polar: 1.33 }
    case 'HEAD':
      return { x: 0, y: bodyHeight / 100 - 0.1, z: 0.9, a: bodyHeight / 100 - 0.1, polar: Math.PI / 2 }

    case 'EditorBody':
    case 'EditorAnimation':
    case 'EditorAge':
      return { x: 0, y: 1.45, z: 6.3, a: 1.3, polar: 1.33 }

    case 'EditorShirt':
      return { x: 0, y: 1.45, z: 6.5, a: 1.3, polar: 1.33 }

    case 'EditorSkinColor':
    case 'EditorFace':
    case 'EditorGlasses':
      return {
        x: 0,
        y: bodyHeight / 100 - 0.1,
        z: 1.1,
        a: bodyHeight / 100 - 0.1,
        polar: Math.PI / 2,
      }

    case 'EditorHair':
      return {
        x: 0,
        y: bodyHeight / 100 - 0.05,
        z: 1.1,
        a: bodyHeight / 100 - 0.05,
        polar: Math.PI / 2,
      }
  }
  /* eslint-enable no-fallthrough */

  return null
}
