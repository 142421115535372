// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ColorPicker-CssProp2_Preset-module--cls1--vzjF_ { /*!*/ }\n\n.ColorPicker-CssProp2_Preset-module--cls2--SLFVX {\n  background-image: linear-gradient(102.67deg, #1bf5ce 0%, #a9e32d 99.48%);\n}", "",{"version":3,"sources":["webpack://./src/views/page/generator/sections/components/ColorPicker-CssProp2_Preset.module.scss"],"names":[],"mappings":"AAAA,mDAAA,IAAA,EAAA;;AACA;EAGA,wEAAA;AACA","sourcesContent":[".cls1 { /*!*/ }\n.cls2 {\ncomposes: cls1;\n\nbackground-image: linear-gradient(102.67deg, #1bf5ce 0%, #a9e32d 99.48%);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"cls1": "ColorPicker-CssProp2_Preset-module--cls1--vzjF_",
	"cls2": "ColorPicker-CssProp2_Preset-module--cls2--SLFVX ColorPicker-CssProp2_Preset-module--cls1--vzjF_"
};
export default ___CSS_LOADER_EXPORT___;
