import React, { FC } from 'react'
import styled from 'astroturf/react'
import { css } from 'astroturf'
import { Typography } from '@/components/ui'
import { NavLink } from 'react-router-dom'

export interface AppsCardProps {
  icon: string
  title: string
  description: string
}

export const AppsCard: FC<AppsCardProps> = ({ icon, title, description }) => {
  return (
    <Core to='/apps/1'>
      <Icon src={icon} alt={title} />
      <Title variant='h3'>{title}</Title>
      <Descr
        css={css`
          opacity: 0.5;
        `}
      >
        {description}
      </Descr>
    </Core>
  )
}

const Core = styled(NavLink)`
  border-radius: var(--size-radius);
  background-color: var(--color-bg-filed);
  display: block;
  padding: 36px;
  color: var(--color-base);
`

const Icon = styled.img``

const Title = styled(Typography)`
  margin-top: 76px;
  max-width: 300px;
`

const Descr = styled(Typography)`
  margin-top: 4px;
  max-width: 300px;
`
