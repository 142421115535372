import React from 'react'
import styled from 'astroturf/react'
import { useForm } from 'react-hook-form'

import { Textfield, Button } from '@/components/ui'
import { TitleSection } from '@/views/components/common'
import { Home } from '@/components/ui/icons'
import { useNavigate } from "react-router-dom";


export const Developer = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm()
  const navigate = useNavigate();
  const navigateTo = () => {
    navigate('/')
  }
  const onSubmit = (data) => console.info(data)

  return (
    <>
    <Header>
    <Button isOnlyIcon variant='surface' onClick={navigateTo}>
      <Home />
      </Button>
    </Header>
      <TitleSection
        title='Become a Partner'
        descr="If you' re a company or a developer looking to integrate our avatars into your product, fill the form below."
      />
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Textfield
          wSize='full'
          error={errors}
          placeholder='Company legal name'
          {...register('company_name', { required: 'Company legal name is required' })}
        />
        <Textfield
          wSize='full'
          error={errors}
          placeholder='Product name'
          {...register('product_name', { required: 'Product name is required' })}
        />
        <Textfield
          wSize='full'
          error={errors}
          placeholder='Your full name'
          {...register('user_name', { required: 'Your full name is required' })}
        />
        <Textfield
          wSize='full'
          error={errors}
          placeholder='Work email'
          {...register('email', { required: 'Work email is required' })}
        />
        <Textfield
          wSize='full'
          error={errors}
          placeholder='Select company size'
          {...register('company_size', { required: 'Select company size is required' })}
        />
        <Textfield
          wSize='full'
          error={errors}
          placeholder='Is your product currently publicly available?'
          {...register('is_product_publicly_available', { required: 'Email Address is required' })}
        />
        <Button type='submit' wSize='full'>
          Send application
        </Button>
      </Form>
    </>
  )
}

const Form = styled.form`
  margin-top: 32px;
  max-width: 360px;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
`
const Header = styled.div`
  display: flex;
  width: calc(100% - 64px);
  margin-left: auto;
  margin-right: auto;
  padding-top: 32px;
  position: absolute;
  justify-content: space-between;
  top: 0;
  left: 32px;
  z-index: 5;
`