// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Social-Separator-module--cls1--e6g_l { /*!*/ }\n\n.Social-Separator-module--cls2--Jeme0 {\n  position: relative;\n  overflow: hidden;\n  text-align: center;\n}", "",{"version":3,"sources":["webpack://./src/views/components/common/Social-Separator.module.scss"],"names":[],"mappings":"AAAA,wCAAA,IAAA,EAAA;;AACA;EAGA,kBAAA;EACA,gBAAA;EACA,kBAAA;AACA","sourcesContent":[".cls1 { /*!*/ }\n.cls2 {\ncomposes: cls1;\n\nposition: relative;\noverflow: hidden;\ntext-align: center;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"cls1": "Social-Separator-module--cls1--e6g_l",
	"cls2": "Social-Separator-module--cls2--Jeme0 Social-Separator-module--cls1--e6g_l"
};
export default ___CSS_LOADER_EXPORT___;
