// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Button-Text-module--cls1--lCcG4 { /*!*/ }\n\n.Button-Text-module--cls2--oTdGl {\n  position: relative;\n  display: block;\n}\n.Button-Text-module--cls2--oTdGl svg {\n  display: block;\n}", "",{"version":3,"sources":["webpack://./src/components/ui/Button-Text.module.scss"],"names":[],"mappings":"AAAA,mCAAA,IAAA,EAAA;;AACA;EAGA,kBAAA;EACA,cAAA;AACA;AAAA;EACA,cAAA;AAEA","sourcesContent":[".cls1 { /*!*/ }\n.cls2 {\ncomposes: cls1;\n\nposition: relative;\ndisplay: block;\nsvg {\ndisplay: block;\n}\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"cls1": "Button-Text-module--cls1--lCcG4",
	"cls2": "Button-Text-module--cls2--oTdGl Button-Text-module--cls1--lCcG4"
};
export default ___CSS_LOADER_EXPORT___;
