export const avatarBody_fragment2 = `
  vec2 vUv = vMapUv;

  vec4 body    = texture2D(uBody, vUv);

  if (uUseSkinRecoloring)
    body.rgb = clamp(body.rgb * uR, 0., 1.);

  diffuseColor = body;
`
