// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Choice-SliderWrap-module--cls1--fTiAA { /*!*/ }\n\n.Choice-SliderWrap-module--cls2--myHCV {\n  width: calc(100% + 72px);\n  padding-left: 68px;\n  padding-right: 68px;\n  overflow: hidden;\n  margin-top: 30px;\n  padding-top: 2px;\n}", "",{"version":3,"sources":["webpack://./src/views/page/generator/sections/Choice-SliderWrap.module.scss"],"names":[],"mappings":"AAAA,yCAAA,IAAA,EAAA;;AACA;EAGA,wBAAA;EACA,kBAAA;EACA,mBAAA;EACA,gBAAA;EACA,gBAAA;EACA,gBAAA;AACA","sourcesContent":[".cls1 { /*!*/ }\n.cls2 {\ncomposes: cls1;\n\nwidth: calc(100% + 72px);\npadding-left: 68px;\npadding-right: 68px;\noverflow: hidden;\nmargin-top: 30px;\npadding-top: 2px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"cls1": "Choice-SliderWrap-module--cls1--fTiAA",
	"cls2": "Choice-SliderWrap-module--cls2--myHCV Choice-SliderWrap-module--cls1--fTiAA"
};
export default ___CSS_LOADER_EXPORT___;
