// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Generator-NameCompany-module--cls1--ukdXd { /*!*/ }\n\n.Generator-NameCompany-module--cls2--FofDl {\n  margin-top: 22px;\n  margin-bottom: auto;\n}", "",{"version":3,"sources":["webpack://./src/views/page/generator/Generator-NameCompany.module.scss"],"names":[],"mappings":"AAAA,6CAAA,IAAA,EAAA;;AACA;EAGA,gBAAA;EACA,mBAAA;AACA","sourcesContent":[".cls1 { /*!*/ }\n.cls2 {\ncomposes: cls1;\n\nmargin-top: 22px;\nmargin-bottom: auto;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"cls1": "Generator-NameCompany-module--cls1--ukdXd",
	"cls2": "Generator-NameCompany-module--cls2--FofDl Generator-NameCompany-module--cls1--ukdXd"
};
export default ___CSS_LOADER_EXPORT___;
