// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Loader-Core-module--cls1--DezEo { /*!*/ }\n\n.Loader-Core-module--cls2--gIHUy {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n}\n.Loader-Core-module--cls2--gIHUy.Loader-Core-module--variant-overlay--BoPwa {\n  position: absolute;\n  top: 0;\n  left: 0;\n  width: 100%;\n  height: 100%;\n}", "",{"version":3,"sources":["webpack://./src/components/ui/Loader-Core.module.scss"],"names":[],"mappings":"AAAA,mCAAA,IAAA,EAAA;;AACA;EAGA,aAAA;EACA,mBAAA;EACA,uBAAA;AACA;AACA;EACA,kBAAA;EACA,MAAA;EACA,OAAA;EACA,WAAA;EACA,YAAA;AACA","sourcesContent":[".cls1 { /*!*/ }\n.cls2 {\ncomposes: cls1;\n\ndisplay: flex;\nalign-items: center;\njustify-content: center;\n\n&.variant-overlay {\nposition: absolute;\ntop: 0;\nleft: 0;\nwidth: 100%;\nheight: 100%;\n}\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"cls1": "Loader-Core-module--cls1--DezEo",
	"cls2": "Loader-Core-module--cls2--gIHUy Loader-Core-module--cls1--DezEo",
	"variant-overlay": "Loader-Core-module--variant-overlay--BoPwa"
};
export default ___CSS_LOADER_EXPORT___;
