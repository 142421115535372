// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".SpinnerCircle-Core-module--cls1--ktu7L { /*!*/ }\n\n.SpinnerCircle-Core-module--cls2--gKu3O {\n  width: 38px;\n  height: 38px;\n  border-radius: 38px;\n  stroke: var(--color-base);\n  animation: SpinnerCircle-Core-module--rotate--ToLjx 0.9s infinite linear;\n  animation-delay: 0.3s;\n  stroke-width: 5px;\n  stroke-linecap: round;\n  transform: rotate(0);\n}\n@keyframes SpinnerCircle-Core-module--rotate--ToLjx {\n  from {\n    transform: rotate(0);\n  }\n  to {\n    transform: rotate(360deg);\n  }\n}", "",{"version":3,"sources":["webpack://./src/components/ui/SpinnerCircle-Core.module.scss"],"names":[],"mappings":"AAAA,0CAAA,IAAA,EAAA;;AACA;EAGA,WAAA;EACA,YAAA;EACA,mBAAA;EACA,yBAAA;EACA,wEAAA;EACA,qBAAA;EACA,iBAAA;EACA,qBAAA;EACA,oBAAA;AACA;AACA;EACA;IACA,oBAAA;EACE;EACF;IACA,yBAAA;EACE;AACF","sourcesContent":[".cls1 { /*!*/ }\n.cls2 {\ncomposes: cls1;\n\nwidth: 38px;\nheight: 38px;\nborder-radius: 38px;\nstroke: var(--color-base);\nanimation: rotate 0.9s infinite linear;\nanimation-delay: 0.3s;\nstroke-width: 5px;\nstroke-linecap: round;\ntransform: rotate(0);\n\n@keyframes rotate {\nfrom {\ntransform: rotate(0);\n}\nto {\ntransform: rotate(360deg);\n}\n}\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"cls1": "SpinnerCircle-Core-module--cls1--ktu7L",
	"cls2": "SpinnerCircle-Core-module--cls2--gKu3O SpinnerCircle-Core-module--cls1--ktu7L",
	"rotate": "SpinnerCircle-Core-module--rotate--ToLjx"
};
export default ___CSS_LOADER_EXPORT___;
