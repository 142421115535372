import React, { FC, useState } from 'react'
import styled from 'astroturf/react'
import { Cover } from './Cover'
import { Play } from '@/components/ui/icons'

export interface VideoProps {
  className?: string
  coverSize: { w: number; h: number }
  id: string
  autoplay?: 0 | 1
}

export const Video: FC<VideoProps> = ({ className, coverSize, id, autoplay = 1 }) => {
  const [isPlay, setIsPlay] = useState(false)

  return (
    <Core className={className}>
      <Cover width={coverSize.w} height={coverSize.h} />
      <Action onClick={() => setIsPlay(true)}>
        <Play />
      </Action>
      {isPlay && (
        <Iframe
          src={`https://www.youtube.com/embed/${id}?autoplay=${autoplay}`}
          frameBorder={0}
          allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
        />
      )}
    </Core>
  )
}

const Core = styled.div`
  border-radius: var(--size-radius);
  background-color: var(--color-bg-filed);
  position: relative;
`

const Action = styled.button`
  @import '../../assets/styles/mixins.scss';
  @include anim(background-size, background-position);
  width: 56px;
  height: 56px;
  border-radius: 56px;
  background-image: var(--color-bg-acc-gradient);
  background-size: 200% 200%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  fill: #fff;

  svg {
    display: block;
    margin-left: auto;
    margin-right: auto;
  }

  &:hover {
    background-position: 100% 0;
  }
`

const Iframe = styled.iframe`
  border-radius: var(--size-radius);
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
`
