import React, { FC } from 'react'
import styled from 'astroturf/react'

export interface SpinnerDotsProps {
  className?: string
}
export const SpinnerDots: FC<SpinnerDotsProps> = ({ className }) => {
  return (
    <Core className={className}>
      <div></div>
    </Core>
  )
}

const Core = styled.div`
  width: 24px;
  height: 6px;
  border-radius: 6px;
  position: relative;
  display: flex;
  justify-content: space-between;

  &::before,
  &::after {
    content: '';
  }
  &::before,
  &::after,
  div {
    width: 6px;
    height: 6px;
    border-radius: 6px;
    display: inline-block;
    animation: dot-flashing 0.9s infinite ease-in-out;
    background-color: var(--color-base);
  }

  div {
    animation-delay: 0.3s;
  }

  &::before {
    animation-delay: 0s;
  }

  &::after {
    animation-delay: 0.6s;
  }

  @keyframes dot-flashing {
    0% {
      opacity: 1;
    }
    50% {
      opacity: 0.2;
    }
    100% {
      opacity: 1;
    }
  }
`
