// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".TextureFromImageDialog-Body-module--cls1--VZkfc { /*!*/ }\n\n.TextureFromImageDialog-Body-module--cls2--FKjsU {\n  font-family: sans-serif;\n  align: center;\n  border: 2px solid gray;\n  color: black;\n  background-color: var(--color-bg-dark);\n  border-radius: var(--size-radius);\n  padding: 20px;\n}", "",{"version":3,"sources":["webpack://./src/views/page/generator/sections/Editor/components/TextureFromImageDialog-Body.module.scss"],"names":[],"mappings":"AAAA,mDAAA,IAAA,EAAA;;AACA;EAGA,uBAAA;EACA,aAAA;EACA,sBAAA;EACA,YAAA;EACA,sCAAA;EACA,iCAAA;EACA,aAAA;AACA","sourcesContent":[".cls1 { /*!*/ }\n.cls2 {\ncomposes: cls1;\n\nfont-family: sans-serif;\nalign: center;\nborder: 2px solid gray;\ncolor: rgba(#000, 1);\nbackground-color: var(--color-bg-dark);\nborder-radius: var(--size-radius);\npadding: 20px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"cls1": "TextureFromImageDialog-Body-module--cls1--VZkfc",
	"cls2": "TextureFromImageDialog-Body-module--cls2--FKjsU TextureFromImageDialog-Body-module--cls1--VZkfc"
};
export default ___CSS_LOADER_EXPORT___;
