import React, { FC } from 'react'
import { Box, WrapBox, RangeSlider } from '@/views/page/generator/sections/components'
import { observer } from 'mobx-react-lite'
import { storeEditor } from '@/services/store'
import { useLayoutContext } from '@/views/components/core/LayoutProvider'
import { cameraPositions } from '../cameraPositions'

export const EditorBody: FC = observer(() => {
  const versionUI = storeEditor.versionUI  // to rerender after observable versionUI increment

  const { body, setBody } = storeEditor
  const { mediaQueries } = useLayoutContext()
  const variant = mediaQueries.isTablet ? 'not-mobile' : 'mobile'
  //console.log("EditorBody()")
  //console.log("  body:", body)

  //storeEditor.viewer.changePosition('HEAD')

  const ageScales = storeEditor.scalesForAge(storeEditor.age)

  const minHeight = 140 * ageScales.scale
  const maxHeight = 210 * ageScales.scale

  //console.log(`minHeight: ${minHeight}, maxHeight: ${maxHeight}`)

  function setHeight(height) {
    setBody({ height });
    storeEditor.changePosition(cameraPositions('BODY', mediaQueries.isTablet))
  }

  return (
    <div style={mediaQueries.isTablet===false ? { } : { height: '75%', overflowY: 'scroll' }}> 
    <WrapBox height={15}>
      <Box title={'Height'}>
        <RangeSlider
          min={minHeight}
          max={maxHeight}
          step={2}
          values={body.height}
          setValue={setHeight}
        />
      </Box>
      <Box title={'Shoulders'}>
        <RangeSlider
          max={10}
          min={0}
          step={1}
          values={body.shoulders}
          setValue={(shoulders) => setBody({ shoulders })}
        />
      </Box>
      <Box title={'Forearms'}>
        <RangeSlider max={10} min={0} step={1} values={body.forearms} setValue={(forearms) => setBody({ forearms })} />
      </Box>
      <Box title={'Сhest'}>
        <RangeSlider max={10} min={0} step={1} values={body.chest} setValue={(chest) => setBody({ chest })} />
      </Box>
      <Box title={'Waist'}>
        <RangeSlider max={10} min={0} step={1} values={body.waist} setValue={(waist) => setBody({ waist })} />
      </Box>
      <Box title={'Hips'}>
        <RangeSlider max={10} min={0} step={1} values={body.hips} setValue={(hips) => setBody({ hips })} />
      </Box>
      <Box title={'Legs'}>
        <RangeSlider max={10} min={0} step={1} values={body.legs} setValue={(legs) => setBody({ legs })} />
      </Box>
    </WrapBox>
    </div>
  )
})

EditorBody.displayName = 'EditorBody'
