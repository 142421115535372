// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Login-MiddleContent-module--cls1--WndFM { /*!*/ }\n\n.Login-MiddleContent-module--cls2--e59CJ {\n  margin-top: auto;\n  margin-bottom: auto;\n  max-width: 360px;\n  width: 100%;\n}", "",{"version":3,"sources":["webpack://./src/views/page/auth/Login-MiddleContent.module.scss"],"names":[],"mappings":"AAAA,2CAAA,IAAA,EAAA;;AACA;EAGA,gBAAA;EACA,mBAAA;EACA,gBAAA;EACA,WAAA;AACA","sourcesContent":[".cls1 { /*!*/ }\n.cls2 {\ncomposes: cls1;\n\nmargin-top: auto;\nmargin-bottom: auto;\nmax-width: 360px;\nwidth: 100%;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"cls1": "Login-MiddleContent-module--cls1--WndFM",
	"cls2": "Login-MiddleContent-module--cls2--e59CJ Login-MiddleContent-module--cls1--WndFM"
};
export default ___CSS_LOADER_EXPORT___;
