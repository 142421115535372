// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Generator-DocsButton-module--cls1--u8ATU { /*!*/ }\n\n.Generator-DocsButton-module--cls2--r0yGm {\n  position: absolute;\n  right: 32px;\n  bottom: 40px;\n}", "",{"version":3,"sources":["webpack://./src/views/page/generator/Generator-DocsButton.module.scss"],"names":[],"mappings":"AAAA,4CAAA,IAAA,EAAA;;AACA;EAGA,kBAAA;EACA,WAAA;EACA,YAAA;AACA","sourcesContent":[".cls1 { /*!*/ }\n.cls2 {\ncomposes: cls1;\n\nposition: absolute;\nright: 32px;\nbottom: 40px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"cls1": "Generator-DocsButton-module--cls1--u8ATU",
	"cls2": "Generator-DocsButton-module--cls2--r0yGm Generator-DocsButton-module--cls1--u8ATU"
};
export default ___CSS_LOADER_EXPORT___;
