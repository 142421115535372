import { makeAutoObservable } from 'mobx'
import { request, polingQRFile, polingUploadFile } from '@/services/adapters/req'

class PollingQR {
  codeQR: string | null = null
  isWaitQR = false
  isWaitFile = false
  uploadFile: null | string = null

  constructor() {
    makeAutoObservable(this)
  }

  startPollingScan = (codeQR: string) => {
    this.isWaitQR = true
    setTimeout(() => {
      this.pollingScan(codeQR)
    }, 20)
  }

  cancelPolling = () => {
    this.isWaitQR = false
    this.isWaitFile = false
    this.uploadFile = null
  }

  pollingScan = (codeQR: string) => {
    this.codeQR = codeQR
    this.uploadFile = null
    setTimeout(async () => {
      if (this.isWaitQR) {
        const { upload_page_visited } = await request(polingQRFile.endpoint({ qrToken: codeQR }), polingQRFile)
        if (!upload_page_visited) {
          if (this.isWaitQR) {
            this.pollingScan(codeQR)
          }
        } else {
          this.isWaitQR = false
          this.isWaitFile = true
          this.pollingFile(codeQR)
        }
      }
    }, 2000)
  }

  pollingFile = async (codeQR: string) => {
    this.codeQR = codeQR
    setTimeout(async () => {
      if (this.isWaitFile) {
        const uploadFile = await request(polingUploadFile.endpoint({ qrToken: codeQR }), polingUploadFile)
        if (!uploadFile) {
          if (this.isWaitFile) {
            this.pollingFile(codeQR)
          }
        } else {
          this.isWaitFile = false
          this.uploadFile = uploadFile
        }
      }
    }, 2000)
  }
}

const storePollingQR = new PollingQR()

export { storePollingQR }
