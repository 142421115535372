// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".EditorShirt-Progress-module--cls1--_IGjM { /*!*/ }\n\n.EditorShirt-Progress-module--cls2--Mz8a0 {\n  text-align: center;\n  margin-bottom: 12px;\n  color: var(--color-base);\n  margin-top: 12px;\n}", "",{"version":3,"sources":["webpack://./src/views/page/generator/sections/Editor/components/EditorShirt-Progress.module.scss"],"names":[],"mappings":"AAAA,4CAAA,IAAA,EAAA;;AACA;EAGA,kBAAA;EACA,mBAAA;EACA,wBAAA;EACA,gBAAA;AACA","sourcesContent":[".cls1 { /*!*/ }\n.cls2 {\ncomposes: cls1;\n\ntext-align: center;\nmargin-bottom: 12px;\ncolor: var(--color-base);\nmargin-top: 12px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"cls1": "EditorShirt-Progress-module--cls1--_IGjM",
	"cls2": "EditorShirt-Progress-module--cls2--Mz8a0 EditorShirt-Progress-module--cls1--_IGjM"
};
export default ___CSS_LOADER_EXPORT___;
